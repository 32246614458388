import { createContext } from 'react';
import { Status } from '../../../types/SubmitDataTypes';
import { CleaningDataPayload, CleaningDataPayloadRecurring } from '../../../types/CleaningDataPayload';
import { InitialBookingState } from '../../../types/StateTypes';
import { CreateEventBody } from '../../../types/CalendarEvents';

export interface ISubmitDataContextData {
  status: Status;
  data: CleaningDataPayload | CleaningDataPayloadRecurring | undefined;
  resetStateAfterSubmit: () => void;
  submitDataAttempt: (
    currentState: InitialBookingState,
    key: string,
    eventBody: CreateEventBody,
    paymentMethodId: string,
    isBookWithKey: boolean
  ) => Promise<string>;
}

export const defaultValues: ISubmitDataContextData = {
  status: Status.unset,
  data: undefined,
  resetStateAfterSubmit: () => null,
  submitDataAttempt: () => Promise.resolve(''),
};

export const SubmitDataContext = createContext<ISubmitDataContextData>(defaultValues);
