import { Slider, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { Field } from '../../../Components';
import styles from './SliderInput.module.scss';

interface SliderInputProps {
  title: string;
  selectedValue: string;
  selectedValueNumber: number;
  onChange: (e: Event, newValue: number | number[]) => void;
  max: number;
  min: number;
  stepIncrement: number;
  maxDescription?: string;
  minDescription?: string;
  midMarkText?: string;
  ariaLabel: string;
}

const SliderInput: FunctionComponent<SliderInputProps> = ({
  selectedValueNumber,
  title,
  selectedValue,
  onChange,
  max,
  min,
  stepIncrement,
  maxDescription,
  minDescription,
  ariaLabel,
  midMarkText,
}: SliderInputProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.titleAndValue}>
        <Typography className={styles.title}>{title}</Typography>
        <Field disabled value={selectedValue} />
      </div>
      <div className={styles.slider}>
        <Typography className={styles.minMax} variant="body1">
          {min}
        </Typography>
        <Slider
          aria-label={ariaLabel}
          value={selectedValueNumber}
          valueLabelDisplay="auto"
          min={min}
          max={max}
          step={stepIncrement}
          onChange={onChange}
        />
        <Typography className={styles.minMax} variant="body1">
          {max}+
        </Typography>
      </div>
      {maxDescription && minDescription && (
        <div className={styles.descriptionContainer}>
          <Typography className={styles.descriptions}>{minDescription}</Typography>
          {midMarkText && <Typography className={styles.descriptions}>{midMarkText}</Typography>}
          <Typography className={styles.descriptions}>{maxDescription}</Typography>
        </div>
      )}
    </div>
  );
};

export default SliderInput;
