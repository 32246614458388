import { FunctionComponent, useEffect } from 'react';
import styles from './EmbeddedReviews.module.scss';
import { shouldAutoPlayReviews } from '../../../../../Config';
import Titles from '../../Titles/Titles';

declare global {
  interface Window {
    SS_EMBED_REVIEW_COUNT: number;
    SS_ACCOUNT_ID: number;
    SS_RATING_GROUP: number;
    SparrowLauncher: unknown;
  }
}

const EmbeddedReviews: FunctionComponent = () => {
  const base = `https://cheetahcleaners.surveysparrow.com/api/internal/reputation-manager-embed?no_of_reviews=5&rating_group=4&account_id=295307&backgroundColor=%23fff2d5&textColor=%23030303&backgroundBodyColor=%23fff&autoplay=${shouldAutoPlayReviews}`;

  const reviewScript = () => {
    const e = 'ss-widget',
      t = 'script',
      a = document,
      r: Window = window;
    let s: HTMLCollectionOf<HTMLScriptElement>, n: HTMLScriptElement, c: HTMLScriptElement;
    r.SS_EMBED_REVIEW_COUNT = 5;
    r.SS_ACCOUNT_ID = 1;
    r.SS_RATING_GROUP = 4;
    if (!a.getElementById(e)) {
      const S = function () {
        // eslint-disable-next-line prefer-rest-params
        S.update(arguments);
      };
      S.args = [];
      S.update = function (e) {
        S.args.push(e);
      };
      r.SparrowLauncher = S;
      s = a.getElementsByTagName(t);
      c = s[s.length - 1];
      n = a.createElement(t);
      n.type = 'text/javascript';
      n.async = !0;
      n.id = e;
      n.src = base;
      if (c?.parentNode) {
        c.parentNode.insertBefore(n, c);
      }
    }
  };

  useEffect(() => {
    reviewScript();
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.titles}>
        <Titles title1="TESTIMONIALS" title2="What Our Customers Say" />
      </div>
      <div id="ss_survey_widget" className={styles.embeddedReviews}>
        <iframe title="Customer Reviews" width="100%" height="500px" className={styles.reviewsFrame} src={base} />
      </div>
    </div>
  );
};

export default EmbeddedReviews;
