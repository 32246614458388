import { makeAutoObservable } from 'mobx';
import { cleaningFieldsInfo } from '../../Assets/Data/BookingInformation';
import { CleaningFrequency, CleaningType, SelectedBookingInfoItems } from '../../types/BookingFieldsEnum';
import {
  BookingTypeOptions,
  CleaningTypeOptions,
  CleaningFrequencyOptions,
  SquareFootageOptions,
  HowCleanIsHomeOptions,
} from '../../types/BookingFormDataTypes';
import { SelectedBookingInfo } from '../../types/StateTypes';
import {
  getStripeInfoForSelectedOption,
  initialSelected,
  updateAllSelectedCleaningData,
} from '../../Utils/StripeDataUtils';
import { Status } from '../../types/SubmitDataTypes';
import { SelectedCleaning } from '../../types/CleaningDataPayload';
import { stripeData } from '../../Assets/Data/StripeData';

class SelectedOptionsManager {
  private selectedBookingInfo: SelectedBookingInfo;
  private changeAllStatus = Status.unset;

  constructor() {
    this.selectedBookingInfo = initialSelected;
    makeAutoObservable(this);
  }

  public isSelectedCleanRecurring = () => {
    return this.selectedBookingInfo.cleaningType.isRecurring;
  };

  public getSelected = () => {
    return this.selectedBookingInfo;
  };

  public getChangeAllStatus = () => {
    return this.changeAllStatus;
  };

  public resetState = () => {
    this.changeAllStatus = Status.inProgress;
    this.selectedBookingInfo = initialSelected;
    this.changeAllStatus = Status.unset;
  };

  public updateSelectedAll = (selected: SelectedBookingInfo) => {
    this.changeAllStatus = Status.inProgress;
    this.selectedBookingInfo = updateAllSelectedCleaningData(stripeData.cleanings, selected);
    this.changeAllStatus = Status.success;
  };

  public updateSelected = (selectedOption: BookingTypeOptions, key: string) => {
    const stripeInfo = getStripeInfoForSelectedOption(
      stripeData.cleanings,
      this.selectedBookingInfo.cleaningType.isRecurring,
      selectedOption,
      this.selectedBookingInfo.squareFootage,
      this.selectedBookingInfo.cleaningFrequency.key,
      key
    );

    const newSelected: SelectedBookingInfo = {
      ...this.selectedBookingInfo,
      [key]: {
        ...selectedOption,
        stripeInfo,
      },
    };

    this.selectedBookingInfo = newSelected;
  };

  public updateSelectedHowCleanIsHomeOption = (selectedOption: HowCleanIsHomeOptions) => {
    this.selectedBookingInfo.howCleanIsHome = selectedOption;
  };

  public updateSelectedSqf = (incomingUpdate: SquareFootageOptions) => {
    const selectedFields: SelectedBookingInfo = {
      ...this.selectedBookingInfo,
      [SelectedBookingInfoItems.squareFootage]: incomingUpdate,
    };
    this.selectedBookingInfo = updateAllSelectedCleaningData(stripeData.cleanings, selectedFields);
  };

  public setData = (newDataKeys: SelectedCleaning) => {
    const cleningTypeEnum = newDataKeys[SelectedBookingInfoItems.cleaningType] as CleaningType;
    const cleaningFreqEnum = newDataKeys[SelectedBookingInfoItems.cleaningFrequency] as CleaningFrequency;
    const cleaningType =
      cleaningFieldsInfo.cleaningType.options.find((i) => i.key === cleningTypeEnum) ||
      cleaningFieldsInfo.cleaningType.options[0];

    const sqfMainOption = newDataKeys[SelectedBookingInfoItems.squareFootage];
    const sqfNumber = Number(sqfMainOption);
    const sqfOption: SquareFootageOptions = !isNaN(sqfNumber)
      ? { key: `${sqfNumber}`, value: 0, numberKey: sqfNumber }
      : cleaningFieldsInfo.squareFootage.options[0];

    const selectedFields: SelectedBookingInfo = {
      [SelectedBookingInfoItems.apartmentType]:
        cleaningFieldsInfo.apartmentType.options.find(
          (i) => i.key === newDataKeys[SelectedBookingInfoItems.apartmentType]
        ) || cleaningFieldsInfo.apartmentType.options[0],

      [SelectedBookingInfoItems.bathrooms]:
        cleaningFieldsInfo.bathrooms.options.find((i) => i.key === newDataKeys[SelectedBookingInfoItems.bathrooms]) ||
        cleaningFieldsInfo.bathrooms.options[0],

      [SelectedBookingInfoItems.halfBathrooms]:
        cleaningFieldsInfo.halfBathrooms.options.find(
          (i) => i.key === newDataKeys[SelectedBookingInfoItems.halfBathrooms]
        ) || cleaningFieldsInfo.halfBathrooms.options[0],

      [SelectedBookingInfoItems.threeForthBathrooms]:
        cleaningFieldsInfo.threeForthBathrooms.options.find(
          (i) => i.key === newDataKeys[SelectedBookingInfoItems.threeForthBathrooms]
        ) || cleaningFieldsInfo.threeForthBathrooms.options[0],

      [SelectedBookingInfoItems.commonAreas]:
        cleaningFieldsInfo.commonAreas.options.find(
          (i) => i.key === newDataKeys[SelectedBookingInfoItems.commonAreas]
        ) || cleaningFieldsInfo.commonAreas.options[0],

      [SelectedBookingInfoItems.staircase]:
        cleaningFieldsInfo.staircase.options.find((i) => i.key === newDataKeys[SelectedBookingInfoItems.staircase]) ||
        cleaningFieldsInfo.staircase.options[0],

      [SelectedBookingInfoItems.squareFootage]: sqfOption,
      [SelectedBookingInfoItems.cleaningType]: cleaningType,

      [SelectedBookingInfoItems.cleaningFrequency]:
        cleaningType.frequency.find((i) => i.key === cleaningFreqEnum) || cleaningType.frequency[0],

      [SelectedBookingInfoItems.howCleanIsHome]:
        cleaningFieldsInfo.howCleanIsHome.options.find(
          (i) => i.value === newDataKeys[SelectedBookingInfoItems.howCleanIsHome]
        ) || cleaningFieldsInfo.howCleanIsHome.options[0],

      [SelectedBookingInfoItems.extraKitchen]:
        cleaningFieldsInfo.extraKitchen.options.find(
          (i) => i.value === newDataKeys[SelectedBookingInfoItems.extraKitchen]
        ) || cleaningFieldsInfo.extraKitchen.options[0],

      [SelectedBookingInfoItems.kitchenLivingRoomDining]:
        cleaningFieldsInfo.kitchenLivingRoomDining.options.find(
          (i) => i.key === newDataKeys[SelectedBookingInfoItems.kitchenLivingRoomDining]
        ) || cleaningFieldsInfo.kitchenLivingRoomDining.options[0],
    };

    const newData = updateAllSelectedCleaningData(stripeData.cleanings, selectedFields);
    this.selectedBookingInfo = newData;

    return newData;
  };

  public updateSelectedcleaningType = (incomingUpdateGroup: CleaningTypeOptions) => {
    const updateCleaningFreq = incomingUpdateGroup.frequency[0];

    const selectedFields: SelectedBookingInfo = {
      ...this.selectedBookingInfo,
      [SelectedBookingInfoItems.cleaningType]: incomingUpdateGroup,
      [SelectedBookingInfoItems.cleaningFrequency]: updateCleaningFreq,
    };

    this.selectedBookingInfo = updateAllSelectedCleaningData(stripeData.cleanings, selectedFields);
  };

  public updateSelectedCleaningFreq = (incomingUpdate: CleaningFrequencyOptions) => {
    const selectedFields: SelectedBookingInfo = {
      ...this.selectedBookingInfo,
      [SelectedBookingInfoItems.cleaningFrequency]: incomingUpdate,
    };
    this.selectedBookingInfo = updateAllSelectedCleaningData(stripeData.cleanings, selectedFields);
  };

  public selectCurrentSelectionsNoCity = () => {
    const booked: string[] = [];
    booked.push(this.selectedBookingInfo.apartmentType.key);
    booked.push(this.selectedBookingInfo.bathrooms.key);
    booked.push(this.selectedBookingInfo.halfBathrooms.key);
    booked.push(this.selectedBookingInfo.threeForthBathrooms.key);
    booked.push(`${this.selectedBookingInfo.squareFootage.key} ${cleaningFieldsInfo.squareFootage.postFix || ''}`);
    booked.push(this.selectedBookingInfo.cleaningType.key);

    return booked;
  };
}

export default new SelectedOptionsManager();
