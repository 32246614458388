import { FunctionComponent } from 'react';
import { imageSourceBaseUrl } from '../../../Config';
import styles from './ImageComponent.module.scss';

interface ImageComponentProps {
  source: string;
  path: string;
  altText: string;
  heightNum?: number;
  widthNum?: number;
  loading?: 'eager' | 'lazy';
  isHighPriority?: boolean;
  className?: string;
  onImageLoad?: () => void;
}

// fetchPriority is not added yet. Monitor this https://github.com/DefinitelyTyped/DefinitelyTyped/pull/63178

const ImageComponent: FunctionComponent<ImageComponentProps> = ({
  source,
  altText,
  heightNum,
  widthNum,
  loading,
  className,
  path,
  isHighPriority,
  onImageLoad,
}: ImageComponentProps) => {
  return (
    <>
      {/* {isHighPriority && <link rel="preload" as="image" href={`${imageSourceBaseUrl}/${path}`} type="image/webp" />} */}
      <img
        onLoad={(evt) => {
          const target = evt.target as HTMLImageElement;
          target.classList.remove(styles.hidden);
          onImageLoad && onImageLoad();
        }}
        // fetchpriority={isHighPriority ? 'high' : 'auto'}
        loading={loading ?? 'eager'}
        src={path && imageSourceBaseUrl ? `${imageSourceBaseUrl}/${path}` : source}
        alt={altText}
        height={heightNum}
        width={widthNum}
        className={`${isHighPriority ? '' : `${styles.imgComponent} ${styles.hidden}`} ${className ? className : ''}`}
      />
    </>
  );
};

export default ImageComponent;
