import { Grid, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { posts } from './BlogPosts';
import styles from './BlogPost.module.scss';
import { ImageComponent } from '../../Components';

const BlogPost: FunctionComponent = () => {
  const { postId } = useParams();

  const postData = postId ? posts[postId] : undefined;

  if (!postData) {
    return null;
  }

  return (
    <Grid container justifyContent="center" alignItems="center" className={styles.root}>
      <Grid
        container
        justifyContent="center"
        direction="column"
        className={styles.container}
        item
        xs={11}
        sm={11}
        md={9}
        lg={9}
        xl={8}>
        <Typography variant="h1" className={styles.title}>
          {postData.title}
        </Typography>
        <div className={styles.imgContainer}>
          <ImageComponent source={postData.image || ''} path={postData.imgSrc} altText="post image" />
        </div>
        {postData.content}
      </Grid>
    </Grid>
  );
};

export default BlogPost;
