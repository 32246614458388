import { FunctionComponent } from 'react';
import styles from './LogoButton.module.scss';
import AnalyticsManager from '../../../../Utils/AnalyticsManager';
import { useLocation, useNavigate } from 'react-router-dom';
import { ImageComponent } from '../../../Components';

interface LogoButtonProps {
  disabled?: boolean;
}

const LogoButton: FunctionComponent<LogoButtonProps> = ({ disabled = false }: LogoButtonProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToPage = (path: string) => {
    if (path !== location.pathname) {
      navigate(path);
    }
  };

  return (
    <button
      disabled={disabled}
      className={styles.logoContainer}
      onClick={() => {
        AnalyticsManager.logIconButtonClick('Cheetah Cleaners logo');
        navigateToPage('/home');
      }}>
      <ImageComponent isHighPriority source="" path="logo.svg" altText="Cheetah Cleaners" />
    </button>
  );
};

export default LogoButton;
