import { LandingPageInfoData } from '../../types/ContactUsData';

export const landingPageData: LandingPageInfoData = {
  mainTitle: 'Schedule your clean',
  submitButtonText: 'Get a quote online',
  formData: {
    bedrooms: {
      label: 'Number of Bedrooms',
      name: 'bedrooms',
      placeholder: '# OF BEDROOMS',
      rules: { required: true },
      errorMessage: 'Number of bedrooms is required',
    },
    bathrooms: {
      label: 'Number of Bathrooms',
      name: 'bathrooms',
      placeholder: '# OF BATHROOMS',
      rules: { required: true },
      errorMessage: 'Number of bathrooms is required',
    },
    firstName: {
      label: 'Name',
      name: 'firstName',
      placeholder: 'NAME',
      rules: { required: true },
      errorMessage: 'Name is required',
    },
    lastName: {
      label: 'Last Name',
      name: 'lastName',
      placeholder: 'LAST NAME',
      rules: { required: true },
      errorMessage: 'Last Name is required',
    },
    email: {
      label: 'Email',
      name: 'email',
      placeholder: 'EMAIL ADDRESS',
      rules: { required: true },
      errorMessage: 'Email is invalid',
    },
    phone: {
      label: 'Phone',
      name: 'phone',
      placeholder: 'PHONE NUMBER',
      rules: { required: true },
      errorMessage: 'Phone number is invalid',
    },
  },
};
