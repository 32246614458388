import { FunctionComponent } from 'react';
import styles from './Footer.module.scss';
import InstagramIcon from '../../../Assets/Images/svg/instagram.svg';
import FacebookIcon from '../../../Assets/Images/svg/facebook.svg';
import GoogleIcon from '@mui/icons-material/Google';
import AnalyticsManager from '../../../Utils/AnalyticsManager';

const SocialMedia: FunctionComponent = () => {
  const instagram = () => {
    AnalyticsManager.logIconButtonClick('instagram');
  };

  const facebook = () => {
    AnalyticsManager.logIconButtonClick('facebook');
  };

  const google = () => {
    AnalyticsManager.logIconButtonClick('google');
  };

  return (
    <>
      <div className={styles.icons}>
        <a
          href="https://www.facebook.com/Cheetah-Cleaners-108120161388222"
          aria-label="Facebook"
          onClick={facebook}
          target="_blank"
          rel="noreferrer noopener"
          className={styles.socialIcons}>
          <FacebookIcon />
        </a>

        <a
          href="https://www.instagram.com/cheetahcleaner/?hl=en"
          aria-label="Instagram"
          onClick={instagram}
          target="_blank"
          rel="noreferrer noopener"
          className={styles.socialIcons}>
          <InstagramIcon />
        </a>

        <a
          href="https://g.page/cheetahcleaners?share"
          aria-label="Google"
          onClick={google}
          target="_blank"
          rel="noreferrer noopener"
          className={styles.socialIcons}>
          <GoogleIcon />
        </a>
      </div>
    </>
  );
};

export default SocialMedia;
