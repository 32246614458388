import { FunctionComponent, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import styles from './BookingSteps.module.scss';
import { bookingSteps } from '../../../Assets/Data/BookingConstants';
import { Typography } from '@mui/material';
import { InfoIconTooltip } from '../../Components';
import { stepsData } from '../../../Assets/Data/BookingInformation';
import { observer } from 'mobx-react';
import PriceManager from '../../../Mobx/BookingFlowData/PriceManager';
import CleanDateTimeManager from '../../../Mobx/BookingFlowData/CleanDateTimeManager';
import SelectedOptionsManager from '../../../Mobx/BookingFlowData/SelectedOptionsManager';
import AddressFormDataManager from '../../../Mobx/BookingFlowData/AddressFormDataManager';
import CalendarTimesManager from '../../../Mobx/FetchingManagers/CalendarTimesManager';

interface BookingStepsProps {
  handleStep: (n: number) => void;
  completed: boolean[];
}

const displayDateFormat = 'MMMM Do, YYYY';
const defaultDateTimeText = bookingSteps[1];

const BookingSteps: FunctionComponent<BookingStepsProps> = ({ handleStep, completed }: BookingStepsProps) => {
  const availableTimesData = CalendarTimesManager.getAvailableTimes();
  const price = PriceManager.getPrice();
  const selectedTime = CleanDateTimeManager.getTimeSlot();
  const selectedDay: moment.Moment | undefined = CleanDateTimeManager.getDateTime();
  const selected = SelectedOptionsManager.getSelected();
  const address = AddressFormDataManager.getDisplayAddress();
  const addressObj = AddressFormDataManager.getAddressFormValues();
  const time = useMemo(() => {
    if (selectedTime && availableTimesData && availableTimesData.timeSlots[selectedTime]) {
      const title = availableTimesData.timeSlots[selectedTime].title;
      const part1 = title.split('(')[1];
      return part1.split(')')[0] + ' Window';
    }

    return '';
  }, [selectedTime, availableTimesData?.timeSlots]);

  const date = useMemo(() => {
    return selectedDay
      ? time
        ? `${selectedDay?.format(displayDateFormat)},`
        : selectedDay?.format(displayDateFormat)
      : defaultDateTimeText;
  }, [selectedDay, time]);

  return (
    <Grid container className={styles.bookingStepsContainer}>
      {/* Cleaning type */}
      <Grid item sm={3} onClick={() => handleStep(0)} className={styles.box}>
        <div
          className={
            completed[stepsData.cleaning]
              ? `${styles.bookingProgressBar} ${styles.completed}`
              : styles.bookingProgressBar
          }
        />
        <Typography
          variant="body1"
          className={completed[stepsData.cleaning] ? `${styles.st} ${styles.completed}` : styles.st}>
          {selected.cleaningFrequency.isRecurring
            ? `${selected.cleaningFrequency.key} Clean`
            : selected.cleaningType.key}
        </Typography>
      </Grid>
      {/* Date, Time */}
      <Grid item sm={3} onClick={() => handleStep(1)} className={styles.box}>
        <div
          className={
            completed[stepsData.dateTime]
              ? `${styles.bookingProgressBar} ${styles.completed}`
              : styles.bookingProgressBar
          }
        />
        <div className={completed[stepsData.dateTime] ? `${styles.st} ${styles.completed}` : styles.st}>
          <Typography variant="body1">{date}</Typography>
          {time && <Typography variant="body1">at {time}</Typography>}
        </div>
      </Grid>
      {/* Address */}
      <Grid item sm={3} onClick={() => handleStep(2)} className={styles.box}>
        <div
          className={
            completed[stepsData.address]
              ? `${styles.bookingProgressBar} ${styles.completed}`
              : styles.bookingProgressBar
          }
        />
        <div className={completed[stepsData.address] ? `${styles.st} ${styles.completed}` : styles.st}>
          <Typography variant="body1">{address}</Typography>
          {addressObj.city && (
            <Typography variant="body1">{`${addressObj.city} ${addressObj.state} ${addressObj.zip}`}</Typography>
          )}
        </div>
      </Grid>
      {/* Price */}
      <Grid item sm={3} className={styles.box}>
        <div
          className={
            completed[stepsData.payment]
              ? `${styles.bookingProgressBar} ${styles.completed}`
              : styles.bookingProgressBar
          }></div>
        <div className={`${styles.st} ${styles.price}`}>
          {price.oneTime.servicePrice > 0 ? (
            <>
              <Typography variant="h4">${price.oneTime.serviceAndAddons}</Typography>
              {selected?.cleaningFrequency?.isRecurring && (
                <div className={styles.recurringPrice}>
                  <Typography
                    variant="body1"
                    className={styles.afterFirstCleanPriceText}>{`After First Clean: `}</Typography>
                  <div className={styles.priceAndInfo}>
                    <Typography
                      variant="body1"
                      className={styles.afterFirstCleanPrice}>{`$${price.recurring.serviceAndAddons}`}</Typography>
                    <InfoIconTooltip text={`This price doesn't include tax.`} />
                  </div>
                </div>
              )}
            </>
          ) : (
            <Typography variant="h4">Contact for pricing</Typography>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default observer(BookingSteps);
