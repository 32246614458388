import { makeAutoObservable } from 'mobx';
import { Moment } from 'moment-timezone';
import { eventsKeyFormat, timeSlotFormat } from '../../Config';
import { ListEventsResponse } from '../../types/CalendarEvents';
import { Status } from '../../types/SubmitDataTypes';
import { getAllEvents } from '../../Utils/ApiCalls/Events';
import { LogHttpCallError } from '../../Utils/ErrorLog';
import { getDateWithFormat, getTomorrowsDateTime } from '../../Utils/CalendarUtils';

const timeSlotsDefault: Record<string, { title: string; eventDuration: number; arrivalRange: number }> = {
  '8:00 AM': { title: 'Morning (8 AM Arrival)', eventDuration: 2, arrivalRange: 0 },
  '11:00 AM': { title: 'Afternoon (11 AM - 2 PM Arrival)', eventDuration: 3, arrivalRange: 3 },
};

class CalendarTimesManager {
  private availableTimesData: ListEventsResponse | undefined = undefined;
  private status: Status = Status.unset;

  constructor() {
    makeAutoObservable(this);
  }

  public getStatus = () => {
    return this.status;
  };

  public fetchAvailableTimes = async (): Promise<void> => {
    if (this.status === Status.inProgress) {
      return;
    }

    this.setStatus(Status.inProgress);

    const response = await this.getEvents();
    if (response) {
      const filtered = this.removeTomorrowIfAfterDeadline(response);
      this.setAvailableTimes(filtered);
      this.setStatus(Status.success);
    } else {
      this.setAvailableTimes({ events: {}, timeSlots: timeSlotsDefault });
      this.setStatus(Status.error);
    }
  };

  public getAvailableTimes = () => {
    return this.availableTimesData;
  };

  public getTextForTimeSlot = (timeSlot: string) => {
    if (timeSlot && this.availableTimesData?.timeSlots && this.availableTimesData.timeSlots[timeSlot]) {
      const arrivalRange = this.availableTimesData.timeSlots[timeSlot].arrivalRange;
      if (arrivalRange > 0) {
        return getDateWithFormat(timeSlot, timeSlotFormat).add(arrivalRange, 'h').format(timeSlotFormat);
      }
    }

    return '';
  };

  public isDateTimeAvailable = (dateTimeToCheck: Moment | undefined) => {
    if (!dateTimeToCheck) {
      return true;
    }

    const date = dateTimeToCheck.format(eventsKeyFormat);
    const timeSlot = dateTimeToCheck.format(timeSlotFormat);

    const timeSlotsTakenForEvents = this.availableTimesData?.events[date];
    const isTimeSlotTakenForEvents = timeSlotsTakenForEvents ? timeSlotsTakenForEvents[timeSlot] : false;

    if (isTimeSlotTakenForEvents) {
      return false;
    }

    const timeSlotsTakenForRecurringEvents = this.availableTimesData?.eventsRecurring
      ? this.availableTimesData.eventsRecurring[date]
      : undefined;
    const isTimeSlotTakenForRecurringEvents = timeSlotsTakenForRecurringEvents
      ? timeSlotsTakenForRecurringEvents[timeSlot]
      : false;
    if (isTimeSlotTakenForRecurringEvents) {
      return false;
    }

    return true;
  };

  private removeTomorrowIfAfterDeadline = (data: ListEventsResponse) => {
    if (!data.timeSlots) {
      return data;
    }

    if (!data.events && !data.eventsRecurring) {
      return data;
    }

    const today = new Date().getTime();
    const deadline = new Date().setHours(17, 0, 0);
    if (today >= deadline) {
      const keyToRemove = getTomorrowsDateTime();
      const slots = Object.keys(data.timeSlots);
      const newValue: Record<string, boolean> = {};
      slots.forEach((s) => (newValue[s] = true));
      if (data.events) {
        data.events[keyToRemove] = newValue;
      }

      if (data.eventsRecurring) {
        data.eventsRecurring[keyToRemove] = newValue;
      }
    }

    return data;
  };

  private setAvailableTimes = (availableTimesData: ListEventsResponse) => {
    this.availableTimesData = availableTimesData;
  };

  private setStatus = (status: Status) => {
    this.status = status;
  };

  private getEvents = async () => {
    try {
      return await getAllEvents();
    } catch (e) {
      LogHttpCallError('getAllEvents', e, true);
      return null;
    }
  };
}

export default new CalendarTimesManager();
