import { FunctionComponent } from 'react';
import moment from 'moment-timezone';
import styles from './CalendarWeekdaysBar.module.scss';
import { Typography } from '@mui/material';

const CalendarWeekdaysBar: FunctionComponent = () => {
  const weekdays = moment.weekdaysShort();
  return (
    <div className={styles.calendarWeekdaysBarContainer}>
      {weekdays.map((label) => (
        <div key={label} className={styles.weekdayCellContainer}>
          <Typography className={styles.weekdayCell}>{label}</Typography>
        </div>
      ))}
    </div>
  );
};

export default CalendarWeekdaysBar;
