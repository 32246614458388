import { SafetyPolicyData } from '../../types/SafetyPolicyData';

const safetyPolicyPart1 = `Cheetah Cleaners is committed to providing you a safe clean and is taking extra safety precautions considering COVID-19. Here are the steps our cleaners and staff are taking to protect you and ourselves:`;
const safetyPolicyPart2 = `• Always wearing a mask and gloves on-site
• Wear special shoes for indoors only
• Disinfecting equipment in between cleans
• Monitoring temperatures and any new symptoms daily
• If a cleaner is exposed to COVID-19, not returning to work until officially cleared`;
const safetyPolicyPart3 = `In addition to this safety policy, we likewise ask you take some steps to keep our cleaners safe. Please determine if some steps to keep our cleaners safe. Please determine if in the past 10 days if you or anyone in your household have:`;
const safetyPolicyPart4 = `• Experienced COVID-19 symptoms, including mild symptoms
• Tested positive for COVID-19
• Had contact with confirmed or highly likely COVID-19 cases`;

const safetyPolicyPart5 = `Please note:`;
const safetyPolicyPart6 = `If any of the above points apply to you, please to do not schedule a clean and resume services when you can answer “no” to all three points.`;

export const safetyPolicy: SafetyPolicyData = {
  title: 'Safety policy',
  text: [
    { text: safetyPolicyPart1, isBold: false },
    { text: safetyPolicyPart2, isBold: false },
    { text: safetyPolicyPart3, isBold: true },
    { text: safetyPolicyPart4, isBold: false },
    { text: safetyPolicyPart5, isBold: true },
    { text: safetyPolicyPart6, isBold: false },
  ],
};
