import { FunctionComponent } from 'react';
import { Grid, Typography } from '@mui/material';
import styles from './MainInfo.module.scss';
import { Hidden, ImageComponent } from '../../../Components';
import { md } from '../../../../Assets/Styles/constants';

interface MainInfoProps {
  imageSrc: string;
  imagePath: string;
  mainText: string;
  title: string;
  disableAdjustMarginTop?: boolean;
  showAboutUs?: boolean;
}

const MainInfo: FunctionComponent<MainInfoProps> = ({
  imageSrc,
  imagePath,
  mainText,
  title,
  showAboutUs = true,
}: MainInfoProps) => {
  return (
    <div className={styles.textSuperContainer}>
      <Grid container direction="row" className={styles.textContainer} item>
        <Hidden screenWidthToShow={md - 1}>
          <Grid item xs={12} md={6} className={`${styles.columns} ${styles.imgContainer} ${styles.imgContainerAsRow}`}>
            <ImageComponent isHighPriority source={imageSrc} path={imagePath} altText="people cleaning" />
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={11} md={6} className={`${styles.columns} ${styles.textContent}`}>
          {showAboutUs && (
            <Typography variant="subtitle2" className={styles.aboutUs}>
              About us
            </Typography>
          )}
          <Typography variant="h2" className={styles.title}>
            {title}
          </Typography>
          <Hidden screenWidthToShow={md - 1} isSmaller={true}>
            <div className={`${styles.columns} ${styles.imgContainer} ${styles.imgContainerAsColumn}`}>
              <ImageComponent source={imageSrc} path={imagePath} altText="people cleaning" />
            </div>
          </Hidden>
          <Typography variant="body0" className={styles.text}>
            {mainText}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default MainInfo;
