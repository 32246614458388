import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Field, InfoModal, MainLink } from '../../Components';
import PrimaryButton from '../../Components/PrimaryButton/PrimaryButton';
import styles from './LoginPage.module.scss';
import { useNavigate } from 'react-router-dom';
import { Status } from '../../../types/SubmitDataTypes';
import { validateEmail } from '../../Containers/PaymentForm/StripePaymentForm/Common';
import { Typography } from '@mui/material';
import { observer } from 'mobx-react';
import LoginManager from '../../../Mobx/Portal/LoginManager';
import { DebugLog } from '../../../Utils/ErrorLog';
import { AlertContext } from '../../Contexts/Alert/AlertContext';

const LoginPage: FunctionComponent = () => {
  const userData = LoginManager.getUser();
  const status: Status = LoginManager.getStatus();
  const [email, setEmail] = useState({ value: '', isValid: true, message: '' });
  const [password, setPassword] = useState({ value: '', isValid: true, message: '' });
  const [modalInfo, setModalInfo] = useState<{ modalTitle: string; modalBodyText: string } | undefined>(undefined);
  const navigate = useNavigate();
  const alertContext = useContext(AlertContext);

  useEffect(() => {
    DebugLog('Status ' + status);
    if (!modalInfo && userData && (status === Status.success || status === Status.unset)) {
      navigate('/portal', { replace: true });
    }
  }, [modalInfo, userData]);

  const onLogin = async () => {
    const isValid = validateCredentials();
    if (isValid.isValidEmail && isValid.isValidPassword) {
      setModalInfo({ modalTitle: 'Signing you in', modalBodyText: 'Please wait...' });
      await LoginManager.signInWithPrsistence(email.value, password.value, alertContext.openAlert);
      setModalInfo(undefined);
    }
  };

  const validateCredentials = () => {
    let isValidEmail = false;
    const isValidPassword = password.value !== '';
    let emailErrorMessage = 'Email cannot be empty';
    if (email.value) {
      isValidEmail = validateEmail(email.value);
      emailErrorMessage = isValidEmail ? '' : 'Invalid email';
    }

    setEmail({ value: email.value, isValid: isValidEmail, message: emailErrorMessage });
    setPassword({
      value: password.value,
      isValid: isValidPassword,
      message: isValidPassword ? '' : 'Password cannot be empty',
    });

    return { isValidEmail, isValidPassword };
  };

  return (
    <div className={styles.root}>
      {modalInfo && <InfoModal title={modalInfo.modalTitle} bodyText={modalInfo.modalBodyText} />}
      <Typography className={styles.title} variant="h1">
        Login to Customer Portal
      </Typography>
      <div className={styles.container}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            void onLogin();
          }}>
          <div className={styles.item}>
            <Field
              isRoundCorners={true}
              className={styles.inputField}
              label="Email"
              type="email"
              autoComplete="email"
              name="email"
              value={email.value}
              error={!email.isValid}
              helperText={!email.isValid && email.message}
              onChange={(e) => {
                const value = e.target.value;
                setEmail({ value, isValid: email.isValid, message: '' });
              }}
            />
          </div>
          <div className={styles.item}>
            <Field
              isRoundCorners={true}
              className={styles.inputField}
              label="Password"
              type="password"
              autoComplete="password"
              name="password"
              value={password.value}
              error={!password.isValid}
              helperText={!password.isValid && password.message}
              onChange={(e) => {
                const value = e.target.value;
                setPassword({ value, isValid: password.isValid, message: '' });
              }}
            />
          </div>
          <div className={styles.buttonContainer}>
            <PrimaryButton type="submit">Login</PrimaryButton>
          </div>
        </form>
        <div className={styles.forgotContainer}>
          <MainLink linkText="Forgot password" toPath="/helpsigningin" isInternalLink>
            Forgot password
          </MainLink>
        </div>
      </div>
    </div>
  );
};

export default observer(LoginPage);
