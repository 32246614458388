import { FunctionComponent } from 'react';
import styles from './CheckListText.module.scss';
import Check from '../../../Assets/Images/svg/check.svg';
import Leaf from '../../../Assets/Images/svg/leaf.svg';
import Stars from '../../../Assets/Images/svg/stars.svg';
import { Grid } from '@mui/material';

const checkListItems = [
  { text: '100% Satisfaction Guarantee', icon: <Stars />, src: 'stars.svg' },
  { text: 'Trained with our checklists', icon: <Check />, src: 'check.svg' },
  { text: 'Bring & use green products', icon: <Leaf />, src: 'leaf.svg' },
];

const CheckListText: FunctionComponent = () => {
  return (
    <div className={styles.root}>
      {checkListItems.length > 0 && (
        <Grid container className={styles.container}>
          {checkListItems.map(({ text, icon }, index) => (
            <Grid xs={12} sm={12} md={4} lg={4} item className={styles.checkListItem} key={`checkListItem-${index}`}>
              {icon}
              <h3 className={styles.checkListText}>{text}</h3>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};

export default CheckListText;
