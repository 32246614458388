import { FunctionComponent } from 'react';
import CleaningTypes from '../../Containers/CleaningPageParts/CleaningTypes/CleaningTypes';
import CleanSelection from '../../Containers/CleaningPageParts/CleanSelection/CleanSelection';
import Faq from '../../Containers/CleaningPageParts/Faq/Faq';
import HowItWorks from '../../Containers/CleaningPageParts/HowItWorks/HowItWorks';
import MainInfo from '../../Containers/CleaningPageParts/MainInfo/MainInfo';
import { mainPageText, infoTitle, mainTitle, mainTitleSubText } from '../../../Assets/Data/MainInfo';
import EmbeddedReviews from '../../Containers/CleaningPageParts/GoogleReview/EmbeddedReviews/EmbeddedReviews';
import WhyChooseUsV2 from '../../Containers/LandingPage/LandingPageV2/WhtChooseUsV2/WhyChooseUsV2';

const HomePage: FunctionComponent = () => {
  return (
    <div>
      <CleanSelection headerText={mainTitle} subHeaderText={mainTitleSubText} imagePath="mainPageArt.svg" imageSrc="" />
      <MainInfo mainText={mainPageText} title={infoTitle} imagePath="mainPageArt2.svg" imageSrc="" />
      <HowItWorks />
      <CleaningTypes />
      <WhyChooseUsV2 />
      <EmbeddedReviews />
      <Faq />
    </div>
  );
};

export default HomePage;
