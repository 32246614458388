import { FunctionComponent, useContext } from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import styles from './Alert.module.scss';
import { AlertContext } from '../../Contexts/Alert/AlertContext';

const Alert: FunctionComponent = () => {
  const vertical = 'bottom';
  const horizontal = 'right';
  const duration = 5000;
  const alertContext = useContext(AlertContext);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    alertContext.closeAlert();
  };

  return (
    <div className={styles.alertComponent}>
      <Snackbar
        open={alertContext.alertInfo.isOpen}
        autoHideDuration={duration}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}>
        <MuiAlert
          onClose={handleClose}
          severity={alertContext.alertInfo.isSuccess ? 'success' : 'error'}
          variant="filled"
          classes={{ root: styles.alertStyle }}>
          {alertContext.alertInfo.message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Alert;
