import { FunctionComponent, useRef } from 'react';
import DrawerMenu from '../DrawerMenu/DrawerMenu';
import { useNavigate, useLocation } from 'react-router-dom';
import TopHeader from '../TopHeader/TopHeader';

type DrawerMenuHandle = React.ElementRef<typeof DrawerMenu>;

const DesktopHeader: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef<DrawerMenuHandle>(null);

  const navigateToPage = (path: string) => {
    if (path !== location.pathname) {
      navigate(path, { state: { from: location.pathname } });
    }
  };

  return (
    <>
      <TopHeader navigateToPage={navigateToPage} onMenuClick={() => ref.current?.openMenu()} />
      <DrawerMenu navigateToPage={navigateToPage} ref={ref} />
    </>
  );
};

export default DesktopHeader;
