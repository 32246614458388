import { FunctionComponent } from 'react';
import InfoPageRoot from '../../Components/InfoPageRoot/InfoPageRoot';
import { safetyPolicy } from '../../../Assets/Data/SafetyData';
import styles from './Safety.module.scss';
import { CardText } from '../../../types/CleaningInfoTypes';
import { Typography } from '@mui/material';

const Safety: FunctionComponent = () => {
  return (
    <InfoPageRoot mainTitle={safetyPolicy.title}>
      {safetyPolicy.text.map((data: CardText, index: number) => (
        <Typography variant="body1" className={styles.informationPart} key={`informationPart-${index}`}>
          {data.isBold ? <b>{data.text}</b> : data.text}
        </Typography>
      ))}
    </InfoPageRoot>
  );
};

export default Safety;
