import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';

class StripeManager {
  private stripePromise: Promise<Stripe | null>;

  constructor() {
    const stripePromise = loadStripe(
      process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_STRIPE_API_KEY || process.env.REACT_APP_STRIPE_API_KEY_PROD || ''
        : process.env.REACT_APP_STRIPE_API_KEY || process.env.REACT_APP_STRIPE_API_KEY_DEV || ''
    );

    this.stripePromise = stripePromise;
  }

  public GetStripePromise = () => {
    return this.stripePromise;
  };
}

export default StripeManager;
