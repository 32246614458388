import { FunctionComponent } from 'react';
import { Grid } from '@mui/material';
import { Field } from '../../../Components';
import { PaymentFields, PaymentFormData } from '../../../../types/PaymentFormDataTypes';
import { FormInputsValidation } from '../../../../types/StripeTypes';
import { validateEmail, validatePhone } from './Common';
import styles from './StripePaymentForm.module.scss';
import AnalyticsManager, { AmplitudeEventNames } from '../../../../Utils/AnalyticsManager';
import { observer } from 'mobx-react';
import PaymentFormDataManager from '../../../../Mobx/BookingFlowData/PaymentFormDataManager';
import { addCartDataToFirebaseFinalPage } from '../../../../Utils/AbandonedCartUtils';
import { DataForAbandonedCart } from '../../../../types/StateTypes';
import PriceManager from '../../../../Mobx/BookingFlowData/PriceManager';
import SelectedOptionsManager from '../../../../Mobx/BookingFlowData/SelectedOptionsManager';
import AddressFormDataManager from '../../../../Mobx/BookingFlowData/AddressFormDataManager';
import CleanDateTimeManager from '../../../../Mobx/BookingFlowData/CleanDateTimeManager';
import PromoCodeManager from '../../../../Mobx/BookingFlowData/PromoCodeManager';

interface PersonalInfoFieldsProps {
  isRecurring: boolean;
  formErrors: FormInputsValidation;
  setFormErrors: (newErrors: FormInputsValidation) => void;
  getAddressFormValues?: () => void;
}

const PersonalInfoFields: FunctionComponent<PersonalInfoFieldsProps> = ({
  isRecurring,
  formErrors,
  setFormErrors,
  getAddressFormValues,
}: PersonalInfoFieldsProps) => {
  const paymentForm: PaymentFormData = PaymentFormDataManager.getPaymentFields();
  const onChangeField = (field: PaymentFields, value: string) => {
    setFormErrors({ ...formErrors, [field]: false });
    PaymentFormDataManager.updatePaymentFormFields(field, value);
  };

  const onBlurField = (field: PaymentFields, value: string, eventType: AmplitudeEventNames) => {
    if (field === paymentForm.email.name) {
      const isValid = validateEmail(paymentForm.email.defaultValue);
      setFormErrors({ ...formErrors, [field]: !isValid });
    } else if (field === paymentForm.phone.name) {
      const isValid = validatePhone(paymentForm.phone.defaultValue);
      setFormErrors({ ...formErrors, [field]: !isValid });
    } else if (field === paymentForm.lastName.name) {
      const isValid = Boolean(paymentForm.lastName.defaultValue);
      setFormErrors({ ...formErrors, [field]: !isValid });
    } else if (field === paymentForm.firstName.name) {
      const isValid = Boolean(paymentForm.firstName.defaultValue);
      setFormErrors({ ...formErrors, [field]: !isValid });
    }

    if (getAddressFormValues) {
      getAddressFormValues();
    }

    const fullBookingState: DataForAbandonedCart = {
      price: PriceManager.getPrice(),
      selected: SelectedOptionsManager.getSelected(),
      addressForm: AddressFormDataManager.getAddressFormValues(),
      paymentForm: PaymentFormDataManager.getPaymentFields(),
      dateTimeWithTimeSlot: CleanDateTimeManager.getDateAndTimeSlot(),
      couponsApplied: PromoCodeManager.getAppliedCoupon(),
      selectedTip: PriceManager.getTip(),
      timeSlot: CleanDateTimeManager.getTimeSlot(),
      addons: AddressFormDataManager.getAddOns(),
    };

    void addCartDataToFirebaseFinalPage(fullBookingState);

    AnalyticsManager.logLead(eventType, value);
  };

  return (
    <Grid container spacing={2} className={styles.personalInfo}>
      <Grid item xs={6} md={6}>
        <Field
          label={paymentForm.firstName.label}
          type="text"
          isRoundCorners={true}
          autoComplete="given-name"
          name={paymentForm.firstName.name}
          placeholder={paymentForm.firstName.placeholder}
          value={paymentForm.firstName.defaultValue}
          onChange={(e) => onChangeField(paymentForm.firstName.name, e?.target?.value)}
          error={formErrors?.firstName ? true : false}
          helperText={formErrors?.firstName ? paymentForm.firstName.helperText : ''}
          onBlur={(e) => onBlurField(paymentForm.firstName.name, e?.target?.value, AmplitudeEventNames.firstName)}
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <Field
          label={paymentForm.lastName.label}
          type="text"
          isRoundCorners={true}
          autoComplete="family-name"
          name={paymentForm.lastName.name}
          placeholder={paymentForm.lastName.placeholder}
          value={paymentForm.lastName.defaultValue}
          onChange={(e) => onChangeField(paymentForm.lastName.name, e?.target?.value)}
          error={formErrors?.lastName ? true : false}
          helperText={formErrors?.lastName ? paymentForm.lastName.helperText : ''}
          onBlur={(e) => onBlurField(paymentForm.lastName.name, e?.target?.value, AmplitudeEventNames.lastName)}
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <Field
          label={paymentForm.email.label}
          type="email"
          isRoundCorners={true}
          autoComplete="email"
          disabled={isRecurring}
          name={paymentForm.email.name}
          placeholder={paymentForm.email.placeholder}
          value={paymentForm.email.defaultValue}
          onChange={(e) => onChangeField(paymentForm.email.name, e?.target?.value)}
          error={formErrors?.email ? true : false}
          helperText={formErrors?.email ? paymentForm.email.helperText : ''}
          onBlur={(e) => onBlurField(paymentForm.email.name, e?.target?.value, AmplitudeEventNames.email)}
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <Field
          label={paymentForm.phone.label}
          type="tel"
          isRoundCorners={true}
          autoComplete="tel"
          name={paymentForm.phone.name}
          placeholder={paymentForm.phone.placeholder}
          value={paymentForm.phone.defaultValue}
          onChange={(e) => onChangeField(paymentForm.phone.name, e?.target?.value)}
          error={formErrors?.phone ? true : false}
          helperText={formErrors?.phone ? paymentForm.phone.helperText : ''}
          onBlur={(e) => onBlurField(paymentForm.phone.name, e?.target?.value, AmplitudeEventNames.phone)}
          inputProps={{ pattern: '[0-9]*' }}
        />
      </Grid>
    </Grid>
  );
};

export default observer(PersonalInfoFields);
