import { FunctionComponent } from 'react';
import HowItWorks from '../../Containers/CleaningPageParts/HowItWorksLanding/HowItWorks';
import Top from '../../Containers/LandingPage/LandingPageV2/Top/Top';
import Quote from '../../Containers/LandingPage/LandingPageV2/Quote/Quote';
import Reviews from '../../Containers/CleaningPageParts/GoogleReview/Reviews/Reviews';
import WhyChooseUsV2 from '../../Containers/LandingPage/LandingPageV2/WhtChooseUsV2/WhyChooseUsV2';

const LandingPage: FunctionComponent = () => {
  return (
    <div>
      <Top />
      <Quote />
      <WhyChooseUsV2 />
      <Reviews />
      <HowItWorks title="We Make Your Life Easier" isWhiteBackground removeScheduleButton />
    </div>
  );
};

export default LandingPage;
