import { FunctionComponent } from 'react';
import styles from './Blog.module.scss';
import { posts } from '../BlogPost/BlogPosts';
import { BlogCard } from '../../Components';
import { Grid, Typography } from '@mui/material';

const Blog: FunctionComponent = () => {
  const postsArray = Object.values(posts);

  return (
    <Grid container justifyContent="center" alignItems="center" className={styles.root}>
      <Grid container justifyContent="center" direction="column" item xs={11} sm={11} md={10} lg={9} xl={8}>
        <Typography variant="h1" className={styles.pageTitle}>
          Blog
        </Typography>
        <Grid container className={styles.postsGrid} spacing="10">
          {postsArray.map((post) => (
            <Grid item xs={12} sm={6} md={4} key={post.title} className={styles.blogPost}>
              <BlogCard postData={post} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Blog;
