import { FunctionComponent, useEffect } from 'react';
import styles from './DeepClean.module.scss';
import { CheckListTable } from '../../Components';
import { deep } from '../../../Assets/Data/CleanDescriptionText';
import CleanSelection from '../../Containers/CleaningPageParts/CleanSelection/CleanSelection';
import ResetYourHome from '../../Containers/CleaningPageParts/ResetYourHome/ResetYourHome';
import MainInfo from '../../Containers/CleaningPageParts/MainInfo/MainInfo';
import { observer } from 'mobx-react';
import { getAddons } from '../../../Mobx/FetchingManagers/StripeDataManager';
import { selectedBookingInfo } from '../../../Mobx/States/InitialSelectedState';
import SelectedOptionsManager from '../../../Mobx/BookingFlowData/SelectedOptionsManager';
import { mainTitleSubText } from '../../../Assets/Data/MainInfo';
import { AddonProductInfo } from '../../../types/StripeDataState';
import EmbeddedReviews from '../../Containers/CleaningPageParts/GoogleReview/EmbeddedReviews/EmbeddedReviews';

const DeepClean: FunctionComponent = () => {
  const addonOptions: AddonProductInfo[] = getAddons();

  useEffect(() => {
    SelectedOptionsManager.updateSelectedAll(selectedBookingInfo);
  }, []);

  return (
    <div className={styles.root}>
      <CleanSelection
        headerText="Need a hand? We can help. "
        subHeaderText={mainTitleSubText}
        imageSrc=""
        imagePath="deepCleanArt1.svg"
        isDisableChecklist={true}
        dropShadow={true}
      />
      <MainInfo mainText={deep.text} title={deep.title} imagePath="deepCleanArt2.svg" imageSrc="" />
      <CheckListTable name="Deep" deep={true} addonsOptions={addonOptions} />
      <EmbeddedReviews />
      <ResetYourHome />
    </div>
  );
};

export default observer(DeepClean);
