import { FunctionComponent, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import CalendarNavigation from './CalendarNavigation/CalendarNavigation';
import CalendarWeekdaysBar from './CalendarWeekdaysBar/CalendarWeekdaysBar';
import CalendarMonth from './CalendarMonth/CalendarMonth';
import TimePicker from './TimePicker/TimePicker';
import { bookingQuestions } from '../../../Assets/Data/BookingConstants';
import { eventsKeyFormat, MONTHS_TO_DISPLAY } from '../../../Config';
import { observer } from 'mobx-react';
import CleanDateTimeManager from '../../../Mobx/BookingFlowData/CleanDateTimeManager';
import SelectedOptionsManager from '../../../Mobx/BookingFlowData/SelectedOptionsManager';
import AnalyticsManager from '../../../Utils/AnalyticsManager';
import BookSectionWrapper from '../BookingProgressV2/BookSectionWrapper/BookSectionWrapper';
import CalendarTimesManager from '../../../Mobx/FetchingManagers/CalendarTimesManager';
import BookingFormTitle from '../BookingProgressV2/BookingFormTitle/BookingFormTitle';

interface CalendarProps {
  removeTitle?: boolean;
}

const Calendar: FunctionComponent<CalendarProps> = ({ removeTitle }: CalendarProps) => {
  const setDateTime = CleanDateTimeManager.getDateTime();
  const cleaningTypeRecurring: boolean = SelectedOptionsManager.isSelectedCleanRecurring();
  const [displayMonth, setDisplayMonth] = useState(0);
  const availableTimesData = CalendarTimesManager.getAvailableTimes();

  const monthsToDisplay = (numberOfMonths: number): string[] => {
    let numberOfMonthsToAdd = numberOfMonths;
    const months: string[] = [];

    // Add first month only if there are days left
    const monthNumber = moment().add(0, 'months').month() + 1;
    const year = moment().add(0, 'months').year();
    const today = moment(new Date()).format(eventsKeyFormat);
    const endOfMonth = moment(`${year}-${monthNumber}`, eventsKeyFormat).endOf('month').format(eventsKeyFormat);

    if (endOfMonth !== today) {
      months.push(`${year}-${monthNumber}`);
    } else {
      numberOfMonthsToAdd += 1;
    }

    // Add the rest of the months
    for (let i = 1; i < numberOfMonthsToAdd; i++) {
      const monthNumber = moment().add(i, 'months').month() + 1;
      const year = moment().add(i, 'months').year();
      months.push(`${year}-${monthNumber}`);
    }

    return months;
  };

  const calendarMonthsToDisplay = useMemo(() => monthsToDisplay(Number(MONTHS_TO_DISPLAY || 3)), []);

  const handleDisplayedMonthChange = (monthIndex: number) => {
    AnalyticsManager.logGroupButtonClick('Month change', { optionSelected: monthIndex });

    if (monthIndex >= 0 && monthIndex < calendarMonthsToDisplay.length) {
      setDisplayMonth(monthIndex);
    }
  };

  return (
    <>
      {!removeTitle && <BookingFormTitle main={bookingQuestions[1].main} sub={bookingQuestions[1].sub} />}
      <BookSectionWrapper>
        <div>
          <CalendarNavigation
            monthsToDisplay={calendarMonthsToDisplay}
            displayDate={displayMonth}
            onMonthChange={handleDisplayedMonthChange}
            disableNav={!availableTimesData}
          />
          <CalendarWeekdaysBar />
          <CalendarMonth
            month={Number(calendarMonthsToDisplay[displayMonth].split('-')[1])}
            year={Number(calendarMonthsToDisplay[displayMonth].split('-')[0])}
            onSelectedDayChange={CleanDateTimeManager.updateDateTime}
            selectedDay={setDateTime}
            cleaningTypeRecurring={cleaningTypeRecurring}
          />
        </div>
        <TimePicker />
      </BookSectionWrapper>
    </>
  );
};

export default observer(Calendar);
