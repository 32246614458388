import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import styles from './BookingFormTitle.module.scss';

interface BookingFormTitleProps {
  main: string;
  sub?: string;
}

const BookingFormTitle: FunctionComponent<BookingFormTitleProps> = ({ main, sub }: BookingFormTitleProps) => {
  return (
    <div className={styles.root}>
      <Typography variant="h1" align="center" classes={{ root: styles.title }}>
        {main}
      </Typography>
      {sub && (
        <Typography variant="h6" align="center" classes={{ root: styles.questionSubText }}>
          {sub}
        </Typography>
      )}
    </div>
  );
};

export default BookingFormTitle;
