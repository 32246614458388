import { FunctionComponent, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Typography, Divider } from '@mui/material';
import styles from './SuccessfulPayment.module.scss';
import { LocationState } from '../../../types/HistoryLocation';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { observer } from 'mobx-react';
import { SubmitDataContext } from '../../Contexts/SubmitDataContext/SubmitDataContext';

const SuccessfulRecurringBook: FunctionComponent = () => {
  const submitDataContext = useContext(SubmitDataContext);

  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location.state as LocationState;

  const defaultRedirectPath = '/home';
  const validFromPath = '/recurringClean';
  const footer =
    'If you have any questions or you want to modify or cancel your cleaning, please call or text (206) 486-0038 or email info@cheetahcleaners.com';

  useEffect(() => {
    return () => {
      submitDataContext.resetStateAfterSubmit();
    };
  }, []);

  const shouldRenderSuccessPage = () => {
    if (locationState?.from !== validFromPath || !submitDataContext.data) {
      navigate(defaultRedirectPath, { replace: true });
      return false;
    }
    return true;
  };

  return shouldRenderSuccessPage() ? (
    <div className={styles.successfulPayment}>
      <div className={styles.successfulPaymentContainer}>
        <Grid container direction="column" className={styles.successInfoContainer}>
          <Grid item xs={12} className={styles.successItem}>
            <Typography variant="h3">You have successfully scheduled a recurring cleaning!</Typography>
          </Grid>
          <Grid item xs={12} className={styles.successItem}>
            <Typography variant="h5">Your card will be charged on the day of service.</Typography>
            {submitDataContext.data?.personalInfo?.email && (
              <Typography variant="h5">
                We will email receipt to <b>{submitDataContext.data.personalInfo.email}</b>.
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} className={styles.successItem}>
            <CheckCircleOutlineIcon className={styles.successIcon} />
          </Grid>
        </Grid>
        <Divider></Divider>
        <div className={styles.contactInfo}>
          <Typography variant="body1">{footer}</Typography>
        </div>
      </div>
    </div>
  ) : null;
};

export default observer(SuccessfulRecurringBook);
