import { FunctionComponent, useMemo } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import styles from './AddonButtonGroup.module.scss';
import { AddonProductInfo, ProductInfo, ProductPrice } from '../../../types/StripeDataState';
import { getPrice } from '../../../Utils/AddonsUtils';
import { AddonDataType } from '../../../types/AddressFormTypes';
import { ImageComponent, SwitchButton } from '..';
import AnalyticsManager from '../../../Utils/AnalyticsManager';
import { CleaningFrequency } from '../../../types/BookingFieldsEnum';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { observer } from 'mobx-react';

interface AddonButtonProps {
  error: boolean;
  shouldRemoveRecurringSwitch: boolean;
  onChangeOptionAddOn: (
    option: ProductInfo,
    price: ProductPrice,
    newRecurringPrice: ProductPrice | undefined,
    isRecurringOptionSelected: boolean,
    keepSelected: boolean,
    quantity?: number
  ) => void;
  item: AddonProductInfo;
  selectedCleaningFrequency: CleaningFrequency;
  addonData: AddonDataType | undefined;
  isRecurringCleanSelected: boolean;
}

const AddonButton: FunctionComponent<AddonButtonProps> = ({
  error,
  onChangeOptionAddOn,
  item,
  selectedCleaningFrequency,
  addonData,
  isRecurringCleanSelected,
  shouldRemoveRecurringSwitch,
}: AddonButtonProps) => {
  const isSelected = addonData !== undefined;
  const isAddOnForFirstCleanOnly = item.isFirstCleanOnly;
  const isFirstCleanOnly = addonData ? addonData.isFirstCleanOnly : true;

  const price = getPrice(item.price, CleaningFrequency.oneTime);
  const priceRecurring = useMemo(
    () => getPrice(item.price, selectedCleaningFrequency),
    [item.price, selectedCleaningFrequency]
  );

  const showBorder = useMemo(() => {
    return (!isAddOnForFirstCleanOnly && isRecurringCleanSelected) || (isSelected && item.isSpecifyQuantity) || false;
  }, [isSelected, item.isSpecifyQuantity, isAddOnForFirstCleanOnly, isRecurringCleanSelected]);

  const onChangeSwitch = (isChecked: boolean) => {
    if (price && price.price > 0) {
      onChangeOptionAddOn(item, price, priceRecurring, isChecked, true, addonData?.quantity || 1);
    }
  };

  return !price || price.price <= 0 ? null : (
    <Grid container item xs={12} sm={6} md={4} className={styles.buttonContainer}>
      <div className={`${styles.addonButtonParts} ${showBorder ? styles.buttonBorder : ''}`}>
        <div className={styles.topPart}>
          <button
            className={`${styles.btn} ${isSelected ? styles.selected : ''} ${error ? styles.error : ''}`}
            onClick={(e) => {
              AnalyticsManager.logButtonClick('Addon', {
                name: item.name,
                clickedToSelect: !isSelected,
              });
              onChangeOptionAddOn(
                item,
                price,
                priceRecurring,
                shouldRemoveRecurringSwitch || !isFirstCleanOnly,
                false,
                addonData?.quantity || 1
              );

              e.stopPropagation();
              e.preventDefault();
            }}>
            <div className={styles.container}>
              {item.imagePath && (
                <ImageComponent
                  className={styles.addOnImg}
                  path={`addOns/${item.imagePath}`}
                  source=""
                  altText="decorative"
                  heightNum={30}
                  widthNum={30}
                  loading="lazy"
                />
              )}
              <div className={styles.textContainer}>
                <Typography className={styles.text} variant="h6">
                  {item.name}
                </Typography>
                <Typography className={`${styles.text} ${styles.priceText}`} variant="h6">
                  {`$${price.price}`}
                </Typography>
              </div>
            </div>
          </button>
        </div>
        <div className={styles.bottomPart}>
          {!isAddOnForFirstCleanOnly && isRecurringCleanSelected && !shouldRemoveRecurringSwitch && (
            <SwitchButton
              isChecked={!isFirstCleanOnly}
              onCheckChange={onChangeSwitch}
              title="Add to recurring cleans"
            />
          )}
          {item.isSpecifyQuantity && isSelected && (
            <div className={styles.qtButtons}>
              <IconButton
                aria-label="decrease"
                onClick={(e) => {
                  onChangeOptionAddOn(
                    item,
                    price,
                    priceRecurring,
                    shouldRemoveRecurringSwitch || !isFirstCleanOnly,
                    true,
                    (addonData?.quantity || 1) - 1
                  );

                  e.stopPropagation();
                  e.preventDefault();
                }}
                className={styles.minus}>
                <RemoveIcon classes={{ root: styles.rootIconStyle }} />
              </IconButton>
              <Typography className={styles.value}>{addonData?.quantity || 1}</Typography>
              <IconButton
                aria-label="increase"
                onClick={(e) => {
                  onChangeOptionAddOn(
                    item,
                    price,
                    priceRecurring,
                    shouldRemoveRecurringSwitch || !isFirstCleanOnly,
                    true,
                    (addonData?.quantity || 1) + 1
                  );

                  e.stopPropagation();
                  e.preventDefault();
                }}
                className={styles.plus}>
                <AddIcon classes={{ root: styles.rootIconStyle }} />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </Grid>
  );
};

export default observer(AddonButton);
