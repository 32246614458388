import { FunctionComponent, useState } from 'react';
import { ReactFCChildren } from '../../../types/ReactFCChildren';
import { AlertContext, IAlertDataContext, IAlertInfo } from './AlertContext';
import { defaultError } from '../../Containers/PaymentForm/StripePaymentForm/Common';

const AlertContextImpl: FunctionComponent<ReactFCChildren> = ({ children }: ReactFCChildren) => {
  const [alertInfo, setAlertInfo] = useState<IAlertInfo>({ isOpen: false });

  const openAlert = (message: string, isSuccess: boolean) => {
    if (typeof message !== 'string') {
      isSuccess = false;
      message = defaultError;
    }

    if (!message) {
      closeAlert();
    } else {
      setAlertInfo({ isOpen: true, message, isSuccess });
    }
  };

  const closeAlert = () => {
    setAlertInfo({ isOpen: false, message: '' });
  };

  const contextValue: IAlertDataContext = { openAlert, alertInfo, closeAlert };

  return <AlertContext.Provider value={contextValue}>{children}</AlertContext.Provider>;
};

export default AlertContextImpl;
