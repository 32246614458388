import { FunctionComponent } from 'react';
import styles from './Texts.module.scss';

interface TextsProps {
  step: string;
  title: string;
  text: string;
}

const Texts: FunctionComponent<TextsProps> = ({ step, text, title }: TextsProps) => {
  return (
    <div className={styles.texts}>
      <h4 className={styles.stepName}>{step}</h4>
      <h5 className={styles.title}>{title}</h5>
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default Texts;
