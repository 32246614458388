import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { baseUrl } from '../Config';

const client: AxiosInstance = axios.create({
  baseURL: baseUrl,
});

export const accessTokenInterceptor = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const accessToken = await window.getAccessToken();
  if (accessToken) {
    if (!config.headers) {
      config.headers = {};
    }

    const headers = config.headers as Record<string, unknown>;
    if (headers['Authorization'] === undefined) {
      (config.headers as Record<string, unknown>)['Authorization'] = `Bearer ${accessToken}`;
    }
  }
  return config;
};

// client.interceptors.request.use(accessTokenInterceptor, (error) => {
//   return Promise.reject(error);
// });

const HttpClient = async function <T>(options: AxiosRequestConfig): Promise<T> {
  const onSuccess = function (response: AxiosResponse<T>) {
    return response.data;
  };

  const onError = function (error: AxiosError) {
    return Promise.reject({ message: error.response || error.message, status: error.response?.status });
  };

  return client(options).then(onSuccess).catch(onError);
};

export default HttpClient;
