import { makeAutoObservable } from 'mobx';
import { CleaningTypeOptions } from '../../types/BookingFormDataTypes';
import { Coupon } from '../../types/Coupon';
import { Price, PriceBase } from '../../types/Price';
import { StripeInfoForClean } from '../../types/StripeDataState';
import { calculatePriceAfterFirstStripe, calculatePriceStripe, getReceiptPrices } from '../../Utils/CalculatePrice';
import { calculateDiscountDeduction, cleaningTypeCouponValidation } from '../../Utils/StripeCouponUtils';
import { initialStripeDataState } from '../States/InitialState';
import { initialSelected } from '../../Utils/StripeDataUtils';
import { GetDefaultCity } from '../../Assets/Data/BookingInformation';

class PriceManager {
  private price: Price;
  private selectedTip: StripeInfoForClean = initialStripeDataState;

  constructor() {
    this.price = this.getInitialPrice();
    makeAutoObservable(this);
  }

  public getTip = () => {
    return this.selectedTip;
  };

  public getPrice = () => {
    return this.price;
  };

  public updateTipField = (value: number, tipProductId: string) => {
    this.selectedTip = {
      productId: tipProductId,
      price: value,
      priceId: '',
    };
  };

  public setPirice = (newPrice: Price) => {
    this.price = newPrice;
  };

  public updatePriceGeneral = (
    data: PriceBase,
    CleaningTypeOptions: CleaningTypeOptions,
    couponsApplied: Coupon | undefined,
    taxRate: number
  ) => {
    const tips = this.selectedTip.price || 0;
    const oneTimeServicePrice = data.oneTime.servicePrice;
    const recurringServicePrice = data.recurring.servicePrice;

    const newCouponDeduction =
      couponsApplied && !cleaningTypeCouponValidation(CleaningTypeOptions.key, couponsApplied.couponData.validation)
        ? calculateDiscountDeduction(couponsApplied.couponData, oneTimeServicePrice, data.oneTime.addons)
        : 0;
    const newCouponDeductionRecurring =
      couponsApplied && couponsApplied.couponData.validation.isValidForRecurring
        ? calculateDiscountDeduction(couponsApplied.couponData, recurringServicePrice, data.recurring.addons)
        : 0;

    const oneTimePrices = getReceiptPrices(oneTimeServicePrice, data.oneTime.addons, tips, newCouponDeduction, taxRate);
    const recurringPrices = CleaningTypeOptions.isRecurring
      ? getReceiptPrices(recurringServicePrice, data.recurring.addons, tips, newCouponDeductionRecurring, taxRate)
      : oneTimePrices;

    this.price = { oneTime: oneTimePrices, recurring: recurringPrices, tips };
  };

  private getInitialPrice = () => {
    const price: number = calculatePriceStripe(initialSelected);
    const selectedCityTaxRate = GetDefaultCity().taxRate;
    const priceAfterFirstClean: number = initialSelected.cleaningType.isRecurring
      ? calculatePriceAfterFirstStripe(initialSelected) || price
      : price;

    const oneTimePrices = getReceiptPrices(price, 0, 0, 0, selectedCityTaxRate);
    const recurringPrices = getReceiptPrices(priceAfterFirstClean, 0, 0, 0, selectedCityTaxRate);
    const priceObj = {
      oneTime: oneTimePrices,
      recurring: recurringPrices,
      tips: 0,
    };

    return priceObj;
  };
}

export default new PriceManager();
