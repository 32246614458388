import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { ReactFCChildren } from '../../../types/ReactFCChildren';
import { BookingKeyContext, IBookingKeyContextDataContext } from './BookingKeyContext';
import { Status } from '../../../types/SubmitDataTypes';
import { FirestoreCleaningForKey, ValidateKeyResponse } from '../../../types/Cleaning';
import { SelectedBookingInfo } from '../../../types/StateTypes';
import { checkKey } from '../../../Utils/ApiCalls/ApiCalls';
import { LogError } from '../../../Utils/ErrorLog';
import AnalyticsManager, { AmplitudeEventNames } from '../../../Utils/AnalyticsManager';
import { toSelectedBookingInfoFromObject } from '../../../Utils/RecurringCleanUtils';
import {
  AddressFormFieldsName,
  InformationFormFieldsName,
  KeyOptions,
  ParkingOptions,
} from '../../../types/AddressFormTypes';
import AddressFormDataManager from '../../../Mobx/BookingFlowData/AddressFormDataManager';
import PaymentFormDataManager from '../../../Mobx/BookingFlowData/PaymentFormDataManager';
import SelectedOptionsManager from '../../../Mobx/BookingFlowData/SelectedOptionsManager';
import CalendarTimesManager from '../../../Mobx/FetchingManagers/CalendarTimesManager';
import { cleaningFieldsInfo } from '../../../Assets/Data/BookingInformation';

const BookingKeyContextImpl: FunctionComponent<ReactFCChildren> = ({ children }: ReactFCChildren) => {
  const [cleaning, setCleanings] = useState<FirestoreCleaningForKey | undefined>(undefined);
  const [key, setKey] = useState('');
  const [status, setStatus] = useState(Status.unset);

  const resetState = () => {
    setCleanings(undefined);
    setKey('');
    setStatus(Status.unset);
  };

  const validateResponse = (cleaningResponse: ValidateKeyResponse) => {
    if (cleaningResponse.key.isKeyRedeemed) {
      return 'Key has already been redeemed.';
    }
    if (cleaningResponse.key.isValid === false) {
      return 'Key is invalid.';
    }
    if (!cleaningResponse.cleaning) {
      return 'Key is invalid.';
    }

    return '';
  };

  const applyKey = async (key: string, currentSelected: SelectedBookingInfo): Promise<string> => {
    setStatus(Status.inProgress);

    try {
      const cleaningResponse = await checkKey(key);
      const errorMessage = validateResponse(cleaningResponse);

      if (errorMessage || !cleaningResponse.cleaning) {
        setCleanings(undefined);
        setKey('');
        setStatus(Status.error);

        return errorMessage || 'Invalid key.';
      } else {
        void CalendarTimesManager.fetchAvailableTimes();

        const cleaning = cleaningResponse.cleaning;
        const selected: SelectedBookingInfo = toSelectedBookingInfoFromObject(
          cleaning.selectedCleaningInfo,
          currentSelected
        );

        let keyOption = cleaning.keyInfo.type as KeyOptions;
        if (Object.values(KeyOptions).indexOf(keyOption) === -1) {
          keyOption = KeyOptions.notSet;
        }

        let parkingOption = cleaning.parkingInfo.type as ParkingOptions;
        if (Object.values(ParkingOptions).indexOf(parkingOption) === -1) {
          parkingOption = ParkingOptions.notSet;
        }

        selected.cleaningType = cleaningFieldsInfo.cleaningType.options[1];
        selected.cleaningFrequency = cleaningFieldsInfo.cleaningType.options[1].frequency[0];
        SelectedOptionsManager.updateSelectedAll(selected);
        PaymentFormDataManager.updatePaymentFormPersonalInfo(cleaning.personalInfo);
        AddressFormDataManager.updateAddressFormFields({
          [AddressFormFieldsName.address]: cleaning.address.streetName,
          [AddressFormFieldsName.apartment]: cleaning.address.apartment,
          [AddressFormFieldsName.zip]: cleaning.address.zipCode,
          [AddressFormFieldsName.state]: cleaning.address.state,
          [AddressFormFieldsName.city]: cleaning.address.city ?? '',
          [AddressFormFieldsName.parking]: parkingOption,
          [AddressFormFieldsName.keyOption]: keyOption,
          [AddressFormFieldsName.petOption]: cleaning.petInfo.type,
          [AddressFormFieldsName.addOns]: [],

          [InformationFormFieldsName.keyInformation]: cleaning.keyInfo.additionalInfo ?? '',
          [InformationFormFieldsName.parkingInformation]: cleaning.parkingInfo.additionalInfo ?? '',
          [InformationFormFieldsName.otherInformation]: cleaning.otherInfo,
          [InformationFormFieldsName.petInformation]: cleaning.petInfo.additionalInfo ?? '',
        });

        setCleanings(cleaningResponse.cleaning);
        setKey(key);
        setStatus(Status.success);

        AnalyticsManager.logEventWithProps(AmplitudeEventNames.KeyApplied, { keyId: key });
      }

      return '';
    } catch (error) {
      setCleanings(undefined);
      setKey('');
      setStatus(Status.error);

      LogError('applyKey', { message: JSON.stringify(error) }, true);
      return 'Invalid key.';
    }
  };

  const applyKeyC = useCallback((key: string, currentSelected: SelectedBookingInfo) => {
    return applyKey(key, currentSelected);
  }, []);

  const resetStateC = useCallback(() => {
    return resetState();
  }, []);

  const contextValue: IBookingKeyContextDataContext = useMemo(
    () => ({
      cleaning,
      key,
      status,
      applyKey: applyKeyC,
      resetState: resetStateC,
    }),
    [cleaning, key, status, applyKey, resetState]
  );

  return <BookingKeyContext.Provider value={contextValue}>{children}</BookingKeyContext.Provider>;
};

export default BookingKeyContextImpl;
