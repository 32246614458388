import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import styles from './Article.module.scss';

const ScheduleClean: FunctionComponent = () => {
  const paragraph = 'body1';

  return (
    <div>
      <Typography className={styles.paragraph1} variant={paragraph}>
        Figuring out how often to schedule a cleaning service really depends on a few factors and your lifestyle, but at
        the end of the day it’s really about your preference and making sure it fits in your budget. A professional
        cleaning service can give suggestions, but doing a little research always helps too. Ask yourself:
      </Typography>
      <ol>
        <li>
          <Typography className={styles.title} variant={paragraph}>
            Who lives in your home (including pets)?
          </Typography>

          <Typography className={styles.paragraph1} variant={paragraph}>
            If you have a larger family or multiple pets, weekly or biweekly cleanings may be best because dust, hair,
            and dirt will accumulate faster. Some people choose more frequent cleans because they need to always have
            their home guest ready. On the other hand, if you live on your own with no pets, not many things, and don’t
            need professional cleaning for entertaining people, monthly cleans could be a great option.
          </Typography>
          <Typography className={styles.paragraph1} variant={paragraph}>
            In general, biweekly cleans are most common because its often enough to not let dirt build up too much.
            These cleans will take less time and use less solutions, microfiber towels, etc., which is why they cost
            less than a monthly or deep clean. When you begin scheduling cleans, you’ll typically need to start with a
            deep clean to get your home to a certain standard, and then will be charged a lower rate after that.
          </Typography>
        </li>

        <li>
          <Typography className={styles.title} variant={paragraph}>
            How big is your home?
          </Typography>

          <Typography className={styles.paragraph1} variant={paragraph}>
            Do you get lost in your home or are you living in a closet? If there’s rooms in your home that don’t really
            get used, many cleaning companies will allow you to customize your clean and leave those areas out. You
            could potentially have the cleaning service do those places more sporadically, like every few months. If you
            have a smaller home dirt will accumulate faster, especially if more people live there, so just take that
            into account when deciding frequency.
          </Typography>
        </li>

        <li>
          <Typography className={styles.title} variant={paragraph}>
            Are you ok with light cleaning in between appointments?
          </Typography>

          <Typography className={styles.paragraph1} variant={paragraph}>
            More dirt will accumulate in a month vs two weeks so it will typically take longer to clean. Depending on
            how much there is to clean, monthly cleans can sometimes take as long and cost as much as a deep clean, so
            be prepared for some upkeep in between cleans if you want to maintain a lower rate. More frequent cleaning
            is easier to stay on top off for a cleaning service that only comes a couple days a month, even if you
            aren’t the tidiest person.
          </Typography>
        </li>

        <li>
          <Typography className={styles.title} variant={paragraph}>
            What’s your budget?
          </Typography>

          <Typography className={styles.paragraph1} variant={paragraph}>
            This is probably the questions that has the final say in the matter, because let’s face it, while a cleaning
            service can be affordable it is still a luxury. It really depends on how much it’s worth it to you – for
            example if you’re a family that wants to spend more time together, maybe hiring a cleaning service outweighs
            getting that new tv or going out to eat every weekend. Whatever your situation, a great cleaning company
            will always be flexible and open to talking and creating a plan that not only fits your needs, but also your
            budget as much as possible.
          </Typography>
        </li>
      </ol>
    </div>
  );
};

export default ScheduleClean;
