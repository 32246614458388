import { cleaningFieldsInfo } from '../Assets/Data/BookingInformation';
import { CleaningType } from '../types/BookingFieldsEnum';
import { SquareFootageOptions } from '../types/BookingFormDataTypes';

export const stringToNumber = (strNumber: string | undefined | null): number => {
  const n = strNumber ? parseInt(strNumber) : -1;
  return isNaN(n) ? -1 : n;
};

export const isMobile = (): boolean => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  } else {
    return false;
  }
};

export const getAllowSqfOptions = (cleaningType: CleaningType): SquareFootageOptions[] => {
  if (cleaningType === CleaningType.moveOut) {
    const squareFootageOptions = cleaningFieldsInfo.squareFootage.options.slice(0, 3);
    return squareFootageOptions;
  }

  return [];
};

export const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const parseDataWithCatch = <T>(data: string): T | undefined => {
  try {
    return JSON.parse(data) as T;
  } catch (e) {
    return undefined;
  }
};
