import { FunctionComponent, useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Typography } from '@mui/material';
import { addressFormData } from '../../../Assets/Data/AddressForm';
import styles from './AddressInfoForm.module.scss';
import { AddressFormRegisteredFields, ParkingOptions } from '../../../types/AddressFormTypes';
import { ButtonGroup, Field, Label } from '../../Components';
import { observer } from 'mobx-react';
import AddressFormDataManager from '../../../Mobx/BookingFlowData/AddressFormDataManager';

const ParkingRow: FunctionComponent = () => {
  const addressFormValues: AddressFormRegisteredFields = AddressFormDataManager.getAddressFormValues();
  const {
    register,
    control,
    formState: { errors },
    unregister,
    setValue,
  } = useFormContext<AddressFormRegisteredFields>();
  const [showParkInfo, setShowParkInfo] = useState(false);
  const constData = addressFormData.constantValues;
  const parkingOptions = Object.keys(constData.parking.options);

  useEffect(() => {
    register('parking', { ...constData.parking.rules });
  }, [register]);

  useEffect(() => {
    if (
      addressFormValues.parking !== ParkingOptions.notSet &&
      constData.parking.options[addressFormValues.parking].requiredClarificationBox
    ) {
      setShowParkInfo(true);
    } else {
      setShowParkInfo(false);
      unregister(constData.informationFields.parkingInformation.name);
    }
  }, [addressFormValues.parking]);

  const parkingOptionsChange = (newOption: string) => {
    const parkingOption = newOption as ParkingOptions;
    if (addressFormValues.parking === parkingOption) {
      return;
    }
    if (Object.values(ParkingOptions).indexOf(parkingOption) === -1) {
      return;
    }
    AddressFormDataManager.updateAddressFormFieldsPartial({ parking: parkingOption });
    setValue('parking', parkingOption as never, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <div className={styles.formRow}>
      <div className={styles.infoFields}>
        <Label text={constData.parking.label} />
        <ButtonGroup
          options={parkingOptions}
          selectedOption={[addressFormValues.parking]}
          onChangeOption={parkingOptionsChange}
          error={errors.parking !== undefined}
          groupName="parking"
        />
        {addressFormValues.parking !== ParkingOptions.notSet &&
          constData.parking.options[addressFormValues.parking].moreText && (
            <Typography className={styles.text}>
              {constData.parking.options[addressFormValues.parking].moreText}
            </Typography>
          )}

        {showParkInfo && (
          <Controller
            control={control}
            name={constData.informationFields?.parkingInformation.name}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, ref } }) => (
              <Field
                multiline
                rows={4}
                placeholder={
                  addressFormValues.parking !== ParkingOptions.notSet
                    ? constData.parking.options[addressFormValues.parking].placeholder
                    : ''
                }
                variant="outlined"
                name={constData.informationFields?.parkingInformation.name}
                error={errors.parkingInformation !== undefined}
                helperText={(errors.parkingInformation && errors.parkingInformation.message) || ''}
                inputProps={{ 'aria-autocomplete': 'none' }}
                inputRef={ref}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export default observer(ParkingRow);
