import { BeforeBookingLeadPageInfoFields } from '../types/ContactUsData';
import { parseDataWithCatch } from './Common';

const bookingIntentDataKey = 'bookingIntentData';

export const setBookingIntentData = (data: BeforeBookingLeadPageInfoFields) => {
  sessionStorage.setItem(bookingIntentDataKey, JSON.stringify(data));
};

export const getBookingIntentData = (): BeforeBookingLeadPageInfoFields | undefined => {
  const data = sessionStorage.getItem(bookingIntentDataKey);
  return data ? parseDataWithCatch<BeforeBookingLeadPageInfoFields>(data) : undefined;
};
