import { createContext } from 'react';
import { Status } from '../../../types/SubmitDataTypes';
import { FirestoreCleaningForKey } from '../../../types/Cleaning';
import { SelectedBookingInfo } from '../../../types/StateTypes';

export interface IBookingKeyContextDataContext {
  cleaning: FirestoreCleaningForKey | undefined;
  key: string;
  status: Status;
  resetState: () => void;
  applyKey: (key: string, currentSelected: SelectedBookingInfo) => Promise<string>;
}

export const defaultValues: IBookingKeyContextDataContext = {
  cleaning: undefined,
  key: '',
  status: Status.unset,
  resetState: () => null,
  applyKey: () => Promise.resolve(''),
};

export const BookingKeyContext = createContext<IBookingKeyContextDataContext>(defaultValues);
