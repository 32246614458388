import withStyles from '@mui/styles/withStyles';
import { FormControl } from '@mui/material';
import { borderColor, textFieldHoverBorderColor } from '../../../Assets/Styles/colors';

export const StyledFormControl = withStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: borderColor,
      },
      '&:hover fieldset': {
        borderColor: textFieldHoverBorderColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: textFieldHoverBorderColor,
      },
    },
  },
}))(FormControl);
