import { Grid } from '@mui/material';
import { FunctionComponent, Fragment } from 'react';
import styles from './CheckListTable.module.scss';
import { cleanChecklist } from '../../../Assets/Data/CleaningInfo';
import { AddonProductInfo } from '../../../types/StripeDataState';
import CheckIcon from '../../../Assets/Images/svg/checkMark.svg';
import CloseIcon from '../../../Assets/Images/svg/cross.svg';
import BookNowButton from '../BookNowButton/BookNowButton';

interface CheckListTableProps {
  name: string;
  deep?: boolean;
  moveOut?: boolean;
  recurring?: boolean;
  addonsOptions?: AddonProductInfo[];
}

const CheckListTable: FunctionComponent<CheckListTableProps> = ({
  deep,
  moveOut,
  recurring,
  addonsOptions,
  name,
}: CheckListTableProps) => {
  return (
    <div className={styles.root}>
      <Grid
        container
        justifyContent="center"
        direction="column"
        item
        xs={12}
        sm={11}
        md={9}
        lg={9}
        className={styles.tableContainer}>
        <Grid item xs="auto" className={styles.textsContainer}>
          <h2 className={styles.subText}>CHECK LIST</h2>
          <h1 className={styles.tableTitle}>{name} Cleaning Checklist</h1>
        </Grid>

        <Grid item xs={12} sm={12} md={10} lg={9} justifyContent="center" className={styles.tableRoot}>
          <table className={styles.table} aria-label="checklist table">
            <tbody>
              {cleanChecklist.map(({ category, values, isAddon }) => {
                if (isAddon && addonsOptions && addonsOptions.length > 0) {
                  return (
                    <Fragment key={`category-${category}`}>
                      <tr className={styles.categoryNameRow}>
                        <th className={styles.headerColumn} colSpan={2}>
                          <h3 className={styles.columnTitle}>{category}</h3>
                        </th>
                      </tr>
                      {addonsOptions.map((row) => (
                        <tr key={`${row.id}-${category}`} className={styles.categoryItem}>
                          <td className={styles.leftColumn}>
                            <p className={styles.columnText}>{row.name}</p>
                          </td>
                          <td className={styles.rightColumn}></td>
                        </tr>
                      ))}
                    </Fragment>
                  );
                } else {
                  return (
                    <Fragment key={`category-${category}`}>
                      <tr className={styles.categoryNameRow}>
                        <th className={styles.headerColumn} colSpan={2}>
                          <h3 className={styles.columnTitle}>{category}</h3>
                        </th>
                      </tr>
                      {values.map((row) => {
                        const rowValue = deep
                          ? row.deep
                          : moveOut
                          ? row.moveOut
                          : recurring
                          ? row.recurring
                          : undefined;
                        return (
                          <tr key={`${row.key}-${category}`} className={styles.categoryItem}>
                            <td className={styles.leftColumn}>
                              <p className={styles.columnText}>{row.key}</p>
                            </td>
                            <td className={styles.rightColumn}>
                              {rowValue ? (
                                rowValue === true ? (
                                  <CheckIcon className={styles.rootIconStyle} />
                                ) : (
                                  <p className={styles.columnText}>{rowValue}</p>
                                )
                              ) : (
                                <CloseIcon className={styles.rootIconStyle} />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </Fragment>
                  );
                }
              })}
            </tbody>
          </table>
        </Grid>
      </Grid>

      <div className={styles.bookNowBtn}>
        <BookNowButton onlyScroll={false} />
      </div>
    </div>
  );
};

export default CheckListTable;
