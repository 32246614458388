import AnalyticsManager from './AnalyticsManager';
import HttpClient from './HttpManager';

export const LogError = (name: string, err: Record<string, unknown>, isFatal?: boolean): void => {
  if (process.env.NODE_ENV === undefined) {
    return;
  }

  if (process.env.NODE_ENV === 'production') {
    AnalyticsManager.logError(name, isFatal || false, err);
    void LogErrorToServer(name, isFatal || false, err);
  } else {
    logToConsole(undefined, err);
  }
};

export const LogHttpCallError = (name: string, err: unknown, isFatal?: boolean): void => {
  if (process.env.NODE_ENV === undefined) {
    return;
  }

  if (process.env.NODE_ENV === 'production') {
    const errorMessage = typeof err == 'string' ? err : (err as never)['message'] || 'unknown error';
    AnalyticsManager.logError(name, isFatal || false, errorMessage);
    // void LogErrorToServer(name, isFatal || false, errorMessage);
  } else {
    logToConsole(err);
  }
};

const logToConsole = (err: unknown, otherError?: unknown) => {
  err && console.log('Error', err);
  otherError && console.log('otherError', otherError);
};

export function DebugLog(...args: unknown[]) {
  if (process.env.NODE_ENV === undefined) {
    return;
  }
  process.env.NODE_ENV !== 'production' && console.debug(...args);
}

const LogErrorToServer = async (name: string, isFatal: boolean, err: unknown) => {
  try {
    await HttpClient<unknown>({
      url: '/log/error',
      method: 'POST',
      data: {
        errorData: { source: name, isFatal: isFatal || false, errorData: err },
      },
    });
  } catch (e) {
    //
  }
};
