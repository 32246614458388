import { FunctionComponent, useContext, useMemo } from 'react';
import { Grid } from '@mui/material';
import { RadioButtonGroup } from '../../../Components';
import styles from './StripePaymentForm.module.scss';
import { observer } from 'mobx-react';
import { BookingKeyContext } from '../../../Contexts/BookingKeyContext/BookingKeyContext';

interface StripePaymentFormProps {
  showPaymentForm: boolean;
  setShowPaymentForm: (val: boolean) => void;
}

const PaymentButtons: FunctionComponent<StripePaymentFormProps> = ({
  showPaymentForm,
  setShowPaymentForm,
}: StripePaymentFormProps) => {
  const bookingKeyContext = useContext(BookingKeyContext);
  const buttonsOptions = useMemo(() => {
    return [
      { key: `Use card on file - ending ${bookingKeyContext.cleaning?.cardUsed || ''}` },
      { key: 'Provide new payment method' },
    ];
  }, [bookingKeyContext.cleaning?.cardUsed]);

  const onButtonClick = (option: unknown) => {
    const op = option as { key: string };
    if (op.key === buttonsOptions[1].key) {
      setShowPaymentForm(true);
    } else {
      setShowPaymentForm(false);
    }
  };

  return (
    <Grid container className={styles.paymentButtonsContainer}>
      <Grid item xs={12}>
        <RadioButtonGroup
          selectedOption={showPaymentForm ? buttonsOptions[1].key : buttonsOptions[0].key}
          options={buttonsOptions}
          onChangeOption={onButtonClick}
          groupName="card select"
        />
      </Grid>
    </Grid>
  );
};

export default observer(PaymentButtons);
