import { FunctionComponent, useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { Grid, Typography, Divider } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import styles from './SuccessfulPayment.module.scss';
import { SelectedCleaning } from '../../../types/CleaningDataPayload';
import { LocationState } from '../../../types/HistoryLocation';
import CalendarTimesManager from '../../../Mobx/FetchingManagers/CalendarTimesManager';
import { SubmitDataContext } from '../../Contexts/SubmitDataContext/SubmitDataContext';

const defaultRedirectPath = '/home';
const validFromPath = '/bookclean';
const footer =
  'If you have any questions or want to reschedule or cancel your appointment, please reach us at (206) 486-0038 or email info@cheetahcleaners.com';

const SuccessfulPayment: FunctionComponent = () => {
  const submitDataContext = useContext(SubmitDataContext);
  const availableTimesData = CalendarTimesManager.getAvailableTimes();
  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location.state as LocationState;

  const shouldRenderSuccessPage: boolean = useMemo(() => {
    return Boolean(locationState?.from === validFromPath && submitDataContext.data);
  }, [locationState?.from]);

  useEffect(() => {
    return () => {
      submitDataContext.resetStateAfterSubmit();
    };
  }, []);

  useEffect(() => {
    if (!shouldRenderSuccessPage) {
      if (defaultRedirectPath !== location.pathname) {
        navigate(defaultRedirectPath, { replace: true });
      }
    }
  }, [shouldRenderSuccessPage]);

  const getSummaryData = () => {
    if (!submitDataContext.data) {
      return [];
    }

    const selectedCleaning = (submitDataContext.data as never)['selectedCleaningInfo']
      ? ((submitDataContext.data as never)['selectedCleaningInfo'] as SelectedCleaning)
      : undefined;

    const apartmentInfo = selectedCleaning
      ? `${selectedCleaning.apartmentType}, ${selectedCleaning.bathrooms}, ${selectedCleaning.halfBathrooms}, ${selectedCleaning.threeForthBathrooms}`
      : '';

    const date = getDateString(submitDataContext.data.date, 8);
    const addressVal = (submitDataContext.data as never)['address']
      ? [{ title: 'Address', value: (submitDataContext.data as never)['address'] }]
      : [];
    const apartmentInfoVal = apartmentInfo ? [{ value: apartmentInfo, title: 'Cleaning information' }] : [];
    const dateVal = date
      ? [
          {
            value: date,
            title: 'Date of cleaning',
          },
        ]
      : [];
    const summaryData: { value: string; title: string }[] = [...addressVal, ...apartmentInfoVal, ...dateVal];

    return summaryData;
  };

  const getDateString = (dateStr: string, firstHour: number): string => {
    const momentDate = dateStr ? moment(dateStr) : undefined;
    if (momentDate) {
      const hours = momentDate.hours();
      if (hours === firstHour) {
        return momentDate.format(`dddd, MMMM Do YYYY [arriving at] h:mm A`);
      }
      const tipeSlot = momentDate.format('h:mm A');
      const timeSlotValueData = availableTimesData?.timeSlots[tipeSlot] || undefined;
      const eventDuration = timeSlotValueData ? timeSlotValueData.eventDuration : 2;
      const adjustedDate = momentDate.clone().add(eventDuration, 'hours').format('h:mm A');
      const d = momentDate.format(`dddd, MMMM Do YYYY [between] h:mm A`);
      return `${d} - ${adjustedDate}`;
    }

    return '';
  };

  return shouldRenderSuccessPage ? (
    <div className={styles.successfulPayment}>
      <div className={styles.successfulPaymentContainer}>
        <Grid container direction="column" className={styles.successInfoContainer}>
          <Grid item xs={12} className={styles.successItem}>
            <Typography variant="h3">Successful Booking</Typography>
          </Grid>
          <Grid item xs={12} className={styles.successItem}>
            <Typography variant="h5">Your card will be charged the day of service.</Typography>
            {submitDataContext.data?.personalInfo?.email && (
              <Typography variant="h5">
                {`We've just sent a confirmation email to `}
                <b>{submitDataContext.data.personalInfo.email}</b>
                {`. Please check your promotions folder if you don't see it.`}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} className={styles.successItem}>
            <CheckCircleOutlineIcon className={styles.successIcon} />
          </Grid>
          <Grid className={styles.successItem}>
            <Typography variant="h4">Summary:</Typography>
          </Grid>
          <Grid item xs={12} className={styles.successItem}>
            {getSummaryData().map((item, index) => (
              <Typography key={`${index}-summary-item`} className={styles.summaryItem}>
                <b>{item.title}:</b>
                {` ${item.value}`}
              </Typography>
            ))}
          </Grid>
        </Grid>
        <Divider></Divider>
        <div className={styles.contactInfo}>
          <Typography variant="body1">{footer}</Typography>
        </div>
      </div>
    </div>
  ) : null;
};

export default SuccessfulPayment;
