import { FunctionComponent } from 'react';
import { Typography, Grid } from '@mui/material';
import styles from './ServingAreas.module.scss';
import { norServingAreas, servingAreas } from '../../../Assets/Data/ServingAreas';

const ServingAreas: FunctionComponent = () => {
  const n = servingAreas.length / 4;
  const m = norServingAreas.length;

  return (
    <Grid container justifyContent="center" alignItems="center">
      <div className={styles.container}>
        <div>
          <Typography variant="h3" className={styles.title}>
            Service Areas
          </Typography>
        </div>

        <Grid container justifyContent="center" direction="column" item xs={12}>
          <div className={styles.areaContainer}>
            <div className={styles.areaContainerColumn}>
              {servingAreas.slice(0, n).map((area) => (
                <Typography variant="h6" key={area} className={styles.areaName}>
                  {area}
                </Typography>
              ))}
            </div>
            <div className={styles.areaContainerColumn}>
              {servingAreas.slice(n, n * 2).map((area) => (
                <Typography variant="h6" key={area} className={styles.areaName}>
                  {area}
                </Typography>
              ))}
            </div>
            <div className={styles.areaContainerColumn}>
              {servingAreas.slice(n * 2, n * 3).map((area) => (
                <Typography variant="h6" key={area} className={styles.areaName}>
                  {area}
                </Typography>
              ))}
            </div>
            <div className={styles.areaContainerColumn}>
              {servingAreas.slice(n * 3).map((area) => (
                <Typography variant="h6" key={area} className={styles.areaName}>
                  {area}
                </Typography>
              ))}
            </div>
          </div>
        </Grid>

        <div>
          <Typography variant="h3" className={styles.title2}>
            {`Areas We Don't Serve`}
          </Typography>
        </div>

        <Grid container justifyContent="center" direction="column" item xs={12}>
          <div className={styles.areaContainer}>
            <div className={styles.areaContainerColumn}>
              {norServingAreas.slice(0, m).map((area) => (
                <Typography variant="h6" key={area} className={styles.areaName}>
                  {area}
                </Typography>
              ))}
            </div>
          </div>
        </Grid>
      </div>
    </Grid>
  );
};

export default ServingAreas;
