import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useIsLandingPage = () => {
  const [isInitialPageLandingPage, setInitialPageLandingPage] = useState(false);
  const [isLandingPage, setIsLandingPage] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setInitialPageLandingPage(pathname === '/landing');
  }, []);

  useEffect(() => {
    setIsLandingPage(pathname === '/landing');
  }, [pathname]);

  return [isLandingPage, isInitialPageLandingPage];
};
