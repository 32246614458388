import { FunctionComponent } from 'react';
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { StripeInputField } from '../../../Components';
import StripeLogo from '../../../../Assets/Images/svg/stripe_logo.svg';
import { PaymentFormData } from '../../../../types/PaymentFormDataTypes';
import { FormInputsValidation, StripeCardElements, StripeCardObject, StripeEvent } from '../../../../types/StripeTypes';
import styles from './StripePaymentForm.module.scss';
import { Grid } from '@mui/material';
import { observer } from 'mobx-react';
import PaymentFormDataManager from '../../../../Mobx/BookingFlowData/PaymentFormDataManager';
import AnalyticsManager from '../../../../Utils/AnalyticsManager';

interface CardPaymentStripeInputFieldsProps {
  setFormErrors: (newErrors: FormInputsValidation) => void;
  setCardDetails: (newCardVals: StripeCardObject) => void;
  formErrors: FormInputsValidation;
  cardDetails: StripeCardObject;
}

const CardPaymentStripeInputFields: FunctionComponent<CardPaymentStripeInputFieldsProps> = ({
  setFormErrors,
  setCardDetails,
  formErrors,
  cardDetails,
}: CardPaymentStripeInputFieldsProps) => {
  const paymentForm: PaymentFormData = PaymentFormDataManager.getPaymentFields();
  const onChangeCardStripeInputField = (e: unknown) => {
    const event: StripeEvent = e as StripeEvent;
    const error = !event.complete ? false : Boolean(event.error);
    setFormErrors({ ...formErrors, [event.elementType]: error });
    setCardDetails({ ...cardDetails, [event.elementType]: event });
  };

  const onBlurCardStripeInputField = (e: unknown) => {
    const event: StripeEvent = e as StripeEvent;
    const cardStripeInputField = event.elementType as StripeCardElements;
    if (Object.values(StripeCardElements).indexOf(cardStripeInputField) === -1) {
      return;
    }

    const complete = cardDetails[cardStripeInputField]?.complete ?? false;
    setFormErrors({ ...formErrors, [event.elementType]: !complete });
  };

  const onFocusStripeInputField = (e: unknown) => {
    const event: StripeEvent = e as StripeEvent;
    setFormErrors({ ...formErrors, [event.elementType]: undefined });
  };

  const getErrorMessage = (StripeInputField: string, defaultErrorMessage: string) => {
    const cardStripeInputField = StripeInputField as StripeCardElements;
    if (Object.values(StripeCardElements).indexOf(cardStripeInputField) === -1) {
      return defaultErrorMessage;
    }

    return cardDetails[cardStripeInputField]?.error?.message ?? defaultErrorMessage;
  };

  const goToStripe = () => {
    AnalyticsManager.logIconButtonClick('Go to stripe');
    window.open('https://stripe.com/');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <StripeInputField
          label={paymentForm.cardNumber.label}
          name={paymentForm.cardNumber.name}
          StripeComponent={CardNumberElement}
          inputProps={{
            onChange: onChangeCardStripeInputField,
            onBlur: onBlurCardStripeInputField,
            onFocus: onFocusStripeInputField,
            pattern: '[0-9]*',
          }}
          error={formErrors.cardNumber}
          helperText={
            formErrors.cardNumber ? getErrorMessage(paymentForm.cardNumber.name, paymentForm.cardNumber.helperText) : ''
          }
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <StripeInputField
          StripeComponent={CardExpiryElement}
          label={paymentForm.expirationDate.label}
          name={paymentForm.expirationDate.name}
          inputProps={{
            onChange: onChangeCardStripeInputField,
            onBlur: onBlurCardStripeInputField,
            onFocus: onFocusStripeInputField,
          }}
          error={formErrors.cardExpiry}
          helperText={
            formErrors.cardExpiry
              ? getErrorMessage(paymentForm.expirationDate.name, paymentForm.expirationDate.helperText)
              : ''
          }
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <StripeInputField
          StripeComponent={CardCvcElement}
          label={paymentForm.cvc.label}
          name={paymentForm.cvc.name}
          inputProps={{
            onChange: onChangeCardStripeInputField,
            onBlur: onBlurCardStripeInputField,
            onFocus: onFocusStripeInputField,
            pattern: '[0-9]*',
          }}
          error={formErrors.cardCvc}
          helperText={formErrors.cardCvc ? getErrorMessage(paymentForm.cvc.name, paymentForm.cvc.helperText) : ''}
        />
      </Grid>
      <Grid item xs={12}>
        <button
          className={styles.stripePowerLogo}
          aria-label="Stripe"
          onClick={(e) => {
            e.preventDefault();
            goToStripe();
          }}>
          <StripeLogo />
        </button>
      </Grid>
    </Grid>
  );
};

export default observer(CardPaymentStripeInputFields);
