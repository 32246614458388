import { FunctionComponent, ReactNode } from 'react';
import styles from './Review.module.scss';
import Star from '../../../../../Assets/Images/svg/star.svg';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

export interface ReviewProps {
  stars: number;
  text: string;
  name: string;
}

const Review: FunctionComponent<ReviewProps> = ({ stars, text, name }: ReviewProps) => {
  // const elementRef = useRef<HTMLDivElement>(null);

  const makeStars = () => {
    const starsObj: ReactNode[] = [];
    for (let i = 0; i < stars; i++) {
      starsObj.push(
        <div key={`starIcon-${i}`} className={styles.starIcon}>
          <Star />
        </div>
      );
    }

    return starsObj;
  };

  // const onClick = () => {
  //   if (elementRef.current) {
  //     const offset = 0;
  //     const left = elementRef.current.getBoundingClientRect().left;
  //     const right = elementRef.current.getBoundingClientRect().right;
  //     const isLeftVisible = left + offset >= 0 && left - offset <= window.innerWidth;
  //     const isRightVisible = right + offset >= 0 && right - offset <= window.innerWidth;
  //     return isLeftVisible && isRightVisible;
  //   }
  // };
  // onClick={onClick} ref={elementRef}

  return (
    <div className={styles.root}>
      <div className={styles.review}>
        <div className={styles.stars}>{makeStars()}</div>
        <p className={styles.text}>{text}</p>
        <p className={styles.name}>{name}</p>
      </div>
      <div className={styles.quoteIcon}>
        <FormatQuoteIcon className={styles.quoteIconMui} />
      </div>
    </div>
  );
};

export default Review;
