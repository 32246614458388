import { makeAutoObservable } from 'mobx';
import { bookingSteps } from '../../Assets/Data/BookingConstants';
import {
  ParkingOptions,
  AddonDataType,
  AddressFormFieldsName,
  AddressFormRegisteredFields,
  InformationFormFieldsName,
  KeyOptions,
} from '../../types/AddressFormTypes';
import { AddressInfo, AddressObject } from '../../types/CleaningDataPayload';
import { GetDefaultCity, GetEmptyCity, selectValidCityOptions } from '../../Assets/Data/BookingInformation';
import { CityOptions } from '../../types/BookingFormDataTypes';

class AddressFormDataManager {
  private addressFormState: AddressFormRegisteredFields;

  constructor() {
    this.addressFormState = this.resetState();
    makeAutoObservable(this);
  }

  public getAddressFormValues = () => {
    return this.addressFormState;
  };

  public getAddOns = () => {
    return this.addressFormState.addOns;
  };

  public updateAddressFormFields = (addressFormValues: AddressFormRegisteredFields) => {
    const addons = this.addressFormState.addOns;
    this.addressFormState = { ...addressFormValues, addOns: addons };
  };

  public updateAddons = (addonsValues: AddonDataType[]) => {
    this.addressFormState.addOns = addonsValues;
  };

  public getSelectedCity = (): CityOptions => {
    return this.getCityFromString(this.addressFormState.city, false);
  };

  public updateAddressFormFieldsPartial = (addressFormValues: Partial<AddressFormRegisteredFields>) => {
    this.addressFormState = { ...this.addressFormState, ...addressFormValues };
  };

  public getCityFromString = (city: string, shouldGetDefaultCity: boolean): CityOptions => {
    if (!city) {
      return shouldGetDefaultCity ? GetDefaultCity() : GetEmptyCity();
    }
    const c = city.toLowerCase();
    const foundCity = selectValidCityOptions.find((op) => op.key.toLowerCase() === c);

    return foundCity ?? shouldGetDefaultCity ? GetDefaultCity() : GetEmptyCity();
  };

  public setData = (
    addressObj: AddressObject,
    parkingInfo: AddressInfo<string>,
    keyInfo: AddressInfo<string>,
    petInfo: AddressInfo<string[]>,
    otherInfo: string,
    addOns: AddonDataType[]
  ) => {
    const initialState: AddressFormRegisteredFields = {
      [AddressFormFieldsName.state]: addressObj.state,
      [AddressFormFieldsName.address]: addressObj.streetName,
      [AddressFormFieldsName.apartment]: addressObj.apartment,
      [AddressFormFieldsName.zip]: addressObj.zipCode,
      [AddressFormFieldsName.city]: addressObj.city,
      [AddressFormFieldsName.parking]: parkingInfo.type as ParkingOptions,
      [AddressFormFieldsName.keyOption]: keyInfo.type as KeyOptions,
      [AddressFormFieldsName.petOption]: petInfo.type,
      [AddressFormFieldsName.addOns]: addOns,

      [InformationFormFieldsName.keyInformation]: keyInfo.additionalInfo,
      [InformationFormFieldsName.parkingInformation]: parkingInfo.additionalInfo,
      [InformationFormFieldsName.petInformation]: petInfo.additionalInfo,
      [InformationFormFieldsName.otherInformation]: otherInfo,
    };

    this.addressFormState = initialState;

    return initialState;
  };

  public resetState = () => {
    const defaultCity = GetDefaultCity();
    const initialState: AddressFormRegisteredFields = {
      [AddressFormFieldsName.address]: '',
      [AddressFormFieldsName.apartment]: '',
      [AddressFormFieldsName.zip]: '',
      [AddressFormFieldsName.city]: defaultCity.key,
      [AddressFormFieldsName.parking]: ParkingOptions.notSet,
      [AddressFormFieldsName.keyOption]: KeyOptions.notSet,
      [AddressFormFieldsName.petOption]: [],
      [AddressFormFieldsName.addOns]: [],
      [AddressFormFieldsName.state]: '',

      [InformationFormFieldsName.keyInformation]: '',
      [InformationFormFieldsName.parkingInformation]: '',
      [InformationFormFieldsName.petInformation]: '',
      [InformationFormFieldsName.otherInformation]: '',
    };

    this.addressFormState = initialState;

    return initialState;
  };

  public getDisplayAddress = (): string => {
    const apartment = this.addressFormState.apartment ? `, Apt. ${this.addressFormState.apartment}` : '';
    const addressToDisplay = this.addressFormState.address
      ? this.addressFormState.address + apartment
      : bookingSteps[2];
    return addressToDisplay.trim();
  };

  public getFullAddress = (): string => {
    if (!this.addressFormState.address) {
      return bookingSteps[2];
    }
    const apartment = this.addressFormState.apartment ? `, Apt. ${this.addressFormState.apartment}` : '';
    const addressToDisplay = this.addressFormState.address + apartment;

    const address = `${addressToDisplay.trim()}, ${this.addressFormState.city}, ${this.addressFormState.state} ${
      this.addressFormState.zip
    }`;
    return address;
  };
}

export default new AddressFormDataManager();
