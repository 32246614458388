import { PaymentFields, PaymentFormJson } from '../../types/PaymentFormDataTypes';

export const leadSourceOptions = [
  'Instagram',
  'Facebook Ad',
  'Facebook Group',
  'Mailed Postcard',
  'Flyer',
  'Business Card',
  'Nextdoor',
  'Referral',
  'Internet Search',
  'Google Maps',
  'Other',
];

export const PaymentFormData: PaymentFormJson = {
  constantValues: {
    email: {
      name: PaymentFields.email,
      defaultValue: '',
      label: 'Email',
      helperText: 'Invalid imail address',
      placeholder: 'Email address',
    },
    firstName: {
      name: PaymentFields.firstName,
      defaultValue: '',
      label: 'First name',
      helperText: 'Invalid first name',
      placeholder: 'First name',
    },
    lastName: {
      name: PaymentFields.lastName,
      defaultValue: '',
      label: 'Last name',
      helperText: 'Invalid last name',
      placeholder: 'Last name',
    },
    phone: {
      name: PaymentFields.phone,
      defaultValue: '',
      label: 'Phone number',
      helperText: 'Invalid phone number',
      placeholder: 'Phone number',
    },
    leadSource: {
      name: PaymentFields.leadSource,
      options: leadSourceOptions,
      defaultValue: '',
      label: 'How did you hear about us?',
      helperText: 'Invalid value',
      placeholder: '',
    },
    cardNumber: {
      name: PaymentFields.cardNumber,
      defaultValue: '',
      label: 'Card number',
      placeholder: '',
      helperText: 'Your card number is invalid.',
    },
    expirationDate: {
      name: PaymentFields.expirationDate,
      defaultValue: '',
      label: 'Card expiration',
      placeholder: '',
      helperText: 'Expiration date is invalid.',
    },
    cvc: {
      name: PaymentFields.cvc,
      defaultValue: '',
      label: 'CVC',
      placeholder: '',
      helperText: 'CVC number is invalid.',
    },
  },
};
