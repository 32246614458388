import { AbandonedCartBody } from '../types/AbandonedCart';
import { DataForAbandonedCart } from '../types/StateTypes';
import { addCartToBackend } from './ApiCalls/ApiCalls';
import { LogError } from './ErrorLog';
import { selectDataForAbandonedCart } from './SelectDataToSubmit';

export const addCartDataToFirebaseFinalPage = async (fullBookingState: DataForAbandonedCart) => {
  try {
    const personalInfo = fullBookingState.paymentForm;
    if (
      personalInfo.email.defaultValue &&
      (personalInfo.firstName.defaultValue || personalInfo.lastName.defaultValue)
    ) {
      const cartData: AbandonedCartBody = {
        allData: selectDataForAbandonedCart(fullBookingState),
      };

      await addCartToBackend(cartData);
    }
  } catch (e) {
    LogError('addCartDataToFirebaseFinalPage', { message: JSON.stringify(e) }, true);
  }
};
