import { FunctionComponent } from 'react';
import { Typography } from '@mui/material';
import styles from './NotFound.module.scss';

const NotFound: FunctionComponent = () => (
  <div className={styles.pageNotFound}>
    <Typography variant="h3">Page not found</Typography>
  </div>
);

export default NotFound;
