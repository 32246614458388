import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import AnalyticsManager from '../../../Utils/AnalyticsManager';
import { BlogPostType } from '../../Pages/BlogPost/BlogPosts';
import ImageComponent from '../ImageComponent/ImageComponent';
import styles from './BlogCard.module.scss';

interface BlogCardProps {
  postData: BlogPostType;
}

const BlogCard: FunctionComponent<BlogCardProps> = ({ postData }: BlogCardProps) => {
  const gotToPost = () => {
    AnalyticsManager.logLinkClick(`navigate to article ${postData.title}`);
  };

  return (
    <Link
      className={styles.root}
      to={`/blog/${postData.id}`}
      onClick={gotToPost}
      aria-label={`navigate to article ${postData.title}`}>
      <div className={`${styles.imgContainer} ${styles.item}`}>
        <ImageComponent source={postData.image || ''} path={postData.imgSrc} altText="decorative" />
      </div>
      <div className={`${styles.text} ${styles.item}`}>
        <Typography variant="h4" className={styles.title}>
          {postData.title}
        </Typography>
      </div>
      <div className={`${styles.hoverView}`}>
        <Typography className={styles.seePost}>See post</Typography>
      </div>
    </Link>
  );
};

export default BlogCard;
