import React from 'react';

export const BookPage = React.lazy(() => import('./BookPage/BookPage'));
export const Contact = React.lazy(() => import('./Contact/Contact'));
export const Faq = React.lazy(() => import('./Faq/Faq'));
export const Services = React.lazy(() => import('./InfoPages/Services'));
export const SuccessfulPayment = React.lazy(() => import('./SuccessfulPayment/SuccessfulPayment'));
export const NotFound = React.lazy(() => import('./NotFound/NotFound'));
export const HomePage = React.lazy(() => import('./HomePage/HomePage'));
export const Safety = React.lazy(() => import('./Safety/Safety'));
export const RecurringClean = React.lazy(() => import('./RecurringClean/RecurringClean'));
export const Terms = React.lazy(() => import('./InfoPages/Terms'));
export const SuccessfulRecurringBook = React.lazy(() => import('./SuccessfulPayment/SuccessfulRecurringBook'));
export const CancellationFees = React.lazy(() => import('./InfoPages/CancellationFees'));
export const Forbidden = React.lazy(() => import('./Forbidden/Forbidden'));
export const MoveOutClean = React.lazy(() => import('./MoveOutClean/MoveOutClean'));
export const ServingAreas = React.lazy(() => import('./ServingAreas/ServingAreas'));
export const DeepClean = React.lazy(() => import('./DeepClean/DeepClean'));
export const RecurringCleanInfo = React.lazy(() => import('./RecurringCleanInfo/RecurringCleanInfo'));
export const SuccessResume = React.lazy(() => import('./SuccessResume/SuccessResume'));
export const LoginPage = React.lazy(() => import('./LoginPage/LoginPage'));
export const Blog = React.lazy(() => import('./Blog/Blog'));
export const BlogPost = React.lazy(() => import('./BlogPost/BlogPost'));
export const PrivacyPolicy = React.lazy(() => import('./InfoPages/PrivacyPolicy'));
export const HelpSignIn = React.lazy(() => import('./HelpSignIn/HelpSignIn'));
export const SignUp = React.lazy(() => import('./Signup/Signup'));

export const SelectedCleaning = React.lazy(() => import('../Containers/CustomerPortal/MenuItems/SelectedCleaning'));
export const Account = React.lazy(() => import('../Containers/CustomerPortal/MenuItems/Account/Account'));
export const CustomerPortal = React.lazy(() => import('./CustomerPortal/CustomerPortal'));
export const Home = React.lazy(() => import('../Containers/CustomerPortal/MenuItems/Home'));

export const BannerAlert = React.lazy(() => import('../Containers/BannerAlert/BannerAlert'));

export const RedirectToExternal = React.lazy(() => import('../../RedirectToExternal'));

export const Alert = React.lazy(() => import('../Containers/Alert/Alert'));
export const AirbnbContactPopup = React.lazy(() => import('../Containers/BookForm/AirbnbContactPopup'));

export const BeforeBookingLeadPage = React.lazy(() => import('./BeforeBookingLeadPage/BeforeBookingLeadPage'));
