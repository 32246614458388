import { makeAutoObservable } from 'mobx';
import { PaymentFormData as InitialData } from '../../Assets/Data/PaymentForm';
import { PersonalInfo } from '../../types/CleaningDataPayload';
import { PaymentFields, PaymentFormData } from '../../types/PaymentFormDataTypes';
import {
  getBookingIntentData,
  setBookingIntentData as setSessionBookingIntentData,
} from '../../Utils/SessionStorageUtils';
import { BeforeBookingLeadPageInfoFields } from '../../types/ContactUsData';

class PaymentFormDataManager {
  private paymentForm: PaymentFormData = InitialData.constantValues;

  constructor() {
    const enteredBookingData = getBookingIntentData();
    if (enteredBookingData) {
      this.setBookingIntentData(enteredBookingData);
    }
    makeAutoObservable(this);
  }

  public getPaymentFields = () => {
    return this.paymentForm;
  };

  public setData = (leadSource: string, personalInfo: PersonalInfo) => {
    this.updateLeadSource(leadSource);
    this.updatePaymentFormPersonalInfo(personalInfo);
    const data: BeforeBookingLeadPageInfoFields = {
      fullName: ((personalInfo.firstName || '') + ' ' + (personalInfo.lastName || '')).trim(),
      email: personalInfo.email,
      phone: personalInfo.phone,
      leadSource,
    };
    setSessionBookingIntentData(data);
  };

  public resetState = () => {
    const dataToKeep: Partial<PaymentFormData> = {
      firstName: this.paymentForm[PaymentFields.firstName],
      lastName: this.paymentForm[PaymentFields.lastName],
      email: this.paymentForm[PaymentFields.email],
      phone: this.paymentForm[PaymentFields.phone],
      leadSource: this.paymentForm[PaymentFields.leadSource],
    };
    this.paymentForm = { ...InitialData.constantValues, ...dataToKeep };
  };

  public isPersonalInfoEntered = (): boolean => {
    return Boolean(
      this.paymentForm[PaymentFields.phone].defaultValue &&
        this.paymentForm[PaymentFields.firstName].defaultValue &&
        this.paymentForm[PaymentFields.lastName].defaultValue &&
        this.paymentForm[PaymentFields.email].defaultValue
    );
  };

  public isBookingFormInfoEntered = (): boolean => {
    return Boolean(
      this.paymentForm[PaymentFields.phone].defaultValue &&
        this.paymentForm[PaymentFields.firstName].defaultValue &&
        this.paymentForm[PaymentFields.email].defaultValue &&
        this.paymentForm[PaymentFields.leadSource].defaultValue
    );
  };

  public updatePaymentFormPersonalInfo = (personalInfo: PersonalInfo) => {
    const changedValueEmail = {
      ...this.paymentForm[PaymentFields.email],
      defaultValue: personalInfo.email,
    };
    const changedValueFirstName = {
      ...this.paymentForm[PaymentFields.firstName],
      defaultValue: personalInfo.firstName,
    };
    const changedValueLastName = {
      ...this.paymentForm[PaymentFields.lastName],
      defaultValue: personalInfo.lastName,
    };
    const changedValuePhone = {
      ...this.paymentForm[PaymentFields.phone],
      defaultValue: personalInfo.phone,
    };
    const newPaymentForm = {
      ...this.paymentForm,
      [PaymentFields.firstName]: changedValueFirstName,
      [PaymentFields.lastName]: changedValueLastName,
      [PaymentFields.phone]: changedValuePhone,
      [PaymentFields.email]: changedValueEmail,
    };

    this.paymentForm = newPaymentForm;
  };

  public getPersonalInfo = () => {
    const info: PersonalInfo = {
      firstName: this.paymentForm.firstName.defaultValue,
      lastName: this.paymentForm.lastName.defaultValue,
      phone: this.paymentForm.phone.defaultValue,
      email: this.paymentForm.email.defaultValue,
    };

    return info;
  };

  public setBookingIntentData = (data: BeforeBookingLeadPageInfoFields) => {
    const [first, last] = data.fullName.split(' ');
    const personalInfo: PersonalInfo = {
      email: data.email,
      phone: data.phone,
      firstName: first || '',
      lastName: last || '',
    };
    this.updatePaymentFormPersonalInfo(personalInfo);
    this.updateLeadSource(data.leadSource);
  };

  public updatePaymentFormFields = (fieldName: PaymentFields, value: string) => {
    this.update(fieldName, value);
  };

  public updateLeadSource = (leadSource: string) => {
    this.updatePaymentFormFields(PaymentFields.leadSource, leadSource);
  };

  public updatePaymentFormArrayFields = (fieldName: PaymentFields, value: string[]) => {
    this.update(fieldName, value);
  };

  private update = (fieldName: PaymentFields, value: string | string[]) => {
    const changedValue = {
      ...this.paymentForm[fieldName],
      defaultValue: value,
    };
    const newPaymentForm = {
      ...this.paymentForm,
      [fieldName]: changedValue,
    };

    this.paymentForm = newPaymentForm;
  };
}

export default new PaymentFormDataManager();
