import { enableLogin } from '../../Config';

const login = enableLogin ? [{ name: 'Customer Portal', path: '/portal' }] : [];
const bookWithKey = []; // enableBookWithKey ? [{ name: 'Book with key', path: '/recurringClean' }] : [];

export const navigationLinks = [
  { name: 'Home', path: '/home' },
  { name: 'Service Areas', path: '/serving-areas' },
  { name: 'Deep Clean', path: '/deep-clean' },
  { name: 'Recurring Clean', path: '/recurring-clean' },
  { name: 'Move Out Clean', path: '/move-out-clean' },
  ...bookWithKey,
  { name: 'Blog', path: '/blog' },
  { name: 'Cancellation Policy', path: '/cancellation' },
  { name: 'FAQ', path: '/faq' },
  { name: 'Contact', path: '/contact' },
  ...login,
];

export const navigationLinksTop = [
  { name: 'Home', path: '/home' },
  { name: 'Service Areas', path: '/serving-areas' },
  { name: 'Deep Clean', path: '/deep-clean' },
  { name: 'Recurring Clean', path: '/recurring-clean' },
  { name: 'Move Out Clean', path: '/move-out-clean' },
  { name: 'Cancellation Policy', path: '/cancellation' },
];

export const careersLink = 'https://jobs.gusto.com/boards/cheetah-cleaners-2d37ed1b-5b9e-4ee3-a761-d0c2b00e2d64';

export const footerQuickLinks = [
  { name: 'Home', path: '/home' },
  { name: 'Service Areas', path: '/serving-areas' },
  { name: 'Cancellation Policy', path: '/cancellation' },
  { name: 'FAQ', path: '/faq' },
  { name: 'Contact', path: '/contact' },
];
