import { FunctionComponent, useMemo } from 'react';
import { Divider, Typography } from '@mui/material';
import styles from './Receipt.module.scss';
import { observer } from 'mobx-react';
import SelectedOptionsManager from '../../../Mobx/BookingFlowData/SelectedOptionsManager';
import CleanDateTimeManager from '../../../Mobx/BookingFlowData/CleanDateTimeManager';
import AddressFormDataManager from '../../../Mobx/BookingFlowData/AddressFormDataManager';
import CalendarTimesManager from '../../../Mobx/FetchingManagers/CalendarTimesManager';

const Information: FunctionComponent = () => {
  const selectedCleaning = SelectedOptionsManager.getSelected();
  const date = CleanDateTimeManager.getDateTime();
  const address = AddressFormDataManager.getFullAddress();
  const selectedTimeSlot = CleanDateTimeManager.getTimeSlot();
  const timeSlotRangeEnd = CalendarTimesManager.getTextForTimeSlot(selectedTimeSlot);
  const dateString =
    date && selectedTimeSlot
      ? `${date.format('dddd, MMMM Do YYYY')} ${
          timeSlotRangeEnd ? `arriving between ${selectedTimeSlot} and ${timeSlotRangeEnd}` : `at ${selectedTimeSlot}`
        }`
      : '';
  const apartmentInfo = `${selectedCleaning.apartmentType.key}, ${selectedCleaning.bathrooms.key}, ${selectedCleaning.halfBathrooms.key}, ${selectedCleaning.threeForthBathrooms.key}`;
  const cleanText = useMemo(() => {
    const text = selectedCleaning.cleaningFrequency.isRecurring
      ? selectedCleaning.cleaningFrequency.key
      : selectedCleaning.cleaningType.key;
    return text.toLowerCase().endsWith('clean') ? text : `${text} Clean`;
  }, [selectedCleaning.cleaningType.key, selectedCleaning.cleaningFrequency.key]);

  return (
    <>
      <Typography className={styles.receiptRow}>{apartmentInfo}</Typography>
      <Divider />
      <Typography className={styles.receiptRow}>{cleanText}</Typography>
      <Divider />
      <Typography className={styles.receiptRow}>
        Starting on <br />
        {dateString ? (
          <span className={styles.dateString}>{dateString}</span>
        ) : (
          <span className={styles.dateStringEmpty}>Select date</span>
        )}
      </Typography>
      <Divider />
      <Typography className={styles.receiptRow}>{address}</Typography>
      <Typography className={styles.receiptRow}>{`${selectedCleaning.squareFootage.numberKey} sqf.`}</Typography>
    </>
  );
};

export default observer(Information);
