import { stripeData } from '../Assets/Data/StripeData';
import { selectedBookingInfo } from '../Mobx/States/InitialSelectedState';
import { AddonDataType } from '../types/AddressFormTypes';
import { CleaningFrequency, SelectedBookingInfoItems } from '../types/BookingFieldsEnum';
import { BookingTypeOptions, SquareFootageOptions } from '../types/BookingFormDataTypes';
import { SelectedBookingInfo } from '../types/StateTypes';
import {
  AddonProductInfo,
  BookingChoiceKeys,
  CleaningSelectionOptions,
  SelectedStripeInfo,
  StripeCleaningFields,
  StripeInfoForClean,
} from '../types/StripeDataState';
import { roundPrice } from './CalculatePrice';

export const StringToNumber = (tipValue: string): number => {
  let tipNumber = parseInt(tipValue);
  tipNumber = isNaN(tipNumber) ? 0 : tipNumber;
  tipNumber = roundPrice(tipNumber);
  return tipNumber;
};

export const preselectAddons = (addons: AddonProductInfo[], cleaningFrequency: CleaningFrequency): AddonDataType[] => {
  const result: AddonDataType[] = [];
  addons.forEach((productInfo) => {
    if (productInfo.moveOutPreselected) {
      const price = productInfo ? productInfo.price : undefined;

      let priceIdOneTime = '';
      let priceOneTime = 0;
      let priceIdRecurring = '';
      let priceRecurring = 0;

      if (price) {
        const pOneTime = price[CleaningFrequency.oneTime];
        const pRecurring = price[cleaningFrequency];
        priceIdOneTime = pOneTime?.id || '';
        priceOneTime = pOneTime?.price || 0;
        priceIdRecurring = pRecurring?.id || '';
        priceRecurring = pRecurring?.price || 0;
      }

      const data: AddonDataType = {
        productId: productInfo.id,
        price: priceOneTime,
        name: productInfo.name,
        isFirstCleanOnly: false,
        quantity: 1,
        priceId: priceIdOneTime,
        recurringPrice: priceRecurring,
        recurringPriceId: priceIdRecurring,
      };
      result.push(data);
    }
  });

  return result;
};

export const preselectAddonsPrice = (addons: AddonProductInfo[]): number => {
  let totalPrice = 0;
  addons.forEach((productInfo) => {
    if (productInfo.moveOutPreselected) {
      const price = productInfo ? productInfo.price : undefined;
      const pFreq = price && price[0];
      const pAll = pFreq ? pFreq : price ? price['all'] : undefined;
      const p = pFreq || pAll;
      if (p) {
        totalPrice += p.price;
      }
    }
  });

  return totalPrice;
};

const getStripeInfo = (
  stripeCleaningData: Record<string, CleaningSelectionOptions>,
  freq: CleaningFrequency,
  selectedOption: BookingTypeOptions,
  key: string
): StripeInfoForClean => {
  const data = stripeCleaningData[key];
  const productInfo = data ? data['all'] : undefined;
  const price = productInfo ? productInfo.price : undefined;
  const priceInfo = price && price[freq];

  const returnObj: StripeInfoForClean = {
    productId: productInfo?.id || '',
    price: priceInfo?.price || 0,
    value: selectedOption.value,
    priceId: priceInfo?.id || '',
  };

  return returnObj;
};

export const getStripeInfoForSelectedOption = (
  cleaningData: Record<string, CleaningSelectionOptions>,
  isRecurring: boolean,
  selectedOption: BookingTypeOptions,
  squareFootage: SquareFootageOptions,
  cleanigFreq: CleaningFrequency,
  key: string
): SelectedStripeInfo => {
  const oneTimeData = isRecurring
    ? getStripeInfo(cleaningData, CleaningFrequency.oneTime, selectedOption, key)
    : getStripeInfo(cleaningData, cleanigFreq, selectedOption, key);
  const recurringData = isRecurring ? getStripeInfo(cleaningData, cleanigFreq, selectedOption, key) : oneTimeData;

  return { oneTime: oneTimeData, recurring: recurringData };
};

export const getDeepCleanInfo = (
  stripeCleaningData: Record<string, CleaningSelectionOptions>,
  sqf: SquareFootageOptions
): StripeInfoForClean => {
  const sqfData = stripeCleaningData[sqf.value];
  const productInfo = sqfData ? sqfData.deep : undefined;
  const price = productInfo ? productInfo.price : undefined;
  const priceInfo = price && price[CleaningFrequency.oneTime];

  const data: StripeInfoForClean = {
    productId: productInfo?.id || '',
    price: priceInfo?.price || 0,
    priceId: priceInfo?.id || '',
  };

  return data;
};

// Deprecate
export const updateAllCleaningData = (
  stripeCleaningData: Record<string, CleaningSelectionOptions>,
  selected: SelectedBookingInfo
): StripeCleaningFields => {
  const isRecurring = selected.cleaningType.isRecurring;

  const oneTimeData = getData(stripeCleaningData, selected, CleaningFrequency.oneTime);
  const recurringData = !isRecurring
    ? oneTimeData
    : getData(stripeCleaningData, selected, selected.cleaningFrequency.key);

  const sqfInfoOneTime = getSqfInfo(stripeCleaningData, selected.squareFootage, CleaningFrequency.oneTime);
  const sqfInfoOneRecurring = !isRecurring
    ? sqfInfoOneTime
    : getSqfInfo(stripeCleaningData, selected.squareFootage, selected.cleaningFrequency.key);

  const newSelected: StripeCleaningFields = {
    [SelectedBookingInfoItems.apartmentType]: { oneTime: oneTimeData.apt, recurring: recurringData.apt },
    [SelectedBookingInfoItems.bathrooms]: { oneTime: oneTimeData.bath, recurring: recurringData.bath },
    [SelectedBookingInfoItems.halfBathrooms]: { oneTime: oneTimeData.halfBath, recurring: recurringData.halfBath },
    [SelectedBookingInfoItems.threeForthBathrooms]: {
      oneTime: oneTimeData.threeForth,
      recurring: recurringData.threeForth,
    },
    [SelectedBookingInfoItems.staircase]: { oneTime: oneTimeData.staircase, recurring: recurringData.staircase },
    [SelectedBookingInfoItems.commonAreas]: { oneTime: oneTimeData.commonAreas, recurring: recurringData.commonAreas },
    [SelectedBookingInfoItems.extraKitchen]: {
      oneTime: oneTimeData.extraKitchen,
      recurring: recurringData.extraKitchen,
    },
    [SelectedBookingInfoItems.kitchenLivingRoomDining]: { oneTime: sqfInfoOneTime, recurring: sqfInfoOneRecurring },
  };

  return newSelected;
};

export const updateAllSelectedCleaningData = (
  stripeCleaningData: Record<string, CleaningSelectionOptions>,
  selected: SelectedBookingInfo
): SelectedBookingInfo => {
  const isRecurring = selected.cleaningType.isRecurring;

  const oneTimeData = getData(stripeCleaningData, selected, CleaningFrequency.oneTime);
  const recurringData = !isRecurring
    ? oneTimeData
    : getData(stripeCleaningData, selected, selected.cleaningFrequency.key);

  selected.apartmentType.stripeInfo = { oneTime: oneTimeData.apt, recurring: recurringData.apt };
  selected.bathrooms.stripeInfo = { oneTime: oneTimeData.bath, recurring: recurringData.bath };
  selected.halfBathrooms.stripeInfo = { oneTime: oneTimeData.halfBath, recurring: recurringData.halfBath };
  selected.threeForthBathrooms.stripeInfo = { oneTime: oneTimeData.threeForth, recurring: recurringData.threeForth };
  selected.staircase.stripeInfo = { oneTime: oneTimeData.staircase, recurring: recurringData.staircase };
  selected.commonAreas.stripeInfo = { oneTime: oneTimeData.commonAreas, recurring: recurringData.commonAreas };
  selected.extraKitchen.stripeInfo = { oneTime: oneTimeData.extraKitchen, recurring: recurringData.extraKitchen };

  const sqfInfoOneTime = getSqfInfo(stripeCleaningData, selected.squareFootage, CleaningFrequency.oneTime);
  const sqfInfoOneRecurring = !oneTimeData
    ? sqfInfoOneTime
    : getSqfInfo(stripeCleaningData, selected.squareFootage, selected.cleaningFrequency.key);
  selected.kitchenLivingRoomDining.stripeInfo = { oneTime: sqfInfoOneTime, recurring: sqfInfoOneRecurring };

  return selected;
};

export const getSqfInfo = (
  stripeCleaningData: Record<string, CleaningSelectionOptions>,
  selectedSqf: SquareFootageOptions,
  selectedCleaningFreq: CleaningFrequency
): StripeInfoForClean => {
  const litchenLivingRoomSqf = stripeCleaningData[BookingChoiceKeys.kitchenLivingRoomDining];
  const sqfMinNum = Math.floor(selectedSqf.numberKey / 100);
  const sqfStrMin = sqfMinNum.toString();
  let sqfOption = litchenLivingRoomSqf[sqfStrMin];

  let closestMinKeyStr = '';
  if (!sqfOption) {
    const sqfKeys = Object.keys(litchenLivingRoomSqf);
    let closestMinKey = sqfKeys[0];
    for (const key of sqfKeys) {
      if (Number(key) < sqfMinNum) {
        closestMinKey = key;
      } else {
        closestMinKeyStr = closestMinKey;
        break;
      }
    }
    sqfOption = litchenLivingRoomSqf[closestMinKeyStr];
  }

  const priceObj = sqfOption ? sqfOption.price[selectedCleaningFreq] : undefined;

  const data: StripeInfoForClean = {
    productId: sqfOption?.id || '',
    price: priceObj?.price || 0,
    value: 1,
    priceId: priceObj?.id || '',
  };

  return data;
};

const getData = (
  stripeCleaningData: Record<string, CleaningSelectionOptions>,
  selected: SelectedBookingInfo,
  cleaningFrequency: CleaningFrequency
) => {
  const apt = getStripeInfo(
    stripeCleaningData,
    cleaningFrequency,
    selected.apartmentType,
    SelectedBookingInfoItems.apartmentType
  );
  const bath = getStripeInfo(
    stripeCleaningData,
    cleaningFrequency,
    selected.bathrooms,
    SelectedBookingInfoItems.bathrooms
  );
  const halfBath = getStripeInfo(
    stripeCleaningData,
    cleaningFrequency,
    selected.halfBathrooms,
    SelectedBookingInfoItems.halfBathrooms
  );
  const threeForth = getStripeInfo(
    stripeCleaningData,
    cleaningFrequency,
    selected.threeForthBathrooms,
    SelectedBookingInfoItems.threeForthBathrooms
  );
  const staircase = getStripeInfo(
    stripeCleaningData,
    cleaningFrequency,
    selected.staircase,
    SelectedBookingInfoItems.staircase
  );
  const commonAreas = getStripeInfo(
    stripeCleaningData,
    cleaningFrequency,
    selected.commonAreas,
    SelectedBookingInfoItems.commonAreas
  );
  const extraKitchen = getStripeInfo(
    stripeCleaningData,
    cleaningFrequency,
    selected.extraKitchen,
    SelectedBookingInfoItems.extraKitchen
  );

  return { apt, bath, halfBath, threeForth, staircase, commonAreas, extraKitchen };
};

export const initialSelected = updateAllSelectedCleaningData(stripeData.cleanings, selectedBookingInfo);
