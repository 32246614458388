import { FunctionComponent, ReactNode, useEffect, useState } from 'react';

interface HiddenProps {
  screenWidthToShow: number;
  children: ReactNode;
  isSmaller?: boolean;
  otherChildren?: ReactNode;
}

const Hidden: FunctionComponent<HiddenProps> = ({
  screenWidthToShow,
  children,
  otherChildren,
  isSmaller,
}: HiddenProps) => {
  const [show, setShow] = useState(
    isSmaller ? window.innerWidth <= screenWidthToShow : window.innerWidth > screenWidthToShow
  );

  const handleResize = () => {
    const screenWidth = window.innerWidth;
    if (isSmaller) {
      setShow(screenWidth <= screenWidthToShow);
    } else {
      setShow(screenWidth > screenWidthToShow);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <>{show ? children : otherChildren || null}</>;
};

export default Hidden;
