import { Grid, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { FunctionComponent, useContext } from 'react';
import { observer } from 'mobx-react';
import PrimaryButton from '../../../Components/PrimaryButton/PrimaryButton';
import styles from './LandingPageInfoInputs.module.scss';
import { Field } from '../../../Components';
import { landingPageData } from '../../../../Assets/Data/LandingPageInfoData';
import { LandingPageInfoFields, LandingPageInfoDataFieldsName } from '../../../../types/ContactUsData';
import { emailPattern } from '../../../../Assets/Data/RegexStrings';
import { useNavigate } from 'react-router-dom';
import { LogError } from '../../../../Utils/ErrorLog';
import { BookingChoiceKeys } from '../../../../types/StripeDataState';
import { cleaningFieldsInfo } from '../../../../Assets/Data/BookingInformation';
import SelectedOptionsManager from '../../../../Mobx/BookingFlowData/SelectedOptionsManager';
import { requestQuoteDataSend } from '../../../../Utils/ApiCalls/ApiCalls';
import { AlertContext } from '../../../Contexts/Alert/AlertContext';

interface LandingPageInfoInputsProps {
  isVertical: boolean;
}

const LandingPageInfoInputs: FunctionComponent<LandingPageInfoInputsProps> = ({
  isVertical,
}: LandingPageInfoInputsProps) => {
  const formData = landingPageData.formData;
  const alertContext = useContext(AlertContext);

  const navigate = useNavigate();
  const methods = useForm<LandingPageInfoFields>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: false,
    shouldUnregister: true,
  });

  const onValidSubmit = (data: LandingPageInfoFields) => {
    void requestQuoteDataSend(data);
    navigate('/bookclean');
  };

  const onSubmitForm = (event: React.SyntheticEvent) => {
    event.preventDefault();
    methods
      .trigger()
      .then((isValid) => {
        if (isValid) {
          onValidSubmit(methods.getValues());
        } else {
          // methods.errors.firstName ? formData.firstName.errorMessage : ''
          alertContext.openAlert('Invalid inputs. Please fill all the required fields', false);
        }
      })
      .catch((e) => LogError('onSubmitForm', { message: JSON.stringify(e) }, false));
  };

  const sm = isVertical ? 12 : 6;

  return (
    <div className={`${styles.root} ${isVertical ? styles.vertical : styles.horizontal}`}>
      {isVertical && <Typography className={styles.title}>{landingPageData.mainTitle}</Typography>}
      <form onSubmit={onSubmitForm} className={styles.form}>
        <Grid container>
          <Grid className={styles.columnsDropDown} item xs={12} sm={sm}>
            <Controller
              control={methods.control}
              name="bedrooms"
              rules={formData.bedrooms.rules}
              render={({ field: { onChange, onBlur, ref } }) => (
                <Field
                  type="number"
                  label={formData.bedrooms.label}
                  inputProps={{ min: 0, max: 4 }}
                  className={styles.field}
                  name={formData.bedrooms.name}
                  placeholder={formData.bedrooms.placeholder}
                  error={methods.formState.errors.bedrooms ? true : false}
                  inputRef={ref}
                  onChange={onChange}
                  onBlur={(e) => {
                    const value = e.target.value;
                    const numVal = Number(value);
                    if (!isNaN(numVal) && numVal >= 0 && numVal <= 4) {
                      const newValue = cleaningFieldsInfo.apartmentType.options.find((i) => i.value === numVal);
                      newValue
                        ? SelectedOptionsManager.updateSelected(newValue, BookingChoiceKeys.apartmentType)
                        : methods.setValue(LandingPageInfoDataFieldsName.bedrooms, '');
                    } else {
                      methods.setValue(LandingPageInfoDataFieldsName.bedrooms, '');
                    }

                    onBlur();
                  }}
                />
              )}
            />
          </Grid>
          <Grid className={styles.columnsDropDown} item xs={12} sm={sm}>
            <Controller
              control={methods.control}
              name="bathrooms"
              rules={formData.bathrooms.rules}
              render={({ field: { onChange, onBlur, ref } }) => (
                <Field
                  type="number"
                  label={formData.bathrooms.label}
                  inputProps={{ min: 1, max: 3 }}
                  className={styles.field}
                  name={formData.bathrooms.name}
                  placeholder={formData.bathrooms.placeholder}
                  error={methods.formState.errors.bathrooms ? true : false}
                  inputRef={ref}
                  onChange={onChange}
                  onBlur={(e) => {
                    const value = e.target.value;
                    const numVal = Number(value);
                    if (!isNaN(numVal) && numVal >= 1 && numVal <= 3) {
                      const newValue = cleaningFieldsInfo.bathrooms.options.find((i) => i.value === numVal);
                      newValue
                        ? SelectedOptionsManager.updateSelected(newValue, BookingChoiceKeys.bathrooms)
                        : methods.setValue(LandingPageInfoDataFieldsName.bathrooms, '');
                    } else {
                      methods.setValue(LandingPageInfoDataFieldsName.bathrooms, '');
                    }

                    onBlur();
                  }}
                />
              )}
            />
          </Grid>
          <Grid className={styles.columnsDropDown} item xs={12} sm={sm}>
            <Controller
              control={methods.control}
              name="firstName"
              rules={formData.firstName.rules}
              render={({ field: { onChange, onBlur, ref } }) => (
                <Field
                  type="text"
                  label={formData.firstName.label}
                  className={styles.field}
                  autoComplete="given-name"
                  name={formData.firstName.name}
                  placeholder={formData.firstName.placeholder}
                  error={methods.formState.errors.firstName ? true : false}
                  inputRef={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </Grid>
          <Grid className={styles.columnsDropDown} item xs={12} sm={sm}>
            <Controller
              control={methods.control}
              name="email"
              rules={{
                ...formData.email.rules,
                pattern: emailPattern,
              }}
              render={({ field: { onChange, onBlur, ref } }) => (
                <Field
                  type="email"
                  label={formData.email.label}
                  className={styles.field}
                  autoComplete="email"
                  name={formData.email.name}
                  placeholder={formData.email.placeholder}
                  error={methods.formState.errors.email ? true : false}
                  inputRef={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid className={styles.submitButton} item xs={12}>
          <PrimaryButton type="submit">{landingPageData.submitButtonText}</PrimaryButton>
        </Grid>
      </form>
    </div>
  );
};

export default observer(LandingPageInfoInputs);
