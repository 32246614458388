import moment from 'moment-timezone';
import { dateAndTimeSlotFormat, defaultTimeZone, eventsKeyFormat, shouldSeparateRecurring } from '../Config';

export const toDateAndTimeWithTimezone = (
  selectedDay: moment.Moment,
  selectedTime: string,
  timezone = defaultTimeZone
) => {
  const dateString = moment(selectedDay).format(eventsKeyFormat);
  const startDate = moment.tz(`${dateString} ${selectedTime}`, dateAndTimeSlotFormat, timezone);
  return startDate;
};

export const getDateWithFormat = (dateStr: string, formatStr: string, timezone = defaultTimeZone) => {
  return moment.tz(dateStr, formatStr, timezone);
};

export const getTomorrowsDateTime = () => {
  const today = moment().add(1, 'd');
  return moment.tz(today, defaultTimeZone).format(eventsKeyFormat);
};

export const availableWeekend = (
  momentObject: moment.Moment,
  disableWeekend: boolean,
  cleaningTypeRecurring: boolean
): boolean => {
  if (cleaningTypeRecurring) {
    return isWeekend(momentObject);
  }

  if (!disableWeekend) return false;
  return isWeekend(momentObject);
};

export const isSameDate = (date1: moment.Moment | undefined, date2: moment.Moment | undefined): boolean => {
  if (!date1 || !date2) {
    return false;
  }
  return date1.format('YYYY-MM-DD') === date2.format('YYYY-MM-DD');
};

export const makeDays = (
  year: number,
  month: number,
  events: Record<string, Record<string, boolean>>,
  eventsRecurring: Record<string, Record<string, boolean>>,
  timeSlots: string[],
  cleaningTypeRecurring: boolean
): {
  isDisabled: boolean;
  dayMoment: moment.Moment;
}[] => {
  const numOfDays = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();
  const days: {
    isDisabled: boolean;
    dayMoment: moment.Moment;
  }[] = [];

  const firstDay = new Date(year, month - 1, 1);
  const firstDayWeekNumber = moment(firstDay);
  if (firstDayWeekNumber.weekday() !== 0) {
    for (let i = firstDayWeekNumber.weekday(); i >= 1; i--) {
      days.push({
        isDisabled: true,
        dayMoment: moment(firstDay).subtract(i, 'days'),
      });
    }
  }

  let isPast = true;
  for (let i = 1; i <= numOfDays; i++) {
    const d = moment(`${year}-${month}`, 'YYYY-MM').date(i);
    if (isPast) {
      isPast = d.isBefore();
    }

    days.push({
      isDisabled:
        isPast ||
        isAllTimeSlotsTaken(d, events, eventsRecurring, timeSlots, cleaningTypeRecurring) ||
        isHoliday(d) ||
        availableWeekend(d, true, cleaningTypeRecurring),
      dayMoment: d,
    });
  }

  return days;
};

const isAllTimeSlotsTaken = (
  d: moment.Moment,
  events: Record<string, Record<string, boolean>>,
  eventsRecurring: Record<string, Record<string, boolean>>,
  timeSlots: string[],
  isRecurring: boolean
) => {
  const eventKey = d.format(eventsKeyFormat);

  const eventsForSelectedDayRecurring = eventsRecurring[eventKey] || {};
  const eventsForSelectedDay = events[eventKey] || {};

  if (shouldSeparateRecurring) {
    return isTimeSlotFull(isRecurring ? eventsForSelectedDayRecurring : eventsForSelectedDay, timeSlots);
  }

  return isTimeSlotTakenForBoth(
    Object.keys(eventsForSelectedDay),
    Object.keys(eventsForSelectedDayRecurring),
    timeSlots
  );
};

const isTimeSlotFull = (eventsForSelectedDay: Record<string, boolean>, timeSlots: string[]) => {
  return Object.keys(eventsForSelectedDay).length === timeSlots.length;
};

const isTimeSlotTakenForBoth = (
  eventsForSelectedDay: string[],
  eventsForSelectedDayRecurring: string[],
  timeSlots: string[]
) => {
  const slots = new Set(timeSlots);
  const events = new Set(eventsForSelectedDay);
  const eventsRecurring = new Set(eventsForSelectedDayRecurring);
  timeSlots.forEach((timeSlot) => {
    if (events.has(timeSlot) || eventsRecurring.has(timeSlot)) {
      slots.delete(timeSlot);
    }
  });

  return slots.size === 0;
};

export const isHoliday = (momentObject: moment.Moment): boolean => {
  const date = momentObject.format(hilidaysToDisableFormat);
  return hilidaysToDisable.includes(date);
};

const hilidaysToDisableFormat = 'MM/DD';
const hilidaysToDisable = ['12/24', '12/25'];

const isWeekend = (momentObject: moment.Moment) => {
  const dayOfWeek = momentObject.weekday();
  const weekend = dayOfWeek === 6 || dayOfWeek === 0;
  return weekend;
};
