import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import styles from './Titles.module.scss';

interface TitlesProps {
  title1: string;
  title2: string;
}

const Titles: FunctionComponent<TitlesProps> = ({ title1, title2 }: TitlesProps) => {
  return (
    <>
      {title1 && (
        <Typography variant="body1" className={styles.processTitle}>
          {title1}
        </Typography>
      )}
      {title2 && (
        <Typography variant="body2" className={styles.mainText}>
          {title2}
        </Typography>
      )}
    </>
  );
};

export default Titles;
