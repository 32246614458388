import { FunctionComponent } from 'react';
import styles from './Footer.module.scss';
import SocialMedia from './SocialMedia';
import QuickLinks from './QuickLinks';
import ContactInformation from './ContactInformation';
import { Grid } from '@mui/material';
import LogoButton from '../Header/LogoButton/LogoButton';
import { mainTitle, mainTitleLandingPage } from '../../../Assets/Data/MainInfo';
import { useIsLandingPage } from '../../CustomHooks/useIsLandingPage';
import { useLocation } from 'react-router-dom';

const Footer: FunctionComponent = () => {
  const [isLandingPage] = useIsLandingPage();
  const { pathname } = useLocation();
  const shouldLoad = pathname !== '/bookclean';
  const currentYear = new Date().getFullYear();

  return (
    <>
      {shouldLoad ? (
        <div className={styles.footer}>
          <Grid container className={styles.footerContent}>
            <Grid
              item
              xs={12}
              sm={12}
              md="auto"
              lg="auto"
              className={`${styles.contactData} ${styles.socialMediaContainer}`}>
              <LogoButton disabled={isLandingPage} />
              <p className={styles.text}>{isLandingPage ? mainTitleLandingPage : mainTitle}</p>
              <SocialMedia />
            </Grid>

            {!isLandingPage && (
              <Grid
                item
                xs={6}
                sm={6}
                md="auto"
                lg="auto"
                className={`${styles.contactData} ${styles.quickLinksContainer}`}>
                <QuickLinks />
              </Grid>
            )}

            <Grid
              item
              xs={isLandingPage ? 12 : 6}
              sm={isLandingPage ? 12 : 6}
              md="auto"
              lg="auto"
              className={`${styles.contactData} ${styles.contactInfoContainer}`}>
              <ContactInformation />
            </Grid>
          </Grid>
          <div className={styles.bottomContainer}>
            <div className={styles.divider}></div>
            <p className={styles.footerBottomText}>{`© ${currentYear} Cheetah Cleaners LLC. All Rights Reserved.`}</p>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Footer;
