// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

body {
  height: 100%;
  min-width: 335px;
  margin: 0;
  font-family: 'playfair display', Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  overflow: hidden !important;
  width: 100vw;
}

html {
  height: 100%;
  margin: 0;
  font-family: 'playfair display', Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;
}

#root {
  height: 100%;
  margin: 0;
  position: relative;
  overflow: hidden !important;
}

button {
  font-family: 'playfair display' !important;
  color: black;
}

p,
h1,
h2,
h3,
h4,
h5,
button,
label {
  font-family: 'Playfair display' !important;
}

button {
  cursor: pointer !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,2BAA2B;EAC3B,sBAAsB;AACxB;;AAEA;;;;;;EAME,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,SAAS;EACT,0GAA0G;EAC1G,mCAAmC;EACnC,kCAAkC;EAClC,eAAe;EACf,2BAA2B;EAC3B,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,SAAS;EACT,+FAA+F;AACjG;;AAEA;EACE,YAAY;EACZ,SAAS;EACT,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;EACE,0CAA0C;EAC1C,YAAY;AACd;;AAEA;;;;;;;;EAQE,0CAA0C;AAC5C;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":["* {\n  -webkit-box-sizing: border-box;\n  -moz-box-sizing: border-box;\n  box-sizing: border-box;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  font-weight: 500;\n}\n\nbody {\n  height: 100%;\n  min-width: 335px;\n  margin: 0;\n  font-family: 'playfair display', Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif !important;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  position: fixed;\n  overflow: hidden !important;\n  width: 100vw;\n}\n\nhtml {\n  height: 100%;\n  margin: 0;\n  font-family: 'playfair display', Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;\n}\n\n#root {\n  height: 100%;\n  margin: 0;\n  position: relative;\n  overflow: hidden !important;\n}\n\nbutton {\n  font-family: 'playfair display' !important;\n  color: black;\n}\n\np,\nh1,\nh2,\nh3,\nh4,\nh5,\nbutton,\nlabel {\n  font-family: 'Playfair display' !important;\n}\n\nbutton {\n  cursor: pointer !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
