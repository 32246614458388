import { FunctionComponent } from 'react';
import { Divider, Typography } from '@mui/material';
import styles from './Receipt.module.scss';
import Information from './Information';
import PromoCodeField from './PromoCodeField';
import CleanPrice from './CleanPrice';
import AddTip from './AddTip';
import PriceTabs from '../PriceTabs/PriceTabs';
import { CleaningType } from '../../../types/BookingFieldsEnum';
import { observer } from 'mobx-react';
import SelectedOptionsManager from '../../../Mobx/BookingFlowData/SelectedOptionsManager';
import { isMobile } from '../../../Utils/Common';

interface OwnProps {
  isRecurringOnly: boolean;
  isDesktop?: boolean;
}

const Receipt: FunctionComponent<OwnProps> = ({ isRecurringOnly, isDesktop = false }: OwnProps) => {
  const isSelectedCleaningRecurring = SelectedOptionsManager.isSelectedCleanRecurring();
  const selectedCleaningType: CleaningType = SelectedOptionsManager.getSelected().cleaningType.key;

  return (
    <div className={`${styles.receiptContainer} ${isDesktop || !isMobile() ? '' : `${styles.receiptContainerMobile}`}`}>
      <Typography className={styles.receiptHeader} variant="h4">
        Receipt
      </Typography>
      <div className={styles.receiptData}>
        <Information />
        <PromoCodeField selectedCleaningType={selectedCleaningType} />
        {isRecurringOnly ? (
          <CleanPrice isRecurring={true} />
        ) : isSelectedCleaningRecurring ? (
          <PriceTabs />
        ) : (
          <CleanPrice isRecurring={false} />
        )}
        <Divider />
        <AddTip />
      </div>
      <div className={styles.submitAllData}>
        <button form="payment-form" type="submit">
          Submit
        </button>
      </div>
    </div>
  );
};

export default observer(Receipt);
