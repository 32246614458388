import { FunctionComponent } from 'react';
import { md } from '../../../../Assets/Styles/constants';
import { initialMoveOut, initialRecurring, selectedBookingInfo } from '../../../../Mobx/States/InitialSelectedState';
import { Hidden } from '../../../Components';
import Titles from '../Titles/Titles';
import CleaningType, { CleaningTypeProps } from './CleaningType/CleaningType';
import styles from './CleaningTypes.module.scss';
import CleaningTypesWrapped from './CleaningTypesWrapped/CleaningTypesWrapped';

const CleaningTypes: FunctionComponent = () => {
  const data: CleaningTypeProps[] = [
    {
      title: 'Recurring',
      // text: 'Outsource your cleaning chores.',
      price: initialRecurring,
      timeToClean: 'Weekly, Biweekly, or monthly',
      mostPopular: false,
    },
    {
      title: 'Deep Clean',
      //  text: 'Freshen up your home.',
      price: selectedBookingInfo,
      timeToClean: 'one time or semi-frequent',
      mostPopular: false,
    },
    {
      title: 'Move Out',
      //  text: `Get it broom clean.`,
      price: initialMoveOut,
      timeToClean: 'one time deep clean',
      mostPopular: false,
    },
  ];

  const dataArray = data.map((item) => (
    <CleaningType
      key={item.title}
      title={item.title}
      text={item.text}
      price={item.price}
      timeToClean={item.timeToClean}
      mostPopular={item.mostPopular}
    />
  ));

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <Titles title1="CLEANING" title2="Types of Cleans" />
        <Hidden screenWidthToShow={md}>
          <div className={styles.types}>{dataArray}</div>
        </Hidden>
        <Hidden screenWidthToShow={md} isSmaller={true}>
          <CleaningTypesWrapped>{dataArray}</CleaningTypesWrapped>
        </Hidden>
      </div>
    </div>
  );
};

export default CleaningTypes;
