import { FunctionComponent, useContext, useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import styles from './RecurringClean.module.scss';
import { InfoModal, ValidateKey } from '../../Components';
import { Status } from '../../../types/SubmitDataTypes';
import { Calendar, PaymentForm, Receipt } from '../../Containers';
import RecurringCleanOptions from './RecurringCleanOptions';
import { observer } from 'mobx-react';
import SelectedOptionsManager from '../../../Mobx/BookingFlowData/SelectedOptionsManager';
import { AlertContext } from '../../Contexts/Alert/AlertContext';
import { BookingKeyContext } from '../../Contexts/BookingKeyContext/BookingKeyContext';
import Addons from '../../Containers/AddressInfoForm/Addons';
import BookingFlowStateManager from '../../../Mobx/BookingFlowStateManager';

const RecurringClean: FunctionComponent = () => {
  const bookingKeyContext = useContext(BookingKeyContext);
  const alertContext = useContext(AlertContext);

  const checkKey = async (key: string) => {
    const errorMessage = await bookingKeyContext.applyKey(key, SelectedOptionsManager.getSelected());

    if (errorMessage) {
      alertContext.openAlert(errorMessage, false);
      return errorMessage;
    }

    return errorMessage;
  };

  useEffect(() => {
    BookingFlowStateManager.setDone();

    return () => {
      BookingFlowStateManager.resetStateAfterBookingFlow();
    };
  }, []);

  return (
    <>
      <Grid container justifyContent="center" className={styles.root}>
        <Grid container item xs={12} sm={10} md={8} lg={8} className={styles.container}>
          <div className={styles.bodyContainer}>
            <Typography className={styles.pageTitle} variant="h3">
              Book Recurring Clean
            </Typography>
            <ValidateKey onSubmit={checkKey} placeholder="Enter key value" />
            {bookingKeyContext.cleaning && bookingKeyContext.status === Status.success && (
              <>
                <Typography variant="h4" className={styles.cleaningInfoTitle}>
                  Cleaning information
                </Typography>
                <div className={styles.cleaningInformationContainer}>
                  <RecurringCleanOptions />
                  <Calendar removeTitle />
                  <Addons addOnLabel="Customize your clean" isCustomizeAddonsOnly shouldRemoveRecurringSwitch />
                  <PaymentForm recurring={true} />
                  <Receipt isRecurringOnly={true} />
                </div>
              </>
            )}
          </div>
          {bookingKeyContext.status === Status.inProgress && <InfoModal title="Validating the key..." bodyText="" />}
        </Grid>
      </Grid>
    </>
  );
};

export default observer(RecurringClean);
