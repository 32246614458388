import { errorBorderColor, mainColor, secondaryColor } from '../../../Assets/Styles/colors';
import { createTheme, responsiveFontSizes, ThemeOptions, Theme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body0: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body0?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body0: true;
  }
}

const props: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: mainColor,
    },
    secondary: {
      main: secondaryColor,
    },
    error: {
      main: errorBorderColor,
    },
  },
  typography: {
    fontFamily: ['Playfair Display', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
    h1: {},
    h2: {
      fontWeight: 600,
      fontSize: 50,
    },
    h3: {
      fontWeight: 500,
      fontSize: 52,
    },
    // h4: {
    //   fontWeight: 500,
    //   fontSize: 20,
    //   letterSpacing: '-0.06px',
    // },
    // h5: {
    //   fontWeight: 500,
    //   fontSize: 16,
    //   letterSpacing: '-0.05px',
    // },
    // h6: {
    //   fontWeight: 500,
    //   fontSize: 14,
    //   letterSpacing: '-0.05px',
    // },
    subtitle1: {
      fontSize: 29,
    },
    subtitle2: {
      fontSize: 22,
    },
    body0: { fontSize: 18 },
    body1: {},
    // overline: {
    //   fontWeight: 500,
    // },
  },
  components: {
    MuiSelect: {
      styleOverrides: { select: { fontFamily: 'Playfair Display' } },
    },
    MuiTypography: {
      styleOverrides: { root: { fontFamily: 'Playfair Display' } },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          outline: 'unset !important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: ['Playfair Display', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
          outline: 'unset !important',
          borderRadius: '4px',
          textTransform: 'none',
        },
      },
    },
  },
};

const getTheme = (): Theme => {
  let theme: Theme = createTheme(props);
  theme = responsiveFontSizes(theme);
  theme.typography.h1 = {
    fontSize: 65,
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      fontSize: '2.3rem',
    },
  };

  return theme;
};

export default getTheme();
