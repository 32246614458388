export const servingAreas = [
  'Ballard',
  'Bitter Lake',
  'Blue Ridge',
  'Broadview',
  'Capitol Hill',
  'Central District',
  'Crown Hill',
  'Eastlake',
  'First Hill',
  'Fremont',
  'Green Lake',
  'Greenwood',
  'Haller Lake',
  'Lake City',
  'Loyal Heights',
  'Madison Park',
  'Madison Valley',
  'Madrona',
  'Maple Leaf',
  'Montlake',
  'Northgate',
  'Oak Tree',
  'Phinney',
  'Queen Anne',
  'Ravenna',
  'Roosevelt',
  'Seattle',
  'Shoreline',
  'University District',
  'Wallingford',
  'Wedgwood',
];

export const norServingAreas = [
  'South Lake Union',
  'West Seattle',
  'Beacon Hill',
  'Downtown Seattle',
  'Belltown',
  'Rainier Valley',
  'Georgetown',
];

export const zipsWeDontServe = [
  98121, 98101, 98154, 98104, 98116, 98136, 98126, 98106, 98134, 98101, 98118, 98146, 98168, 98178,
];
