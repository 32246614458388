import { createContext } from 'react';

export interface IBannerAlertInfo {
  isOpen: boolean;
  message?: string;
  isSuccess?: boolean;
}

export interface IBannerAlertDataContext {
  openAlert: (message: string, isSuccess: boolean) => void;
  alertInfo: IBannerAlertInfo;
  closeAlert: () => void;
}

export const defaultValues: IBannerAlertDataContext = {
  // eslint-disable-next-line @typescript-eslint/no-misused-promises, @typescript-eslint/no-unused-vars
  openAlert: (_message: string, _isSuccess: boolean) => null,
  alertInfo: { isOpen: false },
  closeAlert: () => null,
};

export const BannerAlertContext = createContext<IBannerAlertDataContext>(defaultValues);
