import { isProduction, isStaging } from '../Config';
import { CleaningDataPayload, CleaningDataPayloadRecurring } from '../types/CleaningDataPayload';

const enableCustomGa = true;

class GAManager {
  private trafficType = isProduction
    ? {}
    : isStaging
    ? { traffic_type: 'internal' }
    : { traffic_type: 'internal', debug_mode: '1' };

  public logEvent = (eventType: string): void => {
    try {
      enableCustomGa && gtag('event', eventType, { ...this.trafficType });
    } catch (e) {
      //
    }
  };

  public logRegistrationComplete = () => {
    try {
      enableCustomGa && gtag('event', 'registrationComplete', { ...this.trafficType });
    } catch (e) {
      //
    }
  };

  public logEventWithProps = (eventType: string, eventProperties: Record<string, unknown>): void => {
    try {
      enableCustomGa && gtag('event', eventType, { ...eventProperties, ...this.trafficType });
    } catch (e) {
      //
    }
  };

  public logError = (name: string, isFatal: boolean, errorInfo: unknown) => {
    try {
      enableCustomGa &&
        gtag('event', 'exception', { fatal: isFatal, event_label: name, errorInfo, ...this.trafficType });
    } catch (e) {
      //
    }
  };

  public logRevenue = (data: CleaningDataPayload, isRecurring: boolean) => {
    const coupon = data.selectedCleaningInfoData.coupons ? { coupon: data.selectedCleaningInfoData.coupons } : {};
    const priceOneTime = { oneTimePrice: { ...data.price.oneTime, tips: data.price.tips } };
    const priceRecurring = isRecurring ? { recurringPrice: { ...data.price.recurring, tips: data.price.tips } } : {};
    try {
      enableCustomGa &&
        gtag('event', 'purchase', {
          description: `${data.selectedCleaningInfo.cleaningType} Cleaning Service`,
          isRecurring,
          event_label: 'Booked Clean',
          address: data.addressObj,
          ...this.trafficType,
          ...coupon,
          ...priceOneTime,
          ...priceRecurring,
        });
    } catch (e) {
      //
    }
  };

  public logRevenueWithKey = (data: CleaningDataPayloadRecurring) => {
    const coupon = data.selectedCleaningInfoData.coupons ? { coupon: data.selectedCleaningInfoData.coupons } : {};
    const priceOneTime = { oneTimePrice: { ...data.price.oneTime, tips: data.price.tips } };
    const priceRecurring = { recurringPrice: { ...data.price.recurring, tips: data.price.tips } };
    try {
      enableCustomGa &&
        gtag('event', 'purchase', {
          description: `${data.cleaningType} Cleaning Service`,
          isRecurring: true,
          event_label: 'Booked Clean With Key',
          key: data.key,
          ...this.trafficType,
          ...coupon,
          ...priceOneTime,
          ...priceRecurring,
        });
    } catch (e) {
      //
    }
  };
}

export default GAManager;
