import { FunctionComponent } from 'react';
import { Grid } from '@mui/material';
import styles from './RadioButtonGroup.module.scss';
import AnalyticsManager from '../../../Utils/AnalyticsManager';

interface RadioButtonGroupProps {
  options: { key: string; displayOnBookingForm?: string }[];
  selectedOption: string;
  onChangeOption: (data: unknown) => void;
  allowOptions?: unknown[];
  groupName: string;
}

const RadioButtonGroup: FunctionComponent<RadioButtonGroupProps> = ({
  selectedOption,
  allowOptions,
  onChangeOption,
  options,
  groupName,
}: RadioButtonGroupProps) => {
  return (
    <Grid container className={styles.bookingSelectionGroup}>
      {options.map((option) => (
        <button
          disabled={allowOptions && allowOptions.length > 0 ? !allowOptions.includes(option) : false}
          className={`${styles.box} ${selectedOption === option.key ? styles.selectedBox : styles.notSelectedBox}`}
          key={option.key}
          onClick={(e) => {
            e.preventDefault();
            AnalyticsManager.logGroupButtonClick(groupName, { optionSelected: option.key, type: 'radio button group' });
            selectedOption !== option.key && onChangeOption(option);
          }}>
          {option.displayOnBookingForm || option.key}
        </button>
      ))}
    </Grid>
  );
};

export default RadioButtonGroup;
