import { Skeleton } from '@mui/material';
import { FunctionComponent } from 'react';
import styles from './CalendarMonth.module.scss';

interface SkeletonMonthProps {
  daysCount: number;
}

const SkeletonMonth: FunctionComponent<SkeletonMonthProps> = ({ daysCount }: SkeletonMonthProps) => {
  const days = Array.from(Array(daysCount).keys());

  return (
    <>
      {days.map((i) => (
        <Skeleton
          classes={{ root: styles.dayCellSkeleton }}
          animation="pulse"
          key={`dateCell-${i}`}
          variant="rectangular"
        />
      ))}
    </>
  );
};

export default SkeletonMonth;
