import { FunctionComponent, useEffect, useState, useRef } from 'react';
import styles from './DropdownMui.module.scss';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import Select from '@mui/material/Select';
import { withStyles } from '@mui/styles';
import StyledInput from '@mui/material/InputBase';
import { borderColor } from '../../../Assets/Styles/colors';
import AnalyticsManager from '../../../Utils/AnalyticsManager';

const StyledSelect = withStyles(() => ({
  select: {
    '&.MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '20px',
      justifyContent: 'center',
      height: '100% !important',
      borderRadius: '0 !important',
      borderBottom: `1px solid ${borderColor}`,
      borderRight: `1px solid ${borderColor}`,
    },
    '&.Mui-disabled': {
      'backgroundColor': '#cececf',
      'color': '#000000 !important',
      '-webkit-text-fill-color': 'unset !important',
    },
  },
}))(Select);

interface DropdownProps {
  options: { key: string }[];
  selectedOption: string;
  onChangeOption: (data: unknown) => void;
  allowOptions?: unknown[];
  id?: string;
  label?: string;
  groupName: string;
}

const DropdownMui: FunctionComponent<DropdownProps> = ({
  options,
  allowOptions,
  selectedOption,
  onChangeOption,
  id,
  label,
  groupName,
}: DropdownProps) => {
  const selectRef = useRef<HTMLElement | null>(null);
  const [menuWidth, setMenuWidth] = useState(100);
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const val = options.filter((item) => item.key === event.target.value);
    if (val.length > 0) {
      onChangeOption(val[0]);
      AnalyticsManager.logDropdownItemClick(groupName, { optionSelected: val[0].key, type: 'dropdown' });
    }
  };

  useEffect(() => {
    if (selectRef?.current && selectRef.current.offsetWidth > 0) {
      setMenuWidth(selectRef.current.offsetWidth);
    }
  }, [selectRef]);

  return (
    <StyledSelect
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        transitionDuration: 0,
        classes: { root: styles.r, list: styles.list },
        MenuListProps: {
          sx: { width: menuWidth },
        },
      }}
      className={styles.selectRoot}
      value={selectedOption}
      ref={selectRef}
      id={id}
      label={label}
      disabled={
        options.length <= 1 ||
        (allowOptions && allowOptions.length > 0 ? !allowOptions.includes(selectedOption) : false)
      }
      autoWidth
      onChange={handleChange}
      input={<StyledInput />}>
      {options.map((name) => (
        <MenuItem key={name.key} value={name.key}>
          {name.key}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default DropdownMui;
