import { observer } from 'mobx-react';
import { FunctionComponent, useEffect } from 'react';
import { isProduction } from '../../../Config';
import LoginManager from '../../../Mobx/Portal/LoginManager';
import AllManagers from '../../../Utils/AllManagers';

declare global {
  interface Window {
    getAccessToken: () => Promise<string>;
  }
}

const AuthSetup: FunctionComponent = () => {
  const userData = LoginManager.getUser();

  useEffect(() => {
    if (!isProduction) {
      AllManagers.GetAuthManager().initApp(LoginManager.onSignIn, LoginManager.onSignOut);
    }
  }, []);

  window.getAccessToken = async (): Promise<string> => {
    try {
      const token = userData ? await userData.getIdToken() : '';
      return token;
    } catch (e) {
      return '';
    }
  };

  return null;
};

export default observer(AuthSetup);
