import { FunctionComponent } from 'react';
import { Label, MainLink } from '../../../Components';
import {
  BookingTypeOptions,
  CleaningTypeOptions,
  CleaningFrequencyOptions,
  SquareFootageOptions,
  HowCleanIsHomeOptions,
} from '../../../../types/BookingFormDataTypes';
import { cleaningFieldsInfo, includedText } from '../../../../Assets/Data/BookingInformation';
import { BookingChoiceKeys } from '../../../../types/StripeDataState';
import { bookingQuestions } from '../../../../Assets/Data/BookingConstants';
import { observer } from 'mobx-react';
import SelectedOptionsManager from '../../../../Mobx/BookingFlowData/SelectedOptionsManager';
import styles from './BookForm.module.scss';
import NumberInput from '../NumberInput/NumberInput';
import Addons from '../../AddressInfoForm/Addons';
import BookFormItem from '../../BookForm/BookFormItem';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import SliderInput from '../SliderInput/SliderInput';
import BookSectionWrapper from '../BookSectionWrapper/BookSectionWrapper';
import BookingFormTitle from '../BookingFormTitle/BookingFormTitle';
import { Typography } from '@mui/material';
import { enableBookWithKey } from '../../../../Config';

const BookForm: FunctionComponent = () => {
  const selected = SelectedOptionsManager.getSelected();

  const onBookingOptionChange = (setValue: BookingTypeOptions, choiceKey: string) => {
    SelectedOptionsManager.updateSelected(setValue, choiceKey);
  };

  return (
    <>
      <BookingFormTitle main={bookingQuestions[0].main} sub={bookingQuestions[0].sub} />
      <div>
        <BookSectionWrapper sectionName={cleaningFieldsInfo.cleaningType.caption}>
          <div className={styles.cleaningTypeContainer}>
            <BookFormItem
              selectedOptionString={selected.cleaningType.key}
              onBookingOptionChange={(setValue: unknown) => {
                const value = setValue as CleaningTypeOptions;
                SelectedOptionsManager.updateSelectedcleaningType(value);
              }}
              descriptionText={cleaningFieldsInfo.cleaningType.descriptionText || ''}
              caption=""
              options={cleaningFieldsInfo.cleaningType.options}
              groupName="cleaningType"
            />

            {selected.cleaningType.frequency && selected.cleaningType.frequency.length > 1 && (
              <>
                <BookFormItem
                  selectedOptionString={selected.cleaningFrequency.key}
                  onBookingOptionChange={(setValue: unknown) => {
                    const value = setValue as CleaningFrequencyOptions;
                    SelectedOptionsManager.updateSelectedCleaningFreq(value);
                  }}
                  descriptionText=""
                  caption=""
                  options={selected.cleaningType.frequency}
                  groupName="cleaningFrequency"
                />

                {enableBookWithKey && (
                  <Typography>
                    Had a deep clean in the last 30 days?{' '}
                    <MainLink linkText="Book with key" toPath="/recurringClean" isInternalLink>
                      Book with key
                    </MainLink>{' '}
                    instead.
                  </Typography>
                )}
              </>
            )}
          </div>
        </BookSectionWrapper>

        <BookSectionWrapper sectionName="Home Layout" subText={includedText}>
          <>
            <div className={styles.sqf}>
              <SliderInput
                min={cleaningFieldsInfo.squareFootage.min ?? 0}
                max={cleaningFieldsInfo.squareFootage.max ?? 3000}
                stepIncrement={cleaningFieldsInfo.squareFootage.stepIncrement ?? 1}
                title={cleaningFieldsInfo.squareFootage.caption}
                ariaLabel="Slider for square footage"
                selectedValue={`${selected.squareFootage.key} ${cleaningFieldsInfo.squareFootage.postFix || ''}`}
                selectedValueNumber={selected.squareFootage.numberKey}
                onChange={(e, newValue) => {
                  const val = newValue as number;
                  const value: SquareFootageOptions = { key: `${val}`, value: 0, numberKey: val };
                  SelectedOptionsManager.updateSelectedSqf(value);
                }}
              />
              {cleaningFieldsInfo.squareFootage.descriptionText && (
                <div className={styles.tip}>
                  <EmojiObjectsOutlinedIcon />
                  <Label noPadding text={cleaningFieldsInfo.squareFootage.descriptionText} />
                </div>
              )}
            </div>

            <div className={styles.optionsContainer}>
              <div className={styles.bedrooms}>
                <NumberInput
                  label={cleaningFieldsInfo.apartmentType.caption}
                  selectedOptionString={selected.apartmentType}
                  descriptionText={cleaningFieldsInfo.apartmentType.descriptionText}
                  name="apartmentType"
                  choiceKey={BookingChoiceKeys.apartmentType}
                  options={cleaningFieldsInfo.apartmentType.options}
                  onBookingOptionChange={onBookingOptionChange}
                  tooltipText={cleaningFieldsInfo.apartmentType.descriptionText}
                />
              </div>

              <div className={styles.bathroom}>
                <NumberInput
                  label={cleaningFieldsInfo.bathrooms.caption}
                  selectedOptionString={selected.bathrooms}
                  descriptionText={cleaningFieldsInfo.bathrooms.descriptionText}
                  name="bathrooms"
                  choiceKey={BookingChoiceKeys.bathrooms}
                  options={cleaningFieldsInfo.bathrooms.options}
                  onBookingOptionChange={onBookingOptionChange}
                  tooltipText={cleaningFieldsInfo.bathrooms.descriptionText}
                />
              </div>

              <div className={styles.threeForthBathrooms}>
                <NumberInput
                  label={cleaningFieldsInfo.threeForthBathrooms.caption}
                  selectedOptionString={selected.threeForthBathrooms}
                  descriptionText={cleaningFieldsInfo.threeForthBathrooms.descriptionText}
                  name="threeForthBathrooms"
                  choiceKey={BookingChoiceKeys.threeForthBathrooms}
                  options={cleaningFieldsInfo.threeForthBathrooms.options}
                  onBookingOptionChange={onBookingOptionChange}
                  tooltipText={cleaningFieldsInfo.threeForthBathrooms.descriptionText}
                />
              </div>

              <div className={styles.halfBathrooms}>
                <NumberInput
                  label={cleaningFieldsInfo.halfBathrooms.caption}
                  selectedOptionString={selected.halfBathrooms}
                  descriptionText={cleaningFieldsInfo.halfBathrooms.descriptionText}
                  name="halfBathrooms"
                  choiceKey={BookingChoiceKeys.halfBathrooms}
                  options={cleaningFieldsInfo.halfBathrooms.options}
                  onBookingOptionChange={onBookingOptionChange}
                  tooltipText={cleaningFieldsInfo.halfBathrooms.descriptionText}
                />
              </div>

              <div className={styles.staircase}>
                <NumberInput
                  label={cleaningFieldsInfo.staircase.caption}
                  selectedOptionString={selected.staircase}
                  descriptionText={cleaningFieldsInfo.staircase.descriptionText}
                  name="staircase"
                  choiceKey={BookingChoiceKeys.staircase}
                  options={cleaningFieldsInfo.staircase.options}
                  onBookingOptionChange={onBookingOptionChange}
                  tooltipText={cleaningFieldsInfo.staircase.descriptionText}
                />
              </div>

              <div className={styles.commonAreas}>
                <NumberInput
                  label={cleaningFieldsInfo.commonAreas.caption}
                  selectedOptionString={selected.commonAreas}
                  descriptionText={cleaningFieldsInfo.commonAreas.descriptionText}
                  name="commonAreas"
                  choiceKey={BookingChoiceKeys.commonAreas}
                  options={cleaningFieldsInfo.commonAreas.options}
                  onBookingOptionChange={onBookingOptionChange}
                  tooltipText={cleaningFieldsInfo.commonAreas.descriptionText}
                />
              </div>

              <div className={styles.extraKitchens}>
                <NumberInput
                  label={cleaningFieldsInfo.extraKitchen.caption}
                  selectedOptionString={selected.extraKitchen}
                  descriptionText={cleaningFieldsInfo.extraKitchen.descriptionText}
                  name="extraKitchen"
                  choiceKey={BookingChoiceKeys.extraKitchen}
                  options={cleaningFieldsInfo.extraKitchen.options}
                  onBookingOptionChange={onBookingOptionChange}
                  tooltipText={cleaningFieldsInfo.extraKitchen.descriptionText}
                />
              </div>
            </div>

            <div className={styles.howClean}>
              <SliderInput
                min={cleaningFieldsInfo.howCleanIsHome.min ?? 1}
                max={cleaningFieldsInfo.howCleanIsHome.max ?? 10}
                maxDescription={cleaningFieldsInfo.howCleanIsHome.maxDefinition}
                minDescription={cleaningFieldsInfo.howCleanIsHome.minDefinition}
                ariaLabel="Slider for home clean scale"
                midMarkText="Average"
                stepIncrement={cleaningFieldsInfo.howCleanIsHome.stepIncrement ?? 1}
                title={cleaningFieldsInfo.howCleanIsHome.caption}
                selectedValue={selected.howCleanIsHome.key}
                selectedValueNumber={selected.howCleanIsHome.value}
                onChange={(e, newValue) => {
                  const val = newValue as number;
                  const value: HowCleanIsHomeOptions = { key: `${val}`, value: val };
                  SelectedOptionsManager.updateSelectedHowCleanIsHomeOption(value);
                }}
              />
            </div>
          </>
        </BookSectionWrapper>

        <BookSectionWrapper sectionName="Customize Your Clean">
          <Addons removeLabel isCustomizeAddonsOnly />
        </BookSectionWrapper>

        <BookSectionWrapper sectionName="One Time Add-Ons">
          <Addons removeLabel isFirstAddonOnly />
        </BookSectionWrapper>
      </div>
    </>
  );
};

export default observer(BookForm);
