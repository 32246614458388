import { makeAutoObservable } from 'mobx';
import moment from 'moment-timezone';
import { toDateAndTimeWithTimezone } from '../../Utils/CalendarUtils';

class CleanDateTimeManager {
  private dateTime: moment.Moment | undefined = undefined;
  private timeSlot = '';
  private dateAndTimeSlot: moment.Moment | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  public getTimeSlot = () => {
    return this.timeSlot;
  };

  public getDateTime = () => {
    return this.dateTime;
  };

  public updateDateTime = (newDate: moment.Moment) => {
    this.dateTime = newDate;
    this.timeSlot = '';
  };

  public setData = (isoString: string, timeSlot: string) => {
    const newDate = isoString ? moment(isoString) : undefined;
    if (newDate) {
      this.updateDateTime(newDate);
      this.updateTime(timeSlot);
    } else {
      this.resetState();
    }
  };

  public resetState = () => {
    this.dateTime = undefined;
    this.timeSlot = '';
  };

  public updateTime = (timeSlot: string) => {
    this.timeSlot = timeSlot;
    this.setDateAndTimeSlot();
  };

  public getDateAndTimeSlot = () => {
    return this.dateAndTimeSlot;
  };

  private setDateAndTimeSlot = () => {
    if (!this.dateTime || !this.timeSlot) {
      this.dateAndTimeSlot = undefined;
      return;
    }

    const startDate = toDateAndTimeWithTimezone(this.dateTime, this.timeSlot);
    this.dateAndTimeSlot = startDate;
  };
}

export default new CleanDateTimeManager();
