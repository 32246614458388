import { observer } from 'mobx-react';
import { FunctionComponent, useCallback, useEffect } from 'react';
import AddressFormDataManager from '../../../Mobx/BookingFlowData/AddressFormDataManager';
import PriceManager from '../../../Mobx/BookingFlowData/PriceManager';
import PromoCodeManager from '../../../Mobx/BookingFlowData/PromoCodeManager';
import SelectedOptionsManager from '../../../Mobx/BookingFlowData/SelectedOptionsManager';
import { getAddons } from '../../../Mobx/FetchingManagers/StripeDataManager';
import { AddonDataType } from '../../../types/AddressFormTypes';
import { CleaningType } from '../../../types/BookingFieldsEnum';
import { Coupon } from '../../../types/Coupon';
import { Price, PriceBase } from '../../../types/Price';
import { SelectedBookingInfo } from '../../../types/StateTypes';
import { AddonProductInfo, StripeInfoForClean } from '../../../types/StripeDataState';
import { Status } from '../../../types/SubmitDataTypes';
import { getPriceDataForSelected, sumAddons } from '../../../Utils/CalculatePrice';
import { DebugLog } from '../../../Utils/ErrorLog';
import { preselectAddons } from '../../../Utils/StripeDataUtils';
import BookingFlowStateManager from '../../../Mobx/BookingFlowStateManager';

const RecalculatePrice: FunctionComponent = () => {
  const resetStatus = BookingFlowStateManager.getResetAllStatus();
  const preselectedAddons = BookingFlowStateManager.getPreselectedAddons();
  const selectedCity = AddressFormDataManager.getSelectedCity();

  const changeAllStatus: Status = SelectedOptionsManager.getChangeAllStatus();
  const selected: SelectedBookingInfo = SelectedOptionsManager.getSelected();
  const addonData: AddonProductInfo[] = getAddons();
  const addonDataSelected: AddonDataType[] = AddressFormDataManager.getAddOns();
  const currentPrice: Price = PriceManager.getPrice();
  const couponApplied: Coupon | undefined = PromoCodeManager.getAppliedCoupon();
  const selectedTip: StripeInfoForClean = PriceManager.getTip();

  useEffect(() => {
    if (resetStatus !== Status.inProgress) {
      DebugLog('Applied coupon or selected tip change. Update price');
      const data: PriceBase = {
        oneTime: {
          servicePrice: currentPrice.oneTime.servicePrice,
          addons: currentPrice.oneTime.addons,
        },
        recurring: {
          servicePrice: currentPrice.recurring.servicePrice,
          addons: currentPrice.recurring.addons,
        },
        tips: selectedTip.price,
      };
      PriceManager.updatePriceGeneral(data, selected.cleaningType, couponApplied, selectedCity.taxRate);
    }
  }, [couponApplied, selectedTip]);

  useEffect(() => {
    if (resetStatus !== Status.inProgress && changeAllStatus !== Status.inProgress) {
      DebugLog('Cleaning type change. Update preselected addons. No price update');
      let newAddons: AddonDataType[] = [];
      if (selected.cleaningType.key === CleaningType.moveOut) {
        newAddons = getPreselectedAddons();
      }

      if (preselectedAddons.length > 0) {
        AddressFormDataManager.updateAddons(preselectedAddons);
        BookingFlowStateManager.resetPreselectedAddons();
      } else if (addonDataSelected.length !== newAddons.length) {
        AddressFormDataManager.updateAddons(newAddons);
      }
    }
  }, [selected.cleaningType]);

  useEffect(() => {
    if (resetStatus !== Status.inProgress && changeAllStatus !== Status.inProgress) {
      DebugLog('Booking option change. Update price');
      const data = getPriceDataForSelected(
        selected,
        selectedTip.price,
        currentPrice.oneTime.addons,
        currentPrice.recurring.addons
      );
      PriceManager.updatePriceGeneral(data, selected.cleaningType, couponApplied, selectedCity.taxRate);
    }
  }, [selected]);

  useEffect(() => {
    if (resetStatus !== Status.inProgress && changeAllStatus !== Status.inProgress) {
      DebugLog('Selected addon data change. Update price');
      const addonsSum = sumAddons(addonDataSelected || []);
      const data: PriceBase = {
        oneTime: {
          servicePrice: currentPrice.oneTime.servicePrice,
          addons: addonsSum.oneTime,
        },
        recurring: {
          servicePrice: currentPrice.recurring.servicePrice,
          addons: addonsSum.recurring,
        },
        tips: selectedTip.price,
      };
      PriceManager.updatePriceGeneral(data, selected.cleaningType, couponApplied, selectedCity.taxRate);
    }
  }, [addonDataSelected]);

  const getPreselectedAddons = useCallback(() => {
    return preselectAddons(addonData, selected.cleaningFrequency.key);
  }, [addonData, selected.cleaningFrequency]);

  return null;
};

export default observer(RecalculatePrice);
