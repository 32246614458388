import { forwardRef, useImperativeHandle, useState } from 'react';
import { InputBaseComponentProps } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomStripeInput = forwardRef<any, InputBaseComponentProps>(function StripeInput(props, ref) {
  // eslint-disable-next-line react/prop-types
  const { component: Component, ...other } = props;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [mountNode, setMountNode] = useState<HTMLElement | null>(null);

  useImperativeHandle(
    ref,
    () => ({
      focus: () => mountNode?.focus(),
    }),
    [mountNode]
  );

  useImperativeHandle(ref, () => ({
    // eslint-disable-next-line @typescript-eslint/unbound-method
    focus: () => mountNode?.focus(),
  }));

  return (
    <Component onReady={(element: HTMLElement) => setMountNode(element)} options={{ placeholder: '' }} {...other} />
  );
});

export default CustomStripeInput;
