import { FunctionComponent } from 'react';
import styles from './ResetYourHome.module.scss';
import { Grid } from '@mui/material';
import { BookNowButton, ImageComponent } from '../../../Components';
import { mainTitleSubText } from '../../../../Assets/Data/MainInfo';

const ResetYourHome: FunctionComponent = () => {
  return (
    <div className={styles.textSuperContainer}>
      <Grid container direction="row" className={styles.textMainContainer} item>
        <Grid item xs={12} sm={6} className={`${styles.columns} ${styles.textContent}`}>
          <div className={styles.textContainer}>
            <h1 className={styles.title}>
              Reset your home
              <br />
              to clean.
            </h1>
            <h2 className={styles.text}>{mainTitleSubText}</h2>
            <BookNowButton onlyScroll={false} />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className={`${styles.columns} ${styles.imgContainer} ${styles.imgContainerAsRow}`}>
          <ImageComponent path="peopleCleaning.svg" source="" altText="people cleaning" />
        </Grid>
      </Grid>
    </div>
  );
};

export default ResetYourHome;
