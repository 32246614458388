import { FunctionComponent } from 'react';
import { contactPhone, phoneWithExtension } from '../../../../../Config';
import { ImageComponent, MainLink } from '../../../../Components';
import styles from './CallUs.module.scss';

const CallUs: FunctionComponent = () => {
  const tel = `tel:${contactPhone}`;

  return (
    <div className={styles.root}>
      <div className={styles.phoneIconContainer} aria-label="call us">
        <ImageComponent source="" path="LandingPage/phone.svg" altText="call us" />
      </div>
      <MainLink isInternalLink={false} linkText={phoneWithExtension} href={tel} className={styles.phone}>
        {phoneWithExtension}
      </MainLink>
    </div>
  );
};

export default CallUs;
