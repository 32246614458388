import { FunctionComponent } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import StripePaymentForm from './StripePaymentForm/StripePaymentForm';
import styles from './PaymentForm.module.scss';
import { bookingQuestions } from '../../../Assets/Data/BookingConstants';
import AllManagers from '../../../Utils/AllManagers';
import BookSectionWrapper from '../BookingProgressV2/BookSectionWrapper/BookSectionWrapper';
import BookingFormTitle from '../BookingProgressV2/BookingFormTitle/BookingFormTitle';

interface PaymentFormProps {
  onSubmitPaymentForm?: () => void;
  getAddressFormValues?: () => void;
  recurring?: boolean;
}

const PaymentForm: FunctionComponent<PaymentFormProps> = ({
  onSubmitPaymentForm,
  getAddressFormValues,
  recurring,
}: PaymentFormProps) => {
  const stripePromise = AllManagers.GetStripeManager().GetStripePromise();

  return (
    <>
      <BookingFormTitle main={bookingQuestions[3].main} sub={bookingQuestions[3].sub} />

      <BookSectionWrapper>
        <div className={styles.paymentFormContainer}>
          <Elements stripe={stripePromise}>
            <StripePaymentForm
              onSubmitPaymentForm={onSubmitPaymentForm}
              getAddressFormValues={getAddressFormValues}
              isRecurring={recurring || false}
            />
          </Elements>
        </div>
      </BookSectionWrapper>
    </>
  );
};

export default PaymentForm;
