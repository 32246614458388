import { makeAutoObservable } from 'mobx';
import { CleaningType } from '../../types/BookingFieldsEnum';
import { Coupon } from '../../types/Coupon';
import { Status } from '../../types/SubmitDataTypes';
import AnalyticsManager, { AmplitudeEventNames } from '../../Utils/AnalyticsManager';
import { getCouponDataAsync } from '../../Utils/ApiCalls/ApiCalls';
import { LogError } from '../../Utils/ErrorLog';
import { validatePromoCode } from '../../Utils/StripeCouponUtils';

class PromoCodeManager {
  private status: Status = Status.unset;
  private appliedCoupon: Coupon | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  public resetState = () => {
    this.status = Status.unset;
    this.appliedCoupon = undefined;
  };

  public getCouponData = async (
    code: string,
    selectedCleaningType: CleaningType,
    openAlert: (msg: string, isError: boolean) => void
  ) => {
    this.status = Status.inProgress;
    try {
      const coupon = await getCouponDataAsync(code);
      const validationError = validatePromoCode(coupon, selectedCleaningType, this.appliedCoupon);
      if (validationError) {
        openAlert(validationError, false);
        this.status = Status.error;

        AnalyticsManager.logEventWithProps(AmplitudeEventNames.PromocodeValidationFail, {
          couponId: coupon.couponId,
          promoId: coupon.promotionId,
        });
      } else {
        this.appliedCoupon = { couponData: coupon };
        openAlert('Promo Code Successfully Applied!', true);
        this.status = Status.success;

        AnalyticsManager.logEventWithProps(AmplitudeEventNames.PromoCodeAdded, {
          couponId: coupon.couponId,
          promoId: coupon.promotionId,
        });
      }
    } catch (e) {
      this.status = Status.error;
      LogError('getCouponData', { error: e, code }, false);
      openAlert('Invalid promo code', false);
    }
  };

  public removeCoupon = () => {
    this.appliedCoupon = undefined;
    AnalyticsManager.logEvent(AmplitudeEventNames.PromoCodeRemoved);
  };

  public getStatus = () => {
    return this.status;
  };

  public getAppliedCoupon = () => {
    return this.appliedCoupon;
  };
}

export default new PromoCodeManager();
