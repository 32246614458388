import { AnchorHTMLAttributes, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import AnalyticsManager from '../../../Utils/AnalyticsManager';
import styles from './MainLink.module.scss';

interface MainLinkProps {
  linkText: string;
  isInternalLink: boolean;
  toPath?: string;
}

const MainLink: FunctionComponent<AnchorHTMLAttributes<HTMLAnchorElement> & MainLinkProps> = ({
  children,
  className,
  onClick,
  isInternalLink,
  toPath,
  linkText,
  ...other
}: AnchorHTMLAttributes<HTMLAnchorElement> & MainLinkProps) => {
  const onClickWithLog = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    AnalyticsManager.logLinkClick(linkText);
    if (onClick) {
      onClick(e);
    }
  };

  return isInternalLink ? (
    <Link
      className={`${styles.root} ${className ? className : ''}`}
      to={toPath || ''}
      onClick={(e) => onClickWithLog(e)}
      {...other}>
      {children}
    </Link>
  ) : (
    <a className={`${styles.root} ${className ? className : ''}`} onClick={(e) => onClickWithLog(e)} {...other}>
      {children}
    </a>
  );
};

export default MainLink;
