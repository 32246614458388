import { FunctionComponent } from 'react';
import { Toolbar, IconButton } from '@mui/material';
import styles from './TopHeader.module.scss';
import LogoButton from '../LogoButton/LogoButton';
import MenuIcon from '@mui/icons-material/Menu';
// import PrimaryButton from '../../../Components/PrimaryButton/PrimaryButton';
import { Hidden, MainLink } from '../../../Components';
import { navigationLinksTop } from '../../../../Assets/Data/NavigationLinks';
import { lg } from '../../../../Assets/Styles/constants';
import { useIsLandingPage } from '../../../CustomHooks/useIsLandingPage';
import CallUs from './CallUs/CallUs';
import AnalyticsManager from '../../../../Utils/AnalyticsManager';

interface TopHeaderProps {
  navigateToPage: (path: string) => void;
  onMenuClick: () => void;
}

const TopHeader: FunctionComponent<TopHeaderProps> = ({ onMenuClick }: TopHeaderProps) => {
  const [isLandingPage] = useIsLandingPage();

  // const goToBooking = () => {
  //   AnalyticsManager.logButtonClick('Book now in top header');
  //   navigateToPage('/bookclean');
  // };

  return (
    <div className={`${styles.root} ${isLandingPage ? styles.landingPageRoot : ''}`}>
      <div className={styles.headerContainer}>
        <Toolbar className={styles.headerInnerContainer}>
          <div className={styles.logo}>
            <LogoButton disabled={isLandingPage} />
          </div>
          {!isLandingPage && (
            <Hidden screenWidthToShow={lg}>
              <div className={styles.linkList}>
                {navigationLinksTop.map(({ name, path }) => (
                  <MainLink linkText={name} key={name} toPath={path} isInternalLink>
                    {name}
                  </MainLink>
                ))}
              </div>
            </Hidden>
          )}
          {!isLandingPage ? (
            <div className={styles.menu}>
              <IconButton
                onClick={() => {
                  AnalyticsManager.logButtonClick('Menu');
                  onMenuClick();
                }}
                aria-label="menu"
                className={styles.menuButton}>
                <MenuIcon classes={{ root: styles.rootIconStyle }} />
              </IconButton>
            </div>
          ) : (
            <CallUs />
          )}
        </Toolbar>
      </div>
    </div>
  );
};

export default TopHeader;
