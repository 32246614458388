import { FunctionComponent, useState } from 'react';
import styles from './App.module.scss';
import { ReactFCChildren } from './types/ReactFCChildren';

const AppPageScrollContent: FunctionComponent<ReactFCChildren> = ({ children }: ReactFCChildren) => {
  const [bodyRef, setRef] = useState<HTMLDivElement | null>(null);

  const scrollToTop = (isSmooth?: boolean) => {
    bodyRef?.scrollTo({ top: 0, left: 0, behavior: isSmooth ? 'smooth' : 'auto' });
  };

  window.scrollToTop = scrollToTop;

  return (
    <div className={styles.scrollContent} ref={setRef}>
      {children}
    </div>
  );
};

export default AppPageScrollContent;
