import { SelectProps, Select as MuiSelect, MenuItem } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { FunctionComponent } from 'react';
import { isMobile } from '../../../Utils/Common';
import styles from './Select.module.scss';

interface CustomSelectProps {
  options: string[] | number[];
  customPlaceholder?: { value: string | number; text: string };
  optionsObject?: { value: number | string; text: string }[];
}

const Select: FunctionComponent<CustomSelectProps & SelectProps> = ({
  options,
  onChange,
  className,
  customPlaceholder,
  optionsObject,
  ...rest
}: CustomSelectProps & SelectProps) => {
  const isNative = isMobile();

  const handleChange = (e: SelectChangeEvent<unknown>, child: React.ReactNode) => {
    onChange && onChange(e, child);
    const i = document.getElementById('select-element');
    i?.blur();
  };

  return (
    <MuiSelect
      native={isNative}
      id="select-element"
      className={`${rest.disabled ? styles.disabledRoot : styles.root} ${className ? className : ''}`}
      classes={{ select: styles.select, disabled: styles.disabledStyle }}
      onChange={handleChange}
      {...rest}>
      {customPlaceholder ? (
        isNative ? (
          <option value={customPlaceholder.value} disabled>
            {customPlaceholder.text}
          </option>
        ) : (
          <MenuItem value={customPlaceholder.value} disabled>
            {customPlaceholder.text}
          </MenuItem>
        )
      ) : null}

      {optionsObject
        ? isNative
          ? optionsObject.map((op) => (
              <option key={`${op.value}-dropdownKey`} value={op.value}>
                {op.text}
              </option>
            ))
          : optionsObject.map((op) => (
              <MenuItem key={`${op.value}-dropdownKey`} value={op.value}>
                {op.text}
              </MenuItem>
            ))
        : isNative
          ? options.map((op: string | number) => (
              <option key={`${op}-dropdownKey`} value={op}>
                {op}
              </option>
            ))
          : options.map((op: string | number) => (
              <MenuItem key={`${op}-dropdownKey`} value={op}>
                {op}
              </MenuItem>
            ))}
    </MuiSelect>
  );
};

export default Select;
