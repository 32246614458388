import { cleaningFieldsInfo } from '../../Assets/Data/BookingInformation';
import { SelectedBookingInfoItems } from '../../types/BookingFieldsEnum';
import { SelectedBookingInfo } from '../../types/StateTypes';

// Deep clean minimum
export const selectedBookingInfo: SelectedBookingInfo = {
  [SelectedBookingInfoItems.apartmentType]: cleaningFieldsInfo.apartmentType.options[0],
  [SelectedBookingInfoItems.bathrooms]: cleaningFieldsInfo.bathrooms.options[0],
  [SelectedBookingInfoItems.halfBathrooms]: cleaningFieldsInfo.halfBathrooms.options[0],
  [SelectedBookingInfoItems.threeForthBathrooms]: cleaningFieldsInfo.threeForthBathrooms.options[0],
  [SelectedBookingInfoItems.commonAreas]: cleaningFieldsInfo.commonAreas.options[0],
  [SelectedBookingInfoItems.staircase]: cleaningFieldsInfo.staircase.options[0],
  [SelectedBookingInfoItems.squareFootage]: cleaningFieldsInfo.squareFootage.options[0],
  [SelectedBookingInfoItems.cleaningType]: cleaningFieldsInfo.cleaningType.options[0],
  [SelectedBookingInfoItems.cleaningFrequency]: cleaningFieldsInfo.cleaningType.options[0].frequency[0],
  [SelectedBookingInfoItems.howCleanIsHome]: cleaningFieldsInfo.howCleanIsHome.options[0],
  [SelectedBookingInfoItems.extraKitchen]: cleaningFieldsInfo.extraKitchen.options[0],

  [SelectedBookingInfoItems.kitchenLivingRoomDining]: cleaningFieldsInfo.kitchenLivingRoomDining.options[0],
};

// Recurring clean minimum
export const initialRecurring: SelectedBookingInfo = {
  [SelectedBookingInfoItems.apartmentType]: cleaningFieldsInfo.apartmentType.options[0],
  [SelectedBookingInfoItems.bathrooms]: cleaningFieldsInfo.bathrooms.options[0],
  [SelectedBookingInfoItems.halfBathrooms]: cleaningFieldsInfo.halfBathrooms.options[0],
  [SelectedBookingInfoItems.threeForthBathrooms]: cleaningFieldsInfo.threeForthBathrooms.options[0],
  [SelectedBookingInfoItems.commonAreas]: cleaningFieldsInfo.commonAreas.options[0],
  [SelectedBookingInfoItems.staircase]: cleaningFieldsInfo.staircase.options[0],
  [SelectedBookingInfoItems.squareFootage]: cleaningFieldsInfo.squareFootage.options[0],
  [SelectedBookingInfoItems.cleaningType]: cleaningFieldsInfo.cleaningType.options[1],
  [SelectedBookingInfoItems.cleaningFrequency]: cleaningFieldsInfo.cleaningType.options[1].frequency[0],
  [SelectedBookingInfoItems.howCleanIsHome]: cleaningFieldsInfo.howCleanIsHome.options[0],
  [SelectedBookingInfoItems.extraKitchen]: cleaningFieldsInfo.extraKitchen.options[0],

  [SelectedBookingInfoItems.kitchenLivingRoomDining]: cleaningFieldsInfo.kitchenLivingRoomDining.options[0],
};

// Move out clean minimum
export const initialMoveOut: SelectedBookingInfo = {
  [SelectedBookingInfoItems.apartmentType]: cleaningFieldsInfo.apartmentType.options[0],
  [SelectedBookingInfoItems.bathrooms]: cleaningFieldsInfo.bathrooms.options[0],
  [SelectedBookingInfoItems.halfBathrooms]: cleaningFieldsInfo.halfBathrooms.options[0],
  [SelectedBookingInfoItems.threeForthBathrooms]: cleaningFieldsInfo.threeForthBathrooms.options[0],
  [SelectedBookingInfoItems.commonAreas]: cleaningFieldsInfo.commonAreas.options[0],
  [SelectedBookingInfoItems.staircase]: cleaningFieldsInfo.staircase.options[0],
  [SelectedBookingInfoItems.squareFootage]: cleaningFieldsInfo.squareFootage.options[0],
  [SelectedBookingInfoItems.cleaningType]: cleaningFieldsInfo.cleaningType.options[2],
  [SelectedBookingInfoItems.cleaningFrequency]: cleaningFieldsInfo.cleaningType.options[2].frequency[0],
  [SelectedBookingInfoItems.howCleanIsHome]: cleaningFieldsInfo.howCleanIsHome.options[0],
  [SelectedBookingInfoItems.extraKitchen]: cleaningFieldsInfo.extraKitchen.options[0],

  [SelectedBookingInfoItems.kitchenLivingRoomDining]: cleaningFieldsInfo.kitchenLivingRoomDining.options[0],
};
