import { FunctionComponent } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import styles from './InfoPageStyles.module.scss';
// import PrimaryButton from '../../Components/PrimaryButton/PrimaryButton';
import InfoPageRoot from '../../Components/InfoPageRoot/InfoPageRoot';
// import AnalyticsManager from '../../../Utils/AnalyticsManager';

const Services: FunctionComponent = () => {
  // const navigate = useNavigate();
  // const location = useLocation();

  // const handleBookNowClick = () => {
  //   AnalyticsManager.logButtonClick('Book now on service page clicked');
  //   navigate('/bookclean', { state: { from: location.pathname } });
  // };

  const titleVariant = 'h4';
  const textVariant = 'h6';

  return (
    <InfoPageRoot mainTitle="What We Clean">
      <Typography className={styles.title} variant={titleVariant}>
        What We Clean
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        Cheetah Cleaners offers one time, recurring, move out, and rental turnover cleans. Every one time service is a
        deep clean, and recurring services start with a deep clean to get your home to our quality standard. Our
        recurring service is monthly, biweekly, or weekly.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Days We Clean
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        We clean every day of the week, from 8 AM – 6 PM but reserve weekends for one time, move out, and initial deep
        cleans. Recurring cleans can only be scheduled Monday – Friday.
        <br />
        <br />
        There are two time slots for cleaning appointments, morning and afternoon. Morning cleans have an arrival time
        of 8 – 9 AM, and afternoon cleans from 11 AM – 2 PM. This is to give more flexibility on our end if other
        cleanings run long, there’s traffic, etc.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Cleaning Supplies
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        We bring all the tools and supplies needed to thoroughly clean your home. Our products are green and
        eco-friendly and were selected after extensive use in the field; we are not able to use any of your products. If
        you’d like us to sweep any area, please leave a broom in clear view.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Included Services
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        <ul>
          <li>Changing bed/pillow sheets if clean ones are left out</li>
          <li>Disinfecting high touch surfaces (door knobs, light switches, etc.)</li>
          <li>Changing/taking out trash and recycling if bins are accessible</li>
          <li>Inside of microwave</li>
          <li>Cleaning/shining appliances</li>
        </ul>
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Add On Services
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        <ul>
          <li>
            Inside of fridge (no freezer) – $40.00 <b>(included in move out)</b>
          </li>
          <li>
            Inside of oven – $40.00 <b>(included in move out)</b>
          </li>
          <li>
            Inside of windows – $25.00 <b>(included in move out)</b>
          </li>
          <li>Grout cleaning – case by case basis</li>
        </ul>
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        What We Don’t Clean
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        <ul>
          <li>Walls</li>
          <li>Deep carpet cleaning/carpet stain removal</li>
          <li>Glass/delicate light fixtures</li>
          <li>Fragile/delicate/expensive items</li>
          <li>Compost removal</li>
          <li>Garbage/recycling removal if you’re in an apartment or condo</li>
          <li>Oven grates</li>
          <li>Inside of freezer</li>
          <li>Inside of fireplace/ash removal</li>
          <li>Cat litter box</li>
          <li>Pet feces, urine, vomit</li>
          <li>Hazards (urine, mold, blood, vomit, etc.)</li>
          <li>Paint splatter</li>
          <li>Utility rooms and other unfinished areas of the home</li>
          <li>Garages</li>
          <li>Balconies or any areas outside of home</li>
        </ul>
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Dusting
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        We dust from ceiling edges all the way down to baseboards, including all surfaces. After the first clean and
        even a few recurring cleans, there may be resettling dust. This is normal and may take a few cleans to minimize.
        <br />
        <br />
        If there are fewer than 10 items on a shelf or counter, we’ll dust each item individually and the surface
        underneath. If there are more than 10 items or the items look fragile, we’ll dust around them as much as
        possible.
        <br />
        <br />
        To get ceiling edges and corners, we use an extension pole, but some ceilings can still be out of reach and we
        won’t be able to dust them. We also can’t dust surfaces that are out of reach when standing on a step stool.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Showers & Tubs
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        Showers and tubs can accumulate lime, calcium, and soap scum. We do not do lime and calcium removal unless it
        can be removed with our cleaning products and reasonable scrubbing efforts. We do remove soap scum.
        <br />
        <br />
        Mold and mildew can grow deep into grout and calk. We’ll get the stains out as much as possible but eliminating
        it might require re-grouting or recalking. Showers and bathrooms with tile and grout might need extra time to
        clean, so please let us know ahead of time if you think we’ll need more time.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Floors
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        Vacuuming and mopping is included in each service. We use de-ionized water to mop floors and don’t use any
        specialty products like wood floor or tile cleaner. We’ll try to remove especially tough stains but can’t
        guarantee 100% restoration.{' '}
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Move Out Service
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        Our cleaners do a thorough job, but we can’t guarantee a 100% security deposit return or that the next tenants
        will be happy with the conditions. We require your home is empty of furniture and items for move out cleans. If
        there is still furniture and items, we will not be able to fully clean those areas.
        <br />
        <br />
        Every move out service includes cleaning inside fridge (no freezer), inside oven, inside windows, closets, and
        inside cabinets.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Short Term Rental Turnovers (Airbnb, VRBO, etc.)
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        Getting your Airbnb or rental ready for the next guests can vary from changing sheets and picking a few things
        up, to deep cleaning. Contact us directly to set up rental turnovers and let us know about specific things you
        want us to clean and/or set up (e.g. put dishes in dishwasher, change sheets)
      </Typography>
      {/* <div className={styles.bookNowButtonContainer}>
        <PrimaryButton onClick={handleBookNowClick} className={styles.bookNowButton}>
          Book now
        </PrimaryButton>
      </div> */}
    </InfoPageRoot>
  );
};

export default Services;
