import moment from 'moment-timezone';
import { emailPattern, phonePattern } from '../../../../Assets/Data/RegexStrings';
import { defaultTimeZone, eventsKeyFormat, timeSlotFormat } from '../../../../Config';
import { CreateEventBody } from '../../../../types/CalendarEvents';

export const validateEmail = (email: string): boolean => {
  const validEmailRegex = RegExp(emailPattern);
  return validEmailRegex.test(email);
};

export const validatePhone = (phone: string): boolean => {
  const validPhoneRegex = RegExp(phonePattern);
  return validPhoneRegex.test(phone);
};

export const validatePhoneChar = (ch: string): boolean => {
  const validPhoneRegex = RegExp('^[0-9]');
  return validPhoneRegex.test(ch);
};

export const validate = (
  selectedDay: moment.Moment | undefined,
  selectedTime: string,
  checkedTerms: boolean,
  checkedCancellation: boolean
): string => {
  if (!selectedTime) {
    return 'Invalid date and time';
  }

  if (!selectedDay) {
    return 'Please go back and slect valid date and time.';
  }

  if (!checkedTerms) {
    return 'You need to agree to terms and privacy policy to continue.';
  }

  if (!checkedCancellation) {
    return 'You need to agree to cancellation policy to continue.';
  }

  return '';
};

export const createGoogleCalendarEvent = (
  selectedDayWithTimeSlot: moment.Moment,
  cityStateZip: string,
  cleaningDuration: number
): CreateEventBody => {
  const format = `${eventsKeyFormat} ${timeSlotFormat}`;
  const startDate = selectedDayWithTimeSlot;
  const endDate = moment(startDate, format).add(cleaningDuration, 'hours');

  const eventBody: CreateEventBody = {
    summary: 'Cheetah Cleaners - Deep Clean',
    location: cityStateZip,
    description: '',
    start: {
      dateTime: startDate.utc().format(),
      timeZone: defaultTimeZone,
    },
    end: {
      dateTime: endDate.utc().format(),
      timeZone: defaultTimeZone,
    },
    attendees: [],
    reminders: {
      useDefault: false,
      overrides: [
        { method: 'popup', minutes: 10 },
        { method: 'email', minutes: 24 * 60 },
        { method: 'email', minutes: 24 * 3 * 60 },
      ],
    },
  };

  return eventBody;
};

export const defaultError = 'Error on our side. Please try again.';
