import moment from 'moment-timezone';
import { FunctionComponent, useMemo } from 'react';
import styles from './CalendarMonth.module.scss';
import { isSameDate, makeDays } from '../../../../Utils/CalendarUtils';
import { observer } from 'mobx-react';
import AnalyticsManager from '../../../../Utils/AnalyticsManager';
import CalendarTimesManager from '../../../../Mobx/FetchingManagers/CalendarTimesManager';
import SkeletonMonth from './SkeletonMonth';

interface CalendarMonthProps {
  month: number;
  year: number;
  onSelectedDayChange: (date: moment.Moment) => void;
  selectedDay: moment.Moment | undefined;
  cleaningTypeRecurring: boolean;
}

const CalendarMonth: FunctionComponent<CalendarMonthProps> = ({
  month,
  year,
  onSelectedDayChange,
  selectedDay,
  cleaningTypeRecurring,
}: CalendarMonthProps) => {
  const availableTimesData = CalendarTimesManager.getAvailableTimes();

  const timeSlotsArray = useMemo(() => {
    return availableTimesData ? Object.keys(availableTimesData.timeSlots) : [];
  }, [availableTimesData?.timeSlots]);

  const days = useMemo(
    () =>
      makeDays(
        year,
        month,
        availableTimesData ? availableTimesData.events : {},
        availableTimesData?.eventsRecurring ? availableTimesData.eventsRecurring : {},
        timeSlotsArray,
        cleaningTypeRecurring
      ),
    [
      year,
      month,
      availableTimesData?.events,
      availableTimesData?.eventsRecurring,
      timeSlotsArray,
      cleaningTypeRecurring,
    ]
  );

  const handleClick = (momentObject: moment.Moment) => {
    AnalyticsManager.logGroupButtonClick('Day change', { optionSelected: momentObject.format('YYYY-MM-DD') });
    onSelectedDayChange(momentObject);
  };

  return (
    <div className={styles.daysCellContainer}>
      {availableTimesData ? (
        days.map(({ dayMoment, isDisabled }, index) => {
          return (
            <button
              key={`dateCell-${index}`}
              disabled={isDisabled}
              className={isSameDate(selectedDay, dayMoment) ? styles.selectedCell : styles.dayCell}
              onClick={() => handleClick(dayMoment)}>
              {dayMoment.date()}
            </button>
          );
        })
      ) : (
        <SkeletonMonth daysCount={days.length} />
      )}
    </div>
  );
};

export default observer(CalendarMonth);
