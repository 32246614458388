import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { ReactFCChildren } from '../../../types/ReactFCChildren';
import { ISubmitDataContextData, SubmitDataContext } from './SubmitDataContext';
import { CleaningDataPayload, CleaningDataPayloadRecurring } from '../../../types/CleaningDataPayload';
import { Status } from '../../../types/SubmitDataTypes';
import { InitialBookingState } from '../../../types/StateTypes';
import { CalendarEvent, CreateEventBody } from '../../../types/CalendarEvents';
import AnalyticsManager, { AmplitudeEventNames } from '../../../Utils/AnalyticsManager';
import { DebugLog, LogError, LogHttpCallError } from '../../../Utils/ErrorLog';
import { createCalendarEvent, deleteCalendarEvent } from '../../../Utils/ApiCalls/Events';
import { submitData, submitDataToServerWithKeyRecurring } from '../../../Utils/ApiCalls/ApiCalls';
import { selectDataToSubmit, selectDataToSubmitRecurring } from '../../../Utils/SelectDataToSubmit';
import CleanDateTimeManager from '../../../Mobx/BookingFlowData/CleanDateTimeManager';
import CalendarTimesManager from '../../../Mobx/FetchingManagers/CalendarTimesManager';
import { defaultError } from '../../Containers/PaymentForm/StripePaymentForm/Common';

const overbookError = 'Date and time you selected was already booked. Please go back and pick another date or time';

const SubmitDataContextImpl: FunctionComponent<ReactFCChildren> = ({ children }: ReactFCChildren) => {
  const [status, setStatus] = useState<Status>(Status.unset);
  const [data, setData] = useState<CleaningDataPayload | CleaningDataPayloadRecurring | undefined>();

  const resetStateAfterSubmit = () => {
    setStatus(Status.unset);
    setData(undefined);
  };

  const submitDataAttempt = async (
    currentState: InitialBookingState,
    key: string,
    eventBody: CreateEventBody,
    paymentMethodId: string,
    isBookWithKey: boolean
  ): Promise<string> => {
    setInProgress(eventBody, isBookWithKey);
    const event = await createEvent(eventBody, currentState.selected.cleaningType.isRecurring);

    if (event.error || !event.eventId) {
      let errorMessage = defaultError;
      if (event.error?.isOverbookError) {
        errorMessage = overbookError;
        void CalendarTimesManager.fetchAvailableTimes();
        CleanDateTimeManager.resetState();
      }

      setErrorSubmitData(errorMessage, { eventBody, paymentMethodId, isBookWithKey }, isBookWithKey);
      return errorMessage;
    }

    let data: CleaningDataPayload | CleaningDataPayloadRecurring | undefined;
    let error = '';
    let success = false;
    let isRecurring = false;

    if (isBookWithKey) {
      const dataToSubmitToServerRecurring = selectDataToSubmitRecurring(
        currentState,
        paymentMethodId,
        event.eventId,
        key
      );
      data = dataToSubmitToServerRecurring.data;
      error = dataToSubmitToServerRecurring.error;
      DebugLog({ source: 'dataToSubmitToServerRecurring', data });
      if (dataToSubmitToServerRecurring.data && !error) {
        success = await submitDataToServerRecurring(dataToSubmitToServerRecurring.data);
        isRecurring = true;
      }
    } else {
      const dataToSubmitToServer = selectDataToSubmit(currentState, paymentMethodId, event.eventId);
      data = dataToSubmitToServer.data;
      error = dataToSubmitToServer.error;
      DebugLog({ source: 'dataToSubmitToServer', data });
      if (dataToSubmitToServer.data && !error) {
        const { isSuccess, isEmailError, isValidationError } = await submitDataToServer(dataToSubmitToServer.data);
        success = isSuccess;
        isRecurring = dataToSubmitToServer.isRecurring;
        if (!isSuccess) {
          if (isEmailError) {
            error = 'Email is invalid';
          } else if (isValidationError) {
            error = 'One of the input fields is invalid';
          }
        }
      }
    }

    DebugLog({ data, error });

    if (success && data) {
      setSuccessSubmitData(data, isBookWithKey, isRecurring);
      return '';
    }

    const errorMessage = error || defaultError;
    void deleteEvent(event.eventId, currentState.selected.cleaningType.isRecurring);
    setErrorSubmitData(errorMessage, { data, eventBody, paymentMethodId, isBookWithKey }, isBookWithKey);

    return errorMessage;
  };

  const createEvent = async (
    eventBody: CreateEventBody,
    isRecurring: boolean
  ): Promise<{ eventId: string; error: { isOverbookError: boolean } | undefined }> => {
    try {
      const response = await createCalendarEvent(eventBody, isRecurring);
      return response;
    } catch (e) {
      if ((e as never)['status'] === 409) {
        LogError('createEvent', { message: 'Time is already booked', time: eventBody?.start?.dateTime }, true);
        return { eventId: '', error: { isOverbookError: true } };
      }

      LogHttpCallError('createEvent', e, true);
      return { eventId: '', error: { isOverbookError: false } };
    }
  };

  const submitDataToServer = async (
    dataToSubmitToServer: CleaningDataPayload
  ): Promise<{ isSuccess: boolean; isEmailError: boolean; isValidationError: boolean }> => {
    try {
      await submitData(dataToSubmitToServer);
      return { isSuccess: true, isEmailError: false, isValidationError: false };
    } catch (err) {
      const message = (err as never)['message'];
      const data = message ? (message['data'] as unknown) : undefined;
      const dataParsed = data ? (data as { invalidFields?: boolean; isEmailInvalid?: boolean }) : undefined;
      LogHttpCallError('submitDataToServer', err, true);
      return {
        isSuccess: false,
        isEmailError: dataParsed?.isEmailInvalid || false,
        isValidationError: dataParsed?.invalidFields || false,
      };
    }
  };

  const submitDataToServerRecurring = async (dataToSubmitToServer: CleaningDataPayloadRecurring): Promise<boolean> => {
    try {
      return await submitDataToServerWithKeyRecurring(dataToSubmitToServer);
    } catch (err) {
      LogHttpCallError('submitDataToServerRecurring', err, true);
      return false;
    }
  };

  const deleteEvent = async (eventId: string, isRecurring: boolean): Promise<CalendarEvent | null> => {
    try {
      return await deleteCalendarEvent(eventId, isRecurring);
    } catch (err) {
      LogHttpCallError('deleteEvent', err, true);
      return null;
    }
  };

  const setInProgress = (eventBody: CreateEventBody, isBookWithKey: boolean) => {
    setStatus(Status.unset); // Is this correct?
    AnalyticsManager.logEventWithProps(
      isBookWithKey
        ? AmplitudeEventNames.submitDataForBookingWithKey_start
        : AmplitudeEventNames.submitDataForBooking_start,
      {
        data: eventBody,
        isBookWithKey,
      }
    );
  };

  const setErrorSubmitData = (message: string, data: unknown, isBookWithKey: boolean) => {
    setStatus(Status.error);
    setData(undefined);

    AnalyticsManager.logEventWithProps(
      isBookWithKey
        ? AmplitudeEventNames.submitDataForBookingWithKey_error
        : AmplitudeEventNames.submitDataForBooking_error,
      {
        data,
        isBookWithKey,
        errorMessage: message,
      }
    );
  };

  const setSuccessSubmitData = (
    data: CleaningDataPayload | CleaningDataPayloadRecurring,
    isBookWithKey: boolean,
    isRecurring: boolean
  ) => {
    setStatus(Status.success);
    setData(data);

    AnalyticsManager.logEventWithProps(
      isBookWithKey
        ? AmplitudeEventNames.submitDataForBookingWithKey_success
        : AmplitudeEventNames.submitDataForBooking_success,
      {
        data,
        isBookWithKey,
      }
    );

    isBookWithKey
      ? AnalyticsManager.logRevenueWithKey(data as CleaningDataPayloadRecurring)
      : AnalyticsManager.logRevenue(data as CleaningDataPayload, isRecurring);
  };

  const resetStateAfterSubmitC = useCallback(() => {
    return resetStateAfterSubmit();
  }, []);

  const submitDataAttemptC = useCallback(
    (
      currentState: InitialBookingState,
      key: string,
      eventBody: CreateEventBody,
      paymentMethodId: string,
      isBookWithKey: boolean
    ) => {
      return submitDataAttempt(currentState, key, eventBody, paymentMethodId, isBookWithKey);
    },
    []
  );

  const contextValue: ISubmitDataContextData = useMemo(
    () => ({
      status,
      data,
      resetStateAfterSubmit: resetStateAfterSubmitC,
      submitDataAttempt: submitDataAttemptC,
    }),
    [status, data, resetStateAfterSubmit, submitDataAttempt]
  );

  return <SubmitDataContext.Provider value={contextValue}>{children}</SubmitDataContext.Provider>;
};

export default SubmitDataContextImpl;
