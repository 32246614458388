import { FunctionComponent, ReactNode, useRef, useState } from 'react';
import styles from './ReviewWrapped.module.scss';
import { sm } from '../../../../../Assets/Styles/constants';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import AnalyticsManager from '../../../../../Utils/AnalyticsManager';

interface ReviewWrappeddProps {
  children: ReactNode[];
}

const ReviewWrappedV2: FunctionComponent<ReviewWrappeddProps> = ({ children }: ReviewWrappeddProps) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const scrollProgressRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const reviewCardSize = window.innerWidth > sm ? 400 : window.innerWidth - 20;
  const gapSize = 20;
  const scrollAmount = reviewCardSize + gapSize;

  const onPrev = () => {
    AnalyticsManager.logIconButtonClick('Prev review');
    if (carouselRef.current && canScrollLeft) {
      carouselRef.current.scrollBy({ left: -scrollAmount, top: 0 });
      setCanScrollRight(true);
    }
  };

  const onNext = () => {
    AnalyticsManager.logIconButtonClick('Next review');
    if (carouselRef.current && canScrollRight) {
      carouselRef.current.scrollBy({ left: scrollAmount, top: 0 });
      setCanScrollLeft(true);
    }
  };

  const setScrollLeftRight = () => {
    if (carouselRef.current) {
      const canGoRight =
        carouselRef.current.scrollLeft + carouselRef.current.offsetWidth < carouselRef.current.scrollWidth;
      const canGoLeft = carouselRef.current.scrollLeft > 0;
      setCanScrollRight(canGoRight);
      setCanScrollLeft(canGoLeft);

      setScrollProgress();
    }
  };

  const setScrollProgress = () => {
    if (carouselRef.current && scrollProgressRef.current) {
      const scrollWidth = carouselRef.current.scrollWidth - carouselRef.current.offsetWidth;
      const scrollPosition = carouselRef.current.scrollLeft;
      const scrolledPercent = (scrollPosition * 100) / scrollWidth;
      scrollProgressRef.current.style.width = `${scrolledPercent}%`;
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.carousel} ref={carouselRef} onScroll={setScrollLeftRight}>
        {children.map((item, index) => (
          <div
            key={`${index}-slide`}
            className={styles.slide}
            style={{
              minWidth: `${reviewCardSize}px`,
              width: `${reviewCardSize}px`,
            }}>
            {item}
          </div>
        ))}
      </div>
      <div className={styles.prevNextBtns}>
        <button aria-label="previous review" onClick={() => onPrev()} className={styles.left} disabled={!canScrollLeft}>
          <NavigateBeforeIcon />
        </button>
        <div className={styles.progress}>
          <div ref={scrollProgressRef} className={styles.progressDone}></div>
        </div>
        <button aria-label="next review" onClick={() => onNext()} className={styles.right} disabled={!canScrollRight}>
          <NavigateBeforeIcon />
        </button>
      </div>
    </div>
  );
};

export default ReviewWrappedV2;
