export const moveOut = {
  name: 'Move Out Clean',
  title: 'We’re the thorough cleaners you can rely on.',
  text: `Moving out and don't want to deal with cleaning? With easy online booking, thorough cleaning, and ample communication, you can trust us to get the job done right. 

  We deep clean your entire home, including inside oven, inside fridge, inside cabinets, and interior windows as needed.`,
};

export const deep = {
  name: 'Deep Clean',
  title: 'We get all the nooks and crannies.',
  text: `Family staying over and need to clean up? We got you covered. Haven’t been keeping up with cleaning tasks and need a restart? We'll set a baseline you can work with. Whatever the reason, you can rely on Cheetah Cleaners. 

  We're easy to work with, always thorough, and make it easy way to book and manage your appointments. And with extensive cleaning experience, you can be confident we’ll get the job done right.`,
};

export const recurring = {
  name: 'Recurring Clean',
  title: 'One thing is certain... cleaning can’t be avoided.',
  text: `Do you feel like there's not enough time in the week to clean? Or, maybe you just aren't up for the task anymore? We help manage cleaning chores so you can start take control your life again and knowing your home is taken care of. 

  We'll work with you on a cleaning plan that makes sense for your schedule. Recurring services start with a deep clean to get your home to our baseline, and we don't spare the details.`,
};
