import { Divider, Grid, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import styles from './SuccessResume.module.scss';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useLocation, useNavigate } from 'react-router-dom';
import { LocationState } from '../../../types/HistoryLocation';

const SuccessResume: FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location.state as LocationState;
  const defaultRedirectPath = '/home';
  const validFromPath = '/careers';

  if (locationState?.from !== validFromPath) {
    navigate(defaultRedirectPath, { replace: true });
    return null;
  }

  return (
    <div className={styles.successfulPayment}>
      <div className={styles.successfulPaymentContainer}>
        <Grid container direction="column" className={styles.successInfoContainer}>
          <Grid item xs={12} className={styles.successItem}>
            <CheckCircleOutlineIcon className={styles.successIcon} />
            <Typography variant="h3">You have successfully submitted your application!</Typography>
          </Grid>
        </Grid>
        <Divider />
        <div className={styles.contactInfo}>
          <Typography variant="body1">
            If you need to get in immediate contact with us, please reach us at (206) 486-0038 or email
            info@cheetahcleaners.com
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default SuccessResume;
