import { ReactNode } from 'react';
import GreenCleaningHtml from './Articles/GreenCleaning';
import ClutterFreeHtml from './Articles/ClutterFree';
import FridgeHtml from './Articles/Fridge';
import TimeBackHtml from './Articles/TimeBack';
import OvenHtml from './Articles/Oven';
import MoveOutHtml from './Articles/MoveOut';
import ScheduleCleanHtml from './Articles/ScheduleClean';
import CleaningMoreEfficientlyHtml from './Articles/CleaningMoreEfficiently';

export interface BlogPostType {
  id: string;
  image?: string;
  content: ReactNode;
  title: string;
  imgSrc: string;
}

const post1: BlogPostType = {
  id: 'green-cleaning',
  content: <GreenCleaningHtml />,
  title: 'What is green cleaning and why should I care?',
  imgSrc: 'BlogPost/4.png',
};

const post2: BlogPostType = {
  id: 'oven-deep-clean',
  content: <OvenHtml />,
  title: 'How to Deep Clean your Oven Without the Harmful Chemicals',
  imgSrc: 'BlogPost/2.png',
};

const post3: BlogPostType = {
  id: 'clean-inside-fridge',
  content: <FridgeHtml />,
  title: 'How to Clean the Inside of Your Fridge',
  imgSrc: 'BlogPost/1.png',
};

const post4: BlogPostType = {
  id: 'get-time-back',
  content: <TimeBackHtml />,
  title: '5 Major Life Hacks to Get Time Back in Your Life',
  imgSrc: 'BlogPost/3.png',
};

const post5: BlogPostType = {
  id: 'clutter-free-life',
  content: <ClutterFreeHtml />,
  title: '5 Habits to a Clutter-Free Life',
  imgSrc: 'BlogPost/5.png',
};

const post6: BlogPostType = {
  id: 'move-out',
  content: <MoveOutHtml />,
  title: '6 Tips to Take the Stress Out of Moving',
  imgSrc: 'BlogPost/moveOutBlogBanner.png',
};

const post7: BlogPostType = {
  id: 'schedule-clean',
  content: <ScheduleCleanHtml />,
  title: 'How often should I schedule a cleaning service?',
  imgSrc: 'BlogPost/howOftenScheduleClean.png',
};

const post8: BlogPostType = {
  id: 'cleaning-more-efficiently',
  content: <CleaningMoreEfficientlyHtml />,
  title: 'Cleaning Efficiently: The Basics',
  imgSrc: 'BlogPost/cleaningMoreEfficiently.png',
};

export const posts: Record<string, BlogPostType> = {
  [post1.id]: post1,
  [post2.id]: post2,
  [post3.id]: post3,
  [post4.id]: post4,
  [post5.id]: post5,
  [post6.id]: post6,
  [post7.id]: post7,
  [post8.id]: post8,
};
