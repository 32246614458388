import { observer } from 'mobx-react';
import { FunctionComponent, useEffect } from 'react';
import { BookingProgress } from '../../Containers';
import BookingFlowStateManager from '../../../Mobx/BookingFlowStateManager';
import { useCartData } from '../../CustomHooks/useCartData';
import { Status } from '../../../types/SubmitDataTypes';

const BookPage: FunctionComponent = () => {
  const status = BookingFlowStateManager.getResetAllStatus();
  const { cartData } = useCartData();

  useEffect(() => {
    BookingFlowStateManager.setInProgress();
    if (cartData) {
      BookingFlowStateManager.updateDataFromCart(cartData);
    }

    BookingFlowStateManager.setDone();
  }, [cartData]);

  return <div>{status !== Status.inProgress && <BookingProgress />}</div>;
};

export default observer(BookPage);
