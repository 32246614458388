import { Grid } from '@mui/material';
import { FunctionComponent } from 'react';
import { Label, Select } from '../../../Components';
import styles from './StripePaymentForm.module.scss';
import { PaymentFields, PaymentFormStringObject } from '../../../../types/PaymentFormDataTypes';
import { FormInputsValidation } from '../../../../types/StripeTypes';
import AnalyticsManager from '../../../../Utils/AnalyticsManager';
import { observer } from 'mobx-react';
import PaymentFormDataManager from '../../../../Mobx/BookingFlowData/PaymentFormDataManager';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { StyledFormControl } from '../../../Components/Select/SelectStyledForm';

interface LeadSourceProps {
  leadSource?: string;
  setLeadSource?: (val: string) => void;
  setFormErrors: (newErrors: FormInputsValidation) => void;
  formErrors: FormInputsValidation;
}

const LeadSource: FunctionComponent<LeadSourceProps> = ({ setFormErrors, formErrors }: LeadSourceProps) => {
  const leadSourceData: PaymentFormStringObject = PaymentFormDataManager.getPaymentFields().leadSource;
  const onChangeField = (e: SelectChangeEvent<unknown>) => {
    const value = e.target as HTMLInputElement;
    PaymentFormDataManager.updatePaymentFormFields(PaymentFields.leadSource, value.value);
    setFormErrors({ ...formErrors, [PaymentFields.leadSource]: false });
  };

  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const value = e.target as HTMLInputElement;
    setFormErrors({ ...formErrors, [PaymentFields.leadSource]: !value.value });
    value && AnalyticsManager.logLeadSource(value.value, false);
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={6} className={styles.leadContainer}>
        <Label htmlFor="leadSource" text="How did you hear about us?" />
        <StyledFormControl
          id="leadSource"
          variant="outlined"
          fullWidth
          error={formErrors?.leadSource ? true : false}
          focused={false}>
          <Select
            options={leadSourceData.options || []}
            value={leadSourceData.defaultValue}
            onChange={onChangeField}
            onBlur={onBlurField}
            variant="outlined"
          />
        </StyledFormControl>
      </Grid>
    </Grid>
  );
};

export default observer(LeadSource);
