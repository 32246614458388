import { CleaningsData } from '../../types/CleaningData';
import { RescheduleRequestData, SignUpDataFields } from '../../types/SignUpData';
import HttpClient from '../HttpManager';

export const assignUserRole = async () => {
  const response = await HttpClient<{ status: string }>({
    url: '/users/create-user-setting',
    method: 'POST',
    data: {},
  });

  return response;
};

export const createOrUpdateCustomer = async (data: SignUpDataFields) => {
  const response = await HttpClient<{ status: string }>({
    url: '/portal/update-user',
    method: 'POST',
    data,
  });

  return response;
};

export const getCleaningData = async (email?: string) => {
  const data = email ? `?email=${email}` : ''; // TODO remove this
  const response = await HttpClient<CleaningsData>({
    url: `/portal/portalData${data}`,
    method: 'GET',
  });

  return response;
};

export const getCustomerInfo = async () => {
  const client = await HttpClient<SignUpDataFields>({
    url: `/portal/customerInfo`,
    method: 'GET',
  });

  return client;
};

export const rescheduleClean = async (id: string, newDateTime: string, calendarEventId: string) => {
  const client = await HttpClient<unknown>({
    url: '/portal/reschedule',
    method: 'POST',
    data: { id, calendarEventId, newDateTime },
  });

  return client;
};

export const rescheduleCleanRequest = async (toEmail: string, data: RescheduleRequestData) => {
  const client = await HttpClient<unknown>({
    url: '/portal/reschedule-request',
    method: 'POST',
    data: { toEmail, data },
  });

  return client;
};

export const cancelCleaningCompletely = async (
  dbId: string,
  cleaning: {
    subId: string;
    scheduleId: string;
  },
  tip: {
    subId: string;
    scheduleId: string;
  },
  isRecurring: boolean,
  calendarId: string
) => {
  const client = await HttpClient<SignUpDataFields>({
    url: '/portal/cancel',
    method: 'POST',
    data: { dbId, isRecurring, cleaning, tip, calendarId },
  });

  return client;
};

export const cancelNextCleaning = async (
  dbId: string,
  cleaning: {
    subId: string;
    scheduleId: string;
  },
  tip: {
    subId: string;
    scheduleId: string;
  },
  isRecurring: boolean,
  calendarId: string
) => {
  const client = await HttpClient<SignUpDataFields>({
    url: '/portal/cancel-next',
    method: 'POST',
    data: { dbId, isRecurring, cleaning, tip, calendarId },
  });

  return client;
};
