import { FunctionComponent } from 'react';
import styles from './BookForm.module.scss';
import { Typography } from '@mui/material';
import { InfoIconTooltip, RadioButtonGroup } from '../../Components';
import { SquareFootageOptions } from '../../../types/BookingFormDataTypes';
import { observer } from 'mobx-react';

interface BookFormItemProps {
  selectedOptionString: string;
  descriptionText: string;
  caption: string;
  options: { key: string; displayOnBookingForm?: string }[];
  onBookingOptionChange: (setValue: unknown, choiceKey: string) => void;
  choiceKey?: string;
  allowOptions?: SquareFootageOptions[];
  groupName: string;
}

const BookFormItem: FunctionComponent<BookFormItemProps> = ({
  descriptionText,
  caption,
  options,
  selectedOptionString,
  onBookingOptionChange,
  choiceKey,
  allowOptions,
  groupName,
}: BookFormItemProps) => {
  return (
    <>
      {caption && (
        <div className={styles.description}>
          <Typography variant="h6" gutterBottom={true}>
            {caption}
          </Typography>
          {descriptionText && <InfoIconTooltip text={descriptionText} />}
        </div>
      )}

      <RadioButtonGroup
        selectedOption={selectedOptionString}
        options={options}
        allowOptions={allowOptions}
        onChangeOption={(setValue: unknown) => onBookingOptionChange(setValue, choiceKey || '')}
        groupName={groupName}
      />
    </>
  );
};

export default observer(BookFormItem);
