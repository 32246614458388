import { makeAutoObservable } from 'mobx';
import { Loginstate } from '../../types/LoginState';
import { SignUpDataFields } from '../../types/SignUpData';
import { Status } from '../../types/SubmitDataTypes';
import AllManagers from '../../Utils/AllManagers';
import { createOrUpdateCustomer } from '../../Utils/ApiCalls/CustomerPortal';
import { User } from '../../Utils/AuthManager';
import { DebugLog, LogError } from '../../Utils/ErrorLog';
import CustomerPortalManager from './CustomerPortalManager';

export enum FirebaseSignInErrorCodes {
  UserNotFound = 'auth/user-not-found',
  WrongPassword = 'auth/wrong-password',
  EmailAlreadyInUse = 'auth/email-already-in-use',
  InvalidEmail = 'auth/invalid-email',
}

const errorMessages: Record<string, string> = {
  [FirebaseSignInErrorCodes.UserNotFound]: 'User Not Found',
  [FirebaseSignInErrorCodes.InvalidEmail]: 'Invalid Email',
  [FirebaseSignInErrorCodes.WrongPassword]: 'Wrong password',
  [FirebaseSignInErrorCodes.EmailAlreadyInUse]: 'Email already in use',
};

class LoginManager {
  private state: Loginstate = { user: null, status: Status.unset };

  constructor() {
    makeAutoObservable(this);
  }

  public getUser = () => {
    return this.state.user;
  };

  public resetStatus = () => {
    this.state.status === Status.unset;
  };

  public getStatus = () => {
    return this.state.status;
  };

  public resetState = () => {
    this.state = { user: null, status: Status.unset };
  };

  public isLoggedIn = () => {
    return this.state.user !== null;
  };

  public forgotPassword = async (email: string, onError: (msg: string, isError: boolean) => void) => {
    try {
      await AllManagers.GetAuthManager().resetPassword(email);
      onError('Email to reset password was sent!', true);
      return true;
    } catch (e) {
      LogError('forgotPassword', { message: JSON.stringify(e) }, true);
      onError(this.getErrorMessageFromError(e) || 'Error sending reset password email', false);
      return false;
    }
  };

  public signInWithPrsistence = async (
    email: string,
    password: string,
    onError: (msg: string, isError: boolean) => void
  ) => {
    try {
      this.state.status = Status.inProgress;
      DebugLog('signing in with persistence');
      const userCredential = await AllManagers.GetAuthManager().signInWithPersistence(email, password);
      if (!userCredential?.user) {
        throw new Error('User is empty');
      }

      this.state = { user: userCredential.user, status: Status.success };
    } catch (error) {
      this.state = { user: null, status: Status.error };
      LogError('signIn', { message: JSON.stringify(error) }, true);
      onError(this.getErrorMessageFromError(error) || 'Error signing in', false);
    }
  };

  public deleteAccount = async (onError: (msg: string, isError: boolean) => void) => {
    try {
      this.state.status = Status.inProgress;
      await AllManagers.GetAuthManager().deleteUser();
      // await timeout(10000);
      CustomerPortalManager.resetState();
      this.state.status = Status.success;
    } catch (error) {
      this.state = { user: null, status: Status.error };
      LogError('deleteAccount', { message: JSON.stringify(error) }, true);
      onError(this.getErrorMessageFromError(error) || 'Error deleting account', false);
    }
  };

  public signOut = async (onError: (msg: string, isError: boolean) => void) => {
    try {
      this.state.status = Status.inProgress;
      await AllManagers.GetAuthManager().signOut();
      CustomerPortalManager.resetState();
      this.state.status = Status.success;
    } catch (error) {
      this.state = { user: null, status: Status.error };
      LogError('signOut', { message: JSON.stringify(error) }, true);
      onError(this.getErrorMessageFromError(error) || 'Error signing out', false);
    }
  };

  public onFirstSignIn = async (data: SignUpDataFields) => {
    try {
      await createOrUpdateCustomer(data);
    } catch (e) {
      LogError('signOut', { message: JSON.stringify(e) }, true);
    }
  };

  public onSignIn = (userData: User | null) => {
    this.state = { user: userData, status: Status.success };
  };

  public onSignOut = () => {
    this.state = { user: null, status: Status.success };
  };

  private getErrorMessageFromError = (e: unknown) => {
    const errorMessage = (e as never)['code'] || '';
    const errorCode = errorMessage as FirebaseSignInErrorCodes;
    const message = errorMessages[errorCode];
    return message || '';
  };
}

export default new LoginManager();
