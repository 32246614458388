import { FunctionComponent } from 'react';
import { Typography } from '@mui/material';
import styles from './Forbidden.module.scss';

const Forbidden: FunctionComponent = () => (
  <div className={styles.forbidden}>
    <Typography variant="h3">This website is forbidden in your country.</Typography>
  </div>
);

export default Forbidden;
