import { Grid, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { MainCheckbox, MainLink } from '../../../Components';
import styles from './StripePaymentForm.module.scss';

interface TermsCheckBoxProps {
  terms: { checked: boolean; setChecked: (val: boolean) => void };
  cancellation: { checked: boolean; setChecked: (val: boolean) => void };
}

const TermsCheckBox: FunctionComponent<TermsCheckBoxProps> = ({ terms, cancellation }: TermsCheckBoxProps) => {
  return (
    <Grid item xs={12}>
      <div className={styles.checkbox}>
        <MainCheckbox checked={terms.checked} onChange={() => terms.setChecked(!terms.checked)} name="checkedTerms" />
        <Typography variant="h6">
          {`I agree to Cheetah Cleaners' `}
          <MainLink linkText="Terms" toPath="/terms" isInternalLink target="_blank" rel="noopener noreferrer">
            Terms{' '}
          </MainLink>
          and{' '}
          <MainLink
            linkText="Privacy Policy"
            toPath="/privacy-policy"
            isInternalLink
            target="_blank"
            rel="noopener noreferrer">
            Privacy Policy
          </MainLink>
          .
        </Typography>
      </div>
      <div className={styles.checkbox}>
        <MainCheckbox
          checked={cancellation.checked}
          onChange={() => cancellation.setChecked(!cancellation.checked)}
          name="checkedCancellation"
        />
        <Typography variant="h6">
          {`I agree to Cheetah Cleaners' `}
          <MainLink
            linkText="Cancellation Policy"
            toPath="/cancellation"
            isInternalLink
            target="_blank"
            rel="noopener noreferrer">
            Cancellation Policy
          </MainLink>
          .
        </Typography>
      </div>
    </Grid>
  );
};

export default TermsCheckBox;
