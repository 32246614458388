import { createContext } from 'react';

export interface IAlertInfo {
  isOpen: boolean;
  message?: string;
  isSuccess?: boolean;
}

export interface IAlertDataContext {
  openAlert: (message: string, isSuccess: boolean) => void;
  alertInfo: IAlertInfo;
  closeAlert: () => void;
}

export const defaultValues: IAlertDataContext = {
  // eslint-disable-next-line @typescript-eslint/no-misused-promises, @typescript-eslint/no-unused-vars
  openAlert: (_message: string, _isSuccess: boolean) => null,
  alertInfo: { isOpen: false },
  closeAlert: () => null,
};

export const AlertContext = createContext<IAlertDataContext>(defaultValues);
