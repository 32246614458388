import { makeAutoObservable } from 'mobx';
import { AddonDataType } from '../types/AddressFormTypes';
import { Status } from '../types/SubmitDataTypes';
import AddressFormDataManager from './BookingFlowData/AddressFormDataManager';
import CleanDateTimeManager from './BookingFlowData/CleanDateTimeManager';
import PaymentFormDataManager from './BookingFlowData/PaymentFormDataManager';
import PromoCodeManager from './BookingFlowData/PromoCodeManager';
import SelectedOptionsManager from './BookingFlowData/SelectedOptionsManager';
import { AbandonedCartInfo } from '../types/AbandonedCart';

class BookingFlowStateManager {
  private resetAllStatus: Status = Status.inProgress;
  private preselectedAddonsFromCart: AddonDataType[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public getPreselectedAddons = () => {
    return this.preselectedAddonsFromCart;
  };

  public resetPreselectedAddons = () => {
    this.preselectedAddonsFromCart = [];
  };

  public setPreselectedAddons = (addons: AddonDataType[]) => {
    this.preselectedAddonsFromCart = addons;
  };

  public getResetAllStatus = () => {
    return this.resetAllStatus;
  };

  public setInProgress = () => {
    this.resetAllStatus = Status.inProgress;
  };

  public setDone = () => {
    this.resetAllStatus = Status.success;
  };

  public resetStateAfterBookingFlow = () => {
    this.resetAllStatus = Status.inProgress;
    SelectedOptionsManager.resetState();
    AddressFormDataManager.resetState();
    CleanDateTimeManager.resetState();
    PaymentFormDataManager.resetState();
    PromoCodeManager.resetState();
    this.resetAllStatus = Status.success;
  };

  public updateDataFromCart = (cartData: AbandonedCartInfo) => {
    this.setPreselectedAddons(cartData.addons);
    SelectedOptionsManager.setData(cartData.selectedCleaningInfo);
    AddressFormDataManager.setData(
      cartData.addressObj,
      cartData.parkingInfo,
      cartData.keyInfo,
      cartData.petInfo,
      cartData.otherInfo,
      cartData.addons
    );
    CleanDateTimeManager.setData(cartData.date?.isoString || '', cartData.date?.timeslot || '');
    PromoCodeManager.resetState();
  };
}

export default new BookingFlowStateManager();
