import { FunctionComponent, useEffect } from 'react';
import styles from './MoveOutClean.module.scss';
import { CheckListTable } from '../../Components';
import { moveOut } from '../../../Assets/Data/CleanDescriptionText';
import CleanSelection from '../../Containers/CleaningPageParts/CleanSelection/CleanSelection';
import ResetYourHome from '../../Containers/CleaningPageParts/ResetYourHome/ResetYourHome';
import MainInfo from '../../Containers/CleaningPageParts/MainInfo/MainInfo';
import SelectedOptionsManager from '../../../Mobx/BookingFlowData/SelectedOptionsManager';
import { initialMoveOut } from '../../../Mobx/States/InitialSelectedState';
import { observer } from 'mobx-react';
import { mainTitleSubText } from '../../../Assets/Data/MainInfo';
import EmbeddedReviews from '../../Containers/CleaningPageParts/GoogleReview/EmbeddedReviews/EmbeddedReviews';

const MoveOutClean: FunctionComponent = () => {
  useEffect(() => {
    SelectedOptionsManager.updateSelectedAll(initialMoveOut);
  }, []);

  return (
    <div className={styles.root}>
      <CleanSelection
        headerText="Moving out? We’ll get it clean for whoever’s next."
        subHeaderText={mainTitleSubText}
        imageSrc=""
        imagePath="moveOutCleanArt1.svg"
        isDisableChecklist={true}
        dropShadow={true}
      />
      <MainInfo mainText={moveOut.text} title={moveOut.title} imagePath="moveOutCleanArt2.svg" imageSrc="" />
      <CheckListTable name="Move Out" moveOut={true} />
      <EmbeddedReviews />
      <ResetYourHome />
    </div>
  );
};

export default observer(MoveOutClean);
