import { Skeleton } from '@mui/material';
import { FunctionComponent } from 'react';
import styles from './TimePicker.module.scss';

const SkeletonTimePicker: FunctionComponent = () => {
  return (
    <>
      <Skeleton classes={{ root: styles.timeButtonSkeleton }} animation="pulse" variant="rectangular" />
      <Skeleton classes={{ root: styles.timeButtonSkeleton }} animation="pulse" variant="rectangular" />
    </>
  );
};

export default SkeletonTimePicker;
