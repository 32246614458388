import { isProduction } from '../../Config';
import { ProductsReturnInfo } from '../../types/StripeDataState';

const stripeDataProd: ProductsReturnInfo = {
  addons: [
    {
      name: 'Inside Fridge (Full)',
      price: {
        'One Time': {
          price: 65,
          cleaningFrequency: 'One Time',
          id: 'price_1Mwc8JHUwNyXqsJR2aCbFpMY',
        },
      },
      id: 'prod_Ni2CuQ56mOPdD7',
      imagePath: 'fridgeFull.svg',
      moveOutPreselected: false,
      isFirstCleanOnly: true,
      isSpecifyQuantity: false,
    },
    {
      name: 'Inside Fridge (Empty)',
      price: {
        'One Time': {
          price: 55,
          cleaningFrequency: 'One Time',
          id: 'price_1Mwc8mHUwNyXqsJRrHNGz6NS',
        },
      },
      id: 'prod_Ni2DEtE0SMG66u',
      imagePath: 'fridge.svg',
      moveOutPreselected: true,
      isFirstCleanOnly: true,
      isSpecifyQuantity: false,
    },
    {
      name: 'Inside Of Windows',
      price: {
        'One Time': {
          price: 55,
          cleaningFrequency: 'One Time',
          id: 'price_1Mwc8XHUwNyXqsJRmnCt0xV3',
        },
      },
      id: 'prod_Ni2Cu0Y1iQzOri',
      imagePath: 'window.svg',
      moveOutPreselected: true,
      isFirstCleanOnly: true,
      isSpecifyQuantity: false,
      shouldRemoveFromDeep: true,
    },
    {
      name: 'Inside Kitchen Cabinets (Full)',
      price: {
        'One Time': {
          price: 65,
          cleaningFrequency: 'One Time',
          id: 'price_1Mwc8QHUwNyXqsJRNvKCV7Ne',
        },
      },
      id: 'prod_Ni2CMsoRXCFplk',
      imagePath: 'cabinets.svg',
      moveOutPreselected: false,
      isFirstCleanOnly: true,
      isSpecifyQuantity: false,
      shouldRemoveFromDeep: true,
    },
    {
      name: 'Inside Kitchen Cabinets (Empty)',
      price: {
        'One Time': {
          price: 55,
          cleaningFrequency: 'One Time',
          id: 'price_1Mwc8cHUwNyXqsJRBpZmW00e',
        },
      },
      id: 'prod_Ni2DRVD9eNx8ie',
      imagePath: 'cabinets.svg',
      moveOutPreselected: true,
      isFirstCleanOnly: true,
      isSpecifyQuantity: false,
      shouldRemoveFromDeep: true,
    },
    {
      name: 'Inside Oven',
      price: {
        'One Time': {
          price: 55,
          cleaningFrequency: 'One Time',
          id: 'price_1Mwc8rHUwNyXqsJRAolIhIko',
        },
      },
      id: 'prod_Ni2D2JJNcTyDFb',
      imagePath: 'oven.svg',
      moveOutPreselected: true,
      isFirstCleanOnly: true,
      isSpecifyQuantity: false,
    },
    {
      name: 'Pick Up Toys (15-Min Increments)',
      price: {
        'One Time': {
          price: 15,
          cleaningFrequency: 'One Time',
          id: 'price_1Mwc8wHUwNyXqsJR2cnqAnnK',
        },
        'Monthly': {
          price: 15,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Monthly',
          id: 'price_1Mwc8wHUwNyXqsJR1vCBH0Mq',
        },
        'Biweekly': {
          price: 15,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Biweekly',
          id: 'price_1Mwc8wHUwNyXqsJRk5gVTNLP',
        },
        'Weekly': {
          price: 15,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Weekly',
          id: 'price_1Mwc8wHUwNyXqsJRbL6xGTux',
        },
      },
      id: 'prod_Ni2DGLCzge9712',
      imagePath: 'toys.svg',
      moveOutPreselected: false,
      isFirstCleanOnly: false,
      isSpecifyQuantity: true,
    },
    {
      name: 'Vacuum Couch/Upholstery (Per Item)',
      price: {
        'One Time': {
          price: 15,
          cleaningFrequency: 'One Time',
          id: 'price_1Mwc90HUwNyXqsJRuGOeKLg2',
        },
        'Monthly': {
          price: 15,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Monthly',
          id: 'price_1Mwc90HUwNyXqsJRamuzrkhD',
        },
        'Biweekly': {
          price: 15,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Biweekly',
          id: 'price_1Mwc90HUwNyXqsJRwtFBatWd',
        },
        'Weekly': {
          price: 15,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Weekly',
          id: 'price_1Mwc90HUwNyXqsJRkq1glMci',
        },
      },
      id: 'prod_Ni2DBxasJsIufq',
      imagePath: 'couch.svg',
      moveOutPreselected: false,
      isFirstCleanOnly: false,
      isSpecifyQuantity: true,
    },
    {
      name: 'Change Bed Sheets (Per Bed, No Duvet)',
      price: {
        'One Time': {
          price: 15,
          cleaningFrequency: 'One Time',
          id: 'price_1Mwc96HUwNyXqsJRhRGWJNMD',
        },
        'Monthly': {
          price: 15,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Monthly',
          id: 'price_1Mwc96HUwNyXqsJRk1B8cuPe',
        },
        'Biweekly': {
          price: 15,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Biweekly',
          id: 'price_1Mwc96HUwNyXqsJR2LSyBNQr',
        },
        'Weekly': {
          price: 15,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Weekly',
          id: 'price_1Mwc96HUwNyXqsJRPEwWFyVM',
        },
      },
      id: 'prod_Ni2DCeJh09SLFf',
      imagePath: 'bed.svg',
      moveOutPreselected: false,
      isFirstCleanOnly: false,
      isSpecifyQuantity: true,
    },
    {
      name: 'Clean Doors',
      price: {
        'One Time': {
          price: 30,
          cleaningFrequency: 'One Time',
          id: 'price_1Mwc9DHUwNyXqsJRUkJP4SZz',
        },
        'Monthly': {
          price: 30,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Monthly',
          id: 'price_1Mwc9DHUwNyXqsJRHhNymW1F',
        },
        'Biweekly': {
          price: 30,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Biweekly',
          id: 'price_1Mwc9DHUwNyXqsJRPO9SymLA',
        },
        'Weekly': {
          price: 30,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Weekly',
          id: 'price_1Mwc9DHUwNyXqsJRNR64kWOM',
        },
      },
      id: 'prod_Ni2Dx5erxt7nas',
      imagePath: 'door.svg',
      moveOutPreselected: true,
      isFirstCleanOnly: false,
      isSpecifyQuantity: false,
    },
    {
      name: 'Collect Trash/Recycling (No Compost)',
      price: {
        'One Time': {
          price: 20,
          cleaningFrequency: 'One Time',
          id: 'price_1Mwc9KHUwNyXqsJRhgNXI6b1',
        },
        'Monthly': {
          price: 20,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Monthly',
          id: 'price_1Mwc9KHUwNyXqsJRmN40dWnj',
        },
        'Biweekly': {
          price: 20,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Biweekly',
          id: 'price_1Mwc9KHUwNyXqsJRvfCbHilt',
        },
        'Weekly': {
          price: 20,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Weekly',
          id: 'price_1Mwc9KHUwNyXqsJRZEG2D8kM',
        },
      },
      id: 'prod_Ni2DF5ESskkx22',
      imagePath: 'trash.svg',
      moveOutPreselected: false,
      isFirstCleanOnly: false,
      isSpecifyQuantity: false,
    },
  ],
  cleanings: {
    kitchenLivingRoomDining: {
      '0': {
        name: 'Kitchen, dining, living (0-15)',
        price: {
          'One Time': {
            price: 170,
            cleaningFrequency: 'One Time',
            id: 'price_1MwcCdHUwNyXqsJReiKOKE4b',
          },
          'Biweekly': {
            price: 110.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MwcCdHUwNyXqsJRjJ1xyGD1',
          },
          'Weekly': {
            price: 102,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MwcCdHUwNyXqsJRcy5rIJ2K',
          },
          'Monthly': {
            price: 144.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MwcCdHUwNyXqsJRlaaI4g5M',
          },
        },
        id: 'prod_Ni2HLW9mnPYq0B',
        nickname: 'Kitchen, dining, living',
        sqfRange: '0-15',
        sqfMin: 0,
        sqfMax: 15,
      },
      '15': {
        name: 'Kitchen, dining, living (15-16)',
        price: {
          'One Time': {
            price: 175,
            cleaningFrequency: 'One Time',
            id: 'price_1MwcCZHUwNyXqsJRG9oAuZnh',
          },
          'Weekly': {
            price: 105,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MwcCZHUwNyXqsJRd0f3UV0J',
          },
          'Biweekly': {
            price: 113.75,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MwcCZHUwNyXqsJRJwy7iQDA',
          },
          'Monthly': {
            price: 148.75,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MwcCZHUwNyXqsJRAKlnxGa9',
          },
        },
        id: 'prod_Ni2H6PvBCGqjj4',
        nickname: 'Kitchen, dining, living',
        sqfRange: '15-16',
        sqfMin: 15,
        sqfMax: 16,
      },
      '16': {
        name: 'Kitchen, dining, living (16-17)',
        price: {
          'One Time': {
            price: 180,
            cleaningFrequency: 'One Time',
            id: 'price_1MwcCVHUwNyXqsJRUYBEEEO7',
          },
          'Weekly': {
            price: 108,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MwcCVHUwNyXqsJR72g8LPrR',
          },
          'Biweekly': {
            price: 117,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MwcCVHUwNyXqsJR1P8vQ74n',
          },
          'Monthly': {
            price: 153,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MwcCVHUwNyXqsJR8FmQFhXD',
          },
        },
        id: 'prod_Ni2HyW6lFRNxia',
        nickname: 'Kitchen, dining, living',
        sqfRange: '16-17',
        sqfMin: 16,
        sqfMax: 17,
      },
      '17': {
        name: 'Kitchen, dining, living (17-18)',
        price: {
          'One Time': {
            price: 185,
            cleaningFrequency: 'One Time',
            id: 'price_1MwcCQHUwNyXqsJR9mv53zOv',
          },
          'Weekly': {
            price: 111,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MwcCQHUwNyXqsJRrH7PKyRe',
          },
          'Biweekly': {
            price: 120.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MwcCQHUwNyXqsJRm44tbOyP',
          },
          'Monthly': {
            price: 157.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MwcCQHUwNyXqsJRN1PjjfL5',
          },
        },
        id: 'prod_Ni2GeMyReVKVsS',
        nickname: 'Kitchen, dining, living',
        sqfRange: '17-18',
        sqfMin: 17,
        sqfMax: 18,
      },
      '18': {
        name: 'Kitchen, dining, living (18-19)',
        price: {
          'One Time': {
            price: 190,
            cleaningFrequency: 'One Time',
            id: 'price_1MwcCJHUwNyXqsJRThPdGsEW',
          },
          'Weekly': {
            price: 114,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MwcCJHUwNyXqsJRYrvtHxWy',
          },
          'Biweekly': {
            price: 123.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MwcCJHUwNyXqsJRjMsAfYLy',
          },
          'Monthly': {
            price: 161.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MwcCJHUwNyXqsJRUw08LIop',
          },
        },
        id: 'prod_Ni2GzCB84Utvjv',
        nickname: 'Kitchen, dining, living',
        sqfRange: '18-19',
        sqfMin: 18,
        sqfMax: 19,
      },
      '19': {
        name: 'Kitchen, dining, living (19-20)',
        price: {
          'One Time': {
            price: 195,
            cleaningFrequency: 'One Time',
            id: 'price_1MwcCBHUwNyXqsJRc5P0Bomo',
          },
          'Weekly': {
            price: 117,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MwcCBHUwNyXqsJRJGPvLuuB',
          },
          'Biweekly': {
            price: 126.75,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MwcCBHUwNyXqsJRSZ7NkWQs',
          },
          'Monthly': {
            price: 165.75,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MwcCBHUwNyXqsJRRxUcR3Go',
          },
        },
        id: 'prod_Ni2GApXdZS7HPm',
        nickname: 'Kitchen, dining, living',
        sqfRange: '19-20',
        sqfMin: 19,
        sqfMax: 20,
      },
      '20': {
        name: 'Kitchen, dining, living (20-21)',
        price: {
          'One Time': {
            price: 200,
            cleaningFrequency: 'One Time',
            id: 'price_1MwcBhHUwNyXqsJRV3pjgXQm',
          },
          'Weekly': {
            price: 120,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MwcBhHUwNyXqsJRQjCHFM1p',
          },
          'Biweekly': {
            price: 130,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MwcBhHUwNyXqsJR0EobZ7M2',
          },
          'Monthly': {
            price: 170,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MwcBhHUwNyXqsJRu5fC5t4P',
          },
        },
        id: 'prod_Ni2Gbnbq0pI5kX',
        nickname: 'Kitchen, dining, living',
        sqfRange: '20-21',
        sqfMin: 20,
        sqfMax: 21,
      },
      '21': {
        name: 'Kitchen, dining, living (21-22)',
        price: {
          'One Time': {
            price: 205,
            cleaningFrequency: 'One Time',
            id: 'price_1MwcBWHUwNyXqsJRcf0Fkvm7',
          },
          'Weekly': {
            price: 123,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MwcBWHUwNyXqsJRnjC2l4g7',
          },
          'Biweekly': {
            price: 133.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MwcBWHUwNyXqsJRTq5hJXur',
          },
          'Monthly': {
            price: 174.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MwcBWHUwNyXqsJR03cryvd7',
          },
        },
        id: 'prod_Ni2GjlATy0mZ76',
        nickname: 'Kitchen, dining, living',
        sqfRange: '21-22',
        sqfMin: 21,
        sqfMax: 22,
      },
      '22': {
        name: 'Kitchen, dining, living (22-23)',
        price: {
          'One Time': {
            price: 210,
            cleaningFrequency: 'One Time',
            id: 'price_1MwcBRHUwNyXqsJRKp6EYDWs',
          },
          'Weekly': {
            price: 126,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MwcBRHUwNyXqsJRV6RAAgxz',
          },
          'Biweekly': {
            price: 136.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MwcBRHUwNyXqsJRvVH6r9B9',
          },
          'Monthly': {
            price: 178.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MwcBQHUwNyXqsJRHy0gypwX',
          },
        },
        id: 'prod_Ni2FhMyxqsy1Y5',
        nickname: 'Kitchen, dining, living',
        sqfRange: '22-23',
        sqfMin: 22,
        sqfMax: 23,
      },
      '23': {
        name: 'Kitchen, dining, living (23-24)',
        price: {
          'One Time': {
            price: 215,
            cleaningFrequency: 'One Time',
            id: 'price_1MwcBEHUwNyXqsJRoQ7qX99A',
          },
          'Weekly': {
            price: 129,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MwcBEHUwNyXqsJRTTXIN5W4',
          },
          'Biweekly': {
            price: 139.75,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MwcBEHUwNyXqsJRu88En1xZ',
          },
          'Monthly': {
            price: 182.75,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MwcBEHUwNyXqsJRF1JXMH4O',
          },
        },
        id: 'prod_Ni2FGOZ57eakja',
        nickname: 'Kitchen, dining, living',
        sqfRange: '23-24',
        sqfMin: 23,
        sqfMax: 24,
      },
      '24': {
        name: 'Kitchen, dining, living (24-25)',
        price: {
          'One Time': {
            price: 220,
            cleaningFrequency: 'One Time',
            id: 'price_1MwcB9HUwNyXqsJRzI0emFn4',
          },
          'Weekly': {
            price: 132,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MwcB9HUwNyXqsJRtbGTRoGL',
          },
          'Biweekly': {
            price: 143,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MwcB9HUwNyXqsJR7bMqDdEO',
          },
          'Monthly': {
            price: 187,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MwcB9HUwNyXqsJRnu6BlXK8',
          },
        },
        id: 'prod_Ni2FIIgzwWShOs',
        nickname: 'Kitchen, dining, living',
        sqfRange: '24-25',
        sqfMin: 24,
        sqfMax: 25,
      },
      '25': {
        name: 'Kitchen, dining, living (25-26)',
        price: {
          'One Time': {
            price: 225,
            cleaningFrequency: 'One Time',
            id: 'price_1MwcB4HUwNyXqsJREdHiGvrI',
          },
          'Weekly': {
            price: 135,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MwcB4HUwNyXqsJRO09cyztR',
          },
          'Biweekly': {
            price: 146.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MwcB4HUwNyXqsJRwTOnVQwn',
          },
          'Monthly': {
            price: 191.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MwcB4HUwNyXqsJRDH0cyfN6',
          },
        },
        id: 'prod_Ni2FKS8WEM6Uib',
        nickname: 'Kitchen, dining, living',
        sqfRange: '25-26',
        sqfMin: 25,
        sqfMax: 26,
      },
      '26': {
        name: 'Kitchen, dining, living (26-27)',
        price: {
          'One Time': {
            price: 230,
            cleaningFrequency: 'One Time',
            id: 'price_1MwcAyHUwNyXqsJRlDzltCsZ',
          },
          'Weekly': {
            price: 138,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MwcAyHUwNyXqsJRozoTzCma',
          },
          'Biweekly': {
            price: 149.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MwcAyHUwNyXqsJRQ3vUVfTV',
          },
          'Monthly': {
            price: 195.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MwcAyHUwNyXqsJRO61wtcF9',
          },
        },
        id: 'prod_Ni2FplwrRrooyz',
        nickname: 'Kitchen, dining, living',
        sqfRange: '26-27',
        sqfMin: 26,
        sqfMax: 27,
      },
      '27': {
        name: 'Kitchen, dining, living (27-28)',
        price: {
          'One Time': {
            price: 235,
            cleaningFrequency: 'One Time',
            id: 'price_1MwcArHUwNyXqsJRUXOzWJi9',
          },
          'Weekly': {
            price: 141,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MwcArHUwNyXqsJR70FKs99j',
          },
          'Biweekly': {
            price: 152.75,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MwcArHUwNyXqsJRIFVSZhvt',
          },
          'Monthly': {
            price: 199.75,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MwcArHUwNyXqsJRsaUZDxQM',
          },
        },
        id: 'prod_Ni2FypEhQhuRly',
        nickname: 'Kitchen, dining, living',
        sqfRange: '27-28',
        sqfMin: 27,
        sqfMax: 28,
      },
      '28': {
        name: 'Kitchen, dining, living (28-29)',
        price: {
          'One Time': {
            price: 240,
            cleaningFrequency: 'One Time',
            id: 'price_1MwcAkHUwNyXqsJR8p1dLtO7',
          },
          'Weekly': {
            price: 144,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MwcAkHUwNyXqsJROqxMK1FH',
          },
          'Biweekly': {
            price: 156,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MwcAkHUwNyXqsJR37RCVt3W',
          },
          'Monthly': {
            price: 204,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MwcAkHUwNyXqsJRBlELwNhB',
          },
        },
        id: 'prod_Ni2FMVtVgIw1DP',
        nickname: 'Kitchen, dining, living',
        sqfRange: '28-29',
        sqfMin: 28,
        sqfMax: 29,
      },
      '29': {
        name: 'Kitchen, dining, living (29-30)',
        price: {
          'One Time': {
            price: 245,
            cleaningFrequency: 'One Time',
            id: 'price_1MwcAIHUwNyXqsJRjiTqTXPL',
          },
          'Weekly': {
            price: 147,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MwcAIHUwNyXqsJRPrSghCE3',
          },
          'Biweekly': {
            price: 159.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MwcAIHUwNyXqsJRbhQCSu2A',
          },
          'Monthly': {
            price: 208.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MwcAIHUwNyXqsJRdoURcrTv',
          },
        },
        id: 'prod_Ni2E5v8IZjgwGx',
        nickname: 'Kitchen, dining, living',
        sqfRange: '29-30',
        sqfMin: 29,
        sqfMax: 30,
      },
      '30': {
        name: 'Kitchen, dining, living (30+)',
        price: {
          'One Time': {
            price: 250,
            cleaningFrequency: 'One Time',
            id: 'price_1MwcA6HUwNyXqsJRuYJjHXHe',
          },
          'Weekly': {
            price: 150,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MwcA6HUwNyXqsJRYPnv0cW6',
          },
          'Biweekly': {
            price: 162.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MwcA6HUwNyXqsJRYV0gSZ8j',
          },
          'Monthly': {
            price: 212.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MwcA6HUwNyXqsJRUrlbIaOP',
          },
        },
        id: 'prod_Ni2EIYQc4di335',
        nickname: 'Kitchen, dining, living',
        sqfRange: '30+',
        sqfMin: 30,
        sqfMax: 0,
      },
    },
    bathrooms: {
      all: {
        name: 'Full/Primary Bathroom',
        price: {
          'One Time': {
            price: 75,
            cleaningFrequency: 'One Time',
            id: 'price_1MwcA1HUwNyXqsJRUQpHOfht',
          },
          'Monthly': {
            price: 63.75,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MwcA1HUwNyXqsJR5zCtkCYl',
          },
          'Weekly': {
            price: 45,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MwcA1HUwNyXqsJRXRTx4JnX',
          },
          'Biweekly': {
            price: 48.75,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MwcA1HUwNyXqsJRvaSYm6op',
          },
        },
        id: 'prod_Ni2EGSAF3ENP6w',
        nickname: 'Full/Primary Bathroom',
      },
    },
    threeForthBathrooms: {
      all: {
        name: '3/4 Bathroom',
        price: {
          'Biweekly': {
            price: 42.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1Mwc9xHUwNyXqsJRh8crIVAt',
          },
          'One Time': {
            price: 65,
            cleaningFrequency: 'One Time',
            id: 'price_1Mwc9xHUwNyXqsJRsb8xtYJx',
          },
          'Monthly': {
            price: 55.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1Mwc9xHUwNyXqsJRXkVOQFAP',
          },
          'Weekly': {
            price: 39,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1Mwc9xHUwNyXqsJRt7j4Lkys',
          },
        },
        id: 'prod_Ni2EpZoHUsR3vv',
        nickname: '3/4 Bathroom',
      },
    },
    halfBathrooms: {
      all: {
        name: 'Half Bathroom',
        price: {
          'One Time': {
            price: 20,
            cleaningFrequency: 'One Time',
            id: 'price_1Mwc9sHUwNyXqsJRLLvsCKiv',
          },
          'Monthly': {
            price: 17,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1Mwc9sHUwNyXqsJR7c4mn4Gq',
          },
          'Biweekly': {
            price: 13,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1Mwc9sHUwNyXqsJRUbQ3mDyI',
          },
          'Weekly': {
            price: 12,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1Mwc9sHUwNyXqsJRAs4q7tnH',
          },
        },
        id: 'prod_Ni2EIabOoE1ly4',
        nickname: 'Half Bathroom',
      },
    },
    apartmentType: {
      all: {
        name: 'Bedroom/Office',
        price: {
          'One Time': {
            price: 25,
            cleaningFrequency: 'One Time',
            id: 'price_1Mwc9iHUwNyXqsJRndnkLB0I',
          },
          'Monthly': {
            price: 21.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1Mwc9hHUwNyXqsJRlS92gopI',
          },
          'Biweekly': {
            price: 16.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1Mwc9hHUwNyXqsJRUdUU4UMZ',
          },
          'Weekly': {
            price: 15,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1Mwc9hHUwNyXqsJR541FLbFx',
          },
        },
        id: 'prod_Ni2EpG8v8Vrucl',
        nickname: 'Bedroom/Office',
      },
    },
    commonAreas: {
      all: {
        name: 'Extra Living Room',
        price: {
          'Monthly': {
            price: 21.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1Mwc9cHUwNyXqsJRT67ihWdi',
          },
          'One Time': {
            price: 25,
            cleaningFrequency: 'One Time',
            id: 'price_1Mwc9cHUwNyXqsJR1wY9qMDM',
          },
          'Biweekly': {
            price: 16.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1Mwc9cHUwNyXqsJRX5hx5S2A',
          },
          'Weekly': {
            price: 15,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1Mwc9cHUwNyXqsJRGDqCpbgb',
          },
        },
        id: 'prod_Ni2E8aVbtms2zr',
        nickname: 'Extra Living Room',
      },
    },
    extraKitchen: {
      all: {
        name: 'Extra Kitchen',
        price: {
          'One Time': {
            price: 50,
            cleaningFrequency: 'One Time',
            id: 'price_1Mwc9XHUwNyXqsJR8kjJrCYE',
          },
          'Monthly': {
            price: 42.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1Mwc9XHUwNyXqsJRkCCY6Zjm',
          },
          'Biweekly': {
            price: 32.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1Mwc9WHUwNyXqsJRUxVrSa3T',
          },
          'Weekly': {
            price: 30,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1Mwc9WHUwNyXqsJRb2GJ7Ooo',
          },
        },
        id: 'prod_Ni2D2qeA7c4Cvl',
        nickname: 'Extra Kitchen',
      },
    },
    staircase: {
      all: {
        name: 'Stairs',
        price: {
          'One Time': {
            price: 12.5,
            cleaningFrequency: 'One Time',
            id: 'price_1Mwc9RHUwNyXqsJRTn2oxzD3',
          },
          'Monthly': {
            price: 10.63,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1Mwc9RHUwNyXqsJRd6Wo6s2d',
          },
          'Biweekly': {
            price: 8.13,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1Mwc9RHUwNyXqsJRh2jFxPfh',
          },
          'Weekly': {
            price: 7.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1Mwc9QHUwNyXqsJRe3z0g2Ru',
          },
        },
        id: 'prod_Ni2DeWC98vf02b',
        nickname: 'Stairs',
      },
    },
  },
  tips: {
    id: 'prod_Ni2RVa4eV8GRI0',
    name: 'Tip',
    values: [10, 15, 20],
  },
  dateCreated: '04/13/2023 at 7:56 PM',
};

const stripeDataTest: ProductsReturnInfo = {
  addons: [
    {
      name: 'Inside Fridge (Full)',
      price: {
        'One Time': {
          price: 65,
          cleaningFrequency: 'One Time',
          id: 'price_1MnupEHUwNyXqsJRokRY5hOA',
        },
      },
      id: 'prod_NZ2vChoIuIdV5y',
      imagePath: 'fridgeFull.svg',
      moveOutPreselected: false,
      isFirstCleanOnly: true,
      isSpecifyQuantity: false,
    },
    {
      name: 'Inside Fridge (Empty)',
      price: {
        'One Time': {
          price: 55,
          cleaningFrequency: 'One Time',
          id: 'price_1MnunAHUwNyXqsJR6dsXw5oc',
        },
      },
      id: 'prod_NZ2sIjwlnKYetm',
      imagePath: 'fridge.svg',
      moveOutPreselected: true,
      isFirstCleanOnly: true,
      isSpecifyQuantity: false,
    },
    {
      name: 'Inside Of Windows',
      price: {
        'One Time': {
          price: 55,
          cleaningFrequency: 'One Time',
          id: 'price_1MnuobHUwNyXqsJRk6kjvClE',
        },
      },
      id: 'prod_NZ2uAWf137kAwL',
      imagePath: 'window.svg',
      moveOutPreselected: true,
      isFirstCleanOnly: true,
      isSpecifyQuantity: false,
      shouldRemoveFromDeep: true,
    },
    {
      name: 'Inside Kitchen Cabinets (Full)',
      price: {
        'One Time': {
          price: 65,
          cleaningFrequency: 'One Time',
          id: 'price_1MnuowHUwNyXqsJRijgLg3zt',
        },
      },
      id: 'prod_NZ2uFSJ1ZSqTMP',
      imagePath: 'cabinets.svg',
      moveOutPreselected: false,
      isFirstCleanOnly: true,
      isSpecifyQuantity: false,
      shouldRemoveFromDeep: true,
    },
    {
      name: 'Inside Kitchen Cabinets (Empty)',
      price: {
        'One Time': {
          price: 55,
          cleaningFrequency: 'One Time',
          id: 'price_1MnuntHUwNyXqsJRR6FIYZKz',
        },
      },
      id: 'prod_NZ2tE21TDw9i0b',
      imagePath: 'cabinets.svg',
      moveOutPreselected: true,
      isFirstCleanOnly: true,
      isSpecifyQuantity: false,
      shouldRemoveFromDeep: true,
    },
    {
      name: 'Inside Oven',
      price: {
        'One Time': {
          price: 55,
          cleaningFrequency: 'One Time',
          id: 'price_1MnumrHUwNyXqsJRXRdevani',
        },
      },
      id: 'prod_NZ2srUdMz20nN9',
      imagePath: 'oven.svg',
      moveOutPreselected: true,
      isFirstCleanOnly: true,
      isSpecifyQuantity: false,
    },
    {
      name: 'Pick Up Toys (15-Min Increments)',
      price: {
        'Weekly': {
          price: 15,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Weekly',
          id: 'price_1MnumVHUwNyXqsJRJxWIXQb2',
        },
        'Biweekly': {
          price: 15,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Biweekly',
          id: 'price_1MnumVHUwNyXqsJR88MM5UOM',
        },
        'Monthly': {
          price: 15,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Monthly',
          id: 'price_1MnumVHUwNyXqsJRSK8TJ0Nu',
        },
        'One Time': {
          price: 15,
          cleaningFrequency: 'One Time',
          id: 'price_1MnumVHUwNyXqsJRE94UILlT',
        },
      },
      id: 'prod_NZ2sz11Dfidf0g',
      imagePath: 'toys.svg',
      moveOutPreselected: false,
      isFirstCleanOnly: false,
      isSpecifyQuantity: true,
    },
    {
      name: 'Vacuum Couch/Upholstery (Per Item)',
      price: {
        'Weekly': {
          price: 15,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Weekly',
          id: 'price_1MnuiQHUwNyXqsJRvYCdRWcR',
        },
        'Biweekly': {
          price: 15,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Biweekly',
          id: 'price_1MnuiQHUwNyXqsJRmW0smw5p',
        },
        'Monthly': {
          price: 15,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Monthly',
          id: 'price_1MnuiQHUwNyXqsJRE5LWSahs',
        },
        'One Time': {
          price: 15,
          cleaningFrequency: 'One Time',
          id: 'price_1MnuiQHUwNyXqsJRKjNPbJKm',
        },
      },
      id: 'prod_NZ2oJte7B01jWs',
      imagePath: 'couch.svg',
      moveOutPreselected: false,
      isFirstCleanOnly: false,
      isSpecifyQuantity: true,
    },
    {
      name: 'Change Bed Sheets (Per Bed, No Duvet)',
      price: {
        'Weekly': {
          price: 15,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Weekly',
          id: 'price_1MnuhUHUwNyXqsJRE4XAADWX',
        },
        'Biweekly': {
          price: 15,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Biweekly',
          id: 'price_1MnuhUHUwNyXqsJRnGtLAX0V',
        },
        'Monthly': {
          price: 15,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Monthly',
          id: 'price_1MnuhUHUwNyXqsJRmZs7H544',
        },
        'One Time': {
          price: 15,
          cleaningFrequency: 'One Time',
          id: 'price_1MnuhUHUwNyXqsJRyJpRxYjG',
        },
      },
      id: 'prod_NZ2nSDHSozLGZG',
      imagePath: 'bed.svg',
      moveOutPreselected: false,
      isFirstCleanOnly: false,
      isSpecifyQuantity: true,
    },
    {
      name: 'Clean Doors',
      price: {
        'Weekly': {
          price: 30,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Weekly',
          id: 'price_1MnugZHUwNyXqsJRrquz7ozO',
        },
        'Biweekly': {
          price: 30,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Biweekly',
          id: 'price_1MnugZHUwNyXqsJRpBrBMitP',
        },
        'Monthly': {
          price: 30,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Monthly',
          id: 'price_1MnugZHUwNyXqsJRw9r4yekX',
        },
        'One Time': {
          price: 30,
          cleaningFrequency: 'One Time',
          id: 'price_1MnugZHUwNyXqsJRAWeOqvBs',
        },
      },
      id: 'prod_NZ2m3GNjgqjQgr',
      imagePath: 'door.svg',
      moveOutPreselected: true,
      isFirstCleanOnly: false,
      isSpecifyQuantity: false,
    },
    {
      name: 'Collect Trash/Recycling (No Compost)',
      price: {
        'Weekly': {
          price: 20,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Weekly',
          id: 'price_1MnufmHUwNyXqsJRbGRh0ycK',
        },
        'Biweekly': {
          price: 20,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Biweekly',
          id: 'price_1MnufmHUwNyXqsJRD7obB14f',
        },
        'Monthly': {
          price: 20,
          cleaningType: 'Recurring Clean',
          cleaningFrequency: 'Monthly',
          id: 'price_1MnufmHUwNyXqsJRRJHtbv5d',
        },
        'One Time': {
          price: 20,
          cleaningFrequency: 'One Time',
          id: 'price_1MnufmHUwNyXqsJR2XdY1G6m',
        },
      },
      id: 'prod_NZ2lWwpdZCfEOI',
      imagePath: 'trash.svg',
      moveOutPreselected: false,
      isFirstCleanOnly: false,
      isSpecifyQuantity: false,
    },
  ],
  cleanings: {
    staircase: {
      all: {
        name: 'Stairs',
        price: {
          'Weekly': {
            price: 7.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MnueqHUwNyXqsJRaNBmpWpL',
          },
          'Biweekly': {
            price: 8.13,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MnueqHUwNyXqsJR8mI5PlHi',
          },
          'Monthly': {
            price: 10.63,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MnueqHUwNyXqsJRRuQZ3TAC',
          },
          'One Time': {
            price: 12.5,
            cleaningFrequency: 'One Time',
            id: 'price_1MnueqHUwNyXqsJRGmS85IhA',
          },
        },
        id: 'prod_NZ2kcDMupmcyas',
        nickname: 'Stairs',
      },
    },
    extraKitchen: {
      all: {
        name: 'Extra Kitchen',
        price: {
          'Weekly': {
            price: 30,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MnudgHUwNyXqsJRjKZjc9le',
          },
          'Biweekly': {
            price: 32.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MnudgHUwNyXqsJRUsXrnM9n',
          },
          'Monthly': {
            price: 42.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MnudgHUwNyXqsJRWkKXkkzg',
          },
          'One Time': {
            price: 50,
            cleaningFrequency: 'One Time',
            id: 'price_1MnudgHUwNyXqsJRtjWohAYX',
          },
        },
        id: 'prod_NZ2jwR7ThGu9lA',
        nickname: 'Extra Kitchen',
      },
    },
    commonAreas: {
      all: {
        name: 'Extra Living Room',
        price: {
          'Weekly': {
            price: 15,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MnuceHUwNyXqsJRBiRuKzXh',
          },
          'Biweekly': {
            price: 16.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MnuceHUwNyXqsJRxUrmq7aM',
          },
          'One Time': {
            price: 25,
            cleaningFrequency: 'One Time',
            id: 'price_1MnuceHUwNyXqsJRCFcFY0eY',
          },
          'Monthly': {
            price: 21.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MnuceHUwNyXqsJRX0XveWMI',
          },
        },
        id: 'prod_NZ2ih6N3poqgwC',
        nickname: 'Extra Living Room',
      },
    },
    apartmentType: {
      all: {
        name: 'Bedroom/Office',
        price: {
          'Weekly': {
            price: 15,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MnubjHUwNyXqsJRbv8D4KTk',
          },
          'Biweekly': {
            price: 16.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MnubjHUwNyXqsJRhcLXYDbY',
          },
          'Monthly': {
            price: 21.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MnubjHUwNyXqsJRJahT6sJV',
          },
          'One Time': {
            price: 25,
            cleaningFrequency: 'One Time',
            id: 'price_1MnubjHUwNyXqsJRgrDdI0ZX',
          },
        },
        id: 'prod_NZ2h4maeirxx01',
        nickname: 'Bedroom/Office',
      },
    },
    halfBathrooms: {
      all: {
        name: 'Half Bathroom',
        price: {
          'Weekly': {
            price: 12,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MnuaZHUwNyXqsJRi3l8Q8m6',
          },
          'Biweekly': {
            price: 13,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MnuaZHUwNyXqsJRErvYHrRf',
          },
          'Monthly': {
            price: 17,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MnuaZHUwNyXqsJRZYcsxuDG',
          },
          'One Time': {
            price: 20,
            cleaningFrequency: 'One Time',
            id: 'price_1MnuaZHUwNyXqsJRg73wqfdl',
          },
        },
        id: 'prod_NZ2fzubVvR6efY',
        nickname: 'Half Bathroom',
      },
    },
    threeForthBathrooms: {
      all: {
        name: '3/4 Bathroom',
        price: {
          'Weekly': {
            price: 39,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MnuY0HUwNyXqsJR3Qokncu2',
          },
          'Monthly': {
            price: 55.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MnuY0HUwNyXqsJRk1Vfj64D',
          },
          'One Time': {
            price: 65,
            cleaningFrequency: 'One Time',
            id: 'price_1MnuY0HUwNyXqsJRLpk2sm7f',
          },
          'Biweekly': {
            price: 42.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MnuY0HUwNyXqsJRISYxwEkF',
          },
        },
        id: 'prod_NZ2dPNtWnyN8wf',
        nickname: '3/4 Bathroom',
      },
    },
    bathrooms: {
      all: {
        name: 'Full/Primary Bathroom',
        price: {
          'Biweekly': {
            price: 48.75,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MnuWSHUwNyXqsJR3Hq7UcZd',
          },
          'Weekly': {
            price: 45,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MnuWSHUwNyXqsJROXFeuyzK',
          },
          'Monthly': {
            price: 63.75,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MnuWSHUwNyXqsJReNnNrvsv',
          },
          'One Time': {
            price: 75,
            cleaningFrequency: 'One Time',
            id: 'price_1MnuWSHUwNyXqsJR5S1dx4Sa',
          },
        },
        id: 'prod_NZ2bLWD15ja3sh',
        nickname: 'Full/Primary Bathroom',
      },
    },
    kitchenLivingRoomDining: {
      '0': {
        name: 'Kitchen, dining, living (0-15)',
        price: {
          'Monthly': {
            price: 144.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MlJdfHUwNyXqsJRWogmUCpn',
          },
          'Weekly': {
            price: 102,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MlJdfHUwNyXqsJRSDNTD6hj',
          },
          'Biweekly': {
            price: 110.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MlJdfHUwNyXqsJRiyv5YMdI',
          },
          'One Time': {
            price: 170,
            cleaningFrequency: 'One Time',
            id: 'price_1MepK7HUwNyXqsJRBy05mhiA',
          },
        },
        id: 'prod_NPed6I9EdTe2Y5',
        nickname: 'Kitchen, dining, living',
        sqfRange: '0-15',
        sqfMin: 0,
        sqfMax: 15,
      },
      '15': {
        name: 'Kitchen, dining, living (15-16)',
        price: {
          'Monthly': {
            price: 148.75,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MlJcpHUwNyXqsJR9hr06mUJ',
          },
          'Biweekly': {
            price: 113.75,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MlJcpHUwNyXqsJRADc0FCW7',
          },
          'Weekly': {
            price: 105,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MlJcpHUwNyXqsJRcKQRRb2G',
          },
          'One Time': {
            price: 175,
            cleaningFrequency: 'One Time',
            id: 'price_1MepKiHUwNyXqsJRiT8K6gxS',
          },
        },
        id: 'prod_NPeefNm3WEePwt',
        nickname: 'Kitchen, dining, living',
        sqfRange: '15-16',
        sqfMin: 15,
        sqfMax: 16,
      },
      '16': {
        name: 'Kitchen, dining, living (16-17)',
        price: {
          'Monthly': {
            price: 153,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MlJbPHUwNyXqsJRIPnb1HSr',
          },
          'Biweekly': {
            price: 117,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MlJbPHUwNyXqsJR79RcGmjS',
          },
          'Weekly': {
            price: 108,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MlJbPHUwNyXqsJR729KZ0xV',
          },
          'One Time': {
            price: 180,
            cleaningFrequency: 'One Time',
            id: 'price_1MepKzHUwNyXqsJROtgkYjuT',
          },
        },
        id: 'prod_NPeeblxkEVgryZ',
        nickname: 'Kitchen, dining, living',
        sqfRange: '16-17',
        sqfMin: 16,
        sqfMax: 17,
      },
      '17': {
        name: 'Kitchen, dining, living (17-18)',
        price: {
          'Monthly': {
            price: 157.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MlJabHUwNyXqsJRqf2AnO8U',
          },
          'Biweekly': {
            price: 120.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MlJabHUwNyXqsJRWRkgcyWW',
          },
          'Weekly': {
            price: 111,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MlJabHUwNyXqsJR3VMbzKII',
          },
          'One Time': {
            price: 185,
            cleaningFrequency: 'One Time',
            id: 'price_1MepLKHUwNyXqsJRYqAAJrSP',
          },
        },
        id: 'prod_NPee5WBOXA0XVZ',
        nickname: 'Kitchen, dining, living',
        sqfRange: '17-18',
        sqfMin: 17,
        sqfMax: 18,
      },
      '18': {
        name: 'Kitchen, dining, living (18-19)',
        price: {
          'Monthly': {
            price: 161.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MlJZXHUwNyXqsJRqP50WbqQ',
          },
          'Biweekly': {
            price: 123.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MlJZXHUwNyXqsJR2Ps8Q0or',
          },
          'Weekly': {
            price: 114,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MlJZXHUwNyXqsJR9Wa1b4vB',
          },
          'One Time': {
            price: 190,
            cleaningFrequency: 'One Time',
            id: 'price_1MepLdHUwNyXqsJR9H05GSFs',
          },
        },
        id: 'prod_NPeedsGGwXgXFn',
        nickname: 'Kitchen, dining, living',
        sqfRange: '18-19',
        sqfMin: 18,
        sqfMax: 19,
      },
      '19': {
        name: 'Kitchen, dining, living (19-20)',
        price: {
          'Monthly': {
            price: 165.75,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MlJY6HUwNyXqsJRV0exewLX',
          },
          'Biweekly': {
            price: 126.75,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MlJY6HUwNyXqsJRaMklkPMx',
          },
          'Weekly': {
            price: 117,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MlJY6HUwNyXqsJRJuroUEib',
          },
          'One Time': {
            price: 195,
            cleaningFrequency: 'One Time',
            id: 'price_1MepNuHUwNyXqsJR7yF9tNRx',
          },
        },
        id: 'prod_NPeh9MHJpCOgED',
        nickname: 'Kitchen, dining, living',
        sqfRange: '19-20',
        sqfMin: 19,
        sqfMax: 20,
      },
      '20': {
        name: 'Kitchen, dining, living (20-21)',
        price: {
          'Monthly': {
            price: 170,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MlJX4HUwNyXqsJRO7r3TOoq',
          },
          'Biweekly': {
            price: 130,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MlJX4HUwNyXqsJRX6RrJVvN',
          },
          'Weekly': {
            price: 120,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MlJX4HUwNyXqsJRv1xu841j',
          },
          'One Time': {
            price: 200,
            cleaningFrequency: 'One Time',
            id: 'price_1MepOFHUwNyXqsJR0EKOC4g0',
          },
        },
        id: 'prod_NPehbiZR5mDMBV',
        nickname: 'Kitchen, dining, living',
        sqfRange: '20-21',
        sqfMin: 20,
        sqfMax: 21,
      },
      '21': {
        name: 'Kitchen, dining, living (21-22)',
        price: {
          'Monthly': {
            price: 174.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MlJW7HUwNyXqsJRwk7KZeQZ',
          },
          'Biweekly': {
            price: 133.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MlJW7HUwNyXqsJRcJtJoDGX',
          },
          'Weekly': {
            price: 123,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MlJW7HUwNyXqsJRtBqMIIRu',
          },
          'One Time': {
            price: 205,
            cleaningFrequency: 'One Time',
            id: 'price_1MlJ19HUwNyXqsJRuqeirHXK',
          },
        },
        id: 'prod_NWLixRVcSEsOqo',
        nickname: 'Kitchen, dining, living',
        sqfRange: '21-22',
        sqfMin: 21,
        sqfMax: 22,
      },
      '22': {
        name: 'Kitchen, dining, living (22-23)',
        price: {
          'Monthly': {
            price: 178.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MlJUyHUwNyXqsJRAadgshZ8',
          },
          'Biweekly': {
            price: 136.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MlJUyHUwNyXqsJRCdOWuU7i',
          },
          'Weekly': {
            price: 126,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MlJUyHUwNyXqsJR66TTyQwn',
          },
          'One Time': {
            price: 210,
            cleaningFrequency: 'One Time',
            id: 'price_1MlJ2XHUwNyXqsJRjIPUq4rK',
          },
        },
        id: 'prod_NWLkBzPzQ2C58P',
        nickname: 'Kitchen, dining, living',
        sqfRange: '22-23',
        sqfMin: 22,
        sqfMax: 23,
      },
      '23': {
        name: 'Kitchen, dining, living (23-24)',
        price: {
          'Monthly': {
            price: 182.75,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MlJSzHUwNyXqsJRJPj3US6u',
          },
          'Biweekly': {
            price: 139.75,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MlJSzHUwNyXqsJRlpDSFHbc',
          },
          'Weekly': {
            price: 129,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MlJSzHUwNyXqsJRtiJNbL2W',
          },
          'One Time': {
            price: 215,
            cleaningFrequency: 'One Time',
            id: 'price_1MlJ34HUwNyXqsJRB4hLsQso',
          },
        },
        id: 'prod_NWLkQMUxsPBK1y',
        nickname: 'Kitchen, dining, living',
        sqfRange: '23-24',
        sqfMin: 23,
        sqfMax: 24,
      },
      '24': {
        name: 'Kitchen, dining, living (24-25)',
        price: {
          'Monthly': {
            price: 187,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MlJPnHUwNyXqsJRnesuqJLu',
          },
          'Biweekly': {
            price: 143,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MlJPnHUwNyXqsJR0F1xDtyJ',
          },
          'Weekly': {
            price: 132,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MlJPnHUwNyXqsJRyTczam87',
          },
          'One Time': {
            price: 220,
            cleaningFrequency: 'One Time',
            id: 'price_1MlJ3aHUwNyXqsJR6NCxcUyn',
          },
        },
        id: 'prod_NWLlK5upTW8FfT',
        nickname: 'Kitchen, dining, living',
        sqfRange: '24-25',
        sqfMin: 24,
        sqfMax: 25,
      },
      '25': {
        name: 'Kitchen, dining, living (25-26)',
        price: {
          'Monthly': {
            price: 191.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MlJMzHUwNyXqsJR5gqTgb9U',
          },
          'Biweekly': {
            price: 146.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MlJMzHUwNyXqsJRD9YBLiJQ',
          },
          'Weekly': {
            price: 135,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MlJMzHUwNyXqsJRGcWCZbIk',
          },
          'One Time': {
            price: 225,
            cleaningFrequency: 'One Time',
            id: 'price_1MlJ46HUwNyXqsJR39JGT857',
          },
        },
        id: 'prod_NWLl3PDi3c93rc',
        nickname: 'Kitchen, dining, living',
        sqfRange: '25-26',
        sqfMin: 25,
        sqfMax: 26,
      },
      '26': {
        name: 'Kitchen, dining, living (26-27)',
        price: {
          'Monthly': {
            price: 195.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MlJLoHUwNyXqsJRIbKNZZSs',
          },
          'Biweekly': {
            price: 149.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MlJLoHUwNyXqsJRq3LSfr4V',
          },
          'Weekly': {
            price: 138,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MlJLoHUwNyXqsJRPMrcV5b7',
          },
          'One Time': {
            price: 230,
            cleaningFrequency: 'One Time',
            id: 'price_1MlJ4cHUwNyXqsJREG2iks6W',
          },
        },
        id: 'prod_NWLm5DLJD1Ez7N',
        nickname: 'Kitchen, dining, living',
        sqfRange: '26-27',
        sqfMin: 26,
        sqfMax: 27,
      },
      '27': {
        name: 'Kitchen, dining, living (27-28)',
        price: {
          'Monthly': {
            price: 199.75,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MlJKlHUwNyXqsJRcTeLempz',
          },
          'Biweekly': {
            price: 152.75,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MlJKlHUwNyXqsJReZzfLqgS',
          },
          'Weekly': {
            price: 141,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MlJKlHUwNyXqsJR2y13nDLv',
          },
          'One Time': {
            price: 235,
            cleaningFrequency: 'One Time',
            id: 'price_1MlJ4tHUwNyXqsJR2zGTffGw',
          },
        },
        id: 'prod_NWLm1DmUfaFCea',
        nickname: 'Kitchen, dining, living',
        sqfRange: '27-28',
        sqfMin: 27,
        sqfMax: 28,
      },
      '28': {
        name: 'Kitchen, dining, living (28-29)',
        price: {
          'Monthly': {
            price: 204,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MlJJLHUwNyXqsJRa8m4kL4h',
          },
          'Biweekly': {
            price: 156,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MlJJLHUwNyXqsJRS8X2WNgT',
          },
          'Weekly': {
            price: 144,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MlJJLHUwNyXqsJRGfQ4km0n',
          },
          'One Time': {
            price: 240,
            cleaningFrequency: 'One Time',
            id: 'price_1MlJ5PHUwNyXqsJRZuaWFqNK',
          },
        },
        id: 'prod_NWLmdBuQnDpLvw',
        nickname: 'Kitchen, dining, living',
        sqfRange: '28-29',
        sqfMin: 28,
        sqfMax: 29,
      },
      '29': {
        name: 'Kitchen, dining, living (29-30)',
        price: {
          'Monthly': {
            price: 208.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MlJICHUwNyXqsJRsp0KUzP8',
          },
          'Biweekly': {
            price: 159.25,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MlJICHUwNyXqsJRn6CsNo05',
          },
          'Weekly': {
            price: 147,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MlJICHUwNyXqsJRb2Lblzue',
          },
          'One Time': {
            price: 245,
            cleaningFrequency: 'One Time',
            id: 'price_1MlJ5sHUwNyXqsJRlUFyiexW',
          },
        },
        id: 'prod_NWLnsh1QAdNm7b',
        nickname: 'Kitchen, dining, living',
        sqfRange: '29-30',
        sqfMin: 29,
        sqfMax: 30,
      },
      '30': {
        name: 'Kitchen, dining, living (30+)',
        price: {
          'Monthly': {
            price: 212.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Monthly',
            id: 'price_1MlJCbHUwNyXqsJRI3bgQLWq',
          },
          'Biweekly': {
            price: 162.5,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Biweekly',
            id: 'price_1MlJCbHUwNyXqsJR4WVAdrxj',
          },
          'Weekly': {
            price: 150,
            cleaningType: 'Recurring Clean',
            cleaningFrequency: 'Weekly',
            id: 'price_1MlJCbHUwNyXqsJRWk2J5S6c',
          },
          'One Time': {
            price: 250,
            cleaningFrequency: 'One Time',
            id: 'price_1MlJ69HUwNyXqsJRV2OiFOmm',
          },
        },
        id: 'prod_NWLnWCivrDJU55',
        nickname: 'Kitchen, dining, living',
        sqfRange: '30+',
        sqfMin: 30,
        sqfMax: 0,
      },
    },
  },
  tips: {
    id: 'prod_NZPVuow8xthYyF',
    name: 'Tip',
    values: [10, 15, 20],
  },
  dateCreated: '03/23/2023 at 10:22 PM',
};

export const stripeData = isProduction ? stripeDataProd : stripeDataTest;
