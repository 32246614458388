import { useState, useEffect, FunctionComponent, useContext } from 'react';
import moment from 'moment-timezone';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useNavigate, useLocation } from 'react-router-dom';
import { InfoModal } from '../../../Components';
import { PaymentFormData } from '../../../../types/PaymentFormDataTypes';
import { Status } from '../../../../types/SubmitDataTypes';
import { LogError } from '../../../../Utils/ErrorLog';
import { FormInputsValidation, StripeCardObject } from '../../../../types/StripeTypes';
import { createGoogleCalendarEvent, validate, validateEmail } from './Common';
import PersonalInfoFields from './PersonalInfoFields';
import CardPaymentFields from './CardPaymentFields';
import CheckBoxes from './CheckBoxes';
import PaymentButtons from './PaymentButtons';
import LeadSource from './LeadSource';
import { AddressFormRegisteredFields } from '../../../../types/AddressFormTypes';
import { observer } from 'mobx-react';
import CalendarTimesManager from '../../../../Mobx/FetchingManagers/CalendarTimesManager';
import PaymentFormDataManager from '../../../../Mobx/BookingFlowData/PaymentFormDataManager';
import AddressFormDataManager from '../../../../Mobx/BookingFlowData/AddressFormDataManager';
import CleanDateTimeManager from '../../../../Mobx/BookingFlowData/CleanDateTimeManager';
import { InitialBookingState } from '../../../../types/StateTypes';
import PriceManager from '../../../../Mobx/BookingFlowData/PriceManager';
import SelectedOptionsManager from '../../../../Mobx/BookingFlowData/SelectedOptionsManager';
import PromoCodeManager from '../../../../Mobx/BookingFlowData/PromoCodeManager';
import { AlertContext } from '../../../Contexts/Alert/AlertContext';
import { BookingKeyContext } from '../../../Contexts/BookingKeyContext/BookingKeyContext';
import { SubmitDataContext } from '../../../Contexts/SubmitDataContext/SubmitDataContext';
// import { isProduction } from '../../../../Config';

interface StripePaymentFormProps {
  onSubmitPaymentForm?: () => void;
  getAddressFormValues?: () => void;
  isRecurring: boolean;
}

const StripePaymentForm: FunctionComponent<StripePaymentFormProps> = ({
  isRecurring,
  onSubmitPaymentForm,
  getAddressFormValues,
}: StripePaymentFormProps) => {
  const bookingKeyContext = useContext(BookingKeyContext);
  const alertContext = useContext(AlertContext);
  const submitDataContext = useContext(SubmitDataContext);

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const location = useLocation();

  const paymentForm: PaymentFormData = PaymentFormDataManager.getPaymentFields();
  const calendarEventLocation: string = AddressFormDataManager.getFullAddress();
  const selectedDay: moment.Moment | undefined = CleanDateTimeManager.getDateTime();
  const selectedTime: string = CleanDateTimeManager.getTimeSlot();
  const address: AddressFormRegisteredFields = AddressFormDataManager.getAddressFormValues();
  const availableTimesData = CalendarTimesManager.getAvailableTimes();
  const dateWithTimeslot = CleanDateTimeManager.getDateAndTimeSlot();

  const resultPage = isRecurring ? '/booked-recurring' : '/booked';
  const modalTitle = 'Processing payment';
  const modalBodyText = "Don't refresh this page";

  const [isLoading, setIsLoading] = useState(false);
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [checkedCancellation, setCheckedCancellation] = useState(false);
  const [leadSource, setLeadSource] = useState('');
  const [showPaymentForm, setShowPaymentForm] = useState(!isRecurring || !(bookingKeyContext.cleaning?.cardUsed || ''));
  const [formErrors, setFormErrors] = useState<FormInputsValidation>({
    email: false,
    phone: false,
    firstName: false,
    lastName: false,
    leadSource: false,
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });
  const [cardDetails, setCardDetails] = useState<StripeCardObject>({
    cardNumber: undefined,
    cardExpiry: undefined,
    cardCvc: undefined,
  } as StripeCardObject);

  useEffect(() => {
    if (submitDataContext.status === Status.success) {
      navigate(resultPage, { replace: true, state: { from: location.pathname } });
    } else if (submitDataContext.status === Status.inProgress) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [submitDataContext.status]);

  const validateFields = () => {
    const errors: FormInputsValidation = {
      email: !validateEmail(paymentForm.email.defaultValue),
      phone: paymentForm.phone.defaultValue === '',
      firstName: paymentForm.firstName.defaultValue === '',
      lastName: paymentForm.lastName.defaultValue === '',
      leadSource: showPaymentForm ? paymentForm.leadSource.defaultValue === '' : false,
      cardNumber: showPaymentForm ? !(cardDetails.cardNumber?.complete && !cardDetails.cardNumber?.error) : false,
      cardExpiry: showPaymentForm ? !(cardDetails.cardExpiry?.complete && !cardDetails.cardExpiry?.error) : false,
      cardCvc: showPaymentForm ? !(cardDetails.cardCvc?.complete && !cardDetails.cardCvc?.error) : false,
    };

    setFormErrors(errors);

    if (
      errors.email ||
      errors.phone ||
      errors.firstName ||
      errors.lastName ||
      errors.leadSource ||
      errors.cardNumber ||
      errors.cardExpiry ||
      errors.cardCvc
    ) {
      return false;
    }

    return true;
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (onSubmitPaymentForm) {
      onSubmitPaymentForm();
    }

    if (!validateFields()) {
      alertContext.openAlert('Invalid inputs', false);
      return false;
    }

    const errorMessage = validate(selectedDay, selectedTime, checkedTerms, checkedCancellation);
    if (errorMessage) {
      alertContext.openAlert(errorMessage, false);
      return false;
    }

    if (!dateWithTimeslot) {
      alertContext.openAlert('Date not selected', false);
      return false;
    }

    setIsLoading(true);
    const paymentMethodData = await getPaymentMethodId();
    const paymentMethodId = paymentMethodData?.paymentMethodId || '';
    if (showPaymentForm && !paymentMethodId) {
      alertContext.openAlert(paymentMethodData?.error || 'Unable to collect payment information.', false);
      LogError('handleSubmit', { message: 'Payment method id is empty' }, true);
      setIsLoading(false);
      return;
    }

    const timeSlotData = availableTimesData?.timeSlots[selectedTime] || undefined;
    const eventLength = timeSlotData ? timeSlotData.eventDuration : 2;
    const eventBody = createGoogleCalendarEvent(dateWithTimeslot, calendarEventLocation, eventLength);
    const fullBookingState: InitialBookingState = {
      price: PriceManager.getPrice(),
      selected: SelectedOptionsManager.getSelected(),
      addressForm: AddressFormDataManager.getAddressFormValues(),
      paymentForm: PaymentFormDataManager.getPaymentFields(),
      dateTimeWithTimeSlot: CleanDateTimeManager.getDateAndTimeSlot(),
      couponsApplied: PromoCodeManager.getAppliedCoupon(),
      selectedTip: PriceManager.getTip(),
    };

    if (fullBookingState.selected.squareFootage.numberKey === 0) {
      alertContext.openAlert('Square footage cannot be empty', false);
      LogError('handleSubmit', { message: 'Empty square footage' }, true);
      setIsLoading(false);
      return;
    }

    const errorMessageAfterSubmit = await submitDataContext.submitDataAttempt(
      fullBookingState,
      bookingKeyContext.key,
      eventBody,
      paymentMethodId,
      isRecurring
    );

    if (errorMessageAfterSubmit) {
      alertContext.openAlert(errorMessageAfterSubmit, false);
    }
  };

  const getPaymentMethodId = async (): Promise<{ paymentMethodId: string; error: string } | undefined> => {
    try {
      // TODO rethink this
      if (!showPaymentForm) {
        return undefined;
      }

      if (!stripe || !elements) {
        LogError('getPaymentMethodId', { message: 'stripe and/or elements is empty' }, true);
        return undefined;
      }

      const cardNumberElement = elements.getElement(CardNumberElement);
      if (!cardNumberElement) {
        LogError('getPaymentMethodId', { message: 'Card elements are empty' }, true);
        return undefined;
      }

      const payload = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: {
          email: paymentForm.email?.defaultValue,
          phone: paymentForm.phone?.defaultValue,
          name: `${paymentForm.firstName?.defaultValue} ${paymentForm.lastName?.defaultValue}`,
          address: {
            city: address.city,
            country: 'US',
            line1: address.address,
            line2: address.apartment,
            postal_code: address.zip,
            state: 'WA',
          },
        },
      });
      if (payload.error) {
        LogError('getPaymentMethodId', { message: payload.error }, true);
        return undefined;
      }

      //      if (isProduction && payload.paymentMethod.card?.checks?.cvc_check !== 'pass') {
      //         LogError('getPaymentMethodId', 'cvc check failed', true);
      //         return { paymentMethodId: '', error: 'CVC check failed' };
      //       }

      return { paymentMethodId: payload.paymentMethod.id || '', error: '' };
    } catch (e) {
      LogError('getPaymentMethodId', { message: JSON.stringify(e) }, true);
      return undefined;
    }
  };

  return (
    <form
      id="payment-form"
      onSubmit={(e) => {
        void handleSubmit(e);
      }}>
      {isLoading && <InfoModal title={modalTitle} bodyText={modalBodyText} />}
      <PersonalInfoFields
        getAddressFormValues={getAddressFormValues}
        isRecurring={isRecurring}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
      />
      {isRecurring ? (
        <PaymentButtons showPaymentForm={showPaymentForm} setShowPaymentForm={setShowPaymentForm} />
      ) : (
        <LeadSource
          leadSource={leadSource}
          setLeadSource={setLeadSource}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
        />
      )}
      {showPaymentForm && (
        <CardPaymentFields
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          setCardDetails={setCardDetails}
          cardDetails={cardDetails}
        />
      )}
      <CheckBoxes
        terms={{ checked: checkedTerms, setChecked: setCheckedTerms }}
        cancellation={{ checked: checkedCancellation, setChecked: setCheckedCancellation }}
      />
    </form>
  );
};

export default observer(StripePaymentForm);
