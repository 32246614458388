import { StripeInfoForClean } from './StripeDataState';

export interface AddressFormJson {
  constantValues: AddressFormData;
}

export enum KeyOptions {
  someoneIsHome = 'Someone is home',
  hiddenKey = 'Hidden key',
  other = 'Other',
  notSet = '',
}

export enum ParkingOptions {
  street = 'Street Parking (free within 2 blocks)',
  driveway = 'Driveway',
  paid = 'Paid Parking',
  other = 'Other',
  notSet = '',
}

export enum AddressFormFieldsName {
  address = 'address',
  apartment = 'apartment',
  zip = 'zip',
  city = 'city',
  parking = 'parking',
  keyOption = 'keyOption',
  petOption = 'petOption',
  informationFields = 'informationFields',
  addOns = 'addOns',
  state = 'state',
}

export enum InformationFormFieldsName {
  keyInformation = 'keyInformation',
  otherInformation = 'otherInformation',
  petInformation = 'petInformation',
  parkingInformation = 'parkingInformation',
}

// State
export interface AddressFormRegisteredFields {
  [AddressFormFieldsName.address]: string;
  [AddressFormFieldsName.apartment]: string;
  [AddressFormFieldsName.zip]: string;
  [AddressFormFieldsName.city]: string;
  [AddressFormFieldsName.parking]: ParkingOptions;
  [AddressFormFieldsName.keyOption]: KeyOptions;
  [AddressFormFieldsName.petOption]: string[];
  [AddressFormFieldsName.addOns]: AddonDataType[];
  [AddressFormFieldsName.state]: string;

  [InformationFormFieldsName.keyInformation]: string;
  [InformationFormFieldsName.parkingInformation]: string;
  [InformationFormFieldsName.otherInformation]: string;
  [InformationFormFieldsName.petInformation]: string;
}

// For Payload
export interface AddressFormData {
  [AddressFormFieldsName.address]: CustomDataValue<string>;
  [AddressFormFieldsName.apartment]: CustomDataValue<string>;
  [AddressFormFieldsName.zip]: CustomDataValue<string>;
  [AddressFormFieldsName.city]: CustomDataValue<string>;
  [AddressFormFieldsName.state]: CustomDataValue<string>;
  [AddressFormFieldsName.parking]: ParkingDataValue;
  [AddressFormFieldsName.keyOption]: KeyDataValue;
  [AddressFormFieldsName.petOption]: PetDataValue;
  [AddressFormFieldsName.addOns]: CustomDataValue<AddonDataType[]>;
  [AddressFormFieldsName.informationFields]: InformationFieldsObjects;
}

export interface InformationFieldsObjects {
  [InformationFormFieldsName.otherInformation]: DataInformation;
  [InformationFormFieldsName.petInformation]: DataInformation;
  [InformationFormFieldsName.keyInformation]: DataInformation;
  [InformationFormFieldsName.parkingInformation]: DataInformation;
}

interface Common {
  placeholder?: string;
  descriptionText?: string;
  name: AddressFormFieldsName;
  label: string;
  rules?: { required: string | boolean };
  errorMessagesForAlert?: {
    outOfRange?: string;
    default: string;
  };
}

export type CustomDataValue<T> = Common & {
  defaultValue: T;
};

export type KeyDataValue = CustomDataValue<KeyOptions> & {
  options: {
    [KeyOptions.someoneIsHome]: OptionMetadata;
    [KeyOptions.hiddenKey]: OptionMetadata;
    [KeyOptions.other]: OptionMetadata;
  };
};

export type ParkingDataValue = CustomDataValue<ParkingOptions> & {
  options: {
    [ParkingOptions.street]: OptionMetadata;
    [ParkingOptions.driveway]: OptionMetadata;
    [ParkingOptions.paid]: OptionMetadata;
    [ParkingOptions.other]: OptionMetadata;
  };
};

export type PetDataValue = CustomDataValue<string[]> & {
  options: string[];
};

interface Common2 {
  defaultValue: string;
  placeholder: string;
  moreText?: string;
  descriptionText?: string;
  label?: string;
  rules?: { required: string };
}

export type OptionMetadata = Common2 & {
  requiredClarificationBox: boolean;
};

export type DataInformation = Common2 & {
  name: InformationFormFieldsName;
};

export type AddonDataType = StripeInfoForClean & {
  isFirstCleanOnly: boolean;
  quantity: number;
  recurringPrice: number;
  recurringPriceId: string;
};
