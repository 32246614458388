import { AddonDataType } from '../types/AddressFormTypes';
import { PriceBase, PriceValues } from '../types/Price';
import { SelectedBookingInfo } from '../types/StateTypes';
import { StripeInfoForClean } from '../types/StripeDataState';

const hourlyRate = 48;

export const calculateTime = (price: number): string => {
  const time = price / hourlyRate;
  const roundedTime = Math.round(time + Number.EPSILON);
  return `${roundedTime} - ${roundedTime + 1}`;
};

export const getPriceDataForSelected = (
  selectedData: SelectedBookingInfo,
  selectedTipPrice: number,
  oneTimeAddon: number,
  recurringAddOn: number
): PriceBase => {
  const newServicePrice = calculatePriceStripe(selectedData);
  const newServicePriceAfterFirstClean = selectedData.cleaningType.isRecurring
    ? calculatePriceAfterFirstStripe(selectedData) || newServicePrice
    : newServicePrice;
  const data: PriceBase = {
    oneTime: { servicePrice: newServicePrice, addons: oneTimeAddon },
    recurring: {
      servicePrice: newServicePriceAfterFirstClean,
      addons: recurringAddOn,
    },
    tips: selectedTipPrice,
  };

  return data;
};

export const calculatePriceAfterFirstStripe = (selectedItems: SelectedBookingInfo): number => {
  const apt = selectedItems.apartmentType.stripeInfo.recurring;
  const bath = selectedItems.bathrooms.stripeInfo.recurring;
  const halfBath = selectedItems.halfBathrooms.stripeInfo.recurring;
  const threeForthBath = selectedItems.threeForthBathrooms.stripeInfo.recurring;
  const commonAreas = selectedItems.commonAreas.stripeInfo.recurring;
  const staircase = selectedItems.staircase.stripeInfo.recurring;
  const extraKitchen = selectedItems.extraKitchen.stripeInfo.recurring;

  const aptPrice = apt.price * (apt.value === undefined ? 0 : apt.value);
  const bathPrice = bath.price * (bath.value === undefined ? 0 : bath.value);
  const halfBathPrice = halfBath.price * (halfBath.value === undefined ? 0 : halfBath.value);
  const threeForthBathPrice = threeForthBath.price * (threeForthBath.value === undefined ? 0 : threeForthBath.value);
  const commonAreasPrice = commonAreas.price * (commonAreas.value === undefined ? 0 : commonAreas.value);
  const staircasePrice = staircase.price * (staircase.value === undefined ? 0 : staircase.value);
  const extraKitchenPrice = extraKitchen.price * (extraKitchen.value === undefined ? 0 : extraKitchen.value);

  const sqfProductInfo = selectedItems.kitchenLivingRoomDining.stripeInfo.recurring;
  const sqfProductPrice = sqfProductInfo.price * (sqfProductInfo.value === undefined ? 0 : sqfProductInfo.value);

  if (aptPrice > 0 && bathPrice > 0) {
    const price =
      aptPrice +
      bathPrice +
      halfBathPrice +
      threeForthBathPrice +
      sqfProductPrice +
      extraKitchenPrice +
      commonAreasPrice +
      staircasePrice;
    return price;
  }

  return 0;
};

export const calculatePriceStripe = (selectedItems: SelectedBookingInfo): number => {
  const apt: StripeInfoForClean = selectedItems.apartmentType.stripeInfo.oneTime;
  const bath: StripeInfoForClean = selectedItems.bathrooms.stripeInfo.oneTime;
  const halfBath: StripeInfoForClean = selectedItems.halfBathrooms.stripeInfo.oneTime;
  const threeForthBath: StripeInfoForClean = selectedItems.threeForthBathrooms.stripeInfo.oneTime;
  const commonAreas: StripeInfoForClean = selectedItems.commonAreas.stripeInfo.oneTime;
  const staircase: StripeInfoForClean = selectedItems.staircase.stripeInfo.oneTime;
  const extraKitchen: StripeInfoForClean = selectedItems.extraKitchen.stripeInfo.oneTime;

  const aptPrice = apt.price * (apt.value === undefined ? 0 : apt.value);
  const bathPrice = bath.price * (bath.value === undefined ? 0 : bath.value);
  const halfBathPrice = halfBath.price * (halfBath.value === undefined ? 0 : halfBath.value);
  const threeForthBathPrice = threeForthBath.price * (threeForthBath.value === undefined ? 0 : threeForthBath.value);
  const commonAreasPrice = commonAreas.price * (commonAreas.value === undefined ? 0 : commonAreas.value);
  const staircasePrice = staircase.price * (staircase.value === undefined ? 0 : staircase.value);
  const extraKitchenPrice = extraKitchen.price * (extraKitchen.value === undefined ? 0 : extraKitchen.value);

  const sqfProductInfo = selectedItems.kitchenLivingRoomDining.stripeInfo.oneTime;
  const sqfProductPrice = sqfProductInfo.price * (sqfProductInfo.value === undefined ? 0 : sqfProductInfo.value);

  if (aptPrice > 0 && bathPrice > 0) {
    const price =
      aptPrice +
      bathPrice +
      halfBathPrice +
      threeForthBathPrice +
      commonAreasPrice +
      extraKitchenPrice +
      sqfProductPrice +
      staircasePrice;
    return price;
  }

  return 0;
};

export const sumAddons = (addons: AddonDataType[]): { oneTime: number; recurring: number } => {
  let firstSum = 0;
  let recurringSum = 0;
  addons.forEach((addon) => {
    if (addon.isFirstCleanOnly) {
      firstSum += addon.price * addon.quantity;
    } else {
      recurringSum += addon.price * addon.quantity;
    }
  });

  return { oneTime: firstSum + recurringSum, recurring: recurringSum };
};

export const roundPrice = (price: number): number => {
  return Math.round((price + Number.EPSILON) * 100) / 100;
};

export const getReceiptPrices = (
  servicePrice: number,
  addons: number,
  tipValue: number,
  discounts: number,
  taxRate: number
): PriceValues => {
  const servicePriceValue = roundPrice(servicePrice);
  const discountsValue = roundPrice(discounts);
  const serviceAndAddons = roundPrice(servicePriceValue + addons);
  const subtotal = roundPrice(serviceAndAddons - discountsValue);
  const taxAmount = roundPrice((subtotal * taxRate) / 100);
  const total = roundPrice(subtotal + tipValue + taxAmount);

  return {
    servicePrice: servicePriceValue,
    addons: addons,
    serviceAndAddons: serviceAndAddons,
    discounts: discountsValue,
    taxAmount: taxAmount,
    subtotal: subtotal,
    total: total,
  };
};
