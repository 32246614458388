import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { Field, Label, Select } from '../../Components';
import styles from './AddressInfoForm.module.scss';
import { addressFormData } from '../../../Assets/Data/AddressForm';
import { AddressFormRegisteredFields } from '../../../types/AddressFormTypes';
import { zipsWeDontServe } from '../../../Assets/Data/ServingAreas';
import { AlertContext } from '../../Contexts/Alert/AlertContext';
import { selectValidCityOptions } from '../../../Assets/Data/BookingInformation';

const AddressRow: FunctionComponent = () => {
  const {
    formState: { errors },
    setValue,
    control,
    register,
    getValues,
  } = useFormContext<AddressFormRegisteredFields>();
  const alertContext = useContext(AlertContext);

  const constData = addressFormData.constantValues;
  const zipCodesWeDontServe = new Set(zipsWeDontServe);
  const initialZipValue = getValues().zip ?? '';
  const [zipCode, setZipCode] = useState(initialZipValue);
  const regZip = RegExp(/^[0-9]{1,5}$/);
  const cityOptions = selectValidCityOptions.map((op) => op.key);

  useEffect(() => {
    register('zip', { ...constData.zip.rules });
  }, [register]);

  return (
    <div className={styles.formRow}>
      <Grid container className={styles.addressFieldsContainer}>
        <Grid item xs={12} sm={6} className={styles.activeTextFields}>
          <Controller
            control={control}
            name="address"
            rules={constData.address.rules}
            render={({ field: { onChange, onBlur, ref, value } }) => (
              <Field
                label={constData.address.label}
                name={constData.address.name}
                variant="outlined"
                autoComplete="street-address"
                error={errors.address !== undefined}
                helperText={errors.address?.message || ''}
                inputRef={ref}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={2} className={styles.activeTextFields}>
          <Controller
            control={control}
            name="apartment"
            rules={constData.apartment.rules}
            render={({ field: { onChange, onBlur, ref, value } }) => (
              <Field
                label={constData.apartment.label}
                placeholder={constData.apartment.placeholder}
                variant="outlined"
                name={constData.apartment.name}
                error={false}
                helperText={''}
                inputRef={ref}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={2} className={`${styles.activeTextFields} ${styles.cityOptions}`}>
          <Label htmlFor="city" text={constData.city.label} />
          <Controller
            control={control}
            name="city"
            rules={constData.city.rules}
            render={({ field: { onChange, onBlur, ref, value } }) => (
              <Select
                autoComplete="address-level2"
                inputRef={ref}
                options={cityOptions}
                value={value ?? ''}
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                className={styles.cityOptionSelect}
                error={errors.city !== undefined}
                MenuProps={{
                  PaperProps: { sx: { maxHeight: 200 } },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={2} className={styles.activeTextFields}>
          <Field
            label={constData.zip.label}
            placeholder={constData.zip.placeholder}
            variant="outlined"
            autoComplete="postal-code"
            name={constData.zip.name}
            error={errors.zip !== undefined}
            helperText={errors.zip?.message || ''}
            inputProps={{ maxLength: 5, minLength: 5, inputMode: 'numeric', pattern: '[0-9]*' }}
            value={zipCode}
            onChange={(e) => {
              const value = e.target.value;
              const isValid = regZip.test(value);
              if (isValid) {
                setZipCode(value);
                setValue('zip', value as never, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }
            }}
            onBlur={(e) => {
              const value = e.target.value;
              const numZip = Number(value);
              let valueToSet = '';
              if (value === '' || value.length !== 5 || isNaN(numZip)) {
                alertContext.openAlert(constData.zip.errorMessagesForAlert?.default || '', false);
              } else if (zipCodesWeDontServe.has(numZip)) {
                alertContext.openAlert("We don't serve area with this zip code", false);
              } else {
                valueToSet = value;
              }

              setZipCode(valueToSet);
              setValue('zip', valueToSet as never, {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default AddressRow;
