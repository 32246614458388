import { FunctionComponent } from 'react';
import { CircularProgress, Modal, Typography } from '@mui/material';
import styles from './InfoModal.module.scss';

interface InfoModalProps {
  title: string;
  bodyText: string;
  isCircular?: boolean;
  children?: React.ReactNode;
}

const InfoModal: FunctionComponent<InfoModalProps> = ({
  title,
  bodyText,
  children,
  isCircular = true,
}: InfoModalProps) => {
  return (
    <Modal className={styles.modalContainer} open={true} onClose={undefined}>
      <div className={styles.modalBody}>
        <Typography variant="h3" className={styles.textItem}>
          {title}
        </Typography>
        <Typography variant="h5" className={styles.textItem}>
          {bodyText}
        </Typography>
        {isCircular && <CircularProgress className={styles.icon} />}
        {children}
      </div>
    </Modal>
  );
};

export default InfoModal;
