import * as amplitude from '@amplitude/analytics-browser';
import { CleaningDataPayload, CleaningDataPayloadRecurring } from '../types/CleaningDataPayload';
import { Price } from '../types/Price';
import AllManagers from './AllManagers';
import { DebugLog } from './ErrorLog';

export enum AmplitudeEventNames {
  ButtonClicked = 'Button clicked',
  GroupButtonClick = 'Group button clicked',
  DropdownItemClicked = 'Dropdown item clicked',
  IconButtonClicked = 'Icon button clicked',
  LinkClicked = 'Link clicked',

  NewLeadFromBookNow = 'NewLeadFromBookNow',

  PageVisited = 'Page visited',

  PromocodeValidationFail = 'Promo code validation failed',
  PromoCodeAdded = 'Promo code added',
  PromoCodeRemoved = 'Promo code removed',

  KeyApplied = 'Key applied',
  LeadSource = 'leadSource',
  LeadSourceEntered = 'leadSourceEntered',
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName',
  phone = 'phone',

  Error = 'error', // Not user active event
  submitDataForBooking_error = 'submitDataForBooking_error', // Not user active event

  SessionEnd = 'Session End', // Not user active event
  SessionStart = 'Session Start', // Not user active event
  submitDataForBooking_start = 'submitDataForBooking_start', // Not user active event
  submitDataForBooking_success = 'submitDataForBooking_success', // Not user active event

  submitDataForBookingWithKey_error = 'submitDataForBookingWithKey_error', // Not user active event
  submitDataForBookingWithKey_start = 'submitDataForBookingWithKey_start', // Not user active event
  submitDataForBookingWithKey_success = 'submitDataForBookingWithKey_success', // Not user active event
}

const verbose = false;

class AnalyticsManager {
  private amplitudeKey =
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_AMPLITUDE || process.env.REACT_APP_AMPLITUDE_PROD || ''
      : process.env.REACT_APP_AMPLITUDE || process.env.REACT_APP_AMPLITUDE_DEV || '';

  constructor() {
    this.initAmplitude();
  }

  public initAmplitude = (): void => {
    amplitude.init(this.amplitudeKey || '', undefined, {
      defaultTracking: true,
    });
  };

  public logSessionStart = () => {
    verbose && DebugLog(AmplitudeEventNames.SessionStart);
    amplitude.track(AmplitudeEventNames.SessionStart);
    AllManagers.GetGaManager().logEvent('Session Start');
  };

  public logSessionEnd = () => {
    amplitude.track(AmplitudeEventNames.SessionEnd);
    AllManagers.GetGaManager().logEvent(AmplitudeEventNames.SessionEnd);
  };

  public logEvent = (eventType: AmplitudeEventNames): void => {
    verbose && DebugLog(eventType);
    amplitude.track(eventType);
    AllManagers.GetGaManager().logEvent(eventType);
  };

  public logButtonClick = (
    buttonName: string,
    props: { promoCode?: string; key?: string; name?: string; clickedToSelect?: boolean } = {}
  ): void => {
    verbose && DebugLog(AmplitudeEventNames.ButtonClicked);
    amplitude.track(AmplitudeEventNames.ButtonClicked, { ...props, buttonName });
    AllManagers.GetGaManager().logEventWithProps(`${buttonName} button clicked`, props);
  };

  public logGroupButtonClick = (
    buttonName: string,
    props: { optionSelected?: string | number; type?: string } = {}
  ): void => {
    verbose && DebugLog(AmplitudeEventNames.GroupButtonClick);
    amplitude.track(AmplitudeEventNames.GroupButtonClick, { ...props, buttonName });
    AllManagers.GetGaManager().logEventWithProps(`${buttonName} group button clicked`, props);
  };

  public logDropdownItemClick = (
    buttonName: string,
    props: { optionSelected?: string | number; type?: string } = {}
  ): void => {
    verbose && DebugLog(AmplitudeEventNames.DropdownItemClicked);
    amplitude.track(AmplitudeEventNames.DropdownItemClicked, { ...props, buttonName });
    AllManagers.GetGaManager().logEventWithProps(`${buttonName} dropdown item clicked`, props);
  };

  public logIconButtonClick = (buttonName: string): void => {
    verbose && DebugLog(AmplitudeEventNames.IconButtonClicked);
    amplitude.track(AmplitudeEventNames.IconButtonClicked, { buttonName });
    AllManagers.GetGaManager().logEvent(`${buttonName} icon button clicked`);
  };

  public logLinkClick = (linkName: string): void => {
    verbose && DebugLog(AmplitudeEventNames.LinkClicked);
    amplitude.track(AmplitudeEventNames.LinkClicked, { linkName });
    AllManagers.GetGaManager().logEvent(`${linkName} link clicked`);
  };

  public logLead = (eventType: AmplitudeEventNames, value: string): void => {
    amplitude.track(eventType, { value });
    AllManagers.GetGaManager().logEventWithProps(eventType, { value });
  };

  public logPageChange = (pageName: string) => {
    verbose && DebugLog(AmplitudeEventNames.PageVisited + ' ' + pageName);
    amplitude.track(AmplitudeEventNames.PageVisited, { pageName: location.pathname, pageNameParam: pageName });
    AllManagers.GetGaManager().logEventWithProps('Page visited', {
      pageName: location.pathname,
      pageNameParam: pageName,
    });
  };

  public logEventWithProps = (eventType: AmplitudeEventNames, eventProperties: Record<string, unknown>): void => {
    verbose && DebugLog(eventType);
    amplitude.track(eventType, eventProperties);
    AllManagers.GetGaManager().logEventWithProps(eventType, eventProperties);
  };

  public logLeadSource = (source: string, isSubmitted: boolean) => {
    const type = isSubmitted ? AmplitudeEventNames.LeadSource : AmplitudeEventNames.LeadSourceEntered;
    amplitude.track(type, { source });
    AllManagers.GetGaManager().logEventWithProps(type, { value: source });
  };

  public logError = (name: string, isFatal: boolean, errorInfo: unknown) => {
    amplitude.track(AmplitudeEventNames.Error, { name, isFatal, errorInfo });
    AllManagers.GetGaManager().logError(name, isFatal, errorInfo);
  };

  public logRevenue = (data: CleaningDataPayload, isRecurring: boolean) => {
    this.logCleaningService(data.selectedCleaningInfo.cleaningFrequency, data.price, '', data.address, isRecurring);
    data.price.tips > 0 && this.logTips(data.selectedCleaningInfo.cleaningFrequency, data.price, '', data.address);
    AllManagers.GetGaManager().logRevenue(data, isRecurring);
    AllManagers.GetGaManager().logRegistrationComplete();
    this.logLeadSource(data.leadSource, true);
  };

  public logRevenueWithKey = (data: CleaningDataPayloadRecurring) => {
    this.logCleaningService(data.cleaningFrequency, data.price, data.key, '', true);
    data.price.tips > 0 && this.logTips(data.cleaningFrequency, data.price, data.key, '');
    AllManagers.GetGaManager().logRevenueWithKey(data);
    AllManagers.GetGaManager().logRegistrationComplete();
  };

  private logCleaningService = (
    cleaningType: string,
    price: Price,
    key: string,
    address: string,
    isRecurring: boolean
  ) => {
    const revenue = new amplitude.Revenue()
      .setProductId(`${cleaningType} Cleaning Service`)
      .setPrice(isRecurring ? price.recurring.total : price.oneTime.total)
      .setQuantity(1)
      .setEventProperties({ isRecurring, key, address, isTip: false })
      .setRevenueType('total with tax and tip');
    amplitude.revenue(revenue);
  };

  private logTips = (cleaningType: string, price: Price, key: string, address: string) => {
    const revenueTip = new amplitude.Revenue()
      .setProductId(`${cleaningType} Cleaning Service`)
      .setPrice(price.tips)
      .setQuantity(1)
      .setEventProperties({ isRecurring: true, key, address, isTip: true })
      .setRevenueType('tip');
    amplitude.revenue(revenueTip);
  };
}

export default new AnalyticsManager();
