import { FunctionComponent, ReactNode } from 'react';
import { Modal as MuiModal } from '@mui/material';
import styles from './Modal.module.scss';

interface ModalProps {
  children: ReactNode;
  onClose: () => void;
  open: boolean;
  className?: string;
}

const Modal: FunctionComponent<ModalProps> = ({ children, onClose, open, className }: ModalProps) => {
  return (
    <MuiModal
      className={styles.modalContainer}
      open={open}
      onClose={() => {
        onClose();
      }}>
      <div className={`${styles.modalBody} ${className ? className : ''}`}>{children}</div>
    </MuiModal>
  );
};

export default Modal;
