import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Typography, Grid } from '@mui/material';
import { contactUsData } from '../../../Assets/Data/ContactUsData';
import { emailPattern, phonePattern } from '../../../Assets/Data/RegexStrings';
import { Field, InfoModal } from '../../Components';
import { ContactUsDataFields } from '../../../types/ContactUsData';
import styles from './Contact.module.scss';
import { LogError } from '../../../Utils/ErrorLog';
import PrimaryButton from '../../Components/PrimaryButton/PrimaryButton';
import { sendEmail } from '../../../Utils/ApiCalls/ApiCalls';
import { BannerAlertContext } from '../../Contexts/BannerAlert/BannerAlertContext';
import { isAcceptingNewClients, notAcceptingNewClientText } from '../../../Config';

const Contact: FunctionComponent = () => {
  const bannerAlertContext = useContext(BannerAlertContext);

  const modalTitle = 'Sending your question';
  const modalBodyText = "Please don't refresh the page";
  const errorOnSend = 'Unable to submit the form. Please try again.';
  const successOnSend = 'Your question has been sent! We will reply to you as soon as possible';

  const [processing, setProcessing] = useState(false);
  const methods = useForm<ContactUsDataFields>({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: false,
    shouldUnregister: true,
  });

  const formData = contactUsData.formData;

  const onSubmit = async (data: ContactUsDataFields) => {
    try {
      setProcessing(true);
      const result = await sendEmail(data);
      if (!result) {
        bannerAlertContext.openAlert(errorOnSend, false);
      } else {
        bannerAlertContext.openAlert(successOnSend, true);
      }
    } catch (e) {
      LogError('onSubmit', { message: JSON.stringify(e) }, true);
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    return () => {
      bannerAlertContext.closeAlert();
    };
  }, []);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid container justifyContent="center" direction="column" item xs={12} sm={9} md={7} lg={6} xl={4}>
        {processing ? <InfoModal title={modalTitle} bodyText={modalBodyText} /> : null}
        <div className={styles.contactFormContainer}>
          <Typography variant="h4" className={styles.contactUsTitle}>
            {contactUsData.mainTitle}
          </Typography>
          {!isAcceptingNewClients && (
            <Typography variant="h4" className={styles.notAcceptingClients}>
              {notAcceptingNewClientText}
            </Typography>
          )}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              void methods.handleSubmit(onSubmit)();
            }}
            className={styles.contactForm}>
            <Controller
              control={methods.control}
              name="name"
              rules={formData.name.rules}
              render={({ field: { onChange, onBlur, ref } }) => (
                <Field
                  isRoundCorners={true}
                  label={formData.name.label}
                  type="text"
                  autoComplete="name"
                  name={formData.name.name}
                  placeholder={formData.name.placeholder}
                  error={methods.formState.errors.name ? true : false}
                  helperText={methods.formState.errors.name ? formData.name.errorMessage : ''}
                  inputRef={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />

            <Controller
              control={methods.control}
              name="email"
              rules={{
                ...formData.email.rules,
                pattern: emailPattern,
              }}
              render={({ field: { onChange, onBlur, ref } }) => (
                <Field
                  isRoundCorners={true}
                  label={formData.email.label}
                  type="email"
                  autoComplete="email"
                  name={formData.email.name}
                  placeholder={formData.email.placeholder}
                  error={methods.formState.errors.email ? true : false}
                  helperText={methods.formState.errors.email ? formData.email.errorMessage : ''}
                  inputRef={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />

            <Controller
              control={methods.control}
              name="phone"
              rules={{
                ...formData.phone.rules,
                pattern: phonePattern,
              }}
              render={({ field: { onChange, onBlur, ref } }) => (
                <Field
                  isRoundCorners={true}
                  label={formData.phone.label}
                  type="text"
                  autoComplete="phone"
                  name={formData.phone.name}
                  placeholder={formData.phone.placeholder}
                  error={methods.formState.errors.phone ? true : false}
                  helperText={methods.formState.errors.phone ? formData.phone.errorMessage : ''}
                  inputRef={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />

            <Controller
              control={methods.control}
              name="subject"
              rules={formData.subject.rules}
              render={({ field: { onChange, onBlur, ref } }) => (
                <Field
                  isRoundCorners={true}
                  label={formData.subject.label}
                  type="text"
                  name={formData.subject.name}
                  placeholder={formData.subject.placeholder}
                  error={methods.formState.errors.subject ? true : false}
                  helperText={methods.formState.errors.subject ? formData.subject.errorMessage : ''}
                  inputRef={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />

            <Controller
              control={methods.control}
              name="question"
              rules={formData.question.rules}
              render={({ field: { onChange, onBlur, ref } }) => (
                <Field
                  isRoundCorners={true}
                  label={formData.question.label}
                  type="text"
                  name={formData.question.name}
                  placeholder={formData.question.placeholder}
                  error={methods.formState.errors.question ? true : false}
                  multiline
                  rows={4}
                  helperText={methods.formState.errors.question ? formData.question.errorMessage : ''}
                  inputRef={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />

            <div className={styles.submitContactUsButton}>
              <PrimaryButton type="submit">Submit</PrimaryButton>
            </div>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default Contact;
