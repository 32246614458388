import { FunctionComponent, useMemo, useState } from 'react';
import { ReactFCChildren } from '../../../types/ReactFCChildren';
import { BannerAlertContext, IBannerAlertDataContext, IBannerAlertInfo } from './BannerAlertContext';

const BannerAlertContextImpl: FunctionComponent<ReactFCChildren> = ({ children }: ReactFCChildren) => {
  const [alertInfo, setAlertInfo] = useState<IBannerAlertInfo>({ isOpen: false });

  const openAlert = (message: string, isSuccess: boolean) => {
    if (!message) {
      closeAlert();
    } else {
      setAlertInfo({ isOpen: true, message, isSuccess });
    }
  };

  const closeAlert = () => {
    setAlertInfo({ ...alertInfo, isOpen: false, message: '' });
  };

  const contextValue: IBannerAlertDataContext = useMemo(
    () => ({ openAlert, alertInfo, closeAlert }),
    [alertInfo, openAlert, closeAlert]
  );

  return <BannerAlertContext.Provider value={contextValue}>{children}</BannerAlertContext.Provider>;
};

export default BannerAlertContextImpl;
