import { FunctionComponent } from 'react';
import styles from './WhyChooseUsV2.module.scss';
import Check from '../../../../../Assets/Images/svg/checklist.svg';
import Satisfaction from '../../../../../Assets/Images/svg/satisfactionGuarantee.svg';
import GreenProduct from '../../../../../Assets/Images/svg/greenProducts.svg';
import Dots from '../../../../../Assets/Images/svg/dots.svg';
import Titles from '../../../CleaningPageParts/Titles/Titles';
import { mainColor } from '../../../../../Assets/Styles/colors';

const dataLandingPage = [
  {
    color: mainColor,
    title: '100% Satisfaction Guarantee',
    icon: <Satisfaction className={styles.landingPageIcon} />,
    text: 'We make sure you are happy with the final results and will make it right if you’re not satisfied.',
  },
  {
    color: mainColor,
    title: 'Trained With Checklists',
    icon: <Check className={styles.landingPageIcon} />,
    text: 'Cleaning techs are W2 employees and extensively trained to be effective and timely.',
  },
  {
    color: mainColor,
    title: 'Bring & use green products',
    icon: <GreenProduct className={styles.landingPageIcon} />,
    text: 'All our cleaning products are non-toxic, eco-friendly, and completely safe for your home and health.',
  },
];

interface WhyChooseUsProps {
  title1?: string;
}

const WhyChooseUsV2: FunctionComponent<WhyChooseUsProps> = ({ title1 }: WhyChooseUsProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.whyUs}>
        <Titles title1={title1 || 'ABOUT'} title2="Why choose us?" />

        <div className={styles.steps}>
          {dataLandingPage.map((item) => (
            <div className={styles.step} key={item.title}>
              <div className={styles.stepContainer}>
                <div className={styles.iconContainer}>
                  <div className={styles.icon} style={{ backgroundColor: item.color }}>
                    {item.icon}
                  </div>
                </div>
                <h1 className={styles.title}>{item.title}</h1>
                <div className={styles.divider}></div>
                {item.text && <h2 className={styles.text}>{item.text}</h2>}
              </div>
            </div>
          ))}
          <div className={styles.dots}>
            <Dots />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUsV2;
