import { FunctionComponent } from 'react';
import { Typography } from '@mui/material';
import styles from './BookingProgressMobile.module.scss';
import { Price } from '../../../../types/Price';
import { InfoIconTooltip } from '../../../Components';
import { observer } from 'mobx-react';
import PriceManager from '../../../../Mobx/BookingFlowData/PriceManager';
import SelectedOptionsManager from '../../../../Mobx/BookingFlowData/SelectedOptionsManager';

const MobilePriceBar: FunctionComponent = () => {
  const price: Price = PriceManager.getPrice();
  const isSelectedCleaningRecurring: boolean = SelectedOptionsManager.isSelectedCleanRecurring();
  return (
    <div className={styles.mobilePriceBar}>
      {price.oneTime.servicePrice > 0 ? (
        <>
          <div className={styles.priceAndTime}>
            <Typography variant="h5">Price: ${price.oneTime.serviceAndAddons}</Typography>
          </div>
          {isSelectedCleaningRecurring && (
            <div className={styles.recurringPrice}>
              <Typography className={styles.afterFirstCleanPriceText}>{`After First Clean: `}</Typography>
              <Typography className={styles.afterFirstCleanPrice}>{` $${price.recurring.serviceAndAddons}`}</Typography>
              <InfoIconTooltip text={`This price doesn't include tax.`} />
            </div>
          )}
        </>
      ) : (
        <Typography variant="h5">Contact for pricing</Typography>
      )}
    </div>
  );
};

export default observer(MobilePriceBar);
