import { FunctionComponent } from 'react';
import { Grid, Typography } from '@mui/material';
import styles from './ButtonGroup.module.scss';
import AnalyticsManager from '../../../Utils/AnalyticsManager';

interface ButtonGroupProps {
  error: boolean;
  options: string[];
  onChangeOption: (option: string) => void;
  selectedOption: string[];
  groupName: string;
  id?: string;
}

const ButtonGroup: FunctionComponent<ButtonGroupProps> = ({
  options,
  error,
  groupName,
  onChangeOption,
  selectedOption,
  id,
}: ButtonGroupProps) => {
  return (
    <Grid container className={styles.buttonGroup} id={id}>
      {options.map((option) => (
        <button
          className={`${styles.btn} ${selectedOption?.includes(option) ? styles.selected : ''} ${
            error ? styles.error : ''
          }`}
          key={option}
          onClick={(e) => {
            AnalyticsManager.logGroupButtonClick(groupName, { optionSelected: option, type: 'button group' });
            onChangeOption && onChangeOption(option);
            e.stopPropagation();
            e.preventDefault();
          }}>
          <Typography variant="h6">{option}</Typography>
        </button>
      ))}
    </Grid>
  );
};

export default ButtonGroup;
