import { QuestionAnswer } from '../../types/FaqType';

interface Answer {
  text: string;
  bold?: boolean;
  isLink?: boolean;
}

export const general: QuestionAnswer[] = [
  {
    question: 'How does Cheetah Cleaners work?',
    answer: `Cheetah Cleaners is a residential cleaning service specializing in Move Out, Deep, Recurring, and Rental Turnover Cleans. Recurring Services start with a deep clean to get your home up to our standards, with subsequent cleans focused on maintenance.`,
  },
  {
    question: `What's included in every clean?`,
    answer: `We clean your entire home, including bathrooms, bedrooms, kitchens, dining rooms, laundry, and other living spaces. Check out each cleaning type's page to see a full checklist of what we do and don't clean.  

We offer add-ons such as inside oven, inside fridge, interior windows, and inside cabinet cleaning for additional charges.`,
  },
  {
    question: 'Is Cheetah Cleaners bonded, insured, and licensed?',
    answer: `Cheetah Cleaners is bonded, insured, and licensed.`,
  },
  {
    question: `What if I'm not satisfied with the cleaning?`,
    answer: `If you’re not 100% satisfied with our services, please reach out to (206) 486-0038 or info@cheetahcleaners.com within 48 and we’ll make it right.`,
  },
];

const cleaning: QuestionAnswer[] = [
  {
    question: 'When is my clean?',
    answer: `During booking you’ll choose a morning or afternoon appointment. Morning cleans have an arrival window of 8 - 9 AM, and afternoon cleans have an arrival window of 12 – 2 PM. We can't guarantee exact or consistent arrival times. Your cleaning time slot will show up on your email calendar as the arrival window.

For afternoon cleans only, you’ll receive a notification text message 30 minutes before arriving.`,
  },
  {
    question: 'Does someone need to be present for the cleaning?',
    answer: `Most clients will be present for at least the beginning of the first clean, and because of COVID-19 many people are working from home. If you are going to be home, we require you follow our COVID-19 policy. 

If you aren’t going to be home, please specify when booking or let us know ahead of time.`,
  },
  {
    question: 'What should I do with my pet(s)?',
    answer: `Our cleaners are comfortable with pets, but pets are not always comfortable with us. If you believe your pet shouldn’t be roaming free when we’re cleaning or will be a distraction, please make sure to make arrangements ahead of time.`,
  },
  {
    question: 'How do I prepare for my appointment?',
    answer: `Most clients will spend 5-15 minutes decluttering to make sure the cleaner is maximizing time on cleaning rather than picking stuff up. This can include putting dishes in the dishwasher, putting away clothing, and making sure surfaces are generally cleared of clutter. Your preparation is always appreciated and helps the clean go as smoothly as possible.`,
  },
  {
    question: 'Do you bring all the cleaning supplies and equipment?',
    answer: `We bring everything needed to properly clean your home, with the exception of specialty products like wood cleaner or bleach. We can't use your cleaning products.`,
  },
  {
    question: 'What kinds of cleaning products and equipment do you use?',
    answer: `All of our cleaning products are non-toxic, eco-friendly, and 100% safe for humans and pets. We use Shark vaccuums and an array of tools to clean, from food scrapers to cleaning brushes.`,
  },
];

const billing: QuestionAnswer[] = [
  {
    question: 'How do I pay for my clean?',
    answer: `We use Stripe to process all payments. We accept all major credit and debit card including, but not limited to, Visa, Discover, MasterCard, and American Express. We do not accept cash or check payments. Recurring cleans will be automatically charged the day of service.`,
  },
  {
    question: 'When does my card get charged?',
    answer: `Your card will be automatically charged at 12:05 AM the day of service and you’ll be emailed a receipt. All cleaning types are charged the same.`,
  },
  {
    question: 'What is Cheetah Cleaners’ tipping policy and how does it work?',
    answer: `Tips are always welcome, but never required. Cleaners will never ask for tips. When entering payment information, you’ll have the option to leave a cashless tip (not taxed). 

This tip amount will be applied to all subsequent cleans; to adjust the tip amount please reach us at (206) 486-0038 or email info@cheetahcleaners.com. 

You can also leave a cash or check tip if that is your preference.`,
  },
];

const lastBillingQuestion: { question: string; answer: Answer[] } = {
  question: 'How do I cancel or reschedule my clean?',
  answer: [
    { text: 'To cancel, reschedule, or adjust your appointment please email' },
    { text: ` info@cheetahcleaners.com`, bold: true },
    { text: '. Check out our ' },
    { text: 'Cancellation Policy', isLink: true },
    { text: ' for more details.' },
  ],
};

//'All appointment modifications must be in email. Check out our <b/>Cancellation Policy<b/> for more details.',
export const header = 'Questions? Look here.';
export const subHeader = `Can't find an answer? Contact us by submitting this form`;

export const faq = [
  { sectionName: 'General', faqs: general },
  { sectionName: 'Cleaning', faqs: cleaning },
  { sectionName: 'Billing', faqs: billing, faqs2: [lastBillingQuestion] },
];
