import { Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FunctionComponent } from 'react';
import styles from './InfoIconTooltip.module.scss';
import InfoIcon from '@mui/icons-material/Info';

interface InfoIconProps {
  text: string;
}

const InfoIconTooltip: FunctionComponent<InfoIconProps> = ({ text }: InfoIconProps) => {
  return (
    <Tooltip
      enterTouchDelay={1}
      title={<Typography variant="body1">{text}</Typography>}
      arrow
      className={styles.infoButton}>
      <InfoIcon />
    </Tooltip>
  );
};

export default InfoIconTooltip;
