import { FunctionComponent, useEffect, useState } from 'react';
import AnalyticsManager from '../../../Utils/AnalyticsManager';
import { useLocation } from 'react-router-dom';

const SetAnalytics: FunctionComponent = () => {
  const location = useLocation();
  const [done, setDone] = useState(false);

  useEffect(() => {
    if (!done) {
      AnalyticsManager.logSessionStart();
      setDone(true);
    }
  }, [done]);

  useEffect(() => {
    return () => {
      AnalyticsManager.logSessionEnd();
    };
  }, []);

  useEffect(() => {
    AnalyticsManager.logPageChange(location.pathname);
  }, [location.pathname]);

  return null;
};

export default SetAnalytics;
