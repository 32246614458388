import { FunctionComponent, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { faq } from '../../../Assets/Data/faq';
import styles from './Faq.module.scss';
import { MainLink } from '../../Components';

const MuiAccordionFaq: FunctionComponent = () => {
  const [expanded, setExpanded] = useState('');
  const handleChange = (panel: string) => (event: unknown, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };

  return (
    <>
      {faq.map(({ sectionName, faqs, faqs2 }, index) => (
        <div key={`faqSection-${index}`} className={styles.faqSectionContainer}>
          <Typography variant="h4" className={styles.faqSectionName}>
            {sectionName}
          </Typography>
          {faqs.map(({ question, answer }, index) => (
            <Accordion
              key={`faq-${index}-${sectionName}`}
              expanded={expanded === `faq-${index}-${sectionName}`}
              onChange={handleChange(`faq-${index}-${sectionName}`)}
              className={styles.accordionFaq}
              elevation={0}>
              <AccordionSummary expandIcon={null}>
                <div className={styles.iconContainer}>
                  <AddIcon className={styles.icon} />
                </div>
                <Typography variant="h5" className={styles.faqQuestion}>
                  {question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={styles.faqAnswer}>{answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
          {faqs2 &&
            faqs2.map(({ question, answer }, index) => (
              <Accordion
                key={`faq2-${index}-${sectionName}`}
                expanded={expanded === `faq2-${index}-${sectionName}`}
                onChange={handleChange(`faq2-${index}-${sectionName}`)}
                className={styles.accordionFaq}
                elevation={0}>
                <AccordionSummary expandIcon={null}>
                  <div className={styles.iconContainer}>
                    <AddIcon className={styles.icon} />
                  </div>
                  <Typography variant="h5" className={styles.faqQuestion}>
                    {question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {answer && (
                    <Typography className={styles.faqAnswer}>
                      {answer.map(({ text, bold, isLink }, index) => (
                        <span key={`faq2-answer-${index}-${sectionName}`} className={styles.p}>
                          {bold ? (
                            <b>{text}</b>
                          ) : isLink ? (
                            <MainLink linkText={text} toPath="/cancellation" isInternalLink>
                              {text}
                            </MainLink>
                          ) : (
                            text
                          )}
                        </span>
                      ))}
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
        </div>
      ))}
    </>
  );
};

export default MuiAccordionFaq;
