import { FunctionComponent } from 'react';
import { Collapse, IconButton } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './AlertComponent.module.scss';
import AnalyticsManager from '../../../Utils/AnalyticsManager';

interface AlertComponentProps {
  open: boolean;
  changeOpenState: (val: boolean) => void;
  isError: boolean;
  text: string;
}

const AlertComponent: FunctionComponent<AlertComponentProps> = ({
  open,
  changeOpenState,
  isError,
  text,
}: AlertComponentProps) => {
  return (
    <Collapse in={open} className={styles.alertMessage}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              AnalyticsManager.logIconButtonClick('Close alert');
              changeOpenState(false);
            }}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        severity={isError ? 'error' : 'success'}>
        <AlertTitle>{isError ? 'Error' : 'Success'}</AlertTitle>
        {text}
      </Alert>
    </Collapse>
  );
};

export default AlertComponent;
