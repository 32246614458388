import { FunctionComponent } from 'react';
import { Typography } from '@mui/material';
import styles from './InfoPageStyles.module.scss';
import InfoPageRoot from '../../Components/InfoPageRoot/InfoPageRoot';
import { MainLink } from '../../Components';

const CancellationFees: FunctionComponent = () => {
  const sectionTitleTextVariant = 'h2';
  const titleVariant = 'h3';
  const textVariant = 'body1';

  return (
    <InfoPageRoot mainTitle="Cancellation Policy">
      <Typography className={styles.sectionTitle1} variant={sectionTitleTextVariant}>
        One Time/Deep and Move Out Cleans
      </Typography>
      <div className={styles.reschedule}>
        <MainLink
          linkText="cancel or reschedule form"
          isInternalLink={false}
          target="_blank"
          rel="noreferrer noopener"
          href="https://sprw.io/stt-x9RkDgc6oXgXmLo9vd9Ymd">
          Please fill out this form to cancel or reschedule a one time or move out clean.
        </MainLink>
      </div>

      <Typography className={styles.title} variant={titleVariant}>
        Cancelling
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        For one time and move out cleans, we require at least 1 weeks’ notice (7 days) to fully cancel a clean. If you
        cancel within that 7-day period, you will still be charged the full price of the clean. Your spot is reserved
        ahead of time for, and last-minute cancellations take away from other client bookings.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Rescheduling
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        We know things can suddenly happen and always try to accommodate last minute rescheduling, but it is not always
        possible. If you need to reschedule a clean within 7 days of the appointment but we are not able to move it to
        within 2 business days of the original date, it will be considered a cancellation and you’ll be charged the full
        price of the clean.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Move Out Policy
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        Although we always thoroughly clean, we can’t guarantee 100% of your security deposit back. To fully clean, we
        require your home is empty of furniture, boxes, and any other items. If there is still furniture and items
        around, we may not be able to clean those areas, and you will still be charged full price.
      </Typography>

      <Typography className={styles.sectionTitle} variant={sectionTitleTextVariant}>
        Recurring Cleans
      </Typography>

      <div className={styles.reschedule}>
        <MainLink
          linkText="cancel or reschedule recurring form"
          isInternalLink={false}
          target="_blank"
          rel="noreferrer noopener"
          href="https://sprw.io/stt-ueU3i">
          Please fill out this form to cancel or reschedule a recurring clean.
        </MainLink>
      </div>

      <Typography className={styles.title} variant={titleVariant}>
        Cancelling
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        We require at least 3 business days’ notice (72 business hours) to fully cancel a clean. If you cancel within
        that 3-business day period, you will still be charged the full price of the clean. For example, if you cancel a
        Wednesday clean on Saturday, the count starts from Monday (48 business hours from appointment) and will still be
        deemed a late cancellation. Your spot is reserved ahead of time, and last-minute cancellations take away from
        other client bookings.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Rescheduling
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        We know things can suddenly happen and always try to accommodate last minute rescheduling, but it is not always
        possible. If you need to reschedule a clean within 3 business days of the appointment but we are not able to
        move it to within 2 business days of the original date, it will be considered a cancellation and you’ll be
        charged the full price of the clean.
      </Typography>
    </InfoPageRoot>
  );
};

export default CancellationFees;
