import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import styles from './Article.module.scss';

const Oven: FunctionComponent = () => {
  const paragraph = 'body1';
  const title = 'h3';

  return (
    <div>
      <Typography variant={paragraph} className={styles.paragraph1}>
        Are you looking for an oven cleaning solution but don’t want to use the toxic, harmful products that always seem
        to be the “easiest”? Truth is, while those products may clean an oven better initially, not only does the
        process release harmful chemicals into the air you breathe, it also damages and breaks down the inside of your
        oven over time.
      </Typography>

      <Typography variant={paragraph} className={styles.paragraph1}>
        Without further ado, here’s a green alternative to deep cleaning your oven:
      </Typography>
      <ol>
        <li>
          Gather a small bowl, 1/2 cup of water, 1/2 cup of baking soda, and some dish soap for the cleaning solution,
          plus a sponge.
        </li>
        <li>Mix the water, baking soda, and a few squirts of dish soap in the small bowl.</li>
        <li>
          Take out the oven racks and coat the inside of your oven with the solution (including the inside of the oven
          door!) with the sponge.
        </li>
        <li>Let sit for 12 hours or overnight.</li>
        <li>Wipe everything with some wet rags.</li>
      </ol>

      <Typography variant={paragraph} className={styles.paragraph1}>
        This is a proven way to clean your oven without using toxic chemicals while still getting it to shine like new
        again. While green cleaning is not always the easiest path, it’s always much safer in the long term for your
        home, health, and the environment.
      </Typography>

      <Typography variant={title} className={styles.paragraph1}>
        BONUS!
      </Typography>

      <Typography variant={paragraph} className={styles.paragraph1}>
        What about those oven racks? Is there a way to get those nice and shiny too?? Yes, the ole soak and wait method
        works here:
      </Typography>
      <ol>
        <li>Gather a towel, dish or laundry detergent, a sponge, and some rags for drying.</li>
        <li>Lay a towel on the bottom of your bathtub.</li>
        <li>Place oven racks onto towel and fill tub with hot water so that it covers them.</li>
        <li>Pour a cup of dish or laundry detergent evenly in the water and stir to even out.</li>
        <li>Let soak for 6 – 10 hours or overnight.</li>
        <li>Wipe away all the gunk with a soft sponge, it should come off easily.</li>
        <li>Dry with a microfiber cloth and enjoy your shiny oven racks!</li>
      </ol>

      <Typography variant={paragraph} className={styles.paragraph1}>
        Well, there you have it! With these deep cleaning tips, your oven will not only look great and be sure to
        impress everyone, but you’ll also have the peace of mind knowing there are no residual harmful chemicals left
        over. Check out our other blog posts for more great green cleaning suggestions!
      </Typography>
    </div>
  );
};

export default Oven;
