import { FunctionComponent } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { addressFormData } from '../../../Assets/Data/AddressForm';
import { AddressFormRegisteredFields } from '../../../types/AddressFormTypes';
import { Field } from '../../Components';
import styles from './AddressInfoForm.module.scss';

const OtherInfoRow: FunctionComponent = () => {
  const { control } = useFormContext<AddressFormRegisteredFields>();
  const constData = addressFormData.constantValues;

  return (
    <div className={styles.formRow}>
      <div className={styles.infoFields}>
        <Controller
          control={control}
          name={constData.informationFields?.otherInformation.name}
          rules={{ required: false }}
          render={({ field: { onChange, onBlur, ref } }) => (
            <Field
              label={constData.informationFields?.otherInformation.label}
              multiline
              rows={4}
              placeholder={constData.informationFields?.otherInformation.placeholder}
              variant="outlined"
              name={constData.informationFields?.otherInformation.name}
              error={false}
              helperText={''}
              inputRef={ref}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
      </div>
    </div>
  );
};

export default OtherInfoRow;
