import { FunctionComponent } from 'react';
import { Typography } from '@mui/material';
import { header, subHeader } from '../../../Assets/Data/faq';
import styles from './Faq.module.scss';
import { NavLink as Link } from 'react-router-dom';
import MuiAccordionFaq from './MuiAccordionFaq';
import { PageRoot } from '../../Components';

const Faq: FunctionComponent = () => {
  return (
    <PageRoot>
      <div className={styles.headerContainer}>
        <Typography variant="h3">{header}</Typography>
        <Typography variant="h6">
          {subHeader}{' '}
          <Link to="/contact" className={styles.link}>
            here
          </Link>
          .
        </Typography>
      </div>
      <div className={styles.faqPageSubContainer}>
        <MuiAccordionFaq />
      </div>
    </PageRoot>
  );
};

export default Faq;
