import { FunctionComponent } from 'react';
import { ImageComponent } from '../../../../Components';
import styles from './IconElement.module.scss';

interface IconElementProps {
  imgSource: string;
  imagePath: string;
  altText: string;
  isPerson?: boolean;
}

const IconElement: FunctionComponent<IconElementProps> = ({
  imgSource,
  altText,
  imagePath,
  isPerson = false,
}: IconElementProps) => {
  return (
    <div className={`${styles.iconContainer} ${isPerson ? styles.person : ''}`}>
      <div className={styles.circle}></div>
      <div className={styles.halfCircle}></div>
      <div className={styles.icon}>
        <ImageComponent source={imgSource} path={imagePath} altText={altText} />
      </div>
    </div>
  );
};

export default IconElement;
