import { FunctionComponent, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { QuestionAnswer } from '../../../types/FaqType';
import styles from './MainPageFaq.module.scss';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface MainPageFaqProps {
  data: QuestionAnswer[];
}

const MainPageFaq: FunctionComponent<MainPageFaqProps> = ({ data }: MainPageFaqProps) => {
  const [expanded, setExpanded] = useState<number>(-1);

  const handleChange = (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : -1);
  };

  return (
    <>
      {data.map((item, index) => (
        <Accordion
          className={`${styles.root} ${expanded === index ? styles.rootSelected : ''}`}
          expanded={expanded === index}
          onChange={handleChange(index)}
          key={item.question}>
          <AccordionSummary aria-controls={item.question}>
            <h1 className={`${styles.question} ${expanded === index ? styles.questionSelected : ''}`}>
              {item.question}
            </h1>
          </AccordionSummary>
          <AccordionDetails>
            <h2 className={styles.answer}>{item.answer}</h2>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  () => ({
    'border': '1px solid #E5E5E5',
    'borderRadius': '8px',
    'marginBottom': '10px',
    'marginTop': '10px',
    '&:before': {
      display: 'none',
    },
  })
);

const icon = <ArrowDropDownIcon className={styles.iconStyle} />;

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary expandIcon={icon} {...props} />)(
  ({ theme }) => ({
    'backgroundColor': '#ffffff',
    'borderRadius': '8px',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default MainPageFaq;
