import { FunctionComponent, useState, useEffect } from 'react';
import { addressFormData } from '../../../Assets/Data/AddressForm';
import { Field, Label, ButtonGroup } from '../../Components';
import { useFormContext, Controller } from 'react-hook-form';
import { AddressFormRegisteredFields } from '../../../types/AddressFormTypes';
import styles from './AddressInfoForm.module.scss';
import { observer } from 'mobx-react';
import AddressFormDataManager from '../../../Mobx/BookingFlowData/AddressFormDataManager';

const PetRow: FunctionComponent = () => {
  const addressFormValues: AddressFormRegisteredFields = AddressFormDataManager.getAddressFormValues();
  const {
    register,
    control,
    formState: { errors },
    setValue,
    unregister,
  } = useFormContext<AddressFormRegisteredFields>();
  const [showPetInfo, setShowPetInfo] = useState(false);
  const constData = addressFormData.constantValues;
  const petOptions = constData.petOption.options;

  useEffect(() => {
    register('petOption', { ...constData.petOption.rules });
  }, [register]);

  useEffect(() => {
    const options = addressFormValues.petOption;
    if (options.length !== 0 && options.includes(petOptions[petOptions.length - 1])) {
      setShowPetInfo(true);
    } else {
      setShowPetInfo(false);
      unregister(constData.informationFields.petInformation.name);
    }
  }, [addressFormValues.petOption]);

  const handlePetOptionChange = (newOption: string) => {
    let newPetOptions: string[] = [];
    if (newOption === petOptions[0]) {
      if (addressFormValues.petOption.includes(newOption)) {
        return;
      }
      newPetOptions = [newOption];
    } else {
      const options = addressFormValues.petOption;
      newPetOptions = [...options];
      delete newPetOptions[newPetOptions.indexOf(petOptions[0])];

      if (newPetOptions.includes(newOption)) {
        delete newPetOptions[newPetOptions.indexOf(newOption)];
      } else {
        newPetOptions.push(newOption);
      }

      newPetOptions = newPetOptions.filter(Boolean);
      if (newPetOptions.length === 0) {
        newPetOptions.push(petOptions[0]);
      }
    }

    AddressFormDataManager.updateAddressFormFieldsPartial({ petOption: newPetOptions });
    setValue('petOption', newPetOptions as never, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <div className={styles.infoFields}>
      <Label text={constData.petOption.label} />
      <ButtonGroup
        options={petOptions}
        selectedOption={addressFormValues.petOption}
        onChangeOption={handlePetOptionChange}
        error={errors.petOption !== undefined}
        groupName="pet option"
        id="petOptions"
      />
      {showPetInfo && (
        <Controller
          control={control}
          name={constData.informationFields.petInformation.name}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, ref } }) => (
            <Field
              multiline
              rows={4}
              placeholder={constData.informationFields.petInformation.placeholder}
              variant="outlined"
              name={constData.informationFields.petInformation.name}
              error={errors.petInformation !== undefined}
              helperText={(errors.petInformation && errors.petInformation.message) || ''}
              inputRef={ref}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
      )}
    </div>
  );
};

export default observer(PetRow);
