export interface PaymentFormJson {
  constantValues: PaymentFormData;
}

export enum PaymentFields {
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName',
  phone = 'phone',
  leadSource = 'leadSource',

  cardNumber = 'cardNumber',
  expirationDate = 'expirationDate',
  cvc = 'cvc',
}

export interface PaymentFormData {
  [PaymentFields.email]: PaymentFormStringObject;
  [PaymentFields.firstName]: PaymentFormStringObject;
  [PaymentFields.lastName]: PaymentFormStringObject;
  [PaymentFields.phone]: PaymentFormStringObject;
  [PaymentFields.leadSource]: PaymentFormStringObject;
  [PaymentFields.cardNumber]: PaymentFormStringObject;
  [PaymentFields.expirationDate]: PaymentFormStringObject;
  [PaymentFields.cvc]: PaymentFormStringObject;
}

export interface PaymentFormStringObject {
  name: PaymentFields;
  defaultValue: string;
  label: string;
  placeholder: string;
  helperText: string;
  options?: string[];
}
