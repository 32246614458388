import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { MainLink } from '../../../Components';
import styles from './Article.module.scss';

const Fridge: FunctionComponent = () => {
  const paragraph = 'body1';

  return (
    <div>
      <Typography className={styles.paragraph1} variant={paragraph}>
        Is there a funky smell coming from your fridge? Or maybe that spilled sauce stain has really gotten to you? We
        have a step-by-step method to remove that overwhelming feeling of cleaning and get the inside of your fridge
        back in shape!
      </Typography>

      <Typography className={styles.paragraph1} variant={paragraph}>
        To prepare, we suggest making plenty of counter space, clearing your sink for washing shelves and drawers, and
        making a cleaning solution with water and a few drops of dish soap. When you’ve done all that, just follow these
        steps:
      </Typography>
      <ol>
        <li>Go through all the food in your fridge and throw out any expired or suspicious looking food.</li>
        <li>
          Place everything in your fridge on the counters. If you don’t have a lot of counter space or place to put
          food, we suggest doing one part of the fridge at a time, starting from the top and working your way down,
          placing items back in the fridge after you clean each part.
        </li>
        <li>
          Take out the drawers and racks and clean them in the sink using a sponge and warm water. Do NOT run warm water
          over cold glass as it could crack. Dry with microfiber cloths.
        </li>
        <li>
          Wipe down the inside of the fridge using the dish soap and water solution with a sponge, drying with
          microfiber towels. For especially tough stains, pour some baking soda on the spot and scrub with the sponge or
          toothbrush.
        </li>
        <li>After cleaning all the racks, shelves, and drawers and letting them dry, place them back in the fridge.</li>
        <li>
          Place all the food and condiments back in the fridge, wiping any sticky residue. Pro tip: When placing items
          back on the fridge shelves, have the name and label face forward for easier access!
        </li>
      </ol>
      <Typography className={styles.paragraph1} variant={paragraph}>
        Any cleaning task can seem overwhelming, but when you have an organized plan and break down the process, it’s
        much less stressful. So put on some music, get that cleaning apron on, and scrub away!
      </Typography>
      <Typography className={styles.paragraph1} variant={paragraph}>
        Want to tackle your oven next? Check out our article on{' '}
        <MainLink
          linkText="how to clean your oven without the harmful chemicals."
          toPath="/blog/oven-deep-clean"
          isInternalLink>
          how to clean your oven without the harmful chemicals.
        </MainLink>
      </Typography>
    </div>
  );
};

export default Fridge;
