import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { LogError } from './ErrorLog';

const client: AxiosInstance = axios.create();

const HttpClientExternal = async function <T>(options: AxiosRequestConfig): Promise<T> {
  const onSuccess = function (response: AxiosResponse<T>) {
    return response.data;
  };

  const onError = function (error: AxiosError) {
    LogError('http error', { message: error?.message, code: error?.code });
    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default HttpClientExternal;
