import { FunctionComponent } from 'react';
import { ImageComponent } from '../../../../Components';
import Titles from '../../Titles/Titles';
import Review from '../Review/Review';
import ReviewWrappedV2 from '../ReviewWrapped2/ReviewWrapped';
import styles from './Reviews.module.scss';

const data = [
  {
    stars: 5,
    text: 'From the initial interaction on the website scheduling platform, to the cleaning service, I am very happy with Cheetah Cleaners. Cheetah Cleaners proved I can not only trust the work, but also trust them in my home. I will definitely use the service again and will recommend.',
    name: 'C.A.',
  },
  {
    stars: 5,
    text: 'A newer cleaning service. Took a chance, and was not disappointed! On-par if not better than my experiences with other services. Very thorough, and I found they cleaned spaces that can be easily overlooked. Courteous and on time as well.',
    name: 'D.B.',
  },
  {
    stars: 5,
    text: 'Professional service that’s easy to book. We hired Cheetah for a one time deep clean but will be using them regularly now. Would highly recommend!',
    name: 'J.L.',
  },
  {
    stars: 5,
    text: 'Great job. Great service. Used them for a move out for an apartment that was really grimy. They did a spectacular job. All the details. Windows washed. Window tracks cleaned. You book easily online It was just a great streamlined service all the way around. Would highly recommend',
    name: 'S.R.',
  },
  {
    stars: 5,
    text: 'On time and very thorough. Everything was perfect when I got home. I decided it was worth scheduling more regular cleans.',
    name: 'S.K.',
  },
  {
    stars: 5,
    text: 'Cheetah is professional and thorough. Our house looked great after they completed a one time deep clean! Highly recommend.',
    name: 'K.G.',
  },
];

const Reviews: FunctionComponent = () => {
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.titles}>
          <Titles title1="TESTIMONIALS" title2="What Our Customers Say" />
        </div>
        <div className={styles.content}>
          <ReviewWrappedV2>
            {data.map((item) => (
              <div key={item.name} className={styles.review}>
                <Review stars={item.stars} text={item.text} name={item.name} />
              </div>
            ))}
          </ReviewWrappedV2>
        </div>
      </div>
      <div className={styles.background}>
        <ImageComponent source="" path="LandingPage/dottedMap.png" altText="" />
      </div>
    </div>
  );
};

export default Reviews;
