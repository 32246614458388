import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  UserCredential,
  Auth,
  signOut,
  User as FirebaseUser,
  sendPasswordResetEmail,
  sendEmailVerification,
  deleteUser,
  ActionCodeSettings,
  setPersistence,
  browserSessionPersistence,
  // verifyPasswordResetCode checkActionCode confirmPasswordReset
} from 'firebase/auth';
import { Analytics, getAnalytics } from 'firebase/analytics';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { clientBaseUrl, enableLogin, isProduction } from '../Config';
import { DebugLog } from './ErrorLog';

export type User = FirebaseUser;

class AuthManager {
  private auth: Auth | undefined;
  private useAnalytics = false;
  private analytics: Analytics | undefined;

  private firebaseConfigStaging = {
    apiKey: 'AIzaSyAO_nUn48X0rqBBcLpGCrmpqvA0ZTIdqjQ',
    authDomain: 'cheetah-api.firebaseapp.com',
    projectId: 'cheetah-api',
    storageBucket: 'cheetah-api.appspot.com',
    messagingSenderId: '719267703661',
    appId: '1:719267703661:web:3609b6248d1d26896e0864',
    measurementId: 'G-6QXK5N2BN4',
  };
  private firebaseConfigProd = {
    apiKey: 'AIzaSyCI6t6Y6BJZVlTPx5Q-1sIPWyz6dVxGmwM',
    authDomain: 'cheetah-cleaners-api.firebaseapp.com',
    projectId: 'cheetah-cleaners-api',
    storageBucket: 'cheetah-cleaners-api.appspot.com',
    messagingSenderId: '192567568848',
    appId: '1:192567568848:web:ceeda6ae15bab9d04bb1d5',
    measurementId: 'G-5DK0CF9S8R',
  };

  public signInWithPersistence = async (email: string, password: string) => {
    if (this.auth) {
      await setPersistence(this.auth, browserSessionPersistence);
      return signInWithEmailAndPassword(this.auth, email, password);
    } else {
      DebugLog('auth is null');
    }
  };

  public resetPassword = async (email: string) => {
    if (this.auth) {
      const actionCodeSettings: ActionCodeSettings = {
        handleCodeInApp: true,
        url: `${clientBaseUrl}/login?`,
      };
      await sendPasswordResetEmail(this.auth, email, actionCodeSettings);
    } else {
      throw new Error('Auth is empty');
    }
  };

  public initApp = (onSingIn: (userData: User | null) => void, onSignOut: () => void) => {
    if (enableLogin) {
      DebugLog('in initApp');
      const app = initializeApp(isProduction ? this.firebaseConfigProd : this.firebaseConfigStaging);
      this.setAnalytics(app);
      this.auth = getAuth();
      onAuthStateChanged(this.auth, (user: User | null) => {
        this.onAuthStateChange(user, onSingIn, onSignOut);
      });
      DebugLog('done initApp');
    }
  };

  public deleteUser = async () => {
    if (this.auth?.currentUser) {
      await deleteUser(this.auth.currentUser);
    }
  };

  public signOut = async () => {
    if (!this.auth) {
      throw Error('Auth is undefined');
    }
    await signOut(this.auth);
    return true;
  };

  public signIn = async (email: string, password: string): Promise<UserCredential | undefined> => {
    if (!this.auth) {
      throw Error('Auth is undefined');
    }
    const userCredential = await signInWithEmailAndPassword(this.auth, email, password);
    return userCredential;
  };

  public sendVerifyEmail = async () => {
    if (this.auth?.currentUser) {
      await sendEmailVerification(this.auth.currentUser);
    }
  };

  public getAnalytics = () => {
    return this.analytics;
  };

  private onAuthStateChange = (user: User | null, onSignIn: (user: User | null) => void, onSignOut: () => void) => {
    DebugLog('on auth state change ' + JSON.stringify(user));
    if (user) {
      onSignIn(user);
    } else {
      onSignOut();
    }
  };

  private setAnalytics = (app: FirebaseApp) => {
    const analytics = this.useAnalytics ? getAnalytics(app) : undefined;
    this.analytics = analytics;
  };
}

export default AuthManager;
