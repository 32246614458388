import { withStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import { mainColor } from '../../../Assets/Styles/colors';

const MainCheckbox = withStyles(() => ({
  root: {
    'color': mainColor,
    '&$checked': {
      color: mainColor,
    },
  },
  checked: {},
}))(Checkbox);

export default MainCheckbox;
