import { FunctionComponent, useMemo } from 'react';
import { Grid } from '@mui/material';
import styles from './AddonButtonGroup.module.scss';
import { AddonProductInfo, ProductInfo, ProductPrice } from '../../../types/StripeDataState';
import AddonButton from './AddonButton';
import { AddonDataType } from '../../../types/AddressFormTypes';
import AddonSkeleton from './AddonSkeleton';
import { CleaningFrequency } from '../../../types/BookingFieldsEnum';
import { observer } from 'mobx-react';

interface ButtonGroupProps {
  error: boolean;
  selectedOptions: AddonDataType[];
  optionsWithPics: AddonProductInfo[] | undefined;
  onChangeOptionAddOn: (
    option: ProductInfo,
    price: ProductPrice,
    newRecurringPrice: ProductPrice | undefined,
    isSelectedCleaningRecurring: boolean,
    keepSelected: boolean,
    quantity?: number
  ) => void;
  selectedCleaningFrequency: CleaningFrequency;
  isRecurringCleanSelected: boolean;
  shouldRemoveRecurringSwitch: boolean;
}

const AddonButtonGroup: FunctionComponent<ButtonGroupProps> = ({
  error,
  onChangeOptionAddOn,
  selectedOptions,
  optionsWithPics,
  selectedCleaningFrequency,
  isRecurringCleanSelected,
  shouldRemoveRecurringSwitch,
}: ButtonGroupProps) => {
  const pageContent = useMemo(
    () =>
      optionsWithPics ? (
        <>
          {optionsWithPics.map((item: AddonProductInfo) => {
            const addonData = selectedOptions.find((i) => i.productId === item.id);
            return (
              <AddonButton
                key={item.id}
                error={error}
                onChangeOptionAddOn={onChangeOptionAddOn}
                item={item}
                selectedCleaningFrequency={selectedCleaningFrequency}
                addonData={addonData}
                isRecurringCleanSelected={isRecurringCleanSelected}
                shouldRemoveRecurringSwitch={shouldRemoveRecurringSwitch}
              />
            );
          })}
        </>
      ) : null,
    [selectedCleaningFrequency, selectedOptions, isRecurringCleanSelected]
  );

  return (
    <Grid container className={styles.buttonGroup} spacing={1}>
      {optionsWithPics ? pageContent : <AddonSkeleton />}
    </Grid>
  );
};

export default observer(AddonButtonGroup);
