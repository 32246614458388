import { Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { FunctionComponent } from 'react';
import AddressFormDataManager from '../../../Mobx/BookingFlowData/AddressFormDataManager';
import SelectedOptionsManager from '../../../Mobx/BookingFlowData/SelectedOptionsManager';
import { getAddons, getCustomizeCleanAddons, getOneTimeAddons } from '../../../Mobx/FetchingManagers/StripeDataManager';
import { AddonDataType } from '../../../types/AddressFormTypes';
import { ProductInfo, ProductPrice } from '../../../types/StripeDataState';
import { AddonButtonGroup, Label } from '../../Components';
import styles from './AddressInfoForm.module.scss';
import { CleaningType } from '../../../types/BookingFieldsEnum';

interface AddonsProps {
  useBrightLabel?: boolean;
  removeLabel?: boolean;
  isFirstAddonOnly?: boolean;
  isCustomizeAddonsOnly?: boolean;
  addOnLabel?: string;
  shouldRemoveRecurringSwitch?: boolean;
}

const Addons: FunctionComponent<AddonsProps> = ({
  useBrightLabel,
  removeLabel,
  isFirstAddonOnly,
  isCustomizeAddonsOnly,
  addOnLabel,
  shouldRemoveRecurringSwitch = false,
}: AddonsProps) => {
  const selected = SelectedOptionsManager.getSelected();
  const isMoveOutCleanSelected = selected.cleaningType.key === CleaningType.moveOut;
  const addonData = isFirstAddonOnly
    ? getOneTimeAddons(isMoveOutCleanSelected)
    : isCustomizeAddonsOnly
      ? getCustomizeCleanAddons()
      : getAddons();
  const selectedAddOnValues: AddonDataType[] = AddressFormDataManager.getAddOns();
  const selectedCleaningFrequency = selected.cleaningFrequency.key;
  const isRecurringCleanSelected = selected.cleaningType.isRecurring;

  const handleAddOnsOptionChange = (
    newOption: ProductInfo,
    newPrice: ProductPrice,
    recurringPrice: ProductPrice | undefined,
    selectedValues: AddonDataType[],
    isRecurringOptionSelected: boolean,
    keepSelected: boolean,
    quantity?: number
  ) => {
    const currentOptions = selectedValues
      ? selectedValues.map((x) => {
          return { ...x } as AddonDataType;
        })
      : [];
    const index = currentOptions.findIndex((i) => i.productId === newOption.id);

    if (index > -1) {
      if (keepSelected) {
        currentOptions[index].isFirstCleanOnly = !isRecurringOptionSelected;
        currentOptions[index].quantity = quantity || 1;
      } else {
        currentOptions.splice(index, 1);
      }
    } else {
      const optionToAdd: AddonDataType = {
        productId: newOption.id,
        price: newPrice.price,
        priceId: newPrice.id || '',
        recurringPrice: recurringPrice?.price || 0,
        recurringPriceId: recurringPrice?.id || '',
        name: newOption.name,
        isFirstCleanOnly: !isRecurringOptionSelected,
        quantity: quantity || 1,
      };
      currentOptions.push(optionToAdd);
    }

    const newOptions = currentOptions.filter((o) => o?.price > 0);
    AddressFormDataManager.updateAddons(newOptions);
  };

  const callbackOnOptionChange = (
    newOption: ProductInfo,
    newPrice: ProductPrice,
    newRecurringPrice: ProductPrice | undefined,
    isRecurringOptionSelected: boolean,
    keepSelected: boolean,
    quantity?: number
  ) => {
    handleAddOnsOptionChange(
      newOption,
      newPrice,
      newRecurringPrice,
      selectedAddOnValues,
      isRecurringOptionSelected,
      keepSelected,
      quantity
    );
  };

  return (
    <div className={styles.formRow}>
      <div className={styles.infoFields}>
        {!removeLabel &&
          (useBrightLabel ? (
            <Typography variant="h6" gutterBottom={true}>
              {addOnLabel || 'Addons'}
            </Typography>
          ) : (
            <Label htmlFor="addonButtons" text={addOnLabel || 'Addons'} />
          ))}
        <AddonButtonGroup
          error={false}
          selectedOptions={selectedAddOnValues}
          onChangeOptionAddOn={callbackOnOptionChange}
          optionsWithPics={addonData}
          selectedCleaningFrequency={selectedCleaningFrequency}
          isRecurringCleanSelected={isRecurringCleanSelected}
          shouldRemoveRecurringSwitch={shouldRemoveRecurringSwitch}
        />
      </div>
    </div>
  );
};

export default observer(Addons);
