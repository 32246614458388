import { FunctionComponent, useState, useEffect } from 'react';
import { addressFormData } from '../../../Assets/Data/AddressForm';
import { ButtonGroup, Field, Label } from '../../Components';
import styles from './AddressInfoForm.module.scss';
import { useFormContext, Controller } from 'react-hook-form';
import { AddressFormRegisteredFields, KeyOptions } from '../../../types/AddressFormTypes';
import { observer } from 'mobx-react';
import AddressFormDataManager from '../../../Mobx/BookingFlowData/AddressFormDataManager';

const KeyRow: FunctionComponent = () => {
  const addressFormValues: AddressFormRegisteredFields = AddressFormDataManager.getAddressFormValues();

  const {
    register,
    control,
    formState: { errors },
    setValue,
    unregister,
  } = useFormContext<AddressFormRegisteredFields>();
  const [showKeyInfo, setShowKeyInfo] = useState(false);
  const constData = addressFormData.constantValues;
  const keyOptions = Object.keys(constData.keyOption.options);

  useEffect(() => {
    register('keyOption', { ...constData.keyOption.rules });
  }, [register]);

  useEffect(() => {
    if (
      addressFormValues.keyOption !== KeyOptions.notSet &&
      constData.keyOption.options[addressFormValues.keyOption].requiredClarificationBox
    ) {
      setShowKeyInfo(true);
    } else {
      setShowKeyInfo(false);
      unregister(constData.informationFields.keyInformation.name);
    }
  }, [addressFormValues.keyOption]);

  const handleKeyOptionChange = (newOption: string) => {
    const keyOption = newOption as KeyOptions;
    if (addressFormValues.keyOption === keyOption) {
      return;
    }
    if (Object.values(KeyOptions).indexOf(keyOption) === -1) {
      return;
    }

    AddressFormDataManager.updateAddressFormFieldsPartial({ keyOption: keyOption });
    setValue('keyOption', keyOption as never, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <div className={styles.formRow}>
      <div className={styles.infoFields}>
        <Label text={constData.keyOption.label} />
        <ButtonGroup
          options={keyOptions}
          selectedOption={[addressFormValues.keyOption]}
          onChangeOption={handleKeyOptionChange}
          error={errors.keyOption !== undefined}
          groupName="key option"
        />
        {showKeyInfo && (
          <Controller
            control={control}
            name={constData.informationFields?.keyInformation.name}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, ref } }) => (
              <Field
                multiline
                rows={4}
                placeholder={
                  addressFormValues.keyOption !== KeyOptions.notSet
                    ? constData.keyOption.options[addressFormValues.keyOption].placeholder
                    : ''
                }
                variant="outlined"
                name={constData.informationFields?.keyInformation.name}
                error={Boolean(errors.keyInformation)}
                helperText={(errors.keyInformation && errors.keyInformation.message) || ''}
                inputRef={ref}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export default observer(KeyRow);
