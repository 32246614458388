import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
// import { StrictMode } from 'react';

const rootElement = document.getElementById('root') || document.createElement('div');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <>
    <App />
  </>
);
