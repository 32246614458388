import { shouldSeparateRecurring } from '../../Config';
import { CalendarEvent, CreateEventBody, ListEventsResponse } from '../../types/CalendarEvents';
import HttpClient from '../HttpManager';

export const createCalendarEvent = async (eventBody: CreateEventBody, isRecurring: boolean) => {
  const response = await HttpClient<CalendarEvent>({
    url: `/events/create?isRecurring=${isRecurring && shouldSeparateRecurring}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: eventBody,
  });

  return { eventId: response?.id, error: undefined };
};

export const deleteCalendarEvent = async (eventId: string, isRecurring: boolean) => {
  const response = await HttpClient<CalendarEvent>({
    url: `/events/delete/${eventId}?isRecurring=${isRecurring && shouldSeparateRecurring}`,
    method: 'DELETE',
  });

  return response;
};

export const getAllEvents = async (): Promise<ListEventsResponse | null> => {
  const response = await HttpClient<ListEventsResponse>({
    url: `/events/listEvents?all=${shouldSeparateRecurring}`,
    method: 'GET',
  });

  return response;
};
