import { Divider, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { FunctionComponent, Fragment, useMemo } from 'react';
import AddressFormDataManager from '../../../Mobx/BookingFlowData/AddressFormDataManager';
import PriceManager from '../../../Mobx/BookingFlowData/PriceManager';
import { AddonDataType } from '../../../types/AddressFormTypes';
import { Price } from '../../../types/Price';
import styles from './Receipt.module.scss';

interface OwnProps {
  isRecurringTab: boolean;
  title?: string;
  subTotalTitle?: string;
}

const AddonsReceipt: FunctionComponent<OwnProps> = ({ isRecurringTab, title, subTotalTitle }: OwnProps) => {
  const addons: AddonDataType[] = AddressFormDataManager.getAddOns();
  const price: Price = PriceManager.getPrice();
  const servicePlusAddons = isRecurringTab ? price.recurring.serviceAndAddons : price.oneTime.serviceAndAddons;

  const addonsToDisplay = useMemo(() => {
    if (addons.length > 0) {
      const data = addons.map((addon) => {
        if (addon.price <= 0 || (addon.isFirstCleanOnly && isRecurringTab)) {
          return null;
        }
        return (
          <Fragment key={`${addon.name || ''}-${addon.productId}`}>
            {addon.price > 0 ? (
              <Typography className={styles.prices}>
                <span>{`${addon.name || ''}${addon.quantity > 1 ? ` (${addon.price} * ${addon.quantity})` : ''}`}</span>
                <span>{`$${addon.price * addon.quantity}`}</span>
              </Typography>
            ) : null}
          </Fragment>
        );
      });

      return data.filter((d) => d != null);
    }

    return null;
  }, [addons, addons?.length]);

  return addons && addons.length > 0 ? (
    <>
      <Divider />
      <Typography className={styles.boldText}>{title || 'Add Ons'}</Typography>
      <Divider />
      {addonsToDisplay}
      <Divider />
      <Typography className={styles.prices}>
        <span>{subTotalTitle || 'Service + Add Ons'}</span>
        <span>${servicePlusAddons}</span>
      </Typography>
      <Divider />
    </>
  ) : null;
};

export default observer(AddonsReceipt);
