export enum BookingChoiceKeys {
  apartmentType = 'apartmentType',
  bathrooms = 'bathrooms',
  halfBathrooms = 'halfBathrooms',
  threeForthBathrooms = 'threeForthBathrooms',
  staircase = 'staircase',
  commonAreas = 'commonAreas',
  howCleanIsHome = 'howCleanIsHome',
  extraKitchen = 'extraKitchen',

  kitchenLivingRoomDining = 'kitchenLivingRoomDining',
}

export const stripeNamesMapping: Record<string, string> = {
  'Stairs': BookingChoiceKeys.staircase,
  'Extra Kitchen': BookingChoiceKeys.extraKitchen,
  'Extra Living Room': BookingChoiceKeys.commonAreas,
  'Bedroom/Office': BookingChoiceKeys.apartmentType,
  'Half Bathroom': BookingChoiceKeys.halfBathrooms,
  '3/4 Bathroom': BookingChoiceKeys.threeForthBathrooms,
  'Full/Primary Bathroom': BookingChoiceKeys.bathrooms,
  'Kitchen, dining, living': BookingChoiceKeys.kitchenLivingRoomDining,
};

export enum CleaningFieldBaseAddition {
  coupons = 'coupons',
  addons = 'addons',
  tips = 'tips',
}

export const StripeCleaningFieldName = Object.assign({}, BookingChoiceKeys, CleaningFieldBaseAddition);

export interface StripeCleaningFields {
  [StripeCleaningFieldName.apartmentType]: SelectedStripeInfo | undefined;
  [StripeCleaningFieldName.bathrooms]: SelectedStripeInfo | undefined;
  [StripeCleaningFieldName.halfBathrooms]: SelectedStripeInfo | undefined;
  [StripeCleaningFieldName.threeForthBathrooms]: SelectedStripeInfo | undefined;
  [StripeCleaningFieldName.staircase]: SelectedStripeInfo | undefined;
  [StripeCleaningFieldName.commonAreas]: SelectedStripeInfo | undefined;
  [StripeCleaningFieldName.extraKitchen]: SelectedStripeInfo | undefined;
  [StripeCleaningFieldName.kitchenLivingRoomDining]: SelectedStripeInfo | undefined;
}

export interface ProductPrice {
  nickname?: string;
  price: number;
  cleaningFrequency?: string;
  cleaningType?: string;
  id?: string;
}

export interface ProductInfo {
  name: string;
  price: Record<string, ProductPrice>;
  id: string;
  nickname?: string;
  sqfRange?: string;
  description?: string;
  sqfMin?: number;
  sqfMax?: number;
}

export type AddonProductInfo = ProductInfo & {
  imagePath: string;
  isSpecifyQuantity?: boolean;

  moveOutPreselected: boolean;
  isFirstCleanOnly: boolean;

  shouldRemoveFromDeep?: boolean;
};

export interface ProductsReturnInfo {
  addons: AddonProductInfo[];
  cleanings: Record<string, CleaningSelectionOptions>; //CleaningData;
  tips: TipInfo;
  dateCreated?: string;
}

export interface CleaningData {
  [BookingChoiceKeys.staircase]: CleaningSelectionOptions;
  [BookingChoiceKeys.extraKitchen]: CleaningSelectionOptions;
  [BookingChoiceKeys.commonAreas]: CleaningSelectionOptions;
  [BookingChoiceKeys.apartmentType]: CleaningSelectionOptions;
  [BookingChoiceKeys.halfBathrooms]: CleaningSelectionOptions;
  [BookingChoiceKeys.threeForthBathrooms]: CleaningSelectionOptions;
  [BookingChoiceKeys.bathrooms]: CleaningSelectionOptions;
  [BookingChoiceKeys.kitchenLivingRoomDining]: CleaningSelectionOptions;
}

export interface TipInfo {
  name: string;
  id: string;
  values: number[];
}

export interface CleaningSelectionOptions {
  [key: string]: ProductInfo;
}

export interface SelectedStripeInfo {
  oneTime: StripeInfoForClean;
  recurring: StripeInfoForClean;
}

export interface StripeInfoForClean {
  productId: string;
  price: number;
  priceId: string;
  value?: number;
  name?: string;
}
