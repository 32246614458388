import { FunctionComponent } from 'react';
import { Grid } from '@mui/material';
import styles from './TipOptions.module.scss';
import AnalyticsManager from '../../../Utils/AnalyticsManager';

interface TipOptionsProps {
  options: number[];
  selectedOption: number;
  onChangeOption: (option: number) => void;
}

const TipOptions: FunctionComponent<TipOptionsProps> = ({
  options,
  selectedOption,
  onChangeOption,
}: TipOptionsProps) => {
  const noTipOption = 'No Tip';
  const optionsWithNone = [0, ...options];

  return (
    <Grid container className={styles.bookingSelectionGroup}>
      {optionsWithNone.map((option: number) => (
        <button
          className={
            selectedOption === 0
              ? option === 0
                ? `${styles.selectedBox} ${styles.box}`
                : `${styles.notSelectedBox} ${styles.box}`
              : selectedOption === option
              ? `${styles.selectedBox} ${styles.box}`
              : `${styles.notSelectedBox} ${styles.box}`
          }
          key={option}
          onClick={() => {
            AnalyticsManager.logGroupButtonClick('Tip', { optionSelected: option === 0 ? noTipOption : option });
            onChangeOption(option);
          }}>
          {option === 0 ? noTipOption : option}
        </button>
      ))}
    </Grid>
  );
};

export default TipOptions;
