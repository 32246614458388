import { BaseFormValue } from './BaseFormValue';

enum BaseFieldsName {
  email = 'email',
  phone = 'phone',
}

enum ContactUsDataAdditionalFieldsName {
  name = 'name',
  subject = 'subject',
  question = 'question',
}

enum LandingPageInfoDataAdditionalFieldsName {
  firstName = 'firstName',
  lastName = 'lastName',
  bedrooms = 'bedrooms',
  bathrooms = 'bathrooms',
}

export enum BeforeBookingLeadPageFieldsName {
  fullName = 'fullName',
  email = 'email',
  phone = 'phone',
  leadSource = 'leadSource',
}

const ContactUsDataFieldsName = Object.assign({}, BaseFieldsName, ContactUsDataAdditionalFieldsName);
export const LandingPageInfoDataFieldsName = Object.assign({}, BaseFieldsName, LandingPageInfoDataAdditionalFieldsName);

export interface ContactUsData {
  mainTitle: string;
  formData: {
    [ContactUsDataFieldsName.name]: BaseFormValue;
    [ContactUsDataFieldsName.email]: BaseFormValue;
    [ContactUsDataFieldsName.phone]: BaseFormValue;
    [ContactUsDataFieldsName.subject]: BaseFormValue;
    [ContactUsDataFieldsName.question]: BaseFormValue;
  };
}

export interface ContactUsDataFields {
  [ContactUsDataFieldsName.name]: string;
  [ContactUsDataFieldsName.email]: string;
  [ContactUsDataFieldsName.phone]: string;
  [ContactUsDataFieldsName.subject]: string;
  [ContactUsDataFieldsName.question]: string;
}

export interface LandingPageInfoFields {
  [LandingPageInfoDataFieldsName.bedrooms]: string;
  [LandingPageInfoDataFieldsName.bathrooms]: string;
  [LandingPageInfoDataFieldsName.firstName]: string;
  [LandingPageInfoDataFieldsName.lastName]: string;
  [LandingPageInfoDataFieldsName.email]: string;
  [LandingPageInfoDataFieldsName.phone]: string;
}

export interface LandingPageInfoData {
  mainTitle: string;
  submitButtonText: string;
  formData: {
    [LandingPageInfoDataFieldsName.bedrooms]: BaseFormValue;
    [LandingPageInfoDataFieldsName.bathrooms]: BaseFormValue;
    [LandingPageInfoDataFieldsName.firstName]: BaseFormValue;
    [LandingPageInfoDataFieldsName.lastName]: BaseFormValue;
    [LandingPageInfoDataFieldsName.email]: BaseFormValue;
    [LandingPageInfoDataFieldsName.phone]: BaseFormValue;
  };
}

export interface BeforeBookingLeadPageInfoFields {
  [BeforeBookingLeadPageFieldsName.fullName]: string;
  [BeforeBookingLeadPageFieldsName.email]: string;
  [BeforeBookingLeadPageFieldsName.phone]: string;
  [BeforeBookingLeadPageFieldsName.leadSource]: string;
}

export interface BeforeBookingLeadPageInfoData {
  mainTitle: string;
  subText?: string;
  submitButtonText: string;
  formData: {
    [BeforeBookingLeadPageFieldsName.fullName]: BaseFormValue;
    [BeforeBookingLeadPageFieldsName.email]: BaseFormValue;
    [BeforeBookingLeadPageFieldsName.phone]: BaseFormValue;
    [BeforeBookingLeadPageFieldsName.leadSource]: BaseFormValue;
  };
}
