import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import styles from './Article.module.scss';

const GreenCleaning: FunctionComponent = () => {
  const paragraph = 'body1';

  return (
    <div>
      <Typography className={styles.paragraph1} variant={paragraph}>
        Need more time in your life? Whether it’s to hang out more with family, have room for hobbies in the schedule,
        or anything in between, these five life hacks will start giving you more time to spend on the things you love.
      </Typography>

      <ol>
        <li>
          <Typography className={styles.paragraph1} variant={paragraph}>
            Reduce electronics use
          </Typography>

          <Typography className={styles.paragraph1} variant={paragraph}>
            With everyone on their phones all the time, it’s hard to reduce this habit. But if you start with tasks more
            in your control, such as watching tv, playing video games, and freely browsing the internet, you can create
            more time in your life. Make a committed effort to not turn on the tv or automatically hop on your computer
            after work, no matter how tempting.
          </Typography>
          <Typography className={styles.paragraph1} variant={paragraph}>
            Although these activities can be de-stressors, doing it an excess, like anything, is not great form your
            well-being. After only a few days, you’ll find it’s not that hard and all of a sudden, you’ll have more time
            for other activities.
          </Typography>
        </li>
        <li>
          <Typography className={styles.paragraph1} variant={paragraph}>
            Separate work and home life
          </Typography>

          <Typography className={styles.paragraph1} variant={paragraph}>
            This can be a tough one with so many people working from home. If you don’t work at home, make sure to set
            clear boundaries between home and work life. Don’t bring work home and set clear expectations with
            co-workers. Responding to even one work message after hours can derail your evening and avalanche into more
            work.
          </Typography>
          <Typography className={styles.paragraph1} variant={paragraph}>
            If you work from home, it can be tricky, but again with committed effort you can set boundaries and create
            more time in your life for things that matter. Set clear start and stop times, set up a dedicated workspace
            if possible, and don’t work after hours. Since there’s no commute, it can help to take a walk around the
            block before and after work to mentally “enter” and “leave” work.
          </Typography>
        </li>
        <li>
          <Typography className={styles.paragraph1} variant={paragraph}>
            Outsource some of your chores
          </Typography>

          <Typography className={styles.paragraph1} variant={paragraph}>
            Are you spending your Sunday afternoon cleaning your kitchen, mowing the lawn, and getting groceries? With
            modern technology and an abundance of household service businesses in most areas, it doesn’t have to be this
            way. Even on a budget, it’s possible to find an affordable house cleaning service, yard maintenance service,
            and use mobile apps to order groceries.
          </Typography>
          <Typography className={styles.paragraph1} variant={paragraph}>
            Outsourcing these chores and others addresses not only the time problem, but these people are professionals
            and will do a better job than you most of the time. The important thing is finding the right provider, but
            just an hour of internet research will already save you days, weeks, and months of your life.{' '}
          </Typography>
        </li>
        <li>
          <Typography className={styles.paragraph1} variant={paragraph}>
            Plan your schedule
          </Typography>

          <Typography className={styles.paragraph1} variant={paragraph}>
            This one seems like a no-brainer, but it might be the hardest life hack to truly commit to. Things always
            come up, and often it’s hard to plan life. But let’s be real, these are usually excuses we tell ourselves
            that stop us from organizing our life. It’s ok, we all do it, but it’s important to realize this and start
            getting on a better schedule.
          </Typography>
          <Typography className={styles.paragraph1} variant={paragraph}>
            You can plan your life more broadly, or down to each task like when to eat and snack. Add buffer time in
            between tasks and give yourself more time than you think. The goal is not to rush through life by planning,
            but to create room for the things you love.
          </Typography>
        </li>
        <li>
          <Typography className={styles.paragraph1} variant={paragraph}>
            Always have headphones
          </Typography>

          <Typography className={styles.paragraph1} variant={paragraph}>
            There’s an abundance of information out there nowadays with podcasts, audiobooks, and music all at the touch
            of our fingertips through apps like Spotify, Apple Music, Audible, and others. Why not plug in those
            headphones for mundane tasks such as a morning commute and soak in information on interesting topics?
          </Typography>
          <Typography className={styles.paragraph1} variant={paragraph}>
            And for a tip within a tip, put some life organization podcasts or self-improvement books on the queue!
          </Typography>
        </li>
      </ol>
    </div>
  );
};

export default GreenCleaning;
