import {
  BookingTypeOptions,
  CityOptions,
  SquareFootageOptions,
  StepsObject,
  CleaningFields,
  CleaningTypeOptions,
  HowCleanIsHomeOptions,
} from '../../types/BookingFormDataTypes';
import { CleaningFrequency, CleaningType, SelectedBookingInfoItems } from '../../types/BookingFieldsEnum';
import { FieldsData } from '../../types/StateTypes';
import { SelectedStripeInfo } from '../../types/StripeDataState';
import { initialStripeDataState } from '../../Mobx/States/InitialState';

const initialStripeInfo: SelectedStripeInfo = {
  oneTime: initialStripeDataState,
  recurring: initialStripeDataState,
};

export const cityData: FieldsData<CityOptions[]> = {
  options: [
    { key: 'Seattle', text: 'Seattle', value: 1, taxRate: 10.35, isValid: true },
    { key: 'Shoreline', text: 'Shoreline', value: 2, taxRate: 10.4, isValid: true },
  ],
  caption: 'City',
  descriptionText: '',
};

export const selectValidCityOptions = cityData.options;
export const validCities = selectValidCityOptions.map((op) => op.key.toLowerCase());

export const GetDefaultCity = (): CityOptions => {
  return cityData.options[0];
};

export const GetEmptyCity = (): CityOptions => {
  return { key: '', text: '', value: 3, taxRate: cityData.options[0].taxRate, isValid: false };
};

const apartmentTypeData: FieldsData<BookingTypeOptions[]> = {
  options: [
    { key: '1 Bedroom', value: 1, displayOnBookingForm: '1', stripeInfo: initialStripeInfo },
    { key: '2 Bedrooms', value: 2, displayOnBookingForm: '2', stripeInfo: initialStripeInfo },
    { key: '3 Bedrooms', value: 3, displayOnBookingForm: '3', stripeInfo: initialStripeInfo },
    { key: '4 Bedrooms', value: 4, displayOnBookingForm: '4', stripeInfo: initialStripeInfo },
  ],
  caption: 'How many bedrooms?',
  descriptionText: '',
};

const commonSpacesTypeData: FieldsData<BookingTypeOptions[]> = {
  options: [
    { key: '0 Rooms', value: 0, displayOnBookingForm: '0', stripeInfo: initialStripeInfo },
    { key: '1 Room', value: 1, displayOnBookingForm: '1', stripeInfo: initialStripeInfo },
    { key: '2 Rooms', value: 2, displayOnBookingForm: '2', stripeInfo: initialStripeInfo },
    { key: '3 Rooms', value: 3, displayOnBookingForm: '3', stripeInfo: initialStripeInfo },
  ],
  caption: 'How many other rooms/spaces?',
  descriptionText: 'Extra living room, family room, sun room, office',
};

const staircasesTypeData: FieldsData<BookingTypeOptions[]> = {
  options: [
    { key: '0 Staircases', value: 0, displayOnBookingForm: '0', stripeInfo: initialStripeInfo },
    { key: '1 Staircase', value: 1, displayOnBookingForm: '1', stripeInfo: initialStripeInfo },
    { key: '2 Staircases', value: 2, displayOnBookingForm: '2', stripeInfo: initialStripeInfo },
    { key: '3 Staircases', value: 3, displayOnBookingForm: '3', stripeInfo: initialStripeInfo },
  ],
  caption: 'How many staircases?',
  descriptionText: '',
};

const bathroomData: FieldsData<BookingTypeOptions[]> = {
  options: [
    { key: '1 Full Bath', value: 1, displayOnBookingForm: '1', stripeInfo: initialStripeInfo },
    { key: '2 Full Baths', value: 2, displayOnBookingForm: '2', stripeInfo: initialStripeInfo },
    { key: '3 Full Baths', value: 3, displayOnBookingForm: '3', stripeInfo: initialStripeInfo },
  ],
  caption: 'How many full bathroooms?',
  descriptionText: 'Toilet, sink, shower, tub or main bath',
};

const halfBathroomData: FieldsData<BookingTypeOptions[]> = {
  options: [
    { key: '0 Half Bath', value: 0, displayOnBookingForm: '0', stripeInfo: initialStripeInfo },
    { key: '1 Half Baths', value: 1, displayOnBookingForm: '1', stripeInfo: initialStripeInfo },
    { key: '2 Half Baths', value: 2, displayOnBookingForm: '2', stripeInfo: initialStripeInfo },
  ],
  caption: 'How many half bathrooms?',
  descriptionText: 'Toilet and sink only',
};

const threeForthBathrooms: FieldsData<BookingTypeOptions[]> = {
  options: [
    { key: '0 3/4 Baths', value: 0, displayOnBookingForm: '0', stripeInfo: initialStripeInfo },
    { key: '1 3/4 Baths', value: 1, displayOnBookingForm: '1', stripeInfo: initialStripeInfo },
    { key: '2 3/4 Baths', value: 2, displayOnBookingForm: '2', stripeInfo: initialStripeInfo },
  ],
  caption: 'How many 3/4 bathrooms?',
  descriptionText: 'Toilet, sink, standalone shower',
};

export const includedText = 'Included in base price: kitchen, dining room or eating area, and one living room.';

const squareFootageData: FieldsData<SquareFootageOptions[]> = {
  min: 0,
  max: 3000,
  postFix: 'Sq. Ft',
  stepIncrement: 50,
  options: [
    {
      key: '',
      value: 0, // Not used
      numberKey: 0,
    },
  ],
  caption: 'How many square feet is your home?',
  descriptionText: "Tip: if you're not sure of sq. ft, Google your home to find out!",
};

const howCleanIsHome: FieldsData<HowCleanIsHomeOptions[]> = {
  min: 1,
  max: 10,
  stepIncrement: 1,
  minDefinition: 'Perfectly clean',
  maxDefinition: 'Needs a lot of work',
  options: [
    {
      key: '1',
      value: 1,
    },
  ],
  caption: 'How clean would you rate your home?',
  descriptionText: '',
};

const extraKitchen: FieldsData<BookingTypeOptions[]> = {
  options: [
    { key: '0', value: 0, displayOnBookingForm: '0', stripeInfo: initialStripeInfo },
    { key: '1', value: 1, displayOnBookingForm: '1', stripeInfo: initialStripeInfo },
    { key: '2', value: 2, displayOnBookingForm: '2', stripeInfo: initialStripeInfo },
  ],
  caption: 'How many extra kitchens?',
  descriptionText: '',
};

const kitchenLivingRoomDining: FieldsData<BookingTypeOptions[]> = {
  options: [{ key: '0', value: 0, displayOnBookingForm: '0', stripeInfo: initialStripeInfo }],
  caption: '',
  descriptionText: '',
};

const cleaningTypeData: FieldsData<CleaningTypeOptions[]> = {
  caption: 'Cleaning Type',
  descriptionText: '',
  options: [
    {
      key: CleaningType.deep,
      isRecurring: false,
      frequency: [{ key: CleaningFrequency.oneTime, isRecurring: false }],
      stripeInfo: initialStripeInfo,
    },
    {
      key: CleaningType.recurring,
      isRecurring: true,
      frequency: [
        { key: CleaningFrequency.weekly, isRecurring: true },
        { key: CleaningFrequency.biweekly, isRecurring: true },
        { key: CleaningFrequency.monthly, isRecurring: true },
      ],
      stripeInfo: initialStripeInfo,
    },
    {
      key: CleaningType.moveOut,
      isRecurring: false,
      frequency: [{ key: CleaningFrequency.oneTime, isRecurring: false }],
      stripeInfo: initialStripeInfo,
    },
    // {
    //   key: CleaningType.rental,
    //   isRecurring: false,
    //   frequency: [{ key: CleaningFrequency.oneTime, isRecurring: false }],
    //   stripeInfo: initialStripeInfo,
    // },
  ],
};

export const cleaningFieldsInfo: CleaningFields = {
  [SelectedBookingInfoItems.apartmentType]: apartmentTypeData,
  [SelectedBookingInfoItems.bathrooms]: bathroomData,
  [SelectedBookingInfoItems.halfBathrooms]: halfBathroomData,
  [SelectedBookingInfoItems.threeForthBathrooms]: threeForthBathrooms,
  [SelectedBookingInfoItems.commonAreas]: commonSpacesTypeData,
  [SelectedBookingInfoItems.staircase]: staircasesTypeData,
  [SelectedBookingInfoItems.squareFootage]: squareFootageData,
  [SelectedBookingInfoItems.cleaningType]: cleaningTypeData,
  [SelectedBookingInfoItems.howCleanIsHome]: howCleanIsHome,
  [SelectedBookingInfoItems.extraKitchen]: extraKitchen,

  [SelectedBookingInfoItems.kitchenLivingRoomDining]: kitchenLivingRoomDining,
};

export const stepsData: StepsObject = {
  cleaning: 0,
  dateTime: 1,
  address: 2,
  payment: 3,
};
