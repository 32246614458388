import { FunctionComponent, ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertContext } from '../Contexts/Alert/AlertContext';

interface RedirectToPathProps {
  children?: ReactNode;
  path: string;
  conditionFunc: () => boolean;
  isLoading: boolean;
  afterRedirectBannerText?: string;
}

const RedirectToPath: FunctionComponent<RedirectToPathProps> = ({
  children,
  path,
  conditionFunc,
  isLoading,
  afterRedirectBannerText,
}: RedirectToPathProps) => {
  const alertContext = useContext(AlertContext);

  const navigate = useNavigate();
  const [shouldLoadChildres, setShouldLoadChildren] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      if (conditionFunc()) {
        setShouldLoadChildren(true);
      } else {
        navigate(path, { replace: true });
        if (afterRedirectBannerText) {
          alertContext.openAlert(afterRedirectBannerText, false);
        }
      }
    }
  }, [isLoading]);

  return <>{shouldLoadChildres ? children : null}</>;
};

export default RedirectToPath;
