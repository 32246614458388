import { Drawer, List, ListItem, ListItemText, Typography, IconButton } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import styles from './DrawerMenu.module.scss';
import ClearIcon from '@mui/icons-material/Clear';
// import PrimaryButton from '../../../Components/PrimaryButton/PrimaryButton';
import { navigationLinks } from '../../../../Assets/Data/NavigationLinks';
import { useLocation } from 'react-router';
import AnalyticsManager from '../../../../Utils/AnalyticsManager';

interface DrawerMenuProps {
  navigateToPage: (path: string) => void;
}

interface DrawerMenuHandle {
  openMenu: () => void;
}

const DrawerMenu: React.ForwardRefRenderFunction<DrawerMenuHandle, DrawerMenuProps> = (props, ref) => {
  const [isDwawerOpen, setIsDwawerOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsDwawerOpen(false);
  }, [location.pathname]);

  useImperativeHandle(ref, () => ({
    openMenu: () => {
      setIsDwawerOpen(true);
    },
  }));

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    AnalyticsManager.logIconButtonClick('Toggle Drawer');
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setIsDwawerOpen(open);
  };

  const goToMenuLink = (path: string) => {
    props.navigateToPage(path);
  };

  return (
    <Drawer anchor="right" open={isDwawerOpen} classes={{ paperAnchorRight: styles.drawerRoot }}>
      <div role="presentation">
        <div className={styles.menuRow}>
          <Typography variant="h3" className={styles.menuTitle}>
            MENU
          </Typography>
          <IconButton aria-label="close" onClick={toggleDrawer(false)} className={styles.menuButton}>
            <ClearIcon classes={{ root: styles.rootIconStyle }} />
          </IconButton>
        </div>
        <List>
          {navigationLinks.map(({ name, path }) => (
            <ListItem button key={`${name}-nav`} divider onClick={() => goToMenuLink(path)}>
              <ListItemText
                primary={
                  <Typography variant="h5" className={styles.navButtons}>
                    {name}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
        {/* <div className={styles.bookNowButton}>
          <PrimaryButton
            onClick={() => {
              AnalyticsManager.logButtonClick('Book now in drawer menu clicked');
              goToMenuLink('/bookclean');
            }}>
            Book Now
          </PrimaryButton>
        </div> */}
      </div>
    </Drawer>
  );
};

export default forwardRef(DrawerMenu);
