import { FunctionComponent, useEffect, useState } from 'react';
import styles from './ValidateKey.module.scss';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import { Grid, Typography } from '@mui/material';
import { Field, MainLink } from '..';
import AnalyticsManager from '../../../Utils/AnalyticsManager';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface ValidateKeyProps {
  onSubmit: (value: string) => Promise<string>;
  placeholder: string;
}

const ValidateKey: FunctionComponent<ValidateKeyProps> = ({ onSubmit, placeholder }: ValidateKeyProps) => {
  const [searchParams] = useSearchParams();
  const keyFromParam = searchParams.get('key') || '';
  const [key, setKey] = useState(keyFromParam);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccessApply, setIsSuccessApply] = useState(false);
  const navigate = useNavigate();

  const checkKey = async () => {
    AnalyticsManager.logButtonClick('Apply key', { key });
    const em = await onSubmit(key);
    setErrorMessage(em);
    setIsSuccessApply(!em);
  };

  const updateSearchValueWithKey = () => {
    navigate(
      {
        pathname: '/recurringClean',
        search: `?key=${key}`,
      },
      { replace: true }
    );
  };

  useEffect(() => {
    if (keyFromParam) {
      void checkKey();
    }
  }, [keyFromParam]);

  return (
    <>
      <Grid container className={styles.root}>
        <Grid item xs={9} className={styles.textFieldContainer}>
          <Field
            name="previousClean"
            className={styles.inputField}
            variant="outlined"
            error={Boolean(errorMessage)}
            onChange={(e) => {
              const val = e.target.value;
              setKey(val);
            }}
            value={key}
            onFocus={() => setErrorMessage('')}
            placeholder={placeholder}
            disabled={isSuccessApply}
          />
        </Grid>
        <Grid item xs={3} className={styles.applyBtn}>
          <PrimaryButton disabled={isSuccessApply} onClick={updateSearchValueWithKey}>
            Apply
          </PrimaryButton>
        </Grid>
        {errorMessage && <Typography className={styles.errorMessage}>{errorMessage}</Typography>}
      </Grid>

      {!isSuccessApply && (
        <Typography className={styles.noKeyTextLink}>
          {`Had a deep clean with us in the last 30 days, but don't have a key? `}{' '}
          <MainLink linkText="Contact us" toPath="/contact" isInternalLink>
            Contact us
          </MainLink>{' '}
          to get a key.
        </Typography>
      )}
    </>
  );
};

export default ValidateKey;
