import { Grid } from '@mui/material';
import { FunctionComponent, useRef } from 'react';
import { mainTitleLandingPage, mainTitleSubTextLandingPage } from '../../../../../Assets/Data/MainInfo';
import { ImageComponent } from '../../../../Components';
import styles from './Top.module.scss';

const Top: FunctionComponent = () => {
  const bRef = useRef<HTMLDivElement>(null);

  return (
    <div className={styles.root}>
      <Grid container item xs={12} sm={12} md={10} lg={10} className={styles.container}>
        <Grid item xs={12} sm={6} md={6} lg={7} className={styles.text}>
          <div className={styles.textContainer}>
            <h1 className={styles.headerText}>{mainTitleLandingPage}</h1>
            <h2 className={styles.subHeaderText}>{mainTitleSubTextLandingPage}</h2>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={5} className={styles.imgContainer}>
          <ImageComponent source="" path="LandingPage/landingPageMainPic.png" altText="people cleaning" />
        </Grid>
      </Grid>
      <div className={styles.background2} ref={bRef}></div>
      <div className={styles.background}>
        <ImageComponent
          source=""
          path="LandingPage/Bg.png"
          altText=""
          onImageLoad={() => {
            if (bRef.current) {
              bRef.current.style.backgroundColor = '#ffe7c6';
            }
          }}
        />
      </div>
    </div>
  );
};

export default Top;
