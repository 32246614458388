import { FunctionComponent, useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import styles from './Receipt.module.scss';
import { Field } from '../../Components';
import PromoCodeManager from '../../../Mobx/BookingFlowData/PromoCodeManager';
import { CleaningType } from '../../../types/BookingFieldsEnum';
import AnalyticsManager from '../../../Utils/AnalyticsManager';
import { AlertContext } from '../../Contexts/Alert/AlertContext';

interface PromoCodeFieldProps {
  selectedCleaningType: CleaningType;
}

const PromoCodeField: FunctionComponent<PromoCodeFieldProps> = ({ selectedCleaningType }: PromoCodeFieldProps) => {
  const [promoCode, setPromoCode] = useState('');
  const alertContext = useContext(AlertContext);

  const handlePromoCodeEnter = async () => {
    AnalyticsManager.logButtonClick('Apply promo code', { promoCode });
    await PromoCodeManager.getCouponData(promoCode, selectedCleaningType, alertContext.openAlert);
  };

  const handlePromoCodeChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    setPromoCode(target.value);
  };

  return (
    <Grid container className={styles.promoCode}>
      <Grid item xs={9} className={styles.promoCodeField}>
        <Field
          label=""
          id="promoCode"
          type="text"
          placeholder="Promo code"
          value={promoCode}
          onChange={handlePromoCodeChange}
        />
      </Grid>
      <Grid item xs={3} className={styles.applyPromoCodeButton}>
        <button onClick={() => void handlePromoCodeEnter()}>Apply</button>
      </Grid>
    </Grid>
  );
};

export default PromoCodeField;
