export const bookingSteps = ['Apartment, bathrooms and cleaning type', 'Cleaning date & time', 'Address', 'Payment'];

export interface BookingQuestions {
  main: string;
  sub: string;
}

export const bookingQuestions: BookingQuestions[] = [
  { main: 'Step 2: Clean Info', sub: '' },
  { main: 'Step 3: Choose Time Slot', sub: 'When should we come clean?' },
  { main: 'Step 4: Home Info', sub: '' },
  {
    main: 'Almost booked!',
    sub: 'We just need your contact and payment info to finalize your appointment. You will be charged on the day of your appointment.',
  },
];

export const tipOptions = [
  { key: 'No tip', value: 0 },
  { key: '$10', value: 10 },
  { key: '$15', value: 15 },
  { key: '$20', value: 20 },
];
