import { stripeData } from '../../Assets/Data/StripeData';

export const getAddons = () => {
  return stripeData.addons;
};

export const getCustomizeCleanAddons = () => {
  return stripeData.addons.filter((a) => !a.isFirstCleanOnly);
};

export const getOneTimeAddons = (isMoveOut = false) => {
  return stripeData.addons.filter((a) => a.isFirstCleanOnly && (isMoveOut || !a.shouldRemoveFromDeep));
};

export const getStripeTips = () => {
  return stripeData.tips;
};

export const getData = () => {
  return stripeData;
};
