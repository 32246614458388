import { FunctionComponent, useMemo } from 'react';
import moment from 'moment-timezone';
import { observer } from 'mobx-react';
import { Grid } from '@mui/material';
import styles from './TimePicker.module.scss';
import { eventsKeyFormat } from '../../../../Config';
import CleanDateTimeManager from '../../../../Mobx/BookingFlowData/CleanDateTimeManager';
import AnalyticsManager from '../../../../Utils/AnalyticsManager';
import CalendarTimesManager from '../../../../Mobx/FetchingManagers/CalendarTimesManager';
import SkeletonTimePicker from './SkeletonTimePicker';

const TimePicker: FunctionComponent = () => {
  const availableTimesData = CalendarTimesManager.getAvailableTimes();
  const selectedDay: moment.Moment | undefined = CleanDateTimeManager.getDateTime();
  const selectedTime: string = CleanDateTimeManager.getTimeSlot();

  const timeSlotsArray = useMemo(() => {
    return availableTimesData?.timeSlots ? Object.keys(availableTimesData.timeSlots) : [];
  }, [availableTimesData?.timeSlots]);

  const onChange = (time: string) => {
    AnalyticsManager.logGroupButtonClick('Time change');
    if (selectedTime !== time) {
      CleanDateTimeManager.updateTime(time);
    }
  };

  const eventsForSelectedDay = useMemo(() => {
    const dateFormatted = moment(selectedDay).format(eventsKeyFormat);
    const e = availableTimesData ? availableTimesData.events[dateFormatted] : {};
    return e || {};
  }, [selectedDay, availableTimesData?.events]);

  const recurringEventsForSelectedDay = useMemo(() => {
    if (!availableTimesData?.eventsRecurring) {
      return {};
    }

    const dateFormatted = moment(selectedDay).format(eventsKeyFormat);
    const e = availableTimesData ? availableTimesData.eventsRecurring[dateFormatted] : {};
    return e || {};
  }, [selectedDay, availableTimesData?.eventsRecurring]);

  const getTimeSlotText = (timeSlotKey: string) => {
    const data = availableTimesData ? availableTimesData.timeSlots[timeSlotKey] : undefined;
    if (data) {
      return data.title;
    }

    return timeSlotKey;
  };

  return (
    <Grid container justifyContent="space-evenly" className={styles.timePickerContainer}>
      {availableTimesData ? (
        timeSlotsArray.map((timeSlotKey) => (
          <button
            disabled={!selectedDay || eventsForSelectedDay[timeSlotKey] || recurringEventsForSelectedDay[timeSlotKey]}
            className={`${styles.timeButton} ${selectedTime === timeSlotKey ? styles.timeButtonSelected : ''}`}
            key={timeSlotKey}
            onClick={() => onChange(timeSlotKey)}>
            <p className={styles.timeSlot}>{getTimeSlotText(timeSlotKey)}</p>
          </button>
        ))
      ) : (
        <SkeletonTimePicker />
      )}
    </Grid>
  );
};

export default observer(TimePicker);
