import { FunctionComponent, ReactNode } from 'react';
import { Grid, GridSize } from '@mui/material';
import styles from './PageRoot.module.scss';

interface PageRootParams {
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  children: ReactNode;
  isMainBackgroundColor?: boolean;
  isFullHeight?: boolean;
}

const PageRoot: FunctionComponent<PageRootParams> = ({
  xs,
  sm,
  md,
  lg,
  isMainBackgroundColor,
  isFullHeight,
  children,
}: PageRootParams) => {
  return (
    <Grid
      container
      justifyContent="center"
      className={`${styles.root} ${isMainBackgroundColor ? styles.mainColor : ''} ${
        isFullHeight ? styles.fullHeight : ''
      }`}>
      <Grid container justifyContent="center" item xs={xs || 12} sm={sm || 10} md={md || 10} lg={lg || 9}>
        {children}
      </Grid>
    </Grid>
  );
};

export default PageRoot;
