export const mainPageText = `Overwhelmed with cleaning or just don’t have the time for it? We’re the cleaning crew you deserve. Don’t let the dust build up, hire Cheetah Cleaners and breathe easy while we take care of the cleaning chores.

Our cleaners are highly trained with non-toxic products and follow checklists so that no crevice is missed. Whether you need a deep clean, are moving out, or are looking for regular cleaning help, we got you covered. `;

export const infoTitle = `The cleaning crew you deserve and trust.`;

export const mainTitle = 'Reliable. Detailed. Serving homes in the Seattle area.';
export const mainTitleSubText = 'Schedule your clean online in just a few minutes!';

export const mainTitleLandingPageOld = 'Stop scrubbing, start living.';
export const mainTitleSubTextLandingPageOld = `Don’t have time to clean? We’ll take care of the cleaning so you can get time back in your busy schedule and spend it on things you want to do.`;

export const mainTitleLandingPage = 'Seattle House Cleaning Service';
export const mainTitleSubTextLandingPage = `Locally owned, reputable, and always thorough. We strive for a 5-star experience, every time!`;

export const landingPageQuote = 'See your price and book online or give us a call to consult on your needs!';
