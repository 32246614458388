import { invalidCityErrorMessage } from '../Assets/Data/AddressForm';
import { validCities } from '../Assets/Data/BookingInformation';
import { AbandonedCartInfo } from '../types/AbandonedCart';
import { AddressFormRegisteredFields, KeyOptions, ParkingOptions } from '../types/AddressFormTypes';
import { SelectedBookingInfoItems } from '../types/BookingFieldsEnum';
import {
  CleaningDataPayload,
  CleaningDataPayloadRecurring,
  CouponStripeData,
  CouponStripeToServer,
} from '../types/CleaningDataPayload';
import { Coupon } from '../types/Coupon';
import { PaymentFormData } from '../types/PaymentFormDataTypes';
import { Price } from '../types/Price';
import { DataForAbandonedCart, InitialBookingState } from '../types/StateTypes';
import { StripeCleaningFieldName } from '../types/StripeDataState';
import { calculateTime } from './CalculatePrice';

const defaultState = 'WA';

export const selectDataToSubmitRecurring = (
  currentState: InitialBookingState,
  paymentMethodId: string,
  eventId: string,
  key: string
): { data: CleaningDataPayloadRecurring | undefined; error: string } => {
  validateCity(currentState.addressForm.city);

  if (!currentState.dateTimeWithTimeSlot) {
    return { data: undefined, error: 'Please select valid date and time.' };
  }

  const date = currentState.dateTimeWithTimeSlot;

  const body: CleaningDataPayloadRecurring = {
    price: currentState.price,
    date: date.toISOString(),
    paymentMethodId: paymentMethodId,
    key: key,
    calendarEventId: eventId,
    personalInfo: {
      firstName: currentState.paymentForm.firstName.defaultValue,
      lastName: currentState.paymentForm.lastName.defaultValue,
      phone: currentState.paymentForm.phone.defaultValue,
      email: currentState.paymentForm.email.defaultValue,
    },
    [SelectedBookingInfoItems.cleaningType]: currentState.selected.cleaningType.key,
    [SelectedBookingInfoItems.cleaningFrequency]: currentState.selected.cleaningFrequency.key,
    timeToClean: {
      oneTime: calculateTime(currentState.price.oneTime.servicePrice),
      recurring: calculateTime(currentState.price.recurring.servicePrice),
    },
    parkingInfo: currentState.addressForm.parking,
    selectedCleaningInfoData: {
      [StripeCleaningFieldName.apartmentType]: currentState.selected.apartmentType.stripeInfo,
      [StripeCleaningFieldName.bathrooms]: currentState.selected.bathrooms.stripeInfo,
      [StripeCleaningFieldName.halfBathrooms]: currentState.selected.halfBathrooms.stripeInfo,
      [StripeCleaningFieldName.threeForthBathrooms]: currentState.selected.threeForthBathrooms.stripeInfo,
      [StripeCleaningFieldName.staircase]: currentState.selected.staircase.stripeInfo,
      [StripeCleaningFieldName.commonAreas]: currentState.selected.commonAreas.stripeInfo,
      [StripeCleaningFieldName.coupons]: getCouponDataRecurring(currentState.price, currentState.couponsApplied),
      [StripeCleaningFieldName.addons]: currentState.addressForm.addOns || [],
      [StripeCleaningFieldName.tips]: currentState.selectedTip,
      [StripeCleaningFieldName.extraKitchen]: currentState.selected.extraKitchen.stripeInfo,
      [StripeCleaningFieldName.kitchenLivingRoomDining]: currentState.selected.kitchenLivingRoomDining.stripeInfo,
    },
  };

  return { data: body, error: '' };
};

const getAddress = (addressForm: AddressFormRegisteredFields) => {
  const state = defaultState;
  const apartment = addressForm.apartment ? `, Apt. ${addressForm.apartment}` : '';
  const addressToDisplay = addressForm.address ? addressForm.address + apartment : '';
  const address = `${addressToDisplay.trim()}, ${addressForm.city}, ${state} ${addressForm.zip}`;

  if (!address) {
    throw new Error('Please select valid address.');
  }

  return address;
};

const validatePaymentForm = (paymentForm: PaymentFormData) => {
  if (
    !paymentForm.firstName.defaultValue ||
    !paymentForm.lastName.defaultValue ||
    !paymentForm.phone.defaultValue ||
    !paymentForm.email.defaultValue ||
    !paymentForm.leadSource.defaultValue
  ) {
    throw new Error('Please fill out your persnal info.');
  }
};

const validateAddressForm = (addressForm: AddressFormRegisteredFields) => {
  if (
    !addressForm.address ||
    !addressForm.city ||
    !addressForm.zip ||
    addressForm.parking === ParkingOptions.notSet ||
    addressForm.keyOption === KeyOptions.notSet ||
    addressForm.petOption.length === 0
  ) {
    throw new Error('Please select valid address.');
  }
};

export const isCityValid = (city: string) => {
  const c = city.toLowerCase().trim();
  return validCities.includes(c);
};

export const validateCity = (city: string) => {
  if (!isCityValid(city)) {
    throw new Error(invalidCityErrorMessage);
  }
};

export const selectDataForAbandonedCart = (currentState: DataForAbandonedCart) => {
  const body: AbandonedCartInfo = {
    price: currentState.price,
    date: currentState.dateTimeWithTimeSlot
      ? {
          isoString: currentState.dateTimeWithTimeSlot.toISOString(),
          timeslot: currentState.timeSlot,
          dayAndMonth: currentState.dateTimeWithTimeSlot.format('MM/DD/YYYY'),
        }
      : undefined,
    personalInfo: {
      firstName: currentState.paymentForm.firstName.defaultValue,
      lastName: currentState.paymentForm.lastName.defaultValue,
      phone: currentState.paymentForm.phone.defaultValue,
      email: currentState.paymentForm.email.defaultValue,
    },
    leadSource: currentState.paymentForm.leadSource.defaultValue,
    addressObj: {
      streetName: currentState.addressForm.address,
      apartment: currentState.addressForm.apartment,
      city: currentState.addressForm.city,
      zipCode: currentState.addressForm.zip,
      state: currentState.addressForm.state || defaultState,
    },
    parkingInfo: {
      type: currentState.addressForm.parking,
      additionalInfo: currentState.addressForm.parkingInformation,
    },
    keyInfo: {
      type: currentState.addressForm.keyOption,
      additionalInfo: currentState.addressForm.keyInformation,
    },
    petInfo: {
      type: currentState.addressForm.petOption,
      additionalInfo: currentState.addressForm.petInformation,
    },
    otherInfo: currentState.addressForm.otherInformation,
    addons: currentState.addons,
    selectedCleaningInfo: {
      [SelectedBookingInfoItems.apartmentType]: currentState.selected.apartmentType.key,
      [SelectedBookingInfoItems.bathrooms]: currentState.selected.bathrooms.key,
      [SelectedBookingInfoItems.cleaningType]: currentState.selected.cleaningType.key,
      [SelectedBookingInfoItems.cleaningFrequency]: currentState.selected.cleaningFrequency.key,
      [SelectedBookingInfoItems.squareFootage]: currentState.selected.squareFootage.key,
      [SelectedBookingInfoItems.halfBathrooms]: currentState.selected.halfBathrooms.key,
      [SelectedBookingInfoItems.staircase]: currentState.selected.staircase.key,
      [SelectedBookingInfoItems.commonAreas]: currentState.selected.commonAreas.key,
      [SelectedBookingInfoItems.threeForthBathrooms]: currentState.selected.threeForthBathrooms.key,
      [SelectedBookingInfoItems.howCleanIsHome]: currentState.selected.howCleanIsHome.value,
      [SelectedBookingInfoItems.extraKitchen]: currentState.selected.extraKitchen.value,
      [SelectedBookingInfoItems.kitchenLivingRoomDining]: currentState.selected.kitchenLivingRoomDining.key,
    },
  };

  return body;
};

export const selectDataToSubmit = (
  currentState: InitialBookingState,
  paymentMethodId: string,
  eventId: string
): { data: CleaningDataPayload | undefined; error: string; isRecurring: boolean } => {
  try {
    validateCity(currentState.addressForm.city);
    validateAddressForm(currentState.addressForm);
    validatePaymentForm(currentState.paymentForm);
    if (!currentState.dateTimeWithTimeSlot) {
      throw new Error('Please select valid date and time.');
    }

    const address = getAddress(currentState.addressForm);
    const date = currentState.dateTimeWithTimeSlot;

    const body: CleaningDataPayload = {
      date: date?.toISOString() || '',
      personalInfo: {
        firstName: currentState.paymentForm.firstName.defaultValue,
        lastName: currentState.paymentForm.lastName.defaultValue,
        phone: currentState.paymentForm.phone.defaultValue,
        email: currentState.paymentForm.email.defaultValue,
      },
      leadSource: currentState.paymentForm.leadSource.defaultValue,
      address: address,
      addressObj: {
        streetName: currentState.addressForm.address,
        apartment: currentState.addressForm.apartment,
        city: currentState.addressForm.city,
        zipCode: currentState.addressForm.zip,
        state: currentState.addressForm.state || defaultState,
      },
      parkingInfo: {
        type: currentState.addressForm.parking,
        additionalInfo: currentState.addressForm.parkingInformation,
      },
      keyInfo: {
        type: currentState.addressForm.keyOption,
        additionalInfo: currentState.addressForm.keyInformation,
      },
      petInfo: {
        type: currentState.addressForm.petOption,
        additionalInfo: currentState.addressForm.petInformation,
      },
      otherInfo: currentState.addressForm.otherInformation,
      paymentMethodId: paymentMethodId,
      calendarEventId: eventId,
      price: currentState.price,
      timeToClean: {
        oneTime: calculateTime(currentState.price.oneTime.servicePrice),
        recurring: calculateTime(currentState.price.recurring.servicePrice),
      },
      selectedCleaningInfo: {
        [SelectedBookingInfoItems.apartmentType]: currentState.selected.apartmentType.key,
        [SelectedBookingInfoItems.bathrooms]: currentState.selected.bathrooms.key,
        [SelectedBookingInfoItems.cleaningType]: currentState.selected.cleaningType.key,
        [SelectedBookingInfoItems.cleaningFrequency]: currentState.selected.cleaningFrequency.key,
        [SelectedBookingInfoItems.squareFootage]: currentState.selected.squareFootage.key,
        [SelectedBookingInfoItems.halfBathrooms]: currentState.selected.halfBathrooms.key,
        [SelectedBookingInfoItems.staircase]: currentState.selected.staircase.key,
        [SelectedBookingInfoItems.commonAreas]: currentState.selected.commonAreas.key,
        [SelectedBookingInfoItems.threeForthBathrooms]: currentState.selected.threeForthBathrooms.key,
        [SelectedBookingInfoItems.howCleanIsHome]: currentState.selected.howCleanIsHome.value,
        [SelectedBookingInfoItems.extraKitchen]: currentState.selected.extraKitchen.value,
        [SelectedBookingInfoItems.kitchenLivingRoomDining]: currentState.selected.kitchenLivingRoomDining.key,
      },
      selectedCleaningInfoData: {
        [StripeCleaningFieldName.apartmentType]: currentState.selected.apartmentType.stripeInfo,
        [StripeCleaningFieldName.bathrooms]: currentState.selected.bathrooms.stripeInfo,
        [StripeCleaningFieldName.halfBathrooms]: currentState.selected.halfBathrooms.stripeInfo,
        [StripeCleaningFieldName.threeForthBathrooms]: currentState.selected.threeForthBathrooms.stripeInfo,
        [StripeCleaningFieldName.staircase]: currentState.selected.staircase.stripeInfo,
        [StripeCleaningFieldName.commonAreas]: currentState.selected.commonAreas.stripeInfo,
        [StripeCleaningFieldName.coupons]: getCouponData(currentState.price, currentState.couponsApplied),
        [StripeCleaningFieldName.addons]: currentState.addressForm.addOns || [],
        [StripeCleaningFieldName.tips]: currentState.selectedTip,
        [StripeCleaningFieldName.extraKitchen]: currentState.selected.extraKitchen.stripeInfo,
        [StripeCleaningFieldName.kitchenLivingRoomDining]: currentState.selected.kitchenLivingRoomDining.stripeInfo,
      },
    };

    return { data: body, error: '', isRecurring: currentState.selected.cleaningType.isRecurring };
  } catch (e) {
    const message = (e as never)['message'] || 'Please select valid values.';
    return {
      data: undefined,
      error: message,
      isRecurring: currentState.selected.cleaningType.isRecurring,
    };
  }
};

const getCouponData = (price: Price, coupon?: Coupon): CouponStripeToServer | undefined => {
  if (coupon) {
    const oneTime: CouponStripeData | undefined =
      price.oneTime.discounts > 0
        ? { couponId: coupon.couponData.couponId, promotionId: coupon.couponData.promotionId }
        : undefined;
    const recurring: CouponStripeData | undefined =
      price.recurring.discounts > 0
        ? { couponId: coupon.couponData.couponId, promotionId: coupon.couponData.promotionId }
        : undefined;
    return { oneTime, recurring };
  }
};

const getCouponDataRecurring = (price: Price, coupon?: Coupon): CouponStripeToServer | undefined => {
  if (coupon) {
    const recurring: CouponStripeData | undefined =
      price.recurring.discounts > 0
        ? { couponId: coupon.couponData.couponId, promotionId: coupon.couponData.promotionId }
        : undefined;
    return { oneTime: undefined, recurring };
  }
};
