import { FunctionComponent, useContext, useState } from 'react';
import { Grid } from '@mui/material';
import { BookingSteps } from '../../../Components';
import { AddressInfoForm, BookForm, Calendar, PaymentForm, Receipt } from '../..';
import styles from './BookingProgressDesktop.module.scss';
import { bookingQuestions } from '../../../../Assets/Data/BookingConstants';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { AddressFormRegisteredFields } from '../../../../types/AddressFormTypes';
import { observer } from 'mobx-react';
import CleanDateTimeManager from '../../../../Mobx/BookingFlowData/CleanDateTimeManager';
import AnalyticsManager from '../../../../Utils/AnalyticsManager';
import { addCartDataToFirebaseFinalPage } from '../../../../Utils/AbandonedCartUtils';
import PaymentFormDataManager from '../../../../Mobx/BookingFlowData/PaymentFormDataManager';
import { DataForAbandonedCart } from '../../../../types/StateTypes';
import PriceManager from '../../../../Mobx/BookingFlowData/PriceManager';
import SelectedOptionsManager from '../../../../Mobx/BookingFlowData/SelectedOptionsManager';
import PromoCodeManager from '../../../../Mobx/BookingFlowData/PromoCodeManager';
import AddressFormDataManager from '../../../../Mobx/BookingFlowData/AddressFormDataManager';
import { AlertContext } from '../../../Contexts/Alert/AlertContext';

interface BookingProgressProps {
  onSubmit: (callback?: () => void) => void;
  methods: UseFormReturn<AddressFormRegisteredFields>;
}

const BookingProgressDesktop: FunctionComponent<BookingProgressProps> = ({
  onSubmit,
  methods,
}: BookingProgressProps) => {
  const selectedTime: string = CleanDateTimeManager.getTimeSlot();
  const [bookingProgressContainer, setBookingProgressContainer] = useState<HTMLDivElement | null>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<boolean[]>(Array(bookingQuestions.length + 1).fill(false));
  const selected = SelectedOptionsManager.getSelected();
  const alertContext = useContext(AlertContext);

  const lastStep = () => {
    return activeStep === bookingQuestions.length - 1;
  };

  const isNextDisabled = () => {
    if (activeStep === 1) {
      return !selectedTime;
    }

    return false;
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    AnalyticsManager.logButtonClick('Booking flow back');
    scrollContainerToTop();

    const newCompleted = completed;
    newCompleted[activeStep - 1] = false;
    setCompleted(newCompleted);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scrollToTop();
  };

  const handleProggressBarStep = (step: number) => {
    AnalyticsManager.logButtonClick('Progress bar step clicked');

    if (step > activeStep) {
      return;
    } else {
      const newCompleted = completed;
      newCompleted.fill(false, step);
      setCompleted(newCompleted);
    }
    setActiveStep(step);
  };

  const logAbandonedCart = async () => {
    const fullBookingState: DataForAbandonedCart = {
      price: PriceManager.getPrice(),
      selected: SelectedOptionsManager.getSelected(),
      addressForm: AddressFormDataManager.getAddressFormValues(),
      paymentForm: PaymentFormDataManager.getPaymentFields(),
      dateTimeWithTimeSlot: CleanDateTimeManager.getDateAndTimeSlot(),
      couponsApplied: PromoCodeManager.getAppliedCoupon(),
      selectedTip: PriceManager.getTip(),
      timeSlot: CleanDateTimeManager.getTimeSlot(),
      addons: AddressFormDataManager.getAddOns(),
    };
    await addCartDataToFirebaseFinalPage(fullBookingState);
  };

  const getStepComponent = (step: number) => {
    switch (step) {
      case 0:
        return <BookForm />;
      case 1:
        return <Calendar />;
      case 2:
        return (
          <FormProvider {...methods}>
            <AddressInfoForm handleSubmitInParent={handleComplete} />
          </FormProvider>
        );
      case 3: {
        void logAbandonedCart();
        return <PaymentForm />;
      }
      default:
        return <></>;
    }
  };

  const onClickNext = () => {
    AnalyticsManager.logButtonClick('Booking flow next');
    scrollContainerToTop();
    if (activeStep === 0) {
      if (selected.squareFootage.numberKey > 0) {
        handleComplete();
      } else {
        alertContext.openAlert('Square footage is missing', false);
      }
    } else if (activeStep === 2 || activeStep === 3) {
      onSubmit(handleComplete);
    } else {
      handleComplete();
    }
    window.scrollToTop();
  };

  const scrollContainerToTop = () => {
    if (bookingProgressContainer) {
      bookingProgressContainer.scrollTo({ top: 0, left: 0 });
    }
  };

  return (
    <>
      <BookingSteps completed={completed} handleStep={handleProggressBarStep} />
      <Grid container ref={setBookingProgressContainer} classes={{ root: styles.bookingProgressContainer }}>
        <Grid container classes={{ root: styles.bookingBody }}>
          <Grid item xs={12} md={lastStep() ? 7 : 9} lg={7} classes={{ root: styles.bookingForms }}>
            <Grid>{getStepComponent(activeStep)}</Grid>
            <Grid container classes={{ root: styles.prevnextButtons }}>
              <Grid item xs={6} sm={4}>
                {activeStep === 0 ? null : (
                  <button className={styles.backBtn} onClick={handleBack}>
                    Back
                  </button>
                )}
              </Grid>
              <Grid item xs={6} sm={4} classes={{ root: styles.nextButtonContainer }}>
                {!lastStep() && (
                  <button type="button" disabled={isNextDisabled()} className={styles.nextButton} onClick={onClickNext}>
                    Next
                  </button>
                )}
              </Grid>
              <Grid item xs={12} sm={4}></Grid>
            </Grid>
          </Grid>
          {lastStep() && (
            <Grid item xs={12} md={5} classes={{ root: styles.receiptContainerBody }}>
              <Receipt isRecurringOnly={false} isDesktop={true} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default observer(BookingProgressDesktop);
