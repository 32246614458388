import { ValidateKeyResponse } from '../../types/Cleaning';
import { CleaningDataPayload, CleaningDataPayloadRecurring } from '../../types/CleaningDataPayload';
import { CouponFromServer } from '../../types/Coupon';
import { allowedCountries, IpResponse } from '../../types/IpResponse';
import { ProductsReturnInfo } from '../../types/StripeDataState';
import { AbandonedCartBody, AbandonedCartResponse } from '../../types/AbandonedCart';
import { LogError, DebugLog, LogHttpCallError } from '../ErrorLog';
import HttpClientExternal from '../ExternalHttpManager';
import HttpClient from '../HttpManager';
import { BeforeBookingLeadPageInfoFields, ContactUsDataFields, LandingPageInfoFields } from '../../types/ContactUsData';
import { SubmitResumeBodyToServer } from '../../types/SubmitResume';

// Contact us
export const sendEmail = async (body: ContactUsDataFields): Promise<boolean> => {
  try {
    await HttpClient<unknown>({
      url: '/email/send-email',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
    });

    return true;
  } catch (e) {
    LogHttpCallError('sendEmail', e, true);
    return false;
  }
};

export const requestQuoteDataSend = async (body: LandingPageInfoFields): Promise<boolean> => {
  try {
    await HttpClient<unknown>({
      url: '/request-quote',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: { firstName: body.firstName, email: body.email },
    });

    return true;
  } catch (e) {
    LogHttpCallError('requestQuoteDataSend', e, false);
    return false;
  }
};

export const beforeBookingDataSend = async (body: BeforeBookingLeadPageInfoFields): Promise<boolean> => {
  try {
    await HttpClient<unknown>({
      url: '/booking-intent',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
    });

    return true;
  } catch (e) {
    LogHttpCallError('beforeBookingDataSend', e, false);
    return false;
  }
};

export const logLeadToBackend = async (name: string, email: string) => {
  const logLead = await HttpClient<ValidateKeyResponse>({
    url: '/lead',
    method: 'POST',
    data: {
      email,
      name,
    },
  });

  return logLead;
};

export const submitData = async (dataToSubmitToServer: CleaningDataPayload) => {
  return await HttpClient<unknown>({
    url: '/stripe/setup-intent',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: dataToSubmitToServer,
  });
};

export const getCouponDataAsync = async (code: string) => {
  const coupon = await HttpClient<CouponFromServer>({
    url: `/stripe/get-discount/${code}`,
    method: 'GET',
  });

  return coupon;
};

export const getCartFromBackendWithCatch = async (cartId: string) => {
  try {
    const cart = await HttpClient<{
      data: AbandonedCartBody;
    }>({
      url: `/data/cart/${cartId}`,
      method: 'GET',
    });

    return cart.data;
  } catch (e) {
    LogHttpCallError('getCartFromBackend', e, false);
  }
};

export const addCartToBackend = async (cardData: AbandonedCartBody) => {
  const addCart = await HttpClient<AbandonedCartResponse>({
    url: '/data/cart',
    method: 'POST',
    data: cardData,
  });

  return addCart;
};

export const submitDataToServerWithKeyRecurring = async (
  dataToSubmitToServer: CleaningDataPayloadRecurring
): Promise<boolean> => {
  await HttpClient<unknown>({
    url: '/stripe/setup-recurring-clean',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: dataToSubmitToServer,
  });

  return true;
};

export const checkKey = async (key: string) => {
  const cleaningResponse = await HttpClient<ValidateKeyResponse>({
    url: `/validateKey/${key}`,
    method: 'GET',
  });

  DebugLog(cleaningResponse);

  return cleaningResponse;
};

// Not used, not getting data from server
export const getStripeData = async () => {
  const products = await HttpClient<{ data: ProductsReturnInfo | undefined }>({
    url: '/stripe/get-products',
    method: 'GET',
  });

  return products;
};

// Not Used
export const submitResumeCleaners = async (body: SubmitResumeBodyToServer): Promise<boolean> => {
  try {
    const formData = new FormData();
    formData.append('firstName', body.firstName);
    formData.append('lastName', body.lastName);
    formData.append('email', body.email);
    formData.append('phone', body.phone);
    formData.append('position', 'cleaners');
    formData.append('cleaningInformation', body.cleaningInformation);
    formData.append('resume', body.resume, 'resume');

    await HttpClient<unknown>({
      url: '/submit-resume',
      method: 'POST',
      data: formData,
    });

    return true;
  } catch (e) {
    LogHttpCallError('submitResumeCleaners', e, true);
    return false;
  }
};

// External
export const getLocationFromIp = async (): Promise<boolean> => {
  try {
    const response = await HttpClientExternal<IpResponse>({
      url: 'https://ipapi.co/json',
      method: 'GET',
    });

    const isAllowed = allowedCountries.includes(response.country);
    !isAllowed && LogError('getLocationFromIp', { country: response.country }, false);
    return isAllowed;
  } catch (e) {
    LogError('getLocationFromIp', { message: JSON.stringify(e) }, false);
    return false;
  }
};
