import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import styles from './Article.module.scss';

const ClutterFree: FunctionComponent = () => {
  const paragraph = 'body1';

  // const history = useNavigate();
  // const goToClutterFree = () => {
  //   navigate({ pathname: 'blogPost', search: 'postId=clutter-free-life' });
  // };

  return (
    <div>
      <Typography className={styles.paragraph1} variant={paragraph}>
        Is your cluttered home stressing you out? Don’t fret, with these tips you’ll clear your space and free up your
        mind in no time! Here are our top five tips:
      </Typography>

      <ol>
        <li>
          <Typography className={styles.paragraph1} variant={paragraph}>
            Purge often
          </Typography>
          <Typography className={styles.paragraph1} variant={paragraph}>
            Even if you live well within your means and don’t buy many things, stuff tends to… accumulate. The
            occasional purge can help keep the clutter down. Set aside time a few times a year to go through all your
            things and either donate or throw away. We also suggest checking out local recycling options.
          </Typography>
        </li>
        <li>
          <Typography className={styles.paragraph1} variant={paragraph}>
            Keep a junk drawer
          </Typography>
          <Typography className={styles.paragraph1} variant={paragraph}>
            We all have those random things that seem impossible to organize into a specific place - tape, rubber bands,
            pens. Instead of stressing over the perfect spot to keep these items, create a junk drawer for them.
            Everything can just be swept in there, and voila, you’ve taken care of the problem. And no, it’s not
            cheating, just smart organizing!
          </Typography>
        </li>
        <li>
          <Typography className={styles.paragraph1} variant={paragraph}>
            Go paper free
          </Typography>
          <Typography className={styles.paragraph1} variant={paragraph}>
            Do you have a million pieces of paper in your home and can’t keep track of them? It may seem like a daunting
            task, but using a document storage app like Evernote or Dropbox will simplify your life in so many ways.
            Once the scanning process is complete, you can easily search for documents in the database. Don’t let the
            effort go to waste; create a system for scanning new documents do you stay on top of it.{' '}
          </Typography>
        </li>
        <li>
          <Typography className={styles.paragraph1} variant={paragraph}>
            Live more for experiences, not things
          </Typography>
          <Typography className={styles.paragraph1} variant={paragraph}>
            While things can bring us happiness, you’re probably not going to remember that new gadget you bought from
            Amazon in ten years. But that sweet road trip with friends or family vacation? You’ll remember that for a
            lifetime. Experiences are often more satisfying than buying things, so once you adjust your mindset, you’ll
            not only lead a clutter-free life, but also a more fulfilling life.{' '}
          </Typography>
        </li>
        <li>
          <Typography className={styles.paragraph1} variant={paragraph}>
            Rinse and repeat
          </Typography>
          <Typography className={styles.paragraph1} variant={paragraph}>
            The key to any habit is sticking with it, of course. When you fall out of the habit, it’s important to
            forgive yourself and try again. In fact, it may take many tries until the habit truly forms and sticks.
            Taking the clutter out of your life takes determination, courage, and consistency, but with these tips,
            you’ll be well on your way.
          </Typography>
        </li>
      </ol>
    </div>
  );
};

export default ClutterFree;
