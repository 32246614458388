import { CleanCard, CleaningChecklist } from '../../types/CleaningInfoTypes';

export const cleanChecklist: CleaningChecklist[] = [
  {
    category: 'Dust',
    values: [
      { key: 'Lighting fixtures', deep: true, moveOut: true, recurring: true },
      { key: 'Ceiling corners/edges', deep: true, moveOut: true, recurring: true },
      { key: 'Vents', deep: true, moveOut: true, recurring: true },
      { key: 'Door frames', deep: true, moveOut: true, recurring: true },
      { key: 'Shelves', deep: true, moveOut: true, recurring: true },
      { key: 'TVs & other monitors (not screens)', deep: true, moveOut: 'Not applicable', recurring: true },
      { key: 'Picture frames', deep: true, moveOut: 'Not applicable', recurring: true },
      { key: 'Tables & chairs', deep: true, moveOut: 'Not applicable', recurring: true },
    ],
  },
  {
    category: 'Wipe Down',
    values: [
      { key: 'Kitchen counters', deep: true, moveOut: true, recurring: true },
      { key: 'Kitchen cabinets (exterior)', deep: true, moveOut: true, recurring: true },
      { key: 'Refrigerator (exterior)', deep: true, moveOut: true, recurring: true },
      { key: 'Dishwasher (exterior)', deep: true, moveOut: true, recurring: true },
      { key: 'Microwave (inside & out)', deep: true, moveOut: true, recurring: true },
      { key: 'Bathroom counters', deep: true, moveOut: true, recurring: true },
      { key: 'Bathroom cabinets/drawers (exterior)', deep: true, moveOut: true, recurring: true },
      { key: 'Shower door', deep: true, moveOut: true, recurring: true },
      { key: 'Bathroom mirror', deep: true, moveOut: true, recurring: true },
      { key: 'Window sills/tracks', deep: true, moveOut: true, recurring: true },
      { key: 'Baseboards', deep: true, moveOut: true, recurring: true },
    ],
  },
  {
    category: 'Scrub',
    values: [
      { key: 'Stovetop', deep: true, moveOut: true, recurring: true },
      { key: 'Kitchen sink', deep: true, moveOut: true, recurring: true },
      { key: 'Bathtub/shower', deep: true, moveOut: true, recurring: true },
      { key: 'Bathrooms sink', deep: true, moveOut: true, recurring: true },
      { key: 'Toilet', deep: true, moveOut: true, recurring: true },
      {
        key: 'Heavy grout scrub',
        deep: 'Please reach out',
        moveOut: 'Please reach out',
        recurring: 'Please reach out',
      },
    ],
  },
  {
    category: 'Floors',
    values: [
      { key: 'Vaccuum', deep: true, moveOut: true, recurring: true },
      { key: 'Wet mopping', deep: true, moveOut: true, recurring: true },
    ],
  },
  {
    category: 'Add-ons',
    isAddon: true,
    values: [
      {
        key: 'Inside oven',
        deep: false,
        moveOut: '(pre-selected, can be removed)',
        recurring: false,
      },
      {
        key: 'Inside fridge',
        deep: false,
        moveOut: '(pre-selected, can be removed)',
        recurring: false,
      },
      {
        key: 'Inside cabinets',
        deep: false,
        moveOut: '(pre-selected, can be removed)',
        recurring: false,
      },
      {
        key: 'Interior windows',
        deep: false,
        moveOut: '(pre-selected, can be removed)',
        recurring: false,
      },
      {
        key: 'Wipe Doors',
        deep: false,
        moveOut: '(pre-selected, can be removed)',
        recurring: false,
      },
    ],
  },
  {
    category: 'Before We Leave',
    values: [
      { key: 'Straighten up', deep: true, moveOut: true, recurring: true },
      { key: 'Turn off lights & AC unit', deep: true, moveOut: true, recurring: true },
      { key: 'Offer walkthrough (if applicable)', deep: true, moveOut: true, recurring: '(after initial deep clean)' },
    ],
  },
  {
    category: "What We Don't Clean",
    values: [
      { key: 'Walls', deep: false, moveOut: false, recurring: false },
      { key: 'Ceiling fans', deep: false, moveOut: false, recurring: false },
      { key: 'Dishes/loading dishwasher', deep: false, moveOut: false, recurring: false },
      { key: 'Carpet cleaning/stain removal', deep: false, moveOut: false, recurring: false },
      { key: 'Oven grates', deep: false, moveOut: false, recurring: false },
      { key: 'Fireplace (interior)', deep: false, moveOut: false, recurring: false },
      { key: 'Paint splatter', deep: false, moveOut: false, recurring: false },
      { key: 'Utility rooms/unfinished areas', deep: false, moveOut: false, recurring: false },
      { key: 'Garages', deep: false, moveOut: false, recurring: false },
      { key: 'Outside areas', deep: false, moveOut: false, recurring: false },
      { key: 'Inside oven hood/vent', deep: false, moveOut: false, recurring: false },
    ],
  },
];

const regularCleanText = `Our regular service is a cleaning every week, biweekly, or monthly (every 4 weeks). The first clean will typically take longer because it’s a heavier scrub. Subsequent cleans focus on maintaining the standard and are priced lower than the initial clean. 

Each clean is detailed and thorough. We include cleaning the laundry room, changing bed sheets (if clean ones are left out), and disinfecting high-touch surfaces. If you have a special request or place you don’t want us to clean, put it in “other info” during booking.`;

const deepCleanText = `A one time cleaning a great option for those times you’re too busy to clean or when you want to make your place look especially shiny. Like when you’re hosting a get together or your parents are staying over for the week. 

If you like the result and would like to continue using Cheetah Cleaners, you can schedule occasional services anytime within two months of the last appointment and get regular service pricing.`;

const rentalCleanText = `Cleaning is only one of the many aspects to renting out space. Our rental turnover service will set up your rental for the next client, including changing sheets, pillows, and doing laundry if there’s a washer/dryer on site.

Because each rental’s needs are different, so we do not have an online booking feature for this service yet (coming soon!). To use our turnover service please reach out to let us know what you’re looking for, whether that’s a just quick sweep or a full deep clean. `;

const moveOutCleanText = `We can help get your place looking back to like you just moved in. Sometimes, even cleaner! The whole move out process can be extremely stressful, and cleaning might be the last thing on your mind.

Move out cleans include cleaning the inside of fridge, oven, and cabinets. We also wipe down the inside of windows. In order to properly clean we require your apartment or house is completely empty of furniture and most items.`;

const regularCleanCard: CleanCard = {
  title: 'Regular Service',
  text: { text: regularCleanText, isBold: false },
};

const deepCleanCard: CleanCard = {
  title: 'One Time Service',
  text: { text: deepCleanText, isBold: false },
};

// Unused
export const rentalCleanCard: CleanCard = {
  title: 'Airbnb/Rental Service',
  text: { text: rentalCleanText, isBold: false },
};

const moveOutCleanCard: CleanCard = {
  title: 'Move Out Service',
  text: { text: moveOutCleanText, isBold: false },
};

export const cleanCards: CleanCard[] = [regularCleanCard, deepCleanCard, moveOutCleanCard];
