import { FunctionComponent, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AnalyticsManager from '../../../Utils/AnalyticsManager';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import styles from './BookNowButton.module.scss';
import PaymentFormDataManager from '../../../Mobx/BookingFlowData/PaymentFormDataManager';
import { isAcceptingNewClients, isShowBookingform, notAcceptingNewClientText } from '../../../Config';
import { AlertContext } from '../../Contexts/Alert/AlertContext';

interface BookNowButtonProps {
  onBeforeClick?: () => void;
  onlyScroll?: boolean;
  buttonText?: string;
  isDisabled?: boolean;
}

const BookNowButton: FunctionComponent<BookNowButtonProps> = ({
  onBeforeClick,
  onlyScroll,
  buttonText,
  isDisabled = false,
}: BookNowButtonProps) => {
  const alertContext = useContext(AlertContext);

  const navigate = useNavigate();
  const location = useLocation();

  const onClick = () => {
    if (!isAcceptingNewClients) {
      alertContext.openAlert(notAcceptingNewClientText, false);
      return;
    }

    if (!isShowBookingform) {
      goToBooking();
    } else {
      if (PaymentFormDataManager.isBookingFormInfoEntered()) {
        goToBooking();
      } else {
        goToLeadForBooking();
      }
    }
    window.scrollToTop(true);
  };

  const goToBooking = () => {
    if (location.pathname !== '/bookclean') {
      onBeforeClick && onBeforeClick();
      AnalyticsManager.logButtonClick('Schedule your booking');
      if (!onlyScroll) {
        navigate('/bookclean');
      }
    }
  };

  const goToLeadForBooking = () => {
    if (location.pathname !== '/bookclean-info') {
      onBeforeClick && onBeforeClick();
      AnalyticsManager.logButtonClick('Schedule your booking: lead form');
      if (!onlyScroll) {
        navigate('/bookclean-info');
      }
    }
  };

  return (
    <div className={styles.bookNowButton}>
      <PrimaryButton className={styles.btn} onClick={onClick} disabled={isDisabled}>
        {buttonText ? buttonText : 'Book Online'}
      </PrimaryButton>
    </div>
  );
};

export default BookNowButton;
