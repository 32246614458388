import { FunctionComponent } from 'react';
import { Grid, Typography } from '@mui/material';
import styles from './Quote.module.scss';
import Titles from '../../../CleaningPageParts/Titles/Titles';
import LandingPageInfoInputs from '../../LandingPageInfoInputs/LandingPageInfoInputs';
import Dots from '../../../../../Assets/Images/svg/dots.svg';
import { landingPageQuote } from '../../../../../Assets/Data/MainInfo';

const Quote: FunctionComponent = () => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div>
          <Titles title1="ESTIMATE" title2="Get a Custom Quote" />
          {landingPageQuote && <Typography className={styles.text}>{landingPageQuote}</Typography>}
        </div>
        <div className={styles.dotsAndContent}>
          <div className={styles.dots}>
            <Dots />
          </div>
          <Grid container item xs={12} sm={12} md={10} lg={10} className={styles.allDropdownsContainer}>
            <LandingPageInfoInputs isVertical={false} />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Quote;
