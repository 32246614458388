import { FunctionComponent } from 'react';
import styles from './Label.module.scss';

interface LabelProps {
  text: string;
  htmlFor?: string;
  isRequired?: boolean;
  noPadding?: boolean;
}

const Label: FunctionComponent<LabelProps> = ({ text, htmlFor, isRequired, noPadding }: LabelProps) => {
  return htmlFor ? (
    <label htmlFor={htmlFor} className={styles.root}>
      {text}
      {isRequired && <span className={styles.star}>*</span>}
    </label>
  ) : (
    <p className={`${styles.root} ${noPadding ? styles.noPadding : ''}`}>
      {text}
      {isRequired && <span className={styles.star}>*</span>}
    </p>
  );
};

export default Label;
