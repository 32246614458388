import { FunctionComponent } from 'react';
import { Label } from '..';
import styles from './Field.module.scss';
import MainTextField, { MainTextFieldProps } from './MainTextField/MainTextField';

interface FieldProps {
  isRoundCorners?: boolean;
  label?: string;
  isRequired?: boolean;
  parentClassName?: string;
}

export type FieldAllProps = MainTextFieldProps & FieldProps;

const Field: FunctionComponent<FieldAllProps> = ({
  label,
  id,
  isRequired,
  // placeholder,
  // autoComplete,
  // value,
  // onChange,
  // inputProps,
  // helperText,
  // onBlur,
  isRoundCorners,
  className,
  parentClassName,
  ...other
}: FieldAllProps) => {
  return (
    <div className={styles.container}>
      {label && <Label isRequired={isRequired} htmlFor={id || label} text={label} />}
      <div className={`${parentClassName ? parentClassName : ''}`} id={id || label}>
        <MainTextField
          className={`${styles.textField} ${isRoundCorners ? styles.round : ''} ${className ? className : ''}`}
          id={id || label}
          variant="outlined"
          {...other}
        />
      </div>
    </div>
  );
};

export default Field;
