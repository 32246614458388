import AuthManager from './AuthManager';
import GAManager from './GAManager';
import StripeManager from './StripeManager';

class AllManagers {
  private gaManager: GAManager | undefined;
  private authManager: AuthManager | undefined;
  private stripeManager: StripeManager | undefined;
  // AnalyticsManager

  public GetStripeManager = (): StripeManager => {
    if (!this.stripeManager) {
      this.stripeManager = new StripeManager();
    }

    return this.stripeManager;
  };

  public GetAuthManager = (): AuthManager => {
    if (!this.authManager) {
      this.authManager = new AuthManager();
    }

    return this.authManager;
  };

  public GetGaManager = (): GAManager => {
    if (!this.gaManager) {
      this.gaManager = new GAManager();
    }

    return this.gaManager;
  };
}

export default new AllManagers();
