import { CleaningType } from '../types/BookingFieldsEnum';
import { Coupon, CouponFromServer, CouponValidation } from '../types/Coupon';

export const calculateDiscountDeduction = (coupon: CouponFromServer, servicePrice: number, addons: number): number => {
  let deduction = 0;
  if (coupon.amountOff) {
    deduction = coupon.amountOff;
  } else if (coupon.percentOff) {
    deduction = ((servicePrice + addons) * coupon.percentOff) / 100;
  }

  return deduction;
};

export const validatePromoCode = (
  coupon: CouponFromServer,
  selectedCleaningType: CleaningType,
  currentPromoCode?: Coupon
): string => {
  if (!coupon.validation) {
    return 'Invalid promo code';
  }

  if (currentPromoCode) {
    if (
      currentPromoCode.couponData.couponId === coupon.couponId ||
      currentPromoCode.couponData.promotionId === coupon.promotionId
    ) {
      return 'Promo code was already applied';
    }

    return 'Only one promo code can be applied';
  }

  const validationError = cleaningTypeCouponValidation(selectedCleaningType, coupon.validation);
  if (validationError) {
    return validationError;
  }

  return '';
};

export const cleaningTypeCouponValidation = (
  selectedCleaningType: CleaningType,
  validation: CouponValidation
): string => {
  const { isValidForDeep, isValidForMoveout, isValidForRecurring, isValidForInitialDeep } = validation;
  if (selectedCleaningType === CleaningType.recurring && !isValidForRecurring && !isValidForInitialDeep) {
    return 'This promo code cannot be applied to recurring cleans.';
  } else if (selectedCleaningType === CleaningType.deep && !isValidForDeep) {
    return 'This promo code cannot be applied to one time deep cleans.';
  } else if (selectedCleaningType === CleaningType.moveOut && !isValidForMoveout) {
    return 'This promo code cannot be applied to move out cleans.';
  } else if (selectedCleaningType === CleaningType.rental) {
    return 'This promo code cannot be applied to rental cleaning.';
  }

  return '';
};
