import { ButtonHTMLAttributes, FunctionComponent } from 'react';
import styles from './PrimaryButton.module.scss';

const PrimaryButton: FunctionComponent<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  className,
  ...other
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button className={`${styles.root} ${className ? className : ''}`} {...other}>
      {children}
    </button>
  );
};

export default PrimaryButton;
