import { FunctionComponent } from 'react';
import { InputProps } from '@mui/material';
import { Field } from '..';
import { FieldAllProps } from '../Field/Field';
import {
  CardCvcElementComponent,
  CardExpiryElementComponent,
  CardNumberElementComponent,
} from '@stripe/react-stripe-js';
import CustomStripeInput from './CustomStripeInput';

interface StripeFieldProps {
  StripeComponent: CardCvcElementComponent | CardExpiryElementComponent | CardNumberElementComponent;
}

type Props = FieldAllProps & StripeFieldProps;

const StripeInputField: FunctionComponent<Props> = ({
  inputProps,
  InputProps = {},
  StripeComponent,
  ...other
}: Props) => {
  const stripeInputProps = {
    ...InputProps,
    inputProps: {
      ...inputProps,
      ...InputProps.inputProps,
      component: StripeComponent,
    },
    inputComponent: CustomStripeInput,
  };

  const inputPropsForStripe = stripeInputProps as Partial<InputProps>;

  return <Field isRoundCorners={true} InputProps={inputPropsForStripe} {...other} />;
};

export default StripeInputField;
