import { FunctionComponent } from 'react';
import styles from './HowItWorks.module.scss';
import Titles from '../Titles/Titles';
import { BookNowButton, ImageComponent } from '../../../Components';
import IconElement from './IconElement/IconElement';
import Texts from './Texts/Texts';

interface HowItWorksProps {
  title?: string;
  isWhiteBackground?: boolean;
  removeScheduleButton?: boolean;
}

const data = {
  step1: {
    text: 'We’re easy to work with on and offline.',
    title: 'Easy Booking',
    step: 'Step 1',
    imgPath: 'LandingPage/person.png',
  },
  step2: {
    text: 'We bring all the equipment and supplies.',
    title: 'Everything Is Covered',
    step: 'Step 2',
    imgPath: 'LandingPage/hand.png',
  },
  step3: {
    text: 'You relax, we’ll take care of the dirty work!',
    title: 'You Get Time Back',
    step: 'Step 3',
    imgPath: 'LandingPage/clock.png',
  },
};

const HowItWorks: FunctionComponent<HowItWorksProps> = ({ title, removeScheduleButton }: HowItWorksProps) => {
  return (
    <div className={`${styles.root}`}>
      <div className={styles.howItWorks}>
        <Titles title1="PROCESS" title2={title || 'How it Works'} />
        <div className={styles.steps}>
          <div className={styles.step}>
            <IconElement imgSource="" altText="" imagePath={data.step1.imgPath} isPerson />
            <Texts step={data.step1.step} title={data.step1.title} text={data.step1.text} />
          </div>

          <div className={`${styles.next} ${styles.nextMirror}`}>
            <ImageComponent source="" path="LandingPage/dottedArrow.svg" altText="right arrow" />
          </div>

          <div className={`${styles.step} ${styles.reversedRow}`}>
            <div className={styles.handImgContainer}>
              <IconElement imgSource="" altText="" imagePath={data.step2.imgPath} />
            </div>
            <Texts step={data.step2.step} title={data.step2.title} text={data.step2.text} />
          </div>

          <div className={styles.next}>
            <ImageComponent source="" path="LandingPage/dottedArrow.svg" altText="right arrow" />
          </div>

          <div className={styles.step}>
            <div className={styles.images}>
              <div className={styles.alarmImgContainer}>
                <IconElement imgSource="" altText="" imagePath={data.step3.imgPath} />
              </div>

              <div className={styles.spiralContainer}>
                <div className={styles.spiral}>
                  <ImageComponent source="" path="LandingPage/circle.svg" altText="decorative" />
                  <ImageComponent source="" path="LandingPage/circle.svg" altText="decorative" />
                  <ImageComponent source="" path="LandingPage/circle.svg" altText="decorative" />
                </div>
              </div>
            </div>
            <Texts step={data.step3.step} title={data.step3.title} text={data.step3.text} />
          </div>
        </div>

        <div className={styles.halfCircles}>
          <ImageComponent source="" path="LandingPage/orangeHalfCircles.png" altText="decorative" />
        </div>
        {!removeScheduleButton && (
          <div className={styles.bookNowBtn}>
            <BookNowButton onlyScroll={false} />
          </div>
        )}
      </div>
    </div>
  );
};

export default HowItWorks;
