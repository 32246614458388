import { observer } from 'mobx-react';
import { FunctionComponent, useMemo } from 'react';
import SelectedOptionsManager from '../../../../../Mobx/BookingFlowData/SelectedOptionsManager';
import { getData } from '../../../../../Mobx/FetchingManagers/StripeDataManager';
import { CleaningType as CleaningTypeObj } from '../../../../../types/BookingFieldsEnum';
import { SelectedBookingInfo } from '../../../../../types/StateTypes';
import { ProductsReturnInfo } from '../../../../../types/StripeDataState';
import { calculatePriceStripe, calculatePriceAfterFirstStripe, roundPrice } from '../../../../../Utils/CalculatePrice';
import { preselectAddonsPrice, updateAllSelectedCleaningData } from '../../../../../Utils/StripeDataUtils';
import BookNowButton from '../../../../Components/BookNowButton/BookNowButton';
import styles from './CleaningType.module.scss';

export interface CleaningTypeProps {
  title: string;
  text?: string;
  price: SelectedBookingInfo;
  timeToClean: string;
  mostPopular: boolean;
}

const CleaningType: FunctionComponent<CleaningTypeProps> = ({
  title,
  text,
  price,
  timeToClean,
  mostPopular,
}: CleaningTypeProps) => {
  const data: ProductsReturnInfo = getData();

  const priceStr: string = useMemo(() => {
    let priceNum = 0;
    const selected = updateAllSelectedCleaningData(data.cleanings, price);
    if (selected.cleaningType.isRecurring) {
      priceNum = calculatePriceAfterFirstStripe(selected);
    } else if (selected.cleaningType.key === CleaningTypeObj.moveOut) {
      const p = calculatePriceStripe(selected);
      const addonPrice = preselectAddonsPrice(data.addons);
      priceNum = p + addonPrice;
    } else {
      priceNum = calculatePriceStripe(selected);
    }

    const displayPrice = roundPrice(priceNum);
    const isDecimalPresent = displayPrice % 1 > 0;

    return isDecimalPresent ? `$${displayPrice.toFixed(2)}` : `$${displayPrice}`;
  }, [data]);

  const changeCleaningType = () => {
    SelectedOptionsManager.updateSelectedcleaningType(price.cleaningType);
  };

  return (
    <div className={styles.base}>
      <div className={styles.root}>
        {mostPopular && <div className={styles.mostPopular}>Most popular</div>}
        <div>
          <h1 className={styles.title}>{title}</h1>
          {text && <h2 className={styles.text}>{text}</h2>}
        </div>
        <div className={styles.textContainer}>
          <div className={styles.fromAndPrice}>
            <p className={styles.from}>From</p>
            <p className={styles.price}>{priceStr}</p>
          </div>
          <p className={styles.timeToClean}>{timeToClean}</p>
          <div className={styles.btnContainer}>
            <BookNowButton onlyScroll={false} onBeforeClick={changeCleaningType} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(CleaningType);
