import { FunctionComponent } from 'react';
import styles from './HowItWorks.module.scss';
import Home from '../../../../Assets/Images/svg/home.svg';
import HandWhipe from '../../../../Assets/Images/svg/handWhipe.svg';
import Schedule from '../../../../Assets/Images/svg/schedule.svg';
import ChevronLeft from '../../../../Assets/Images/svg/chevronLeft.svg';
import Titles from '../Titles/Titles';
import { BookNowButton } from '../../../Components';

interface HowItWorksProps {
  title?: string;
  isWhiteBackground?: boolean;
  removeScheduleButton?: boolean;
}

const HowItWorks: FunctionComponent<HowItWorksProps> = ({
  title,
  isWhiteBackground,
  removeScheduleButton,
}: HowItWorksProps) => {
  return (
    <div className={`${styles.root} ${isWhiteBackground ? styles.whiteRoot : ''}`}>
      <div className={styles.howItWorks}>
        <Titles title1="PROCESS" title2={title || 'How it Works'} />
        <div className={styles.steps}>
          <div className={styles.step}>
            <div className={styles.iconContainer}>
              <div className={styles.dividerEmpty}></div>
              <div className={styles.icon}>
                <Home />
              </div>
              <div className={styles.divider}></div>
            </div>
            <h4 className={styles.title}>Step 1</h4>
            <h5 className={styles.text}>Select the size of your home.</h5>
          </div>

          <div className={styles.next}>
            <ChevronLeft />
          </div>

          <div className={styles.step}>
            <div className={styles.iconContainer}>
              <div className={styles.divider}></div>
              <div className={styles.icon}>
                <HandWhipe />
              </div>
              <div className={styles.divider}></div>
            </div>
            <h4 className={styles.title}>Step 2</h4>
            <h5 className={styles.text}>Choose any add-ons.</h5>
          </div>

          <div className={styles.next}>
            <ChevronLeft />
          </div>

          <div className={styles.step}>
            <div className={styles.iconContainer}>
              <div className={styles.divider}></div>
              <div className={styles.icon}>
                <Schedule />
              </div>
              <div className={styles.dividerEmpty}></div>
            </div>

            <h4 className={styles.title}>Step 3</h4>
            <h5 className={styles.text}>Schedule a day and time slot.</h5>
          </div>
        </div>

        {!removeScheduleButton && (
          <div className={styles.bookNowBtn}>
            <BookNowButton onlyScroll={false} />
          </div>
        )}
      </div>
    </div>
  );
};

export default HowItWorks;
