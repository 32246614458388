import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import styles from './Article.module.scss';

const MoveOut: FunctionComponent = () => {
  const paragraph = 'body1';

  return (
    <div>
      <Typography className={styles.paragraph1} variant={paragraph}>
        Do you hate moving? Well, it doesn’t have to be so stressful and overly time-consuming. By methodically
        approaching moving and planning ahead, you can remove the stress and feel good stepping into your new place.
        Here’s our six handy tips:
      </Typography>
      <ol>
        <li>
          <Typography className={styles.title} variant={paragraph}>
            Start packing sooner rather than later
          </Typography>
          <Typography className={styles.paragraph1} variant={paragraph}>
            For some reason, packing always takes a lot longer than you think. Maybe it’s the sentimental pauses and
            reflecting on moving away, or just putting it off until later. Either way, getting started as early as
            possible will give you peace of mind that you’ll have enough time. Break up the process into rooms and
            categories, such as packing away the least used things first, and saving the kitchen for one of the last
            spots to pack.
          </Typography>
        </li>
        <li>
          <Typography className={styles.title} variant={paragraph}>
            Throw things away
          </Typography>
          <Typography className={styles.paragraph1} variant={paragraph}>
            Give yourself ample time to go through your things and throw stuff out you don’t need. Remember, less is
            more. Do you really need those old clothes in the back of the closet that you don’t wear, or can you donate
            them? Is that collection of empty bottles worth lugging to your new place? Throw it all away and only keep
            the essentials.
          </Typography>
        </li>
        <li>
          <Typography className={styles.title} variant={paragraph}>
            Rent reusable packing boxes
          </Typography>
          <Typography className={styles.paragraph1} variant={paragraph}>
            Rentable boxes will make the packing and moving process much less chaotic. A lot of services will bring and
            pick up the boxes or have designated places to get them. You can choose the size and number of boxes you
            need to neatly pack everything away. Using a service like this not only saves the hassle of finding a lot of
            boxes, you also don’t have to worry about disposing of a bunch of cardboard.
          </Typography>
        </li>
        <li>
          <Typography className={styles.title} variant={paragraph}>
            Hire help as early as possible
          </Typography>
          <Typography className={styles.paragraph1} variant={paragraph}>
            If you’re planning on hiring any help for the moving process, do so as early as possible to ensure you’ll
            have a reservation. Just getting quotes for movers, renting a truck, or move out cleaners can take some
            time, so make sure to plan for that as well. When everything is planned and taken care of already, you’ll
            have peace of mind on actual moving day.
          </Typography>
        </li>
        <li>
          <Typography className={styles.title} variant={paragraph}>
            Plan food purchases leading up to move
          </Typography>
          <Typography className={styles.paragraph1} variant={paragraph}>
            Ever moved with a fridge full of food left behind, only to throw it all away? We’ve all been there, but it’s
            totally avoidable with a little organization. Don’t purchase large quantities of food at least a few weeks
            in advance, especially the days leading up to moving day. Ideally, you can plan your food to have nothing
            left over when you leave.
          </Typography>
        </li>
        <li>
          <Typography className={styles.title} variant={paragraph}>
            Inspect the property ahead of time
          </Typography>
          <Typography className={styles.paragraph1} variant={paragraph}>
            It’s always good to know the lay of the land beforehand to prepare for the move in. Plan for where to put
            specific boxes and make sure there’s enough room to move heavier furniture without bumping into your things.
            This will make carrying stuff in a breeze and you’ll be done with the moving truck in no time.
          </Typography>
        </li>
      </ol>
      <Typography variant={paragraph} className={styles.paragraph1}>
        You may have noticed a common theme throughout all the tips – planning and giving yourself lots of time. Like
        anything, a good plan will keep you calm and help you avoid any last minute mishaps. We wish you the best luck
        on your move!
      </Typography>
    </div>
  );
};

export default MoveOut;
