import { FunctionComponent } from 'react';
import { Typography } from '@mui/material';
import { cleaningFieldsInfo } from '../../../Assets/Data/BookingInformation';
import { CleaningFrequencyOptions } from '../../../types/BookingFormDataTypes';
import { observer } from 'mobx-react';
import SelectedOptionsManager from '../../../Mobx/BookingFlowData/SelectedOptionsManager';
import BookFormItem from '../../Containers/BookForm/BookFormItem';

const RecurringCleanOptions: FunctionComponent = () => {
  const selected = SelectedOptionsManager.getSelected();
  return (
    <div>
      <Typography variant="h6" gutterBottom={true}>
        {cleaningFieldsInfo.cleaningType.caption}
      </Typography>

      {selected.cleaningType.frequency && selected.cleaningType.frequency.length > 1 && (
        <BookFormItem
          selectedOptionString={selected.cleaningFrequency.key}
          onBookingOptionChange={(setValue: unknown) => {
            const value = setValue as CleaningFrequencyOptions;
            SelectedOptionsManager.updateSelectedCleaningFreq(value);
          }}
          descriptionText=""
          caption=""
          options={selected.cleaningType.frequency}
          groupName="cleaningType"
        />
      )}
    </div>
  );
};

export default observer(RecurringCleanOptions);
