import { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './AddressInfoForm.module.scss';
import { AddressFormRegisteredFields } from '../../../types/AddressFormTypes';
import { bookingQuestions } from '../../../Assets/Data/BookingConstants';
import AddressRow from './AddressRow';
import KeyRow from './KeyRow';
import PetRow from './PetRow';
import ParkingRow from './ParkingRow';
import OtherInfoRow from './OtherInfoRow';
import BookSectionWrapper from '../BookingProgressV2/BookSectionWrapper/BookSectionWrapper';
import BookingFormTitle from '../BookingProgressV2/BookingFormTitle/BookingFormTitle';

interface OwnProps {
  handleSubmitInParent?: () => void;
}

const AddressInfoForm: FunctionComponent<OwnProps> = ({ handleSubmitInParent }: OwnProps) => {
  const { handleSubmit } = useFormContext<AddressFormRegisteredFields>();

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    handleSubmit(() => {
      if (handleSubmitInParent) {
        void handleSubmitInParent();
      }
    });
  };
  return (
    <>
      <BookingFormTitle main={bookingQuestions[2].main} sub={bookingQuestions[2].sub} />

      <BookSectionWrapper>
        <form onSubmit={onSubmit} className={styles.addressForm}>
          <AddressRow />
          <ParkingRow />
          <KeyRow />
          <PetRow />
          <OtherInfoRow />
        </form>
      </BookSectionWrapper>
    </>
  );
};

export default AddressInfoForm;
