import { FunctionComponent } from 'react';
import styles from './Footer.module.scss';
import { careersLink, footerQuickLinks } from '../../../Assets/Data/NavigationLinks';
import { MainLink } from '../../Components';

const QuickLinks: FunctionComponent = () => {
  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Quick Link</h1>
      {footerQuickLinks.map((ql) => (
        <MainLink linkText={ql.name} className={styles.text} toPath={ql.path} isInternalLink={true} key={ql.name}>
          {ql.name}
        </MainLink>
      ))}
      <MainLink
        linkText="Careers"
        className={styles.text}
        isInternalLink={false}
        target="_blank"
        rel="noopener noreferrer"
        href={careersLink}>
        Careers
      </MainLink>
      <MainLink
        linkText="Terms of Service"
        className={styles.text}
        toPath="/terms"
        isInternalLink
        target="_blank"
        rel="noopener noreferrer">
        Terms of Service
      </MainLink>
      <MainLink
        linkText="Privacy Policy"
        className={styles.text}
        toPath="/privacy-policy"
        isInternalLink
        target="_blank"
        rel="noopener noreferrer">
        Privacy Policy
      </MainLink>
    </div>
  );
};

export default QuickLinks;
