import {
  AddressFormFieldsName,
  AddressFormJson,
  InformationFormFieldsName,
  KeyOptions,
  ParkingOptions,
} from '../../types/AddressFormTypes';

export const invalidCityErrorMessage = 'We only serve Seattle and Shoreline area.';

export const addressFormData: AddressFormJson = {
  constantValues: {
    [AddressFormFieldsName.address]: {
      name: AddressFormFieldsName.address,
      defaultValue: '',
      label: 'Address',
      rules: { required: 'Address is required.' },
      errorMessagesForAlert: {
        outOfRange: "Oops. We don't clean in your area... yet.",
        default: 'Address is invalid',
      },
    },
    [AddressFormFieldsName.apartment]: {
      name: AddressFormFieldsName.apartment,
      defaultValue: '',
      label: 'Apt/Unit',
    },
    [AddressFormFieldsName.zip]: {
      name: AddressFormFieldsName.zip,
      defaultValue: '',
      label: 'ZIP',
      rules: { required: 'Valid zip is required.' },
      errorMessagesForAlert: {
        outOfRange: invalidCityErrorMessage,
        default: 'Invalid zip code. Please input a valid zip number.',
      },
    },
    [AddressFormFieldsName.city]: {
      name: AddressFormFieldsName.city,
      defaultValue: '',
      label: 'City',
      rules: { required: 'City is required.' },
      errorMessagesForAlert: {
        default: invalidCityErrorMessage,
      },
    },
    [AddressFormFieldsName.state]: {
      name: AddressFormFieldsName.state,
      defaultValue: 'WA',
      label: 'State',
      rules: { required: 'State is required.' },
      errorMessagesForAlert: {
        default: invalidCityErrorMessage,
      },
    },
    [AddressFormFieldsName.parking]: {
      name: AddressFormFieldsName.parking,
      defaultValue: ParkingOptions.notSet,
      label: 'Parking information',
      rules: { required: 'Parking information is required.' },
      errorMessagesForAlert: {
        default: 'Parking information is required.',
      },
      options: {
        [ParkingOptions.street]: {
          placeholder: '',
          defaultValue: '',
          requiredClarificationBox: false,
        },
        [ParkingOptions.driveway]: {
          defaultValue: '',
          placeholder: '',
          requiredClarificationBox: false,
        },
        [ParkingOptions.paid]: {
          defaultValue: '',
          placeholder: 'Please give us more information on where to park (ex. street metered parking, garage)',
          moreText: `We'll send a photo of the parking receipt and you'll be charged the fee after the clean.`,
          requiredClarificationBox: true,
        },
        [ParkingOptions.other]: {
          defaultValue: '',
          placeholder: 'Please give us more information on how to park',
          requiredClarificationBox: true,
        },
      },
    },
    [AddressFormFieldsName.keyOption]: {
      name: AddressFormFieldsName.keyOption,
      defaultValue: KeyOptions.someoneIsHome,
      label: 'How do we get in?',
      rules: { required: true },
      options: {
        [KeyOptions.someoneIsHome]: {
          placeholder: 'Please give us more information about how to contact you to get inside',
          defaultValue: '',
          requiredClarificationBox: false,
        },
        [KeyOptions.hiddenKey]: {
          defaultValue: '',
          placeholder: 'Please give us more information how to find the key',
          requiredClarificationBox: true,
        },
        [KeyOptions.other]: {
          defaultValue: '',
          placeholder: 'Please give us more information about how to get in',
          requiredClarificationBox: true,
        },
      },
    },
    [AddressFormFieldsName.petOption]: {
      name: AddressFormFieldsName.petOption,
      defaultValue: [],
      label: 'Any pets?',
      options: ['No pets', 'Cat', 'Dog', 'Other'],
      rules: { required: true },
    },
    [AddressFormFieldsName.addOns]: {
      name: AddressFormFieldsName.addOns,
      defaultValue: [],
      label: 'Add Ons',
      rules: { required: false },
    },
    [AddressFormFieldsName.informationFields]: {
      [InformationFormFieldsName.otherInformation]: {
        name: InformationFormFieldsName.otherInformation,
        defaultValue: '',
        label: 'Other information (optional)',
        placeholder: 'Please give us more information',
      },
      [InformationFormFieldsName.petInformation]: {
        name: InformationFormFieldsName.petInformation,
        defaultValue: '',
        placeholder: 'Please give us more information about other pets',
      },
      [InformationFormFieldsName.keyInformation]: {
        name: InformationFormFieldsName.keyInformation,
        defaultValue: '',
        placeholder: '',
      },
      [InformationFormFieldsName.parkingInformation]: {
        name: InformationFormFieldsName.parkingInformation,
        defaultValue: '',
        placeholder: '',
      },
    },
  },
};
