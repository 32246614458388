import { FunctionComponent, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import styles from './Receipt.module.scss';
import { Field, TipOptions } from '../../Components';
import { TipInfo } from '../../../types/StripeDataState';
import { StringToNumber } from '../../../Utils/StripeDataUtils';
import { observer } from 'mobx-react';
import PriceManager from '../../../Mobx/BookingFlowData/PriceManager';
import { getStripeTips } from '../../../Mobx/FetchingManagers/StripeDataManager';

const AddTip: FunctionComponent = () => {
  const tips = PriceManager.getTip().price;
  const stripeTipOptions: TipInfo = getStripeTips();

  if (!stripeTipOptions) {
    return null;
  }

  const [customTip, setCustomTip] = useState('');

  const handleCustomTipChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    const option = target.value;
    if (!option) {
      PriceManager.updateTipField(0, stripeTipOptions.id);
      setCustomTip('');
      return;
    }

    const reg = RegExp(/^[0-9]{1,3}$/);
    const isValidTip = reg.test(option);
    if (isValidTip) {
      PriceManager.updateTipField(StringToNumber(option), stripeTipOptions.id);
      setCustomTip(option);
    }
  };

  return (
    <>
      <Typography className={styles.receiptRow}>Add tip</Typography>
      <Grid container className={styles.tipsOptions}>
        <Grid item xs={12} sm={9} className={styles.tipsButtons}>
          <TipOptions
            selectedOption={tips}
            options={stripeTipOptions.values}
            onChangeOption={(tipsVal) => {
              PriceManager.updateTipField(tipsVal, stripeTipOptions.id);
              setCustomTip('');
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3} className={styles.customTipContainer}>
          <Field
            label=""
            id="Custom"
            type="text"
            placeholder="Custom"
            value={customTip}
            onChange={handleCustomTipChange}
            inputProps={{
              maxLength: '1000',
              pattern: '[0-9]*',
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default observer(AddTip);
