import { FunctionComponent } from 'react';
import styles from './Footer.module.scss';
import CallIcon from '@mui/icons-material/Call';
import Main from '../../../Assets/Images/svg/mail.svg';
import Location from '../../../Assets/Images/svg/location.svg';
import { inforEmail, phoneFormatted } from '../../../Config';
import { MainLink } from '../../Components';

const ContactInformation: FunctionComponent = () => {
  const footerContact = {
    email: inforEmail,
    phoneNumber: phoneFormatted,
    address: 'Seattle, WA, USA',
  };

  const href = `mailto:${footerContact.email}`;
  const tel = `tel:${footerContact.phoneNumber}`;

  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Contact Information</h1>
      <div className={styles.textContainer}>
        <div className={styles.svgContainer}>
          <CallIcon className={styles.phoneIcon} />
        </div>
        <p className={styles.text}>
          <MainLink
            isInternalLink={false}
            linkText={footerContact.phoneNumber}
            className={styles.emailAndPhoneLink}
            href={tel}>
            {footerContact.phoneNumber}
          </MainLink>
        </p>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.svgContainer}>
          <Main />
        </div>
        <p className={styles.text}>
          <MainLink
            isInternalLink={false}
            linkText={footerContact.email}
            className={styles.emailAndPhoneLink}
            href={href}>
            {footerContact.email}
          </MainLink>
        </p>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.svgContainer}>
          <Location />
        </div>
        <p className={styles.text}>{footerContact.address}</p>
      </div>
    </div>
  );
};

export default ContactInformation;
