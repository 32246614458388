import { FunctionComponent } from 'react';
import { Grid } from '@mui/material';
import styles from './BookingProgressMobile.module.scss';
import { AddressInfoForm, BookForm, Calendar, PaymentForm, Receipt } from '../..';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import MobilePriceBar from './MobilePriceBar';
import { AddressFormRegisteredFields } from '../../../../types/AddressFormTypes';

interface BookingProgressMobileProps {
  onSubmitAddressForm: () => void;
  getAddressFormValues: () => void;
  methods: UseFormReturn<AddressFormRegisteredFields>;
}

const BookingProgressMobile: FunctionComponent<BookingProgressMobileProps> = ({
  onSubmitAddressForm,
  getAddressFormValues,
  methods,
}: BookingProgressMobileProps) => {
  return (
    <>
      <MobilePriceBar />
      <Grid container className={styles.bookingProgressContainerMobile}>
        <Grid container>
          <Grid item xs={12}>
            <div>
              <div className={styles.container}>
                <BookForm />
              </div>

              <div className={styles.container}>
                <Calendar />
              </div>

              <div className={styles.container}>
                <FormProvider {...methods}>
                  <AddressInfoForm />
                </FormProvider>
              </div>

              <div className={styles.container}>
                <PaymentForm getAddressFormValues={getAddressFormValues} onSubmitPaymentForm={onSubmitAddressForm} />
              </div>
            </div>

            <div>
              <Receipt isRecurringOnly={false} />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default BookingProgressMobile;
