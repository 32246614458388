import { FunctionComponent } from 'react';
import { Typography } from '@mui/material';
import styles from './InfoPageStyles.module.scss';
import { MainLink } from '../../Components';
import InfoPageRoot from '../../Components/InfoPageRoot/InfoPageRoot';

const Terms: FunctionComponent = () => {
  const titleVariant = 'h2';
  const textVariant = 'body1';
  return (
    <InfoPageRoot mainTitle="Terms of Service">
      <Typography className={styles.title} variant={titleVariant}>
        Terms
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        Cheetah Cleaners LLC (“Cheetah Cleaners”, “we”, or “us”) is ready to clean your home. The Terms of Service
        (“Terms”) outlined below will govern our relationship with you.
        <br />
        <br />
        We’re excited you’ve decided to use our services, including, but not limited to, our technologies, functions,
        and tools offered on our website as well as our sales, customer service, and cleaners, all of which we refer to
        simply as the “services”.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Authority
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        By using the Services, you state that:
        <br />
        <dd />
        <ul>
          <li>you can form a binding contract with Cheetah Cleaners;</li>
          <li>
            you will comply with these Terms and all applicable local, state, national, and international laws, rules,
            and regulations;
          </li>
          <li>you agree to pay the quoted price for the Services, unless otherwise agreed to in writing.</li>
        </ul>
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Cleaning Supplies and Equipment
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        We use non-toxic and eco-friendly supplies and all equipment required for cleaning your apartment.
        <br />
        <dd />
        We’re happy to use any specific products you leave out and provide instructions for using, if that’s your
        preference.
        <br />
        <dd />
        We will accommodate within reason. In order to use our services we require your home have running water.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Satisfaction Guarantee
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        If you are not satisfied with your original clean, we want to make it right. Please call or text (206) 486-0038
        or email{' '}
        <MainLink linkText="email" isInternalLink={false} href="mailto:info@cheetahcleaners.com">
          info@cheetahcleaners.com
        </MainLink>
        . We’ll come back to reclean if needed.
        <br />
        <br />
        Our Satisfaction Guarantee does not apply to other free recleans or places not in standard condition (e.g.
        biohazards, hoarding, etc.).
        <br />
        <br />
        While there is not necessarily a limit on how many recleans you can receive, we reserve the right to require
        additional time and payment for future cleans and/or void our Satisfaction Guarantee for future cleans if
        recleans are requested too often.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Safety
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        Our goal is to maintain a safe environment for everyone. Our cleaning staff is employed, not contracted,
        background checked, insured & bonded, and professionally trained. We reserve the right for employees to leave
        from your home should the environment be/become unsafe.
        <br />
        <br />
        By using our services you agree you will follow these guidelines to maintain a safe environment:
        <br />
        <dd />
        <ul>
          <li>
            identifying fragile items, securing valuables, and communicating any special instructions to the cleaner
            before the clean;
          </li>
          <li>
            notifying us of pets and, if necessary, placing them in spaces that would not threaten our staff or impede
            our services;
          </li>
          <li>
            removing any biohazards, including, but not limited to, human bodily fluids, animal waste, rodent feces,
            live/dead insects, and sharp bio-products (needles, IV tubing, etc.);
          </li>
          <li>not requiring or requesting the lifting or moving of furniture or heavy items.</li>
        </ul>
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Damages
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        Our cleaning staff will conduct themselves professionally in your home. In the rare event accidental damage
        occurs, you’ll need to notify our office within 48 hours of when the clean is completed by calling or texting
        (206) 486-0038 or emailing info@cheetahcleaners.com. You’ll need to provide us a photo and estimate of the
        damages and be available to be contacted. We cannot guarantee reimbursement for damages that do not comply with
        this. Further, for potential caulking and grout damages, Cheetah Cleaners does not assume responsibility for the
        restoration of severely worn, stained, or mildewed caulking and grout.
        <br />
        <br />
        Except in emergencies (e.g., a leaking toilet), you must not undertake the repair or replacement of the damage
        for which you may seek reimbursement. Cheetah Cleaners reserves the right to contract suitable professionals to
        repair damages and will make payment arrangements directly with its contractors to settle any damage repair.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Communication
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        At Cheetah Cleaners LLC, we prioritize effective and timely communication to ensure a seamless experience for
        our clients. We employ various communication methods to interact with our clients, including but not limited to
        email, text messages, phone calls, and automated messaging systems.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Appointment Notifications
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        Upon scheduling a service with us, clients will receive confirmation notifications via their preferred
        communication channel (email, text, or phone call). Additionally, reminder notifications may be sent prior to
        the scheduled appointment to ensure clarity and to accommodate any necessary adjustments.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Feedback and Reviews
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        {`We value our clients' feedback immensely. We may reach out to clients following the completion of services to
        request feedback regarding their experience. Clients may receive messages via email or text inviting them to
        provide reviews or suggestions. Your feedback helps us continually enhance our services and improve our customer
        experience.`}
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Promotional Communications
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        Periodically, we may send promotional or informational communications regarding our services, special offers, or
        updates that we believe may be of interest to our clients. These messages may be sent via email, text, or other
        means of communication provided by the client. Clients have the option to opt-out of these communications at any
        time by following the provided instructions in the message or by contacting our customer support team.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Data Security and Privacy
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        Client information is treated with the utmost confidentiality and is securely stored. We adhere to stringent
        data protection measures to safeguard all personal information shared with us during communication interactions.
        <br />
        <br />
        By engaging with our services, clients agree to receive communication related to appointments, feedback
        requests, and promotional materials as described in this section. Should you have any concerns or wish to modify
        your communication preferences, please contact our customer support team.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Pricing and Discounts
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        Cheetah Cleaners reserves the right to adjust pricing and discounts of its Services at any time. You will be
        notified 14 days prior to any changes to price.{' '}
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Electronic Services and Licenses
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        Cheetah Cleaners grants you a personal, worldwide, royalty-free, non-assignable, nonexclusive, revocable, and
        non-sublicensable license to access and use our desktop and mobile website and other related electronic services
        (“Electronic Services”).
        <br />
        <br />
        You may not copy, modify, distribute, sell, or lease any part of our Electronic Services, nor may you reverse
        engineer or attempt to extract the source code of that software, unless laws prohibit these restrictions or you
        have our written permission to do so. You may not use our branding, logos, designs, photographs, videos, or any
        other materials appearing or used in our Electronic Services, without our written consent.
        <br />
        <br />
        In short: You may not use the Electronic Services or the content on the Electronic Services in ways that are not
        authorized by these Terms. Nor may you help anyone else in doing so.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Privacy and Security
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        Privacy matters. You can learn how we handle your information when you use our Services by reading our Privacy
        Policy. We encourage you to give the{' '}
        <MainLink
          linkText="Privacy Policy"
          toPath="/privacy-policy"
          isInternalLink
          target="_blank"
          rel="noopener noreferrer">
          Privacy Policy
        </MainLink>{' '}
        a careful look because, by using our Services, you agree that Cheetah Cleaners can collect, use, and share your
        information in a manner consistent with that policy.
        <br />
        <br />
        If you feel that we’re not following our{' '}
        <MainLink
          linkText="Privacy Policy"
          toPath="/privacy-policy"
          isInternalLink
          target="_blank"
          rel="noopener noreferrer">
          Privacy Policy
        </MainLink>
        , you may contact us either by email (info@cheetahcleaners.com) or by phone (206) 486-0038.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Data Charges and Mobile Phones
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        You are responsible for any mobile charges that you incur for using our Services (including our Electronic
        Services) including text-messaging and data charges. If you’re unsure what those charges may be, you should ask
        your service provider before using our Services (including our Electronic Services).
        <br />
        <br />
        If you change or deactivate the phone number or email address that you used to create an account, you must
        update your account information through your dashboard within 24 hours to prevent us from sending to someone
        else messages intended for you.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Modifying the Services and Termination
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        We may add or remove features, products, or functionalities to our Services, or suspend or stop the Services
        altogether. We may take any of these actions at any time without prior notice. Cheetah Cleaners may terminate
        the Services it provides you at any time, for any reason, and without prior notice. That means that we may stop
        providing you with any Services, or impose new or additional limits on your ability to use our Services.{' '}
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Indemnity
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        You agree, to the extent permitted by law, to indemnify, defend, and hold harmless Cheetah Cleaners, our
        affiliates, directors, officers, stockholders, employees, licensors, and agents from and against any and all
        complaints, charges, claims, damages, losses, costs, liabilities, and expenses (including attorneys’ fees) due
        to, arising out of, or relating in any way to your access to or use of the Services and your breach of these
        Terms.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Disclaimers
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” AND TO THE EXTENT PERMITTED BY LAW WITHOUT WARRANTIES OF
        ANY KIND, EITHER EXPRESS OR IMPLIED. IN ADDITION, WHILE CHEETAH CLEANERS ATTEMPTS TO PROVIDE A GOOD USER
        EXPERIENCE, WE DO NOT REPRESENT OR WARRANT THAT: (A) THE SERVICES WILL ALWAYS BE SECURE, ERROR-FREE, OR TIMELY;
        (B) THE SERVICES WILL ALWAYS FUNCTION WITHOUT DELAYS, DISRUPTIONS, OR IMPERFECTIONS; OR (C) THAT ANY PRICING,
        TERMS, CONTENT, USER CONTENT, OR INFORMATION YOU OBTAIN ON OR THROUGH THE SERVICES WILL BE TIMELY OR ACCURATE.
        CHEETAH CLEANERS TAKES NO RESPONSIBILITY AND ASSUMES NO LIABILITY GREATER THAN REQUIRED BY APPLICABLE LAW.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Limitation of Liability
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        TO THE MAXIMUM EXTENT PERMITTED BY LAW, CHEETAH CLEANERS AND OUR MANAGING MEMBERS, SHAREHOLDERS, EMPLOYEES,
        AFFILIATES, LICENSORS, AND SUPPLIERS WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
        PUNITIVE, OR MULTIPLE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR
        ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (A) OUR PROVISION OF, FAILURE TO, OR
        INABILITY TO RENDER, AND YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE, THE SERVICES OR (B) THE
        UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR CONTENT, EVEN IF CHEETAH CLEANERS HAS BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Severability
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        If any provision of these Terms is found to be unenforceable, then that provision will be severed from these
        Terms and will not affect the validity and enforceability of any remaining provisions.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Additional Terms for Specific Services
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        We sometimes need to craft additional terms and conditions (“Additional Terms”) for your specific needs
        (“Specific Services”). The Additional Terms, in addition to these Terms, will also become part of your agreement
        with us if you use Specific Services. If any part of the Additional Terms conflicts with these Terms, the
        Additional Terms will prevail.
      </Typography>

      <Typography className={styles.title} variant={titleVariant}>
        Final Terms; No Third Party Beneficiary, No Waiver; No Assignment; Reservation of Rights
      </Typography>
      <Typography className={styles.text} variant={textVariant}>
        These Terms (together with any Additional Terms) make up the entire agreement between you and Cheetah Cleaners
        and supersede any prior agreements. These Terms do not create or confer any third-party beneficiary rights. If
        we do not enforce a provision in these Terms, it will not be considered a waiver. You may not transfer any of
        your rights or obligations under these Terms without our consent. We reserve all rights not expressly granted to
        you.
      </Typography>
    </InfoPageRoot>
  );
};

export default Terms;
