import { FunctionComponent, ReactNode, useRef, useState } from 'react';
import styles from './CleaningTypesWrapped.module.scss';
import ArrowLeft from '../../../../../Assets/Images/svg/arrowLeft.svg';
import SwipeableViews from 'react-swipeable-views';
import AnalyticsManager from '../../../../../Utils/AnalyticsManager';

interface CleaningTypesWrappedProps {
  children: ReactNode[];
}

const CleaningTypesWrapped: FunctionComponent<CleaningTypesWrappedProps> = ({
  children,
}: CleaningTypesWrappedProps) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [activeStep, setActiveStep] = useState(0);

  const onPrev = () => {
    AnalyticsManager.logIconButtonClick('Prev cleaning type');

    const prevStep = activeStep - 1;
    if (prevStep >= 0) {
      setActiveStep(prevStep);
    }
  };

  const onNext = () => {
    AnalyticsManager.logIconButtonClick('Next cleaning type');

    const newStep = activeStep + 1;
    if (newStep < children.length) {
      setActiveStep(newStep);
    }
  };

  const onChangeSlide = (index: number) => {
    if (index < children.length || index >= 0) {
      setActiveStep(index);
    }
  };

  return (
    <div className={styles.typesSmall}>
      <div className={styles.carousel} ref={carouselRef}>
        <SwipeableViews slideClassName={styles.slideStyle} onChangeIndex={onChangeSlide} index={activeStep}>
          {children.map((item, index) => (
            <div key={`${index}-slide`} className={styles.slide}>
              {item}
            </div>
          ))}
        </SwipeableViews>
      </div>
      <div className={styles.btnContainer}>
        <button
          aria-label="previous cleaning type"
          onClick={onPrev}
          className={styles.left}
          disabled={activeStep === 0}>
          <ArrowLeft />
        </button>
        <button
          aria-label="next cleaning type"
          onClick={onNext}
          className={styles.right}
          disabled={activeStep === children.length - 1}>
          <ArrowLeft />
        </button>
      </div>
    </div>
  );
};

export default CleaningTypesWrapped;
