import { FunctionComponent } from 'react';
import { Divider, Typography } from '@mui/material';
import styles from './Receipt.module.scss';
import { SelectedBookingInfo } from '../../../types/StateTypes';
import { PriceValues } from '../../../types/Price';
import { Coupon } from '../../../types/Coupon';
import { InfoIconTooltip } from '../../Components';
import SecondaryButton from '../../Components/SecondaryButton/SecondaryButton';
import AddonsReceipt from './AddonsReceipt';
import PromoCodeManager from '../../../Mobx/BookingFlowData/PromoCodeManager';
import { observer } from 'mobx-react';
import SelectedOptionsManager from '../../../Mobx/BookingFlowData/SelectedOptionsManager';
import PriceManager from '../../../Mobx/BookingFlowData/PriceManager';

interface OwnProps {
  isRecurring: boolean;
}

const CleanPrice: FunctionComponent<OwnProps> = ({ isRecurring }: OwnProps) => {
  const selectedCleaning: SelectedBookingInfo = SelectedOptionsManager.getSelected();
  const price = PriceManager.getPrice();
  const priceOneTime: PriceValues = price.oneTime;
  const priceRecurring: PriceValues = price.recurring;
  const tips: number = price.tips;
  const promotion: Coupon | undefined = PromoCodeManager.getAppliedCoupon();
  const priceValues = isRecurring ? priceRecurring : priceOneTime;

  return (
    <>
      <Typography className={styles.prices}>
        <span>Service</span>
        <span>${priceValues.servicePrice}</span>
      </Typography>

      <AddonsReceipt
        subTotalTitle={isRecurring ? 'Base service + customizations' : ''}
        title={isRecurring ? 'Customization' : ''}
        isRecurringTab={isRecurring}
      />

      {promotion && priceValues.discounts > 0 && (
        <>
          <div className={styles.promoDiscounts}>
            <div className={styles.promoLeft}>
              <Typography>{`Discount code ${promotion.couponData.name}`}</Typography>
              <SecondaryButton onClick={PromoCodeManager.removeCoupon} text="Remove" />
            </div>
            <Typography>-${priceValues.discounts}</Typography>
          </div>
          <Typography className={styles.promoName}>{promotion.couponData.description}</Typography>
          <Divider></Divider>
          <Typography className={styles.prices}>
            <span>Subtotal</span>
            <span>${priceValues.subtotal}</span>
          </Typography>
        </>
      )}
      <Typography className={styles.prices}>
        <span>Sales Tax</span>
        <span>${priceValues.taxAmount}</span>
      </Typography>
      <div className={styles.tipContainer}>
        <Typography>
          <span className={styles.tipDataContainer}>
            <span className={styles.tipTitle}>Tip</span>
            <InfoIconTooltip
              text={'Tips are appreciated, but not required. You can also tip in cash after the clean.'}
            />
          </span>
          <span>${tips}</span>
        </Typography>
        {selectedCleaning.cleaningType.isRecurring && (
          <div className={styles.tipInfoContainer}>
            <InfoIconTooltip
              text={'This tip will be applied to all recurring cleanings. You can change this value later.'}
            />
          </div>
        )}
      </div>
      <Divider></Divider>
      <Typography className={styles.totalPrice}>
        <span>Total</span>
        <span>${priceValues.total}</span>
      </Typography>
    </>
  );
};

export default observer(CleanPrice);
