import { FunctionComponent } from 'react';
import moment from 'moment-timezone';
import { Grid } from '@mui/material';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import styles from './CalendarNavigation.module.scss';
import { sm } from '../../../../Assets/Styles/constants';
import { MONTHS_TO_DISPLAY } from '../../../../Config';
import { Hidden } from '../../../Components';

interface CalendarNavigationProps {
  monthsToDisplay: string[];
  displayDate: number;
  onMonthChange: (monethNumbe: number) => void;
  disableNav: boolean;
}

const CalendarNavigation: FunctionComponent<CalendarNavigationProps> = ({
  monthsToDisplay,
  displayDate,
  onMonthChange,
  disableNav,
}: CalendarNavigationProps) => {
  const maxMonthIndex = Number(MONTHS_TO_DISPLAY || 3) - 1;
  const prevMonth = monthsToDisplay[displayDate - 1]
    ? moment(monthsToDisplay[displayDate - 1], 'YYYY-MM').format('MMMM')
    : '';
  const currentMonthDisplayed = monthsToDisplay[displayDate]
    ? moment(monthsToDisplay[displayDate], 'YYYY-MM').format('MMMM')
    : '';
  const nextMonth = monthsToDisplay[displayDate + 1]
    ? moment(monthsToDisplay[displayDate + 1], 'YYYY-MM').format('MMMM')
    : '';

  return (
    <Grid container className={styles.calendarNavigationContainer}>
      <Grid item xs={4} className={styles.prevMonth}>
        {displayDate === 0 ? null : (
          <button className={styles.prevNextBtn} onClick={() => onMonthChange(displayDate - 1)} disabled={disableNav}>
            <ChevronLeftRoundedIcon className={styles.clickableNavComponent} />
            <Hidden screenWidthToShow={sm}>
              <div className={styles.clickableNavComponent}>{prevMonth}</div>
            </Hidden>
          </button>
        )}
      </Grid>
      <Grid item xs={4} className={styles.displayedMonth}>
        <div>{currentMonthDisplayed}</div>
      </Grid>
      <Grid item xs={4} className={styles.nextMonth}>
        {displayDate === maxMonthIndex ? null : (
          <button className={styles.prevNextBtn} onClick={() => onMonthChange(displayDate + 1)} disabled={disableNav}>
            <Hidden screenWidthToShow={sm}>
              <div className={styles.clickableNavComponent}>{nextMonth}</div>
            </Hidden>
            <ChevronRightRoundedIcon className={styles.clickableNavComponent} />
          </button>
        )}
      </Grid>
    </Grid>
  );
};

export default CalendarNavigation;
