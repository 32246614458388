export enum StripeCardElements {
  cardNumber = 'cardNumber',
  cardExpiry = 'cardExpiry',
  cardCvc = 'cardCvc',
}

export interface StripeCardObject {
  cardNumber: StripeEvent | undefined;
  cardExpiry: StripeEvent | undefined;
  cardCvc: StripeEvent | undefined;
}

export interface FormInputsValidation {
  email: boolean;
  phone: boolean;
  firstName: boolean;
  lastName: boolean;
  leadSource: boolean;
  cardNumber: boolean;
  cardExpiry: boolean;
  cardCvc: boolean;
}

export interface StripeEvent {
  complete: boolean;
  elementType: string;
  error:
    | {
        message: string;
        code: string;
      }
    | undefined;
}
