import { FunctionComponent, Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import RecalculatePrice from './Client/Components/Utils/RecalculatePrice';
import AppRoutes from './AppRoutes';
import AuthSetup from './Client/Components/Utils/AuthSetup';
import theme from './Client/Components/Utils/MuiTheme';
import SetAnalytics from './Client/Components/Utils/SetAnalytics';
import AppPageScrollContent from './AppPageScrollContent';
import { DebugLog } from './Utils/ErrorLog';
import { AirbnbContactPopup, Alert } from './Client/Pages/lazyImports';
import AlertContextImpl from './Client/Contexts/Alert/AlertContextImplementation';
import BannerAlertContextImpl from './Client/Contexts/BannerAlert/BannerAlertContextImplementation';
import BookingKeyContextImpl from './Client/Contexts/BookingKeyContext/BookingKeyContextImpl';
import SubmitDataContextImpl from './Client/Contexts/SubmitDataContext/SubmitDataContextImpl';

declare global {
  interface Window {
    dataLayer: unknown[];
    scrollToTop: (isSmooth?: boolean) => void;
  }
}

const App: FunctionComponent = () => {
  useEffect(() => {
    DebugLog(process.env.NODE_ENV);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <SetAnalytics />
        <RecalculatePrice />
        <AppPageScrollContent>
          <Suspense>
            <AlertContextImpl>
              <BannerAlertContextImpl>
                <BookingKeyContextImpl>
                  <SubmitDataContextImpl>
                    <AppRoutes />
                  </SubmitDataContextImpl>
                </BookingKeyContextImpl>

                <AuthSetup />

                <AirbnbContactPopup />
                <Alert />
              </BannerAlertContextImpl>
            </AlertContextImpl>
          </Suspense>
        </AppPageScrollContent>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
