import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getCartFromBackendWithCatch } from '../../Utils/ApiCalls/ApiCalls';
import { AbandonedCartInfo } from '../../types/AbandonedCart';
import PaymentFormDataManager from '../../Mobx/BookingFlowData/PaymentFormDataManager';

export const useCartData = () => {
  const [cartData, setCartData] = useState<AbandonedCartInfo | undefined>(undefined);
  const [isLoading, setIdLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const cartId = searchParams.get('cartId');
  const { pathname } = useLocation();

  const handleCartId = async (cartId: string) => {
    const cartData = await getCartFromBackendWithCatch(cartId);
    if (cartData?.allData) {
      PaymentFormDataManager.setData(cartData.allData.leadSource, cartData.allData.personalInfo);
      setCartData(cartData.allData);
    } else {
      setSearchParams();
    }
  };

  useEffect(() => {
    if (pathname === '/bookclean' && cartId) {
      setIdLoading(true);
      void handleCartId(cartId).finally(() => {
        setIdLoading(false);
      });
    } else {
      setIdLoading(false);
    }
  }, [pathname, cartId]);

  return { cartData, isLoading };
};
