import { makeAutoObservable } from 'mobx';
import { CleaningData, CleaningsData } from '../../types/CleaningData';
import { CustomerPortalDataState } from '../../types/CustomerPortalDataState';
import { Status } from '../../types/SubmitDataTypes';
import { getCleaningData } from '../../Utils/ApiCalls/CustomerPortal';
import { LogError } from '../../Utils/ErrorLog';

class CustomerPortalManager {
  private state: CustomerPortalDataState = {
    cleaningData: undefined,
    status: Status.unset,
    cleaningsMap: undefined,
  };

  constructor() {
    makeAutoObservable(this);
  }

  public resetState = () => {
    this.state = {
      cleaningData: undefined,
      status: Status.unset,
      cleaningsMap: undefined,
    };
  };

  public getStatus = () => {
    return this.state.status;
  };

  public getCleaningsMap = () => {
    return this.state.cleaningsMap;
  };

  public getCleaningById = (id: string) => {
    return this.state.cleaningsMap?.get(id) || undefined;
  };

  public getCleaningData = () => {
    return this.state.cleaningData;
  };

  public fetchCleaningData = async (email?: string) => {
    this.InProgressGetData();
    const data = await this.fetchCleaningDataFromServer(email);
    if (!data) {
      this.ErrorGetData();
      return;
    }

    const cleaningsMap = this.makeCleaningMap(data);
    this.SuccesGetData(data, cleaningsMap);
  };

  private fetchCleaningDataFromServer = async (email?: string) => {
    try {
      return await getCleaningData(email);
    } catch (error) {
      LogError('getCleaningData', { message: JSON.stringify(error) }, true);
      return undefined;
    }
  };

  private makeCleaningMap = (cleaningsData: CleaningsData) => {
    const cleaningMap = new Map<string, CleaningData>();
    cleaningsData?.futureCleanings?.map((cleaning) => cleaningMap.set(cleaning.id, cleaning));
    cleaningsData?.previousCleanings?.map((cleaning) => cleaningMap.set(cleaning.id, cleaning));
    return cleaningMap;
  };

  private ErrorGetData = () => {
    this.state = { cleaningsMap: undefined, cleaningData: undefined, status: Status.error };
  };

  private SuccesGetData = (data: CleaningsData | undefined, mapData: Map<string, CleaningData> | undefined) => {
    this.state = { cleaningData: data, status: Status.success, cleaningsMap: mapData };
  };

  private InProgressGetData = () => {
    this.state.status = Status.inProgress;
  };
}

export default new CustomerPortalManager();
