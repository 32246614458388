import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import styles from './BookSectionWrapper.module.scss';

interface BookSectionWrapper {
  children: React.ReactNode;
  sectionName?: string;
  subText?: string;
}

const BookSectionWrapper: FunctionComponent<BookSectionWrapper> = ({
  children,
  sectionName,
  subText,
}: BookSectionWrapper) => {
  return (
    <div className={styles.box}>
      {sectionName && (
        <div className={styles.boxTitleContainer}>
          <Typography variant="h4" align="center" classes={{ root: styles.questionsContainer }}>
            {sectionName}
          </Typography>
          {subText && (
            <Typography variant="h6" classes={{ root: styles.subText }}>
              {subText}
            </Typography>
          )}
        </div>
      )}
      <div className={styles.boxBody}>{children}</div>
    </div>
  );
};

export default BookSectionWrapper;
