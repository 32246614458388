import { FunctionComponent, ReactNode } from 'react';
import { Grid, Typography } from '@mui/material';
import styles from './InfoPageRoot.module.scss';

interface InfoPageRoot {
  mainTitle: string;
  children: ReactNode;
}

const InfoPageRoot: FunctionComponent<InfoPageRoot> = ({ mainTitle, children }: InfoPageRoot) => {
  return (
    <Grid container justifyContent="center" alignItems="center" className={styles.termsRoot}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        item
        xs={12}
        sm={10}
        md={9}
        lg={9}
        className={styles.container}>
        <Typography variant="h1" className={styles.mainTitle}>
          {mainTitle}
        </Typography>
        {children}
      </Grid>
    </Grid>
  );
};

export default InfoPageRoot;
