import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import styles from './Article.module.scss';

const GreenCleaning: FunctionComponent = () => {
  const paragraph = 'body1';
  const title = 'h3';
  const subtitle = 'h3';

  return (
    <div>
      <Typography className={styles.paragraph} variant={paragraph}>
        Green cleaning is a cleaning process that’s safer for the environment and doesn’t omit pollutants into the air.
        Not only is green cleaning better for our environment and planet, but it is also better for your health and
        safety in the long run.
      </Typography>

      <Typography className={styles.boldTitle} variant={title}>
        So why should you care?
      </Typography>

      <Typography className={styles.subTitle} variant={subtitle}>
        You and your family’s health
      </Typography>
      <Typography className={styles.paragraph} variant={paragraph}>
        When you see that “Warning!” label, how could that be a good sign? Bleach, magic erasers, and other common
        household cleaners have many harmful chemicals that should can be dangerious if consumed or exposed to. Even
        touching these products can produce rashes, burns, and other skin damage. Children and pets are already hard to
        keep up with, and with them putting everything in their mouths, it’s just another reason to switch to
        eco-friendly alternatives to keep everyone safe.
      </Typography>

      <Typography className={styles.subTitle} variant={subtitle}>
        Clean up your indoor air
      </Typography>
      <Typography className={styles.paragraph} variant={paragraph}>
        Many seemingly ok household cleaning products release pollutants when used which are harmful to breathe. These
        chemicals can linger in the air for a while even when the smell goes away. Over time this can irritate your
        lungs and cause coughing, sneezing, wheezing, headaches, and can even cause chronic illnesses.
      </Typography>

      <Typography className={styles.subTitle} variant={subtitle}>
        Help the environment
      </Typography>
      <Typography className={styles.paragraph} variant={paragraph}>
        When you use green and eco-friendly products, you are also helping reduce our impact on the environment. Green
        cleaners are typically manufactured with more sustainable practices, the ingredients are naturally derived, and
        the containers are often biodegradable. When you make your own products and reuse spray bottles, it may not seem
        like much, but it’s saving a lot of plastic. Plus, your vocal support for green cleaning can have a ripple
        effect and influence others to do the same!
      </Typography>

      <Typography className={styles.subTitle} variant={subtitle}>
        It’s simpler and more cost effective
      </Typography>
      <Typography className={styles.paragraph} variant={paragraph}>
        Have you ever stared at the cleaning products aisle and felt overwhelmed? Don’t worry, that’s not just you. Most
        of those cleaning products have the same harmful chemicals that are just repackaged and marketed differently.
        When it comes to cleaning, simple is always the best approach, and green cleaning takes this philosophy to
        heart. Some of the simplest green cleaning products are made with dish soap, baking soda, and water. Not only is
        it safer, it’s more cost-effective, too!
      </Typography>
    </div>
  );
};

export default GreenCleaning;
