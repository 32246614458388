import { useEffect, FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import BookingProgressDesktop from './BookingProgressDesktop/BookingProgressDesktop';
import BookingProgressMobile from './BookingProgressMobile/BookingProgressMobile';
import { AddressFormRegisteredFields } from '../../../types/AddressFormTypes';
import { LogError } from '../../../Utils/ErrorLog';
import { md } from '../../../Assets/Styles/constants';
import { observer } from 'mobx-react';
import AddressFormDataManager from '../../../Mobx/BookingFlowData/AddressFormDataManager';
import CalendarTimesManager from '../../../Mobx/FetchingManagers/CalendarTimesManager';
import CleanDateTimeManager from '../../../Mobx/BookingFlowData/CleanDateTimeManager';
import BookingFlowStateManager from '../../../Mobx/BookingFlowStateManager';

const BookingProgress: FunctionComponent = () => {
  const [showDesktop, setShowDesktopp] = useState(window.innerWidth > md);
  const addressData = AddressFormDataManager.getAddressFormValues();
  const dateTimeValue = CleanDateTimeManager.getDateAndTimeSlot();
  const methods = useForm<AddressFormRegisteredFields>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: false,
    shouldUnregister: false,
    defaultValues: addressData,
  });

  useEffect(() => {
    void fetchTimes();
    return () => {
      BookingFlowStateManager.resetStateAfterBookingFlow();
    };
  }, []);

  const fetchTimes = async () => {
    await CalendarTimesManager.fetchAvailableTimes();
    if (dateTimeValue) {
      const isDateTimeAvailable = CalendarTimesManager.isDateTimeAvailable(dateTimeValue);
      if (!isDateTimeAvailable) {
        CleanDateTimeManager.resetState();
      }
    }
  };

  const onSubmitAddressForm = (validCallBack?: () => void) => {
    methods
      .trigger()
      .then((isValid) => {
        if (isValid) {
          const values = methods.getValues();
          AddressFormDataManager.updateAddressFormFields(values);
          validCallBack && validCallBack();
        }
      })
      .catch((e) => LogError('onSubmitAddressForm', { message: JSON.stringify(e) }, true));
  };

  const getAddressFormValues = () => {
    const values = methods.getValues();
    AddressFormDataManager.updateAddressFormFields(values);
  };

  const handleResize = () => {
    const screenWidth = window.innerWidth;
    setShowDesktopp(screenWidth > md);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {showDesktop ? (
        <BookingProgressDesktop onSubmit={onSubmitAddressForm} methods={methods} />
      ) : (
        <BookingProgressMobile
          getAddressFormValues={getAddressFormValues}
          onSubmitAddressForm={onSubmitAddressForm}
          methods={methods}
        />
      )}
    </>
  );
};

export default observer(BookingProgress);
