import { FunctionComponent, useEffect } from 'react';
import styles from './RecurringCleanInfo.module.scss';
import { CheckListTable } from '../../Components';
import { recurring } from '../../../Assets/Data/CleanDescriptionText';
import CleanSelection from '../../Containers/CleaningPageParts/CleanSelection/CleanSelection';
import ResetYourHome from '../../Containers/CleaningPageParts/ResetYourHome/ResetYourHome';
import MainInfo from '../../Containers/CleaningPageParts/MainInfo/MainInfo';
import { observer } from 'mobx-react';
import SelectedOptionsManager from '../../../Mobx/BookingFlowData/SelectedOptionsManager';
import { initialRecurring } from '../../../Mobx/States/InitialSelectedState';
import { mainTitleSubText } from '../../../Assets/Data/MainInfo';
import EmbeddedReviews from '../../Containers/CleaningPageParts/GoogleReview/EmbeddedReviews/EmbeddedReviews';

const RecurringCleanInfo: FunctionComponent = () => {
  useEffect(() => {
    SelectedOptionsManager.updateSelectedAll(initialRecurring);
  }, []);

  return (
    <div className={styles.root}>
      <CleanSelection
        headerText="No time to clean? Get on our schedule."
        subHeaderText={mainTitleSubText}
        imageSrc=""
        imagePath="recurringCleanArt1.svg"
        isDisableChecklist={true}
        dropShadow={true}
      />
      <MainInfo mainText={recurring.text} title={recurring.title} imagePath="recurringCleanArt2.svg" imageSrc="" />
      <CheckListTable name="Recurring" recurring={true} />
      <EmbeddedReviews />
      <ResetYourHome />
    </div>
  );
};

export default observer(RecurringCleanInfo);
