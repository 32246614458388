import { withStyles } from '@mui/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { mainColor, disabledColor, errorBorderColor, borderColor } from '../../../../Assets/Styles/colors';

export type MainTextFieldProps = Partial<TextFieldProps>;

const MainTextField = withStyles(() => ({
  root: {
    'width': '100%',
    'color': mainColor,
    'backgroundColor': '#ffffff',
    '& p': {
      fontSize: '15px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: borderColor,
        top: '0',
        // backgroundColor: '#ffffff',
      },
      '& legend': {
        display: 'none',
      },
      'borderRadius': '0px',
      '&:hover fieldset': {
        zIndex: '10',
        borderColor: mainColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: mainColor,
        zIndex: '11',
      },
      '&.Mui-disabled': {
        'backgroundColor': disabledColor,
        'borderRadius': '4px',
        '& fieldset': {
          borderColor: borderColor,
        },
      },
      '&.Mui-error': {
        '& fieldset': {
          borderColor: errorBorderColor,
          zIndex: '10',
        },
      },
      '&.MuiInputBase-multiline': {
        borderRadius: '4px',
      },
    },
  },
}))(TextField);

export default MainTextField;
