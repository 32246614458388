import { BookingChoiceKeys } from './StripeDataState';

export enum AdditionalBookingChoiceKeys {
  city = 'city',
  cleaningType = 'cleaningType',
  cleaningFrequency = 'cleaningFrequency',
  squareFootage = 'squareFootage',
}

export const SelectedBookingInfoItems = Object.assign({}, BookingChoiceKeys, AdditionalBookingChoiceKeys);

export enum ApartmentType {
  Studio,
  OneB,
  TwoB,
  ThreeB,
}

export enum CleaningFrequency {
  oneTime = 'One Time',
  weekly = 'Weekly',
  biweekly = 'Biweekly',
  monthly = 'Monthly',
}

export enum CleaningType {
  deep = 'Deep Clean',
  recurring = 'Recurring Clean',
  moveOut = 'Move Out Clean',
  rental = 'Airbnb/Rental Clean',
}
