import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import styles from './Article.module.scss';

const CleaningMoreEfficiently: FunctionComponent = () => {
  const paragraph = 'body1';

  return (
    <div>
      <Typography className={styles.paragraph1} variant={paragraph}>
        Cleaning is always going to take time, especially if you want to do it right. We’ve compiled some tips on how to
        clean more efficiently and save yourself time, stress, energy, and planning. These techniques are proven, and we
        train our own cleaning techs with these methods!
      </Typography>
      <ol>
        <li>
          <Typography className={styles.title} variant={paragraph}>
            Declutter first
          </Typography>

          <Typography className={styles.paragraph1} variant={paragraph}>
            To make the best use of your time and keep the focus on cleaning, we suggest putting things away and
            decluttering your space first. It’s very easy to get distracted nowadays, so having to move things out of
            the way every five minutes will only make cleaning more annoying and take longer.
          </Typography>
        </li>

        <li>
          <Typography className={styles.title} variant={paragraph}>
            Clean from left to right, top to bottom
          </Typography>

          <Typography className={styles.paragraph1} variant={paragraph}>
            Ever started cleaning only to forget what you’ve already cleaned? No worries, the solution is to start at
            one point of the room and move to the right, starting at the top and cleaning towards the bottom. You can
            even divide the room into “slices” to really focus on one area at a time.{' '}
          </Typography>
        </li>

        <li>
          <Typography className={styles.title} variant={paragraph}>
            Focus on one room at a time
          </Typography>

          <Typography className={styles.paragraph1} variant={paragraph}>
            Back to the idea of keeping your focus, we also suggest cleaning one room at a time. Getting a single room
            in one go means you won’t have to go back over your work and gives you a sense of accomplishment early on in
            your clean. Some people will say this is wrong, and depending on the task, they might be right! If you want
            to just dust or just declutter, then focusing on one task at a time will be more efficient (still cleaning
            left to right, top to bottom).
          </Typography>
        </li>

        <li>
          <Typography className={styles.title} variant={paragraph}>
            Save vacuuming and mopping for last
          </Typography>

          <Typography className={styles.paragraph1} variant={paragraph}>
            As you clean, dust and dirt will fall to the ground. Unless you have pets waiting for the crumbs to fall,
            don’t worry about vacuuming it right away. To save yourself time, vacuum only after you’ve finished cleaning
            a room or area. Dust bunnies can collect near the walls and in corners, so make sure to get those spots!
            Save the mopping for after vacuuming if you plan on doing it.
          </Typography>
        </li>
      </ol>
    </div>
  );
};

export default CleaningMoreEfficiently;
