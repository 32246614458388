import { FunctionComponent } from 'react';
import AnalyticsManager from '../../../Utils/AnalyticsManager';
import styles from './SecondaryButton.module.scss';

interface SecondaryButtonProps {
  text: string;
  onClick: () => void;
  logText?: string;
}

const SecondaryButton: FunctionComponent<SecondaryButtonProps> = ({ text, onClick, logText }: SecondaryButtonProps) => {
  const onClickWithLog = () => {
    AnalyticsManager.logButtonClick(logText || text);
    onClick();
  };

  return (
    <button className={styles.btn} onClick={onClickWithLog}>
      {text}
    </button>
  );
};

export default SecondaryButton;
