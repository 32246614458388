import Tab from '@mui/material/Tab';
import { TabList, TabContext, TabPanel } from '@mui/lab';
import { useState } from 'react';
import { FunctionComponent } from 'react';
import CleanPrice from '../Receipt/CleanPrice';
import styles from './PriceTabs.module.scss';

const PriceTabs: FunctionComponent = () => {
  const [value, setValue] = useState('1');

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  return (
    <div>
      <TabContext value={value}>
        <div>
          <TabList
            classes={{ indicator: styles.indicator }}
            onChange={(event, value) => handleChange(value as string)}
            aria-label="price tabs">
            <Tab classes={{ selected: styles.tabButton }} label="Initial Deep Clean" value="1" />
            <Tab classes={{ selected: styles.tabButton }} label="Recurring Clean" value="2" />
          </TabList>
        </div>
        <TabPanel value="1">
          <CleanPrice isRecurring={false} />
        </TabPanel>
        <TabPanel value="2">
          <CleanPrice isRecurring={true} />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default PriceTabs;
