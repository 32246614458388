import { cleaningFieldsInfo } from '../Assets/Data/BookingInformation';
import { SelectedBookingInfoItems } from '../types/BookingFieldsEnum';
import { SquareFootageOptions } from '../types/BookingFormDataTypes';
import { SelectedBookingInfo, SelectedCleaningInfo } from '../types/StateTypes';

export const toSelectedBookingInfoFromObject = (
  selectedCleaningInfo: SelectedCleaningInfo,
  currentSelected: SelectedBookingInfo
): SelectedBookingInfo => {
  const apts = cleaningFieldsInfo.apartmentType.options.filter((op) => op.key === selectedCleaningInfo.apartmentType);
  const apt = apts.length > 0 ? apts[0] : cleaningFieldsInfo.apartmentType.options[0];

  const howCleanOpts = cleaningFieldsInfo.howCleanIsHome.options.filter(
    (op) => op.key === selectedCleaningInfo.howCleanIsHome
  );
  const howClean = howCleanOpts.length > 0 ? howCleanOpts[0] : cleaningFieldsInfo.howCleanIsHome.options[0];

  const bathrooms = cleaningFieldsInfo.bathrooms.options.filter((op) => op.key === selectedCleaningInfo.bathrooms);
  const bathroom = bathrooms.length > 0 ? bathrooms[0] : cleaningFieldsInfo.bathrooms.options[0];

  const halfBathrooms = cleaningFieldsInfo.halfBathrooms.options.filter(
    (op) => op.key === selectedCleaningInfo.halfBathrooms
  );
  const halfBathroom = halfBathrooms.length > 0 ? halfBathrooms[0] : cleaningFieldsInfo.halfBathrooms.options[0];

  const threeForthBathrooms = cleaningFieldsInfo.threeForthBathrooms.options.filter(
    (op) => op.key === selectedCleaningInfo.threeForthBathrooms
  );
  const threeForthBathroom =
    threeForthBathrooms.length > 0 ? threeForthBathrooms[0] : cleaningFieldsInfo.threeForthBathrooms.options[0];

  const commonAreas = cleaningFieldsInfo.commonAreas.options.filter(
    (op) => op.key === selectedCleaningInfo.commonAreas
  );
  const commonArea = commonAreas.length > 0 ? commonAreas[0] : cleaningFieldsInfo.commonAreas.options[0];

  const staircases = cleaningFieldsInfo.staircase.options.filter((op) => op.key === selectedCleaningInfo.staircase);
  const staircase = staircases.length > 0 ? staircases[0] : cleaningFieldsInfo.staircase.options[0];

  let squareFootageStr = selectedCleaningInfo.squareFootage ?? '';
  let squareFootageNum = squareFootageStr ? parseInt(squareFootageStr) : 0;
  if (isNaN(squareFootageNum)) {
    squareFootageNum = 0;
    squareFootageStr = '';
  }

  const squareFootage: SquareFootageOptions = {
    key: squareFootageStr,
    value: squareFootageNum,
    numberKey: squareFootageNum,
  };

  const cleaningType = currentSelected.cleaningType.isRecurring ? currentSelected.cleaningType : getRecurringGroup();

  const extraKitchens = cleaningFieldsInfo.extraKitchen.options.filter(
    (op) => op.key === selectedCleaningInfo.extraKitchen
  );
  const extraKitchen = extraKitchens.length > 0 ? extraKitchens[0] : cleaningFieldsInfo.extraKitchen.options[0];

  const kitchenLivingRoomDinings = currentSelected.kitchenLivingRoomDining;

  const selected: SelectedBookingInfo = {
    [SelectedBookingInfoItems.apartmentType]: apt,
    [SelectedBookingInfoItems.bathrooms]: bathroom,
    [SelectedBookingInfoItems.halfBathrooms]: halfBathroom,
    [SelectedBookingInfoItems.threeForthBathrooms]: threeForthBathroom,
    [SelectedBookingInfoItems.commonAreas]: commonArea,
    [SelectedBookingInfoItems.staircase]: staircase,
    [SelectedBookingInfoItems.squareFootage]: squareFootage,
    [SelectedBookingInfoItems.cleaningType]: cleaningType,
    [SelectedBookingInfoItems.cleaningFrequency]: cleaningType.frequency[0],
    [SelectedBookingInfoItems.howCleanIsHome]: howClean,
    [SelectedBookingInfoItems.extraKitchen]: extraKitchen,

    [SelectedBookingInfoItems.kitchenLivingRoomDining]: kitchenLivingRoomDinings,
  };

  return selected;
};

const getRecurringGroup = () => {
  const rec = cleaningFieldsInfo.cleaningType.options.findIndex((op) => op.isRecurring);
  const recur = rec !== -1 ? cleaningFieldsInfo.cleaningType.options[rec] : cleaningFieldsInfo.cleaningType.options[0];
  return recur;
};
