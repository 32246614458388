import { ContactUsData } from '../../types/ContactUsData';

export const contactUsData: ContactUsData = {
  mainTitle: 'Have questions? Contact us!',
  formData: {
    name: {
      label: 'Name',
      name: 'name',
      placeholder: 'Enter your name',
      rules: { required: true },
      errorMessage: 'Name is required',
    },
    email: {
      label: 'Email',
      name: 'email',
      placeholder: 'Contact email',
      rules: { required: true },
      errorMessage: 'Email is invalid',
    },
    phone: {
      label: 'Phone',
      name: 'phone',
      placeholder: 'Phone number',
      rules: { required: false },
      errorMessage: 'Phone number is invalid',
    },
    subject: {
      label: 'Subject',
      name: 'subject',
      placeholder: 'Enter subject line',
      rules: { required: true },
      errorMessage: 'Subject is required',
    },
    question: {
      label: 'Question',
      name: 'question',
      placeholder: 'Type your question',
      rules: { required: true },
      errorMessage: 'Question text is required',
    },
  },
};
