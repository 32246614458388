import { FunctionComponent } from 'react';
import { general } from '../../../../Assets/Data/faq';
import { MainLink } from '../../../Components';
import MainPageFaq from '../../../Pages/Faq/MainPageFaq';
import Titles from '../Titles/Titles';
import styles from './Faq.module.scss';

const Faq: FunctionComponent = () => {
  return (
    <div className={styles.root}>
      <div className={styles.faqContainer}>
        <Titles title1="QUESTIONS" title2="Frequently Asked Questions" />
        <div className={styles.faqs}>
          <MainPageFaq data={general} />
        </div>
        <div className={styles.linkBtn}>
          <p>
            Have more questions?{' '}
            <MainLink linkText="Check more FAQs" toPath="/faq" isInternalLink>
              Check more FAQs
            </MainLink>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Faq;
