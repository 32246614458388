import { CleaningFrequency } from '../types/BookingFieldsEnum';
import { ProductPrice } from '../types/StripeDataState';

export const getPrice = (
  prices: Record<string, ProductPrice>,
  selectedCleaningFrequency: CleaningFrequency
): ProductPrice | undefined => {
  const allOptions = prices['all'];
  if (allOptions) {
    return allOptions;
  } else {
    const option = prices[selectedCleaningFrequency];
    return option;
  }
};
