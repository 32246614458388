import Switch from '@mui/material/Switch';
import { FunctionComponent } from 'react';
import styles from './SwitchButton.module.scss';

interface SwitchButtonProps {
  isChecked: boolean;
  onCheckChange: (isChecked: boolean) => void;
  title?: string;
}

const SwitchButton: FunctionComponent<SwitchButtonProps> = ({ isChecked, onCheckChange, title }: SwitchButtonProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCheckChange(event.target.checked);
  };

  return (
    <div className={styles.root}>
      <Switch checked={isChecked} onChange={handleChange} inputProps={{ 'aria-label': 'switch' }} />
      {title && <p className={styles.title}>{title}</p>}
    </div>
  );
};

export default SwitchButton;
