import { FunctionComponent, useMemo } from 'react';
import { IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import styles from './NumberInput.module.scss';
import { BookingTypeOptions } from '../../../../types/BookingFormDataTypes';
import { InfoIconTooltip } from '../../../Components';

interface NumberInputProps {
  label: string;
  tooltipText?: string;
  onBookingOptionChange: (setValue: BookingTypeOptions, choiceKey: string) => void;
  name: string;
  descriptionText?: string;
  selectedOptionString: BookingTypeOptions;
  choiceKey: string;
  options: BookingTypeOptions[];
}

const NumberInput: FunctionComponent<NumberInputProps> = ({
  label,
  tooltipText,
  choiceKey,
  onBookingOptionChange,
  selectedOptionString,
  options,
}: NumberInputProps) => {
  const sortedOptions = useMemo(() => {
    return [...options].sort((a, b) => a.value - b.value);
  }, [options]);

  const changeValue = (valueToAdd: number) => {
    const newValue = selectedOptionString.value + valueToAdd;
    if (newValue > sortedOptions[sortedOptions.length - 1].value || newValue < sortedOptions[0].value) {
      return;
    }
    const newValueToSet = options.find((item) => item.value === newValue);
    if (newValueToSet) {
      onBookingOptionChange(newValueToSet, choiceKey);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.textAndTooltip}>
        <Typography className={styles.label} gutterBottom={true}>
          {label}
        </Typography>
        {tooltipText && <InfoIconTooltip text={tooltipText} />}
      </div>
      <div className={styles.buttons}>
        <IconButton aria-label="decrease" onClick={() => changeValue(-1)} className={styles.minus}>
          <RemoveIcon classes={{ root: styles.rootIconStyle }} />
        </IconButton>
        <Typography className={styles.value}>{selectedOptionString.value}</Typography>
        <IconButton aria-label="increase" onClick={() => changeValue(1)} className={styles.plus}>
          <AddIcon classes={{ root: styles.rootIconStyle }} />
        </IconButton>
      </div>
    </div>
  );
};

export default NumberInput;
